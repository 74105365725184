import React from "react";
import { Box, Typography } from "@mui/material";

const PainScale = ({ value }) => {
  let color = "";
  let gradient = "";

  // Determine color and gradient based on the pain level
  if (value >= 7) {
    color = "error.dark"; // red
    gradient = "linear-gradient(180deg, #FFCDD2 0%, #F44336 100%)";
  } else if (value >= 4) {
    color = "info.dark"; // blue
    gradient = "linear-gradient(180deg, #BBDEFB 0%, #2196F3 100%)";
  } else {
    color = "success.dark"; // green
    gradient = "linear-gradient(180deg, #C8E6C9 0%, #4CAF50 100%)";
  }

  // Calculate the scale values for the current color
  const scaleValues = [];
  for (let i = 1; i <= 15; i++) {
    const scaleColor =
      i >= 10 ? "error.dark" : i >= 6 ? "info.dark" : "success.dark";
    scaleValues.push(
      <Box
        key={i}
        sx={{
          backgroundColor: scaleColor,
          display: "inline-block",
          width: "5%",
          height: "10px",
          margin: "0 2px",
        }}
      />
    );
  }

  return (
    <Box sx={{ position: "relative", textAlign: "center" }}>
      <Box
        sx={{
          width: "100px",
          height: "100px",
          background: gradient,
          clipPath: "polygon(50% 0%, 0% 100%, 100% 100%)",
          display: "inline-block",
          position: "relative",
          marginBottom: "10px",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            bottom: "-10px",
            left: `calc(${value * (100 / 15)}% - 10px)`, // Adjust the position based on the pain level and number of boxes
            borderLeft: "10px solid transparent",
            borderRight: "10px solid transparent",
            borderTop: `10px solid ${color}`,
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "5px",
          width: "80%",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        {scaleValues}
      </Box>
      <Typography variant="subtitle1">
        Leaves of this month Performance: {value}
      </Typography>
    </Box>
  );
};

export default PainScale;

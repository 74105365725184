import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Select,
  InputLabel,
  TextareaAutosize,
  Snackbar,
  Alert,
  DialogContentText,
  Grid,
  CardHeader,
  CardContent,
  Card,
  CardActions,
  Box,
  FormControl,
  Link,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import {
  CREATE_TASK_URL,
  DELETE_TASK_URL,
  GET_ALL_TASKS_URL,
  GET_ALL_TEAMS_URL,
  GET_ALL_USERS_URL,
  UPDATE_STATUS_URL,
  UPDATE_TASK_DETAILS_URL,
} from "../../api/user";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const OLDAdminTableComponent = () => {
  const [loader, setLoader] = useState(false);
  const isMobile = useMediaQuery("(max-width: 768px)");
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [taskIdToDelete, setTaskIdToDelete] = useState(null);

  const [userList, setUserList] = useState([]);
  const [teamList, setTeamList] = useState([]);

  const [selectedUser, setSelectedUser] = useState("");
  const [selectedTeam, setSelectedTeam] = useState("");

  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [isCompleteDialogOpen, setIsCompleteDialogOpen] = useState(false);

  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [showFilters, setShowFilters] = useState(false);
  const [filterText, setFilterText] = useState("");
  const token =  localStorage.getItem("token");


  // Use a single formData object to handle form data
  const [selectedColumn, setSelectedColumn] = useState("");
  const [columns, setColumns] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    taskDetails: "",
    team: "",
    status: "Assigned",
    assignedDate: "",
    completedDate: "",
    points: "",
    rating: "",
    comments: "",
  });

  const columnFilterTypes = {
    Name: "text",
    TaskDetails: "text",
    Team: "text",
    CompleteDate: "date", // Use "date" for date input
    AssignedDate: "date", // Use "date" for date input
    Status: "select",
    Points: "text",
    Rating: "text",
  };
  const [assignedDateFilter, setAssignedDateFilter] = useState({
    startDate: "",
    endDate: "",
  });
  const [completedDateFilter, setCompletedDateFilter] = useState({
    startDate: "",
    endDate: "",
  });

  useEffect(() => {
    fetchData();
    fetchUser();
    fetchTeams();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(GET_ALL_TASKS_URL);
      if (response.status === 200) {
        const modifiedData = response.data.map((item) => ({
          Name: item.assignedTo ? item.assignedTo.name : '', // Use an empty string if item.assignedTo is null or undefined
          TaskDetails: item.taskDetails,
          Team: item.team ? item.team.name : '', // Use an empty string if item.team is null or undefined
          AssignedDate: item.assignedDate,
          CompleteDate: item.completedDate,
          Status: item.status,
          Rating: item.rating,
          Points: item.rating * 10,
          _id: item._id,
          Comments: item.comments,
          timestamp: new Date(item.updatedAt), // Convert updatedAt to Date object
        }));

        // Filter out "Completed and Validated" tasks
        const filterData = modifiedData.filter(
          (data) => data.Status !== "Completed and Validated"
        );

        // Sort the filtered data based on updatedAt timestamp in descending order
        filterData.sort(
          (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
        );

        setData(filterData);
        setColumns(Object.keys(modifiedData[0]));
      }
    } catch (error) {
      console.error("Error fetching tasks", error);
    }
  };

  const fetchUser = async () => {
    try {
      const response = await axios.get(GET_ALL_USERS_URL,{
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (response.status === 200) {
        // Filter users based on admin status and account approval
        const filteredUsers = response.data.filter(
          (user) =>
            user.isAdmin === false &&
            user.isApproved === true &&
            user.isDeactivated === false
        );
        setUserList(filteredUsers);
      }
    } catch (error) {
      console.error("Error fetching tasks", error);
    }
  };

  const fetchTeams = async () => {
    try {
      const response = await axios.get(GET_ALL_TEAMS_URL,{ headers: {
        Authorization: `Bearer ${token}`
      }});
      if (response.status === 200) {
        setTeamList(response.data);
      }
    } catch (error) {
      console.error("Error fetching tasks", error);
    }
  };

  const calculateDaysDifference = (startDate, endDate) => {
    const oneDay = 1000 * 60 * 60 * 24; // Milliseconds in a day
    const start = new Date(startDate).getTime();
    const end = new Date(endDate).getTime();
    const difference = Math.abs(end - start);
    return Math.ceil(difference / oneDay);
  };

  const handleCreateAssignment = () => {
    setIsCreateDialogOpen(true);
  };

  const handleCloseCreateDialog = () => {
    setIsCreateDialogOpen(false);

    // Clear the formData object
    setFormData({
      name: "",
      taskDetails: "",
      team: "",
      status: "Assigned",
      assignedDate: "",
      completedDate: "",
      rating: "",
      points: "",
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    // Update the formData object
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleUserSelect = (event) => {
    const selectedUserId = event.target.value;
    setSelectedUser(selectedUserId);

    // Update the formData object with the selected user
    setFormData((prevData) => ({
      ...prevData,
      name: selectedUserId,
    }));
  };

  const handleTeamSelect = (event) => {
    const selectedTeamId = event.target.value;
    setSelectedTeam(selectedTeamId);

    // Update the formData object with the selected team
    setFormData((prevData) => ({
      ...prevData,
      team: selectedTeamId,
    }));
  };

  const handleCreateTaskSubmit = async () => {
    try {
      setLoader(true);
      const newTaskData = {
        assignedTo: selectedUser,
        taskDetails: formData.taskDetails,
        team: selectedTeam,
        status: formData.status,
        assignedDate: formData.assignedDate,
      };

      const response = await axios.post(CREATE_TASK_URL, newTaskData);

      const newTask = [
        {
          _id: response.data._id,
          assignedTo: userList.find((user) => user._id === selectedUser),
          taskDetails: formData.taskDetails,
          team: teamList.find((team) => team._id === selectedTeam),
          status: formData.status,
          assignedDate: formData.assignedDate,
        },
      ];

      const modifiedData = newTask.map((item) => ({
        Name: item.assignedTo.name,
        TaskDetails: item.taskDetails,
        Team: item.team.name,
        AssignedDate: item.assignedDate,
        CompleteDate: "-",
        Status: item.status,
        Rating: 0,
        Points: 0,
        _id: item._id,
      }));
      setData(data.concat(modifiedData));

      // Update the data state with the new task

      // Close the create task dialog
      setLoader(false);
      setIsCreateDialogOpen(false);

      // Clear/reset the selectedUser and selectedTeam state
      setSelectedUser("");
      setSelectedTeam("");

      // Clear the formData object
      setFormData({
        name: "",
        taskDetails: "",
        team: "",
        assignedDate: "",
        status: "Assigned",
      });

      setSnackbarMessage("Task created successfully");
      setIsSnackbarOpen(true);
    } catch (error) {
      if (error.response.status === 400) {
        // If the error is a validation error, handle multiple validation errors
        if (error.response.data && error.response.data.details) {
          const validationErrors = Object.values(
            error.response.data.details
          ).flat();
          setSnackbarMessage(
            `Validation error: ${validationErrors.join(", ")}`
          );
        } else {
          // If no details provided, show a generic error message
          setSnackbarMessage("Validation error: Something went wrong.");
        }
        // Open the Snackbar
        setIsSnackbarOpen(true);
      }
      console.error("Error creating task", error);
    }
  };
  const handleCreateAssignmentSubmit = () => {
    const newId = data.length + 1;
    setData((prevData) => [
      ...prevData,
      {
        ...formData, // Use formData for assignment details
        id: newId,
      },
    ]);
    setIsCreateDialogOpen(false);

    // Clear the formData object
    setFormData({
      name: "",
      taskDetails: "",
      team: "",
      assignedDate: "",
      status: "Assigned",
      completedDate: "",
      rating: "",
    });
  };
  const resetFilters = () => {
    setFilterText("");
    setSelectedColumn("");
    setAssignedDateFilter({ startDate: "", endDate: "" });
    setCompletedDateFilter({ startDate: "", endDate: "" });
  };
  const handleDeleteTask = async () => {
    try {
      await axios.delete(DELETE_TASK_URL(taskIdToDelete));
      const updatedData = data.filter((task) => task._id !== taskIdToDelete);
      setData(updatedData);
      setTaskIdToDelete(null);
      setOpenDeleteDialog(false); // Close the delete dialog
    } catch (error) {
      console.error("Error deleting task:", error);
    }
  };

  const confirmDelete = (taskId) => {
    setTaskIdToDelete(taskId);
    setOpenDeleteDialog(true); // Open the delete dialog
  };

  const cancelDelete = () => {
    setTaskIdToDelete(null);
    setOpenDeleteDialog(false); // Close the delete dialog
  };

  const handleStatusChange = async (taskId, newStatus) => {
    try {
      if (newStatus === "Completed and Validated") {
        // Open the complete task dialog
        setIsCompleteDialogOpen(true);
        // Set the task ID in formData
        setFormData((prevData) => ({
          ...prevData,
          id: taskId,
        }));
      } else {
        // Update the status on the client-side first
        const updatedData = data.map((item) =>
          item._id === taskId ? { ...item, Status: newStatus } : item
        );
        setData(updatedData);

        // Send the status change to the server
        const response = await axios.put(UPDATE_STATUS_URL(taskId), {
          status: newStatus,
        });

        if (response.status === 200) {
          // Status updated successfully on the server
        } else {
          // Handle error if the status update on the server fails
          console.error("Error updating status on the server");
          // You might want to revert the status change on the client-side in case of an error
          // Revert the status change on the client-side
          const revertedData = data.map((item) =>
            item._id === taskId ? { ...item, Status: item.Status } : item
          );
          setData(revertedData);
        }
      }
    } catch (error) {
      console.error("Error changing task status", error);
    }
  };

  const handleCompleteDialogClose = () => {
    setIsCompleteDialogOpen(false);

    // Clear the formData object
    setFormData({
      name: "",
      taskDetails: "",
      team: "",
      assignedDate: "",
      status: "Assigned",
      completedDate: "",
      rating: "",
      comments: "",
    });
  };

  const handleCompleteAssignmentSubmit = async () => {
    try {
      const completedTaskData = {
        _id: formData.id,
        completedDate: formData.completedDate,
        rating: formData.rating,
        comments: formData.comments,
        status: "Completed and Validated",
      };

      const response = await axios.put(
        UPDATE_TASK_DETAILS_URL(formData.id),
        completedTaskData
      );
      if (response.status === 200) {
        // Task completed successfully, update the UI with the completed task
        const updatedData = data.map((item) =>
          item._id === formData.id
            ? {
                ...item,
                Status: "Completed and Validated",
                CompleteDate: formData.completedDate,
                Rating: formData.rating,
              }
            : item
        );

        // Update the data state with the updated task
        setData(updatedData);

        // Close the complete task dialog
        setIsCompleteDialogOpen(false);

        // Clear/reset the formData state
        setFormData({
          taskDetails: "",
          team: "",
          assignedDate: "",
          status: "Assigned",
          completedDate: "",
          rating: "",
        });
      }
    } catch (error) {
      console.error("Error completing task", error);
    }
  };
  const toggleShowFilters = () => setShowFilters((s) => !s);

  //   const filteredData = data.filter((item) =>
  //   !filterText || item[selectedColumn].toLowerCase().includes(filterText.toLowerCase())
  // );
  // Inside your component, add the following function to check if a value is numeric:
  function isNumeric(value) {
    return !isNaN(value) && isFinite(value);
  }
  const filteredData = data.filter((row) => {
    if (selectedColumn === "AssignedDate") {
      return isDateInRange(
        row.AssignedDate,
        assignedDateFilter.startDate,
        assignedDateFilter.endDate
      );
    } else if (selectedColumn === "CompleteDate") {
      return isDateInRange(
        row.CompleteDate,
        completedDateFilter.startDate,
        completedDateFilter.endDate
      );
    }

    if (!filterText) {
      return true; // No filter text, so include all data
    } else if (!selectedColumn) {
      // If no specific column is selected, search through all columns
      const searchText = filterText.toLowerCase();
      return Object.keys(row).some((key) => {
        if (key === "Rating") {
          return (
            isNumeric(row[key]) && row[key].toString().includes(searchText)
          );
        }
        return row[key].toLowerCase().includes(searchText);
      });
    } else {
      // If a specific column is selected, search only in that column
      if (selectedColumn === "Rating") {
        return (
          isNumeric(row[selectedColumn]) &&
          row[selectedColumn].toString().includes(filterText)
        );
      }
      return (
        row[selectedColumn] &&
        row[selectedColumn].toLowerCase().includes(filterText.toLowerCase())
      );
    }
  });
  function isDateInRange(date, startDate, endDate) {
    if (!startDate || !endDate) {
      return true; // If start date or end date is not specified, include all data
    }

    const currentDate = new Date(date);
    const rangeStartDate = new Date(startDate);
    const rangeEndDate = new Date(endDate);

    return currentDate >= rangeStartDate && currentDate <= rangeEndDate;
  }
  const handleStartDateChange = (event) => {
    const newStartDate = event.target.value;

    // Determine the appropriate date filter state to update based on the selected column
    if (selectedColumn === "AssignedDate") {
      setAssignedDateFilter((prevFilter) => ({
        ...prevFilter,
        startDate: newStartDate,
      }));
    } else if (selectedColumn === "CompleteDate") {
      setCompletedDateFilter((prevFilter) => ({
        ...prevFilter,
        startDate: newStartDate,
      }));
    }
  };

  const handleEndDateChange = (event) => {
    const newEndDate = event.target.value;

    // Determine the appropriate date filter state to update based on the selected column
    if (selectedColumn === "AssignedDate") {
      setAssignedDateFilter((prevFilter) => ({
        ...prevFilter,
        endDate: newEndDate,
      }));
    } else if (selectedColumn === "CompleteDate") {
      setCompletedDateFilter((prevFilter) => ({
        ...prevFilter,
        endDate: newEndDate,
      }));
    }
  };
  const handleRowClick = (row) => {
    const clickedUserName = row.Name;
    localStorage.setItem("Name", clickedUserName);
    // Find the user object based on the clicked user's name (case-insensitive)
    const matchedUser = userList.find(
      (user) => user.name.toLowerCase() === clickedUserName.toLowerCase()
    );
    if (matchedUser) {
      const userId = matchedUser._id;
      localStorage.setItem("clickedUserId", userId);
      navigate("/dashboard");
    }
  };
  return (
    <Box>
      <Button
        onClick={toggleShowFilters}
        variant="contained"
        disableElevation
        className="filter"
        style={{
          marginTop: "50px",
        }}
      >
        {showFilters ? "Hide filters" : "Filter"}
      </Button>
      <Button
        variant="contained"
        color="primary"
        className="createTask"
        style={{
          marginLeft: isMobile ? "-38%" : "-5%",
          marginTop: "50px",
          marginBottom: "10px",
        }}
        onClick={handleCreateAssignment}
      >
        Create Task
      </Button>
      {showFilters && (
        <Box py={1}>
          <Card variant="outlined">
            <CardHeader
              titleTypographyProps={{ variant: "h6" }}
              title="Filter options"
            />
            <CardContent>
              <Grid container spacing={4}>
                <Grid item container spacing={4}>
                  <Grid item xs={12} sm={6} lg={3}>
                    {selectedColumn &&
                    columnFilterTypes[selectedColumn] === "text" ? (
                      <TextField
                        value={filterText}
                        onChange={(e) => setFilterText(e.target.value)}
                        label="Search"
                        variant="filled"
                        fullWidth
                      />
                    ) : selectedColumn &&
                      columnFilterTypes[selectedColumn] === "select" ? (
                      // Render select input for "Status" here as shown in the previous example
                      <FormControl variant="filled" fullWidth>
                        <InputLabel>Select {selectedColumn}</InputLabel>
                        <Select
                          value={filterText}
                          onChange={(e) => setFilterText(e.target.value)}
                        >
                          <MenuItem value="">All</MenuItem>
                          <MenuItem value="Assigned">Assigned</MenuItem>
                          <MenuItem value="Pending">Pending</MenuItem>
                          <MenuItem value="Completed">Completed</MenuItem>
                          <MenuItem value="Completed and Validated">
                            Completed and Validated
                          </MenuItem>
                        </Select>
                      </FormControl>
                    ) : selectedColumn &&
                      columnFilterTypes[selectedColumn] === "date" ? (
                      <div>
                        <InputLabel>
                          {selectedColumn === "AssignedDate"
                            ? "Assigned Date"
                            : "Completed Date"}
                        </InputLabel>
                        <TextField
                          type="date"
                          value={
                            selectedColumn === "AssignedDate"
                              ? assignedDateFilter.startDate
                              : completedDateFilter.startDate
                          }
                          onChange={handleStartDateChange}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />

                        <h2>-</h2>
                        <InputLabel>End date</InputLabel>
                        <TextField
                          type="date"
                          value={
                            selectedColumn === "AssignedDate"
                              ? assignedDateFilter.endDate
                              : completedDateFilter.endDate
                          }
                          onChange={handleEndDateChange}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <FormControl variant="filled" fullWidth>
                      <InputLabel>Select Column</InputLabel>
                      <Select
                        value={selectedColumn}
                        onChange={(e) => setSelectedColumn(e.target.value)}
                      >
                        {columns.map((column) => (
                          <MenuItem key={column} value={column}>
                            {column}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Button onClick={resetFilters} size="small">
                Reset
              </Button>
            </CardActions>
          </Card>
        </Box>
      )}

      <div style={{ maxHeight: "800px", overflowY: "auto" }}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell style={{ width: "60px" }}>Task Details</TableCell>
                <TableCell>Team</TableCell>
                <TableCell>Assigned Date</TableCell>
                <TableCell>Completed Date</TableCell>
                <TableCell>Total Days Taken</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Rating</TableCell>
                <TableCell>Comments</TableCell>
                <TableCell>Actions</TableCell>
                <TableCell>Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData.map((row) => (
                <TableRow key={row._id}>
                  <TableCell style={{ width: "110px" }}>
                    <Link
                    
                      onClick={() => handleRowClick(row) }
                 
                    >
                      {row.Name}
                    </Link>
                  </TableCell>
                  <TableCell style={{ width: "180px" }}>
                    {row.TaskDetails}
                  </TableCell>
                  <TableCell>{row.Team}</TableCell>
                  <TableCell>{row.AssignedDate.substring(0, 10)}</TableCell>
                  <TableCell>
                    {row.Status === "Assigned" || !row.CompleteDate
                      ? "-"
                      : row.CompleteDate.substring(0, 10)}
                  </TableCell>

                  <TableCell style={{ minWidth: "70px" }}>
                    {row.Status === "Completed and Validated"
                      ? calculateDaysDifference(
                          row.AssignedDate,
                          row.CompleteDate
                        )
                      : "-"}
                  </TableCell>
                  <TableCell>{row.Status}</TableCell>
                  <TableCell>{row.Rating}</TableCell>
                  <TableCell>{!row.Comments ? "-" : row.Comments}</TableCell>

                  <TableCell style={{ width: "70px" }}>
                    <Select
                      value={row.Status}
                      onChange={(e) =>
                        handleStatusChange(row._id, e.target.value)
                      }
                    >
                      <MenuItem value="Assigned">Assigned</MenuItem>
                      <MenuItem value="Pending">Pending</MenuItem>
                      <MenuItem value="Completed">Completed</MenuItem>
                      <MenuItem value="Completed and Validated">
                        Completed and Validated
                      </MenuItem>
                    </Select>
                  </TableCell>
                  <TableCell>
                    <Button onClick={() => confirmDelete(row._id)}>
                      Delete
                    </Button>
                    <Dialog
                      open={openDeleteDialog}
                      onClose={cancelDelete}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        Confirm Deletion
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          Are you sure you want to delete this task?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={cancelDelete} color="primary">
                          No
                        </Button>
                        <Button
                          onClick={handleDeleteTask}
                          color="primary"
                          autoFocus
                        >
                          Yes
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <Dialog
            open={isCreateDialogOpen}
            onClose={handleCloseCreateDialog}
            PaperProps={{
              style: {
                maxWidth: "600px", // Adjust the width as needed
                width: "100%",
              },
            }}
          >
            <DialogTitle>Create Task</DialogTitle>
            <DialogContent>
              <div>
                <InputLabel htmlFor="name">User</InputLabel>
                <Select
                  labelId="name-label"
                  id="name"
                  name="name"
                  value={selectedUser}
                  onChange={handleUserSelect}
                  fullWidth
                >
                  {userList.map((user) => (
                    <MenuItem key={user._id} value={user._id}>
                      {user.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div>
                <InputLabel htmlFor="taskDetails">
                  Task Details (can include links)
                </InputLabel>
                <TextareaAutosize
                  id="taskDetails"
                  name="taskDetails"
                  value={formData.taskDetails}
                  onChange={handleInputChange}
                  minRows={5}
                  style={{
                    width: "100%",
                    maxWidth: "100%",
                  }}
                />
              </div>
              <div>
                <InputLabel htmlFor="team">Team</InputLabel>
                <Select
                  labelId="team-label"
                  id="team"
                  name="team"
                  value={selectedTeam}
                  onChange={handleTeamSelect}
                  fullWidth
                >
                  {teamList.map((team) => (
                    <MenuItem key={team._id} value={team._id}>
                      {team.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div>
                <InputLabel htmlFor="assignedDate">Assigned Date</InputLabel>
                <TextField
                  type="date"
                  name="assignedDate"
                  value={formData.assignedDate}
                  onChange={handleInputChange}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div>
                <InputLabel htmlFor="status">Status</InputLabel>
                <Select
                  labelId="status-label"
                  id="status"
                  name="status"
                  value={formData.status}
                  onChange={handleInputChange}
                  fullWidth
                >
                  <MenuItem value="Assigned">Assigned</MenuItem>
                  <MenuItem value="Pending">Pending</MenuItem>
                  <MenuItem value="Completed">Completed</MenuItem>
                  <MenuItem value="Completed and Validated">
                    Completed and Validated
                  </MenuItem>
                </Select>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseCreateDialog} color="primary">
                Cancel
              </Button>
              {loader ? (
                <CircularProgress />
              ) : (
                <Button onClick={handleCreateTaskSubmit} color="primary">
                  Create
                </Button>
              )}
            </DialogActions>
          </Dialog>

          <Dialog
            open={isCompleteDialogOpen}
            onClose={handleCompleteDialogClose}
          >
            <DialogTitle>Complete Task</DialogTitle>
            <DialogContent>
              <TextField
                label="Completed Date"
                type="date"
                name="completedDate"
                value={formData.completedDate}
                onChange={handleInputChange}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                label="Rating ( out of 5 )"
                type="number"
                name="rating"
                value={formData.rating}
                onChange={handleInputChange}
                fullWidth
                inputProps={{
                  min: 1,
                  max: 5,
                }}
              />
              <TextField
                label="Comments"
                type="text"
                name="comments"
                value={formData.comments}
                onChange={handleInputChange}
                fullWidth
                inputProps={{
                  min: 1,
                  max: 5,
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCompleteDialogClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleCompleteAssignmentSubmit} color="primary">
                Complete and Validate
              </Button>
            </DialogActions>
          </Dialog>
          <Snackbar
            open={isSnackbarOpen}
            autoHideDuration={3000}
            onClose={() => setIsSnackbarOpen(false)}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            <Alert
              severity={
                snackbarMessage.includes("successfully") ? "success" : "error"
              }
              onClose={() => setIsSnackbarOpen(false)}
            >
              {snackbarMessage.includes("successfully") ? (
                <>{snackbarMessage}</>
              ) : (
                <>{snackbarMessage}</>
              )}
            </Alert>
          </Snackbar>
        </TableContainer>
      </div>
    </Box>
  );
};

export default OLDAdminTableComponent;

import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
  Grid,
} from "@mui/material";
import { CHANGE_PASSWORD_ADMIN_URL, FETCH_USER_DATA_NOID_URL, FETCH_USER_DATA_URL } from "../../api/profile";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const AdminProfileComponent = () => {
  const [isChangePasswordDialogOpen, setChangePasswordDialogOpen] =
    useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [token, setToken] = useState("");
  const [data, setData] = useState([]);
  const [admin, setAdmin] = useState("");
  const [id, setId] = useState("");

  useEffect(() => {
    fetchToken();
  }, []);

  const fetchToken = async () => {
    try {
      const storedToken = await localStorage.getItem("token");
      const storedAdmin = await localStorage.getItem("isAdmin");
      const storedId = await localStorage.getItem("id");
      setToken(storedToken);
      setAdmin(storedAdmin);
      setId(storedId);
    } catch (error) {
      console.error("Error fetching token: ", error);
    }
  };

  useEffect(() => {
    if (token) {
      fetchData();
    }
  }, [token]);

  const fetchData = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      //const userDataUrl = FETCH_USER_DATA_URL(admin,id);

      // Fetch user data based on the constructed URL
      const response = await axios.get(FETCH_USER_DATA_NOID_URL, {
        headers,
      });

      if (response.status === 200) {
        setData(response.data);
      
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setMessage("Passwords do not match.");
      return;
    }

    try {
      const response = await axios.post(CHANGE_PASSWORD_ADMIN_URL, {
        userId: data._id, // Use the userId from fetched data
        newPassword: newPassword,
      });

      if (response.status === 200) {
        setMessage("Password changed successfully.");
        setCurrentPassword("");
        setNewPassword("");
        setConfirmPassword("");
        setChangePasswordDialogOpen(false);
      } else {
        setMessage("Error changing password.");
      }
    } catch (error) {
      setMessage("Error changing password.");
      console.error("Error:", error);
    }
  };

  const handleCloseChangePasswordDialog = () => {
    setChangePasswordDialogOpen(false);
    setCurrentPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setMessage("");
  };

  return (
    <div>
      <h2>User Profile</h2>
      <div>
        <Typography variant="h5">Welcome {data.name}</Typography>
      </div>
      <Button
        variant="contained"
        color="primary"
        style={{ margin: "16px" }}
        onClick={() => setChangePasswordDialogOpen(true)}
      >
        Change Password
      </Button>
      <Dialog
        open={isChangePasswordDialogOpen}
        onClose={handleCloseChangePasswordDialog}
        aria-labelledby="change-password-dialog-title"
      >
        <DialogTitle id="change-password-dialog-title">
          Change Password
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            To change your password, please enter your current password, new
            password, and confirm the new password.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Current Password"
            type="password"
            fullWidth
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
          <TextField
            margin="dense"
            label="New Password"
            type="password"
            fullWidth
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Confirm New Password"
            type="password"
            fullWidth
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseChangePasswordDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleChangePassword} color="primary">
            Change Password
          </Button>
        </DialogActions>
        <DialogContent>
          <p>{message}</p>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AdminProfileComponent;

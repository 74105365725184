import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../DashboardPage.css";
import TableComponent from "../Dashboard/TableComponent";
import axios from "axios";
import { GET_ALL_USERS_URL } from "../../api/user";
import { FETCH_USER_DATA_URL } from "../../api/profile";
import { Snackbar, Alert, Button, CircularProgress } from "@mui/material"; // Import Snackbar and Alert

import {
  useMediaQuery,
  IconButton,
  Drawer,
  AppBar,
  Toolbar,
  TextField,
  Box,
} from "@mui/material";

import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import moment from "moment"; // Import the moment library
import { Calendar, DateObject } from "react-multi-date-picker";
import "react-datepicker/dist/react-datepicker.css";
import { DataGrid } from "@mui/x-data-grid";
import { FETCH_LEAVE_BY_ID, SUBMIT_LEAVE } from "../../api/leave";

function NoShow() {
  const [data, setData] = useState([]);
  const [onBoardingStatus, setOnBoardingStatus] = useState(false);
  const [leaveRequest, setLeaveRequest] = useState("");
  const [noShow, setNoShow] = useState("");

  const columns = [
    { field: "name", headerName: "Name", flex: 1 },
    { field: "dates", headerName: "Dates", flex: 1 },
    {
      field: "reason",
      headerName: "Reason",
      flex: 1,
      cellClassName: (params) =>
        params.value === "No Show" ? "no-show-cell" : "",
    },
    {
      field: "decision",
      headerName: "Decision",
      flex: 1,
      cellClassName: (params) =>
        params.value === "Accept" ? "accepted-cell" : "rejected-cell",
    },
  ];

  const getRowId = (row) => row._id; // Assuming _id is a unique identifier in your data
  useEffect(() => {
    fetchData();
  }, []);
  const [isLoading, setIsLoading] = useState(false); // New loading state
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false); // State for Snackbar
  const [snackbarMessage, setSnackbarMessage] = useState(""); // Message for the Snackbar
  const [selectedDates, setSelectedDates] = useState([``]); // New state for selected dates
  const onChange = (dates) => {
    const formattedDates = dates.map((value) => {
      const formattedDate = new Intl.DateTimeFormat("en-US", {
        day: "numeric",
        month: "short",
        year: "numeric",
      }).format(new Date(value.year, value.month - 1, value.day));
      return formattedDate;
    });

    setSelectedDates(formattedDates);
  };

  const [leaveReason, setLeaveReason] = useState(""); // New state for leave reason

  const handleLeaveReasonChange = (event) => {
    setLeaveReason(event.target.value);
  };

  const handleSubmitLeave = async () => {
    try {
      setIsLoading(true); // Set loading to true when submitting
      const res = await axios.post(
        SUBMIT_LEAVE,
        { reason: leaveReason, dates: selectedDates },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setIsLoading(false); // Set loading to false regardless of success or error
      setLeaveReason("");
      setSelectedDates([``]);
      setSnackbarMessage(res.data.message);
      // Open the Snackbar
      setIsSnackbarOpen(true);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false); // Set loading to false regardless of success or error
    }

  };

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    const verified = await localStorage.getItem("verified");
    setOnBoardingStatus(verified);
    const storedToken = await localStorage.getItem("token");
    setToken(storedToken);
    const storedAdmin = await localStorage.getItem("isAdmin");
    setAdmin(storedAdmin);
    try {
      const res = await axios.get(FETCH_LEAVE_BY_ID, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      const noshowlength = res.data.data.filter(
        (data) => data.reason === "No Show"
      );
      setNoShow(noshowlength.length);
      setLeaveRequest(res.data.data.length);
      setData(noshowlength);
    } catch (error) {
      console.error(error);
    }
  };
  const [token, setToken] = useState("");
  const [admin, setAdmin] = useState("false");
  const [userName, setUserName] = useState("vicky");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const isMobile = useMediaQuery("(max-width: 768px)");
  const handleLogout = () => {
    // Remove the token from local storage
    localStorage.removeItem("token");
    localStorage.removeItem("isAdmin");
    localStorage.removeItem("verified");
    // localStorage.removeItem("id");
    // Redirect the user to the login page or any other desired page
    window.location.href = "/login";
  };
  return (
    <div className="container">
      {isMobile ? (
        <>
          <AppBar
            position="fixed"
            style={{ background: "transparent", boxShadow: "none" }}
          >
            <Toolbar>
              <IconButton
                color="primary"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
              <p>Welcome, Admin!</p>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="temporary"
            open={isDrawerOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <div className="left-container">
              <div className="logo">
                <h1>Markoknow</h1>
              </div>
              <div className="divider"></div>
              <div className="icons">
                {admin === "true" ? (
                  <>
                    <div className="icon">
                      <Link to="/access" className="icon-link">
                        Access
                      </Link>
                    </div>
                  </>
                ) : null}
                {admin === "true" ? (
                  <>
                    <div className="icon">
                      <Link to="/chatSupport" className="icon-link">
                        ChatSupport
                      </Link>
                    </div>
                  </>
                ) : null}
                {admin === "false" ? (
                  <>
                    <div className="icon">
                      <Link to="/userNotification" className="icon-link">
                        Notification
                      </Link>
                    </div>
                  </>
                ) : null}
                {admin === "true" ? (
                  <>
                    <div className="icon">
                      <Link to="/adminDashboard" className="icon-link">
                        AdminDashboard
                      </Link>
                    </div>
                  </>
                ) : null}
                <div className="icon">
                  <Link to="/dashboard" className="icon-link">
                    Dashboard
                  </Link>
                </div>{" "}
                {admin === "true" ? null : (
                  <>
                    <div className="icon">
                      <Link to="/lead" className="icon-link">
                        Lead
                      </Link>
                    </div>
                  </>
                )}
                <div className="icon">
                  <Link to="/tasks" className="icon-link">
                    Tasks
                  </Link>
                </div>
                {admin === "true" ? null : (
                  <>
                    <div className="icon">
                      <Link to="/leave" className="icon-link">
                        Leave
                      </Link>
                    </div>
                  </>
                )}
                {admin === "true" ? null : (
                  <>
                    <div className="icon">
                      <Link to="/userOnBoarding" className="icon-link">
                        OnBoarding
                      </Link>
                      {onBoardingStatus === "true" ? (
                        <CheckIcon style={{ color: "green" }} />
                      ) : (
                        <CloseIcon style={{ color: "red" }} />
                      )}
                    </div>
                  </>
                )}
                <div className="icon">
                  <Link to="/profile" className="icon-link">
                    Profile
                  </Link>
                </div>
                {admin === "true" ? null : (
                  <>
                    <div className="icon">
                      <Link to="/" className="icon-link" onClick={handleLogout}>
                        Logout
                      </Link>
                    </div>
                  </>
                )}
              </div>
            </div>
          </Drawer>
        </>
      ) : (
        <>
          <div className="left-container">
            <div className="logo">
              <h1>Markoknow</h1>
            </div>
            <div className="divider"></div>
            <div className="icons">
              {admin === "true" ? (
                <>
                  <div className="icon">
                    <Link to="/access" className="icon-link">
                      Access
                    </Link>
                  </div>
                </>
              ) : null}
              {admin === "true" ? (
                <>
                  <div className="icon">
                    <Link to="/chatSupport" className="icon-link">
                      ChatSupport
                    </Link>
                  </div>
                </>
              ) : null}
              {admin === "false" ? (
                <>
                  <div className="icon">
                    <Link to="/userNotification" className="icon-link">
                      Notification
                    </Link>
                  </div>
                </>
              ) : null}
              {admin === "true" ? (
                <>
                  <div className="icon">
                    <Link to="/adminDashboard" className="icon-link">
                      AdminDashboard
                    </Link>
                  </div>
                </>
              ) : null}
              <div className="icon">
                <Link to="/dashboard" className="icon-link">
                  Dashboard
                </Link>
              </div>{" "}
              {admin === "true" ? null : (
                <>
                  <div className="icon">
                    <Link to="/lead" className="icon-link">
                      Lead
                    </Link>
                  </div>
                </>
              )}
              <div className="icon">
                <Link to="/tasks" className="icon-link">
                  Tasks
                </Link>
              </div>
              {admin === "true" ? null : (
                <>
                  <div className="icon">
                    <Link to="/leave" className="icon-link">
                      Leave
                    </Link>
                  </div>
                </>
              )}
              {admin === "true" ? null : (
                <>
                  <div className="icon">
                    <Link to="/userOnBoarding" className="icon-link">
                      OnBoarding
                    </Link>
                    {onBoardingStatus === "true" ? (
                      <CheckIcon style={{ color: "green" }} />
                    ) : (
                      <CloseIcon style={{ color: "red" }} />
                    )}
                  </div>
                </>
              )}
              <div className="icon">
                <Link to="/profile" className="icon-link">
                  Profile
                </Link>
              </div>
              {admin === "true" ? null : (
                <>
                  <div className="icon">
                    <Link to="/" className="icon-link" onClick={handleLogout}>
                      Logout
                    </Link>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}
      <div className="right-container">
        <div className="right-container-top">
          <p>
            Hi {userName}
            {admin === "true" ? " admin for overview 👋" : null}
          </p>
        </div>

        <div className="right-container-table" style={{ marginTop: "20px" }}>
          <h2>No Show Status</h2>
          <div style={{ height: 500, width: "100%" }}>
            <DataGrid
              rows={data}
              columns={columns}
              pageSize={10}
              getRowId={getRowId}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default NoShow;

import React, { useState, useEffect } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import axios from "axios";
import { FETCH_USER_DATA_URL } from "../../api/profile";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const TableComponent = () => {
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState(""); // Status filter
  const [token, setToken] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [admin, setAdmin] = useState("false");
  const [id, setId] = useState("");
  const itemsPerPage = 10; // Number of items to display per page

  useEffect(() => {
    fetchToken();
  }, []);

  const fetchToken = async () => {
    try {
      const storedAdmin = await localStorage.getItem("isAdmin");
      const storedId = await localStorage.getItem("Name");
      const storedToken = await localStorage.getItem("token");
      setId(storedId);
      setAdmin(storedAdmin);
      setToken(storedToken);
    } catch (error) {
      console.error("Error fetching token: ", error);
    }
  };

  useEffect(() => {
    if (token) {
      fetchData();
    }
  }, [token]);

  const fetchData = async () => {
    try {
      const fetchUserDataUrl = FETCH_USER_DATA_URL(admin, id);

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.get(fetchUserDataUrl, {
        headers,
      });

      if (response.status === 200) {
        setData(response.data.tasks);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const filteredData = data
    .filter(
      (row) =>
        row.taskDetails.toLowerCase().includes(filter.toLowerCase()) ||
        row.team.name.toLowerCase().includes(filter.toLowerCase()) || // Updated condition
        row.status.toLowerCase().includes(statusFilter.toLowerCase()) // Status filter
    )
    .sort((a, b) => new Date(b.assignedDate) - new Date(a.assignedDate));

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Function to handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
    setCurrentPage(1); // Reset to the first page when filtering
  };

  const handleStatusFilterChange = (event) => {
    setStatusFilter(event.target.value);
    setCurrentPage(1); // Reset to the first page when filtering
  };

  // Function to format date as DD MMM YYYY
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  // Get the data to display on the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const dataToDisplay = filteredData.slice(startIndex, endIndex);

  return (
    <div>
      <TextField
        label="Filter by Task Details or Team"
        variant="outlined"
        value={filter}
        onChange={handleFilterChange}
        style={{ marginBottom: "16px" }}
      />
      <Select
        value={statusFilter}
        onChange={handleStatusFilterChange}
        variant="outlined"
        style={{ marginBottom: "16px" }}
      >
        <MenuItem value="">All Status</MenuItem>
        <MenuItem value="Assigned">Assigned</MenuItem>
        <MenuItem value="Pending">Pending</MenuItem>
        <MenuItem value="Completed">Completed</MenuItem>
        <MenuItem value="Completed and Validated">
          Completed and Validated
        </MenuItem>
      </Select>
      <Typography
        variant="body1"
        paragraph
        style={{
          fontSize: "29px",
        }}
      >
        Task Deatils
      </Typography>
      <TableContainer
        component={Paper}
        style={{
          width: "100%",
          marginBottom: "5%",
          boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)",
          borderRadius: "10px",
        }}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Task Details</TableCell>
              <TableCell>Team</TableCell>
              <TableCell>Assigned Date</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataToDisplay.map((row) => (
              <TableRow key={row._id}>
                <TableCell>{row.taskDetails}</TableCell>
                <TableCell>{row.team.name}</TableCell>
                <TableCell>{formatDate(row.assignedDate)}</TableCell>
                <TableCell>{row.status}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <div style={{ textAlign: "center", marginTop: "5px" }}>
        {totalPages > 1 ? (
          <div>
            {Array.from({ length: totalPages }, (_, index) => {
              const pageNumber = index + 1;
              return (
                <Button
                  key={pageNumber}
                  variant="outlined"
                  // color={currentPage === pageNumber ? "primary" : "default"}
                  onClick={() => handlePageChange(pageNumber)}
                >
                  {pageNumber}
                </Button>
              );
            })}
          </div>
        ) : (
          <div>No pages to display</div>
        )}
      </div>
    </div>
  );
};

export default TableComponent;

import React, { useState, useEffect } from "react";
import "./Overview.css";
import "../DashboardPage.css";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Collapse,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  useMediaQuery,
  IconButton,
  Drawer,
  AppBar,
  Toolbar,
} from "@mui/material";
import axios from "axios";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, useNavigate } from "react-router-dom";
import {
  GET_ALL_TASKS_URL,
  GET_ALL_TEAMS_URL,
  GET_ALL_USERS_URL,
} from "../../api/user";
import LeftContainer from "../UI/LeftContainer";

const BASE_URL = process.env.REACT_APP_BASE_URL;

// ... (existing imports)

const OverviewTable = () => {
  const navigation = useNavigate();
  const [data, setData] = useState([]);

  const [userList, setUserList] = useState([]);
  const [teamList, setTeamList] = useState([]);
  const [currentSize, setCurrentSize] = useState(0); // New state for storing the size
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [isPastTableOpen, setIsPastTableOpen] = useState(false);
  const [status, setStatus] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const token = localStorage.getItem("token");
  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const togglePastTable = () => {
    setIsPastTableOpen(!isPastTableOpen);
  };

  useEffect(() => {
    fetchData();
    fetchUser();
    fetchTeams();
    // Calculate the size of the array where isCurrent is true
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(GET_ALL_TASKS_URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response.status === 200) {
        const modifiedData = {};
  
        response.data.forEach((task) => {
          // Skip tasks with missing assignedTo or team
          if (!task.assignedTo || !task.team) {
            console.warn("Task with missing assignedTo or team:", task);
            return;
          }
  
          const name = task.assignedTo.name;
          const assignedTo_id = task.assignedTo._id;
          const team = task.team.name;
          const team_id = task.team._id;
          const isCurrent = task.isCurrent;
          const key = `${name}-${team}`;
  
          if (!modifiedData[key]) {
            modifiedData[key] = {
              assignedTo_id: assignedTo_id,
              team_id: team_id,
              assignedTo: name,
              team: team,
              completedTasks: 0,
              currentAssignedTasks: 0,
              currentAssignedTaskList: [],
              averageRating: 0,
              points: 0,
              isCurrent: isCurrent,
            };
          }
  
          if (
            task.status === "Assigned" ||
            task.status === "Pending" ||
            task.status === "Completed"
          ) {
            modifiedData[key].currentAssignedTasks++;
  
            const currentAssignedTask = {
              assignedDate: task.assignedDate,
              taskDetails: task.taskDetails,
              taskId: task._id,
              rating: task.rating || 0,
            };
  
            modifiedData[key].currentAssignedTaskList.push(currentAssignedTask);
            modifiedData[key].points += task.rating || 0;
          } else if (task.status === "Completed and Validated") {
            modifiedData[key].completedTasks++;
  
            const completedRating = task.rating || 0;
            modifiedData[key].averageRating += completedRating;
          }
        });
  
        Object.values(modifiedData).forEach((entry) => {
          const totalRating =
            entry.currentAssignedTaskList.reduce(
              (sum, task) => sum + task.rating,
              0
            ) + entry.averageRating;
  
          entry.averageRating = (
            totalRating /
            (entry.currentAssignedTaskList.length + entry.completedTasks)
          ).toFixed(1);
  
          entry.points = (entry.averageRating * 4).toFixed(1);
        });
  
        const result = Object.values(modifiedData);
  
        const sortedData = [...result].sort((a, b) => {
          if (a.isCurrent && b.isCurrent) return 0;
          if (a.isCurrent) return -1;
          return 1;
        });
  
        const newSize = sortedData.filter((row) => row.isCurrent).length;
        setCurrentSize(newSize);
        setData(sortedData);
      }
    } catch (error) {
      console.error("Error fetching tasks", error);
    }
  };
  
  
  

  const handleStatusChange = (rowIndex, isCurrent) => {
    // Open the dialog and store the selected row index
    setIsDialogOpen(true);
    setStatus(isCurrent ? "Resume" : "Pause");
    setSelectedRowIndex({ rowIndex, isCurrent });
  };
  const handleDialogStatusChange = async () => {
    try {
      // Update the status in the data
      setData((prevData) => {
        const updatedData = [...prevData];
        updatedData[selectedRowIndex.rowIndex] = {
          ...updatedData[selectedRowIndex.rowIndex],
          isCurrent: selectedRowIndex.isCurrent,
        };

        const newSize = updatedData.filter((row) => row.isCurrent).length;
        // Update the state with the new size
        setCurrentSize(newSize);

        return updatedData;
      });

      // Call the backend API to update the status in the database
      const { team_id, assignedTo_id } = data[selectedRowIndex.rowIndex];
      const res = await axios.put(
        `${BASE_URL}/api/taskRequest/tasks/${team_id}/${assignedTo_id}`,
        {
          isCurrent: selectedRowIndex.isCurrent,
        }
      );

      setIsDialogOpen(false); // Close the dialog
      navigation("/past");
    } catch (error) {
      console.error("Error updating task status:", error);
      // console.log("fddsfdsf")
      // Handle error, show a message, or roll back the update on error
    }
  };
  const handlePastStatusChange = (rowIndex) => {
    // Open the dialog and store the selected row index
    setIsDialogOpen(true);
    setSelectedRowIndex(rowIndex);
  };

  const onExitDialog = () => {
    navigation("/past");
  };

  const MarkStatusDialog = ({ open, onClose, onPause, onExit, button }) => {
    const handlePauseClick = async () => {
      // Call the onPause function passed from the parent component
      await onPause("pause");
      navigation("/past");
      // Close the dialog
      onClose();
    };

    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Mark Status</DialogTitle>
        <DialogActions>
          <Button onClick={handlePauseClick} color="primary">
            {button}
          </Button>
          <Button onClick={onExitDialog} color="primary">
            Exit
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const fetchUser = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(GET_ALL_USERS_URL,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        const filteredUsers = response.data.filter(
          (user) => user.isAdmin === false && user.isApproved === true
        );
        setUserList(filteredUsers);
      }
    } catch (error) {
      console.error("Error fetching tasks", error);
    }
  };

  const fetchTeams = async () => {
    try {
      const response = await axios.get(GET_ALL_TEAMS_URL,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        setTeamList(response.data);
      }
    } catch (error) {
      console.error("Error fetching tasks", error);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("isAdmin");
    window.location.href = "/login";
  };

  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <>
      <div className="container">
        {isMobile ? (
          <>
            <AppBar
              position="fixed"
              style={{ background: "transparent", boxShadow: "none" }}
            >
              <Toolbar>
                <IconButton
                  color="primary"
                  aria-label="open drawer"
                  onClick={handleDrawerToggle}
                  edge="start"
                >
                  <MenuIcon />
                </IconButton>
                {/* <LeftContainer handleLogout={handleLogout} /> */}
              </Toolbar>
            </AppBar>
            <Drawer
              variant="temporary"
              open={isDrawerOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true,
              }}
            >
              {/* ... Other code ... */}
              <LeftContainer handleLogout={handleLogout} />
              {/* ... Other code ... */}
            </Drawer>
          </>
        ) : (
          <>
            <LeftContainer handleLogout={handleLogout} />
            {/* ... Other code ... */}
          </>
        )}

        <div className="right-container">
          <div className="right-container-top">
            <p>Welcome, Admin!</p>
          </div>

          <div
            style={{ maxHeight: "1000px", overflowY: "auto" }}
            className="right-container-table"
          >
            <h1>Overview</h1>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Team</TableCell>
                    <TableCell>Total Tasks Completed</TableCell>
                    <TableCell>Current Task Assigned</TableCell>
                    <TableCell>Rating</TableCell>
                    <TableCell>Points</TableCell> {/* New column heading */}
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data
                    .filter((row) => row.isCurrent)
                    .map((row, index) => (
                      <TableRow key={row.assignedTo}>
                        <TableCell>{row.assignedTo}</TableCell>
                        <TableCell>{row.team}</TableCell>
                        <TableCell>{row.completedTasks}</TableCell>
                        <TableCell>{row.currentAssignedTasks}</TableCell>
                        <TableCell>{row.averageRating}</TableCell>
                        <TableCell>{row.points}</TableCell>{" "}
                        {/* Display Points */}
                        <TableCell>
                          <Button
                            variant="contained"
                            color="primary"
                            className="createTask"
                            onClick={() => handleStatusChange(index, false)}
                          >
                            mark status
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  <MarkStatusDialog
                    open={isDialogOpen}
                    onClose={() => setIsDialogOpen(false)}
                    onPause={handleDialogStatusChange}
                    button={status}
                    onExit={() => setIsDialogOpen(false)}
                  />
                </TableBody>
              </Table>
              <Snackbar
                open={isSnackbarOpen}
                autoHideDuration={3000}
                onClose={() => setIsSnackbarOpen(false)}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              >
                <Alert
                  severity="success"
                  onClose={() => setIsSnackbarOpen(false)}
                >
                  {snackbarMessage}
                </Alert>
              </Snackbar>
            </TableContainer>
          </div>
        </div>
      </div>
    </>
  );
};

export default OverviewTable;

import { BASE_URL } from "./client";

export const ON_BOARDING_PROCESSES_URL = `${BASE_URL}/api/onBoarding/processes`;
export const GET_ALL_USERS_URL = `${BASE_URL}/api/userRequest/all`;
export const ON_BOARDING_PROCESS_SEND_URL = (process) =>
  `${BASE_URL}/api/onBoarding/processSend/${process}`;
export const FETCH_ALL_ON_BOARDING_DATA_URL = `${BASE_URL}/api/onBoarding/fetchDataAll`;
export const FETCH_PROCESS_DATA_URL = (processName) =>
  `${BASE_URL}/api/onBoarding/process/${processName}`;
export const PROCESS_DELETE_URL = (processName) =>
  `${BASE_URL}/api/onBoarding/process/${processName}`;
export const PROCESS_UPDATE_URL = (processName) =>
  `${BASE_URL}/api/onBoarding/process/${processName}`;
export const PROCESS_SUBMIT_URL = `${BASE_URL}/api/onBoarding/process`;
export const PROCESS_RECEIVE_URL = `${BASE_URL}/api/onBoarding/processRecieve`;
export const UPDATE_STEPS_URL = (userId) => `${BASE_URL}/api/onBoarding/updateSteps/${userId}`;
export const ON_BOARDING_VERIFIED_URL = (id) =>
  `${BASE_URL}/api/onBoarding/verifiedProcess/${id}`;
import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  TextField,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { useMediaQuery, Drawer, AppBar, Toolbar } from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";

import DeleteIcon from "@mui/icons-material/Delete";
import "./Team.css";
import axios from "axios";
import { Link, Route, Routes } from "react-router-dom";
import TeamDetails from "./TeamDetails";
import {
  CREATE_TEAM_URL,
  GET_ALL_TEAMS_URL,
  GET_ALL_USERS_URL,
  DELETE_TEAM_URL,
} from "../../api/user";
import LeftContainer from "../UI/LeftContainer";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const Team = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [isCreateTeamOpen, setIsCreateTeamOpen] = useState(false);
  const [teamName, setTeamName] = useState("");
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [token, setToken] = useState("");

  const [teams, setTeams] = useState([]);
  const [users, setUsers] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [admin, setAdmin] = useState("");
  const [manager, setManager] = useState("");
  const [manageTeams, setManageTeams] = useState([]);

  // Confirmation dialog state
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [teamToDelete, setTeamToDelete] = useState(null);

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const storedToken = await localStorage.getItem("token");
      setToken(storedToken);
      const tokenPayload = storedToken
        ? JSON.parse(atob(storedToken.split(".")[1]))
        : {};
      const currentUserId = tokenPayload.userId;
  
      const response = await axios.get(GET_ALL_USERS_URL, {
        headers: {
          Authorization: `Bearer ${storedToken}`
        }
      });
      if (response.status === 200) {
        const userFilter = response.data.filter(
          (value) => value.isDeactivated === false && value.isApproved === true
        );
        const allUsers = userFilter;
        const currentUser = allUsers.find((user) => user._id === currentUserId);
        setCurrentUser(currentUser);
        setAdmin(currentUser.isAdmin);
        setManager(currentUser.isManager);
        setManageTeams(currentUser.managedTeams);
      }
    } catch (error) {
      console.error("Error fetching user data: ", error);
    }
  };

  useEffect(() => {
    fetchTeams();
    fetchUsers();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("isAdmin");
    window.location.href = "/login";
  };

  const fetchTeams = async () => {
    try {
      const storedToken = await localStorage.getItem("token"); // Retrieve the token from localStorage
      const response = await axios.get(GET_ALL_TEAMS_URL, {
        headers: {
          Authorization: `Bearer ${storedToken}` // Include the token in the Authorization header
        }
      });
      if (response.status === 200) {
        setTeams(response.data);
      }
    } catch (error) {
      console.error("Error fetching teams", error);
    }
  };
  

  const fetchUsers = async () => {
    try {
      const storedToken = await localStorage.getItem("token");
      const response = await axios.get(GET_ALL_USERS_URL, {
        headers: {
          Authorization: `Bearer ${storedToken}`
        }
      });
      if (response.status === 200) {
        const userFilter = response.data.filter(
          (value) => value.isDeactivated === false && value.isApproved === true
        );
        setUsers(userFilter);
      }
    } catch (error) {
      console.error("Error fetching tasks", error);
    }
  };

  const handleCreateTeam = () => {
    setIsCreateTeamOpen(true);
  };

  const handleCloseCreateTeam = () => {
    setIsCreateTeamOpen(false);
    setTeamName("");
    setSelectedMembers([]);
  };

  const handleDeleteMemberFromTeam = (memberToDelete) => {
    setSelectedTeam((prevTeam) => ({
      ...prevTeam,
      members: prevTeam.members.filter((member) => member !== memberToDelete),
    }));
  };

  const handleAddMember = (member) => {
    const memberObject = users.find((user) => user.name === member);
    setSelectedMembers([...selectedMembers, memberObject]);
    setAnchorEl(null);
  };

  const handleDeleteMember = (member) => {
    setSelectedMembers(selectedMembers.filter((m) => m !== member));
  };

  const handleCreateTeamSubmit = async () => {
    const newTeam = {
      name: teamName,
      members: selectedMembers.map((member) => member._id),
    };
  
    try {
      const storedToken = await localStorage.getItem("token"); // Retrieve the token from localStorage
      const response = await axios.post(CREATE_TEAM_URL, newTeam, {
        headers: {
          Authorization: `Bearer ${storedToken}` // Include the token in the Authorization header
        }
      });
      const createdTeam = response.data;
      setTeams([...teams, createdTeam]);
      handleCloseCreateTeam();
    } catch (error) {
      console.error("Error creating team:", error);
    }
  };
  

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleTeamDetailsClick = (team) => {
    setSelectedTeam(team);
  };

  const handleTeamClick = (team) => {
    if (selectedTeam === team) {
      setSelectedTeam(null);
    } else {
      setSelectedTeam(team);
    }
  };
  const handleDeleteTeam = (teamId) => {
    // Find the team to delete
    const teamToDelete = teams.find((team) => team._id === teamId);
    // Set the teamToDelete state to the found team
    setTeamToDelete(teamToDelete);
    // Open the confirmation dialog
    setIsDeleteConfirmationOpen(true);
  };
  const handleDeleteTeamConfirmation = (teamId) => {
    const teamToDelete = teams.find((team) => team._id === teamId);
    setTeamToDelete(teamToDelete);
    setIsDeleteConfirmationOpen(true);
  };

  const handleDeleteTeamCancel = () => {
    setIsDeleteConfirmationOpen(false);
    setTeamToDelete(null);
  };

  const handleDeleteTeamConfirm = async () => {
    if (teamToDelete) {
      try {
        const storedToken = await localStorage.getItem("token"); // Retrieve the token from localStorage
        await axios.delete(DELETE_TEAM_URL(teamToDelete._id), {
          headers: {
            Authorization: `Bearer ${storedToken}` // Include the token in the Authorization header
          }
        });
        const updatedTeams = teams.filter(
          (team) => team._id !== teamToDelete._id
        );
        setTeams(updatedTeams);
        setIsDeleteConfirmationOpen(false);
        setTeamToDelete(null);
      } catch (error) {
        console.error("Error deleting team:", error);
      }
    }
  };
   
  return (
    <div className="container">
      {isMobile ? (
        <>
          <AppBar
            position="fixed"
            style={{ background: "transparent", boxShadow: "none" }}
          >
            <Toolbar>
              <IconButton
                color="primary"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
              <p>Welcome, Admin!</p>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="temporary"
            open={isDrawerOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
          >
           {/* ... Other code ... */}
           <LeftContainer handleLogout={handleLogout} />
            {/* ... Other code ... */}
          </Drawer>
        </>
      ) : (
        <>
          <LeftContainer handleLogout={handleLogout} />
          {/* ... Other code ... */}
        </>
      )}
      <div className="right-container">
        <div className="right-container-top">
          <p>Teams</p>
        </div>
        <div className="right-container-table">
          {admin && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleCreateTeam}
            >
              Create Team
            </Button>
          )}
          <Dialog open={isCreateTeamOpen} onClose={handleCloseCreateTeam}>
            <DialogTitle>Create Team</DialogTitle>
            <DialogContent>
              <TextField
                label="Team Name"
                value={teamName}
                onChange={(e) => setTeamName(e.target.value)}
                fullWidth
              />
              <List>
                {selectedMembers.map((member, index) => (
                  <ListItem key={index}>
                    <ListItemText primary={member.name} />{" "}
                    {/* Display member name */}
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => handleDeleteMember(member)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
              <Button
                aria-controls="member-menu"
                aria-haspopup="true"
                onClick={handleMenuOpen}
                color="primary"
              >
                Add Members
              </Button>
              <Menu
                id="member-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                {users.map((user, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => handleAddMember(user.name)}
                  >
                    {user.name}
                  </MenuItem>
                ))}
              </Menu>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseCreateTeam} color="primary">
                Cancel
              </Button>
              <Button onClick={handleCreateTeamSubmit} color="primary">
                Create
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={isDeleteConfirmationOpen}
            onClose={handleDeleteTeamCancel}
            fullWidth
          >
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to delete the team "
                {teamToDelete ? teamToDelete.name : ""}"?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDeleteTeamCancel} color="primary">
                Cancel
              </Button>
              <Button onClick={handleDeleteTeamConfirm} color="primary">
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
          <br />
          <br />
          {admin && (
            <div className="team-row">
              <div className="team-box-container">
                {teams.map((team, index) => (
                  <div key={index} className="team-box-wrapper">
                    <Link
                      to={`/team/${team.name}/${team._id}`}
                      key={index}
                      className="link-no-underline"
                    >
                      <Paper
                        className={`team-box ${
                          selectedTeam === team ? "selected" : ""
                        }`}
                        key={index}
                        onClick={() => handleTeamDetailsClick(team)}
                      >
                        <h2>{team.name}</h2>
                        <p>Total Members: {team.members.length}</p>
                      </Paper>
                    </Link>
                    {admin && (
                      <IconButton
                        onClick={() => handleDeleteTeam(team._id)}
                        aria-label="Delete Team"
                        color="error"
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
          {manager && (
            <div className="team-row">
              <div className="team-box-container">
                {manageTeams.map((managedTeamId) => {
                  const managedTeam = teams.find(
                    (team) => team._id === managedTeamId
                  );
                  return (
                    <Link
                      to={`/team/${managedTeam.name}/${managedTeam._id}`}
                      key={managedTeamId}
                      className="link-no-underline"
                    >
                      <Paper
                        className={`team-box ${
                          selectedTeam === managedTeam ? "selected" : ""
                        }`}
                        key={managedTeamId}
                        onClick={() => handleTeamDetailsClick(managedTeam)}
                      >
                        <h2>{managedTeam.name}</h2>
                        <p>Total Members: {managedTeam.members.length}</p>
                      </Paper>
                    </Link>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
      <Routes>
        {/* <Route path="/team/:teamName" element={<TeamDetails />} /> */}
        <Route path="/team/:teamName/:teamId" element={<TeamDetails />} />
      </Routes>
    </div>
  );
};

export default Team;

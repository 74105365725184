import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../DashboardPage.css";
import TableComponent from "./TableComponent";
import axios from "axios";
import { GET_ALL_USERS_URL } from "../../api/user";
import { FETCH_USER_DATA_URL,UPDATE_PROFILE_URL} from "../../api/profile";
import {
  useMediaQuery,
  IconButton,
  Drawer,
  AppBar,
  Toolbar,
  Snackbar,
  TextField,
  Alert,
  Button,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import { UserLeftContainer } from "../UI/index";

const Dashboard = () => {
  const [userName, setUserName] = useState("");
  const [data, setData] = useState([]);
  const [id, setId] = useState("");
  const [admin, setAdmin] = useState("false");
  const [manager, setManager] = useState("");
  const [manageTeams, setManageTeams] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [token, setToken] = useState("");
  const [onBoardingStatus, setOnBoardingStatus] = useState(false);
  const [dob, setDob] = useState(""); // State to store DOB
  const [isDobMissing, setIsDobMissing] = useState(false); // State for popup visibility
  const [newDob, setNewDob] = useState(""); // State for user to input new DOB
  const [successMessage, setSuccessMessage] = useState(""); // State for success message
  const [openSuccessToast, setOpenSuccessToast] = useState(false); // State for success toast visibility

  useEffect(() => {
    fetchUserData2();
  }, []);

  const isAdmin = () => {
    return admin === "true";
  };

  const fetchUserData2 = async () => {
    try {
      const verified = await localStorage.getItem("verified");
      const storedAdmin = await localStorage.getItem("isAdmin");
      const id1 = await localStorage.getItem("clickedUserId");
      const storedToken = await localStorage.getItem("token");

      setAdmin(storedAdmin === "true");
      setId(id1);
      setToken(storedToken);
      setOnBoardingStatus(verified);

      if (storedAdmin === "true") {
        const tokenPayload = storedToken
          ? JSON.parse(atob(storedToken.split(".")[1]))
          : {};
        const currentUserId = tokenPayload.userId;

        const response = await axios.get(GET_ALL_USERS_URL, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });

        if (response.status === 200) {
          const allUsers = response.data;
          const currentUser = allUsers.find(
            (user) => user._id === currentUserId
          );

          setCurrentUser(currentUser);
          localStorage.setItem("isManager", currentUser.isManager);
          setManager(currentUser.isManager);
          setManageTeams(currentUser.managedTeams);
        }
      }
    } catch (error) {
      console.error("Error fetching user data: ", error);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("isAdmin");
    localStorage.removeItem("verified");
    window.location.href = "/login";
  };

  useEffect(() => {
    fetchToken();
  }, []);

  const fetchToken = async () => {
    try {
      const storedToken = await localStorage.getItem("token");
      setToken(storedToken);
    } catch (error) {
      console.error("Error fetching token: ", error);
    }
  };

  useEffect(() => {
    if (token) {
      fetchData();
      fetchUserData();
    }
  }, [token]);

  const fetchData = async () => {
    try {
      const id1 = await localStorage.getItem("clickedUserId");

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.get(FETCH_USER_DATA_URL(admin, id1), {
        headers,
      });

      if (response.status === 200) {
        setData(response.data.tasks);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  
  const fetchUserData = async () => {
    try {
      const id1 = await localStorage.getItem("clickedUserId");
      const storedToken = await localStorage.getItem("token");
      setToken(storedToken);

      const headers = {
        Authorization: `Bearer ${storedToken}`,
      };

      const response = await axios.get(FETCH_USER_DATA_URL(id1), {
        headers,
      });

      if (response.status === 200) {
        const userData = response.data;
        setUserName(userData.name);
        setDob(userData.dob || ""); // Store DOB if available

        // Show popup if DOB is missing
        if (!userData.dob) {
          setIsDobMissing(true);
        }

        // console.log(response);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

 // Handle DOB update
 const updateDob = async () => {
  try {
    const id1 = await localStorage.getItem("clickedUserId");
    
    // Ensure the new DOB is in ISO format (YYYY-MM-DD)
    const formattedDob = new Date(newDob).toISOString();

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const requestBody = {
      dob: formattedDob, // Sending the formatted DOB
    };

    const response = await axios.put(UPDATE_PROFILE_URL(admin, id1), requestBody, {
      headers,
    });

    if (response.status === 200) {
      // console.log("DOB updated successfully:", response.data);
      setIsDobMissing(false); // Hide the popup after successful update
      setSuccessMessage("Date of Birth updated successfully!"); // Set success message
      setOpenSuccessToast(true); // Show success toast
      fetchUserData(); // Refresh user data to show the updated DOB
    }
  } catch (error) {
    console.error("Error updating DOB:", error);
  }
};


// Handle closing the popup
const handleClosePopup = () => {
  setIsDobMissing(false);
};

  // Handle closing the success toast
  const handleCloseSuccessToast = () => {
    setOpenSuccessToast(false);
  };



  const calculateStatusCounts = () => {
    let assignedCount = 0;
    let pendingCount = 0;
    let completedCount = 0;

    data.forEach((task) => {
      if (task.status === "Assigned") {
        assignedCount++;
      } else if (task.status === "Pending") {
        pendingCount++;
      } else if (
        task.status === "Completed" ||
        task.status === "Completed and Validated"
      ) {
        completedCount++;
      }
    });

    return { assignedCount, pendingCount, completedCount };
  };

  const { assignedCount, pendingCount, completedCount } =
    calculateStatusCounts();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <div className="container">

      {isMobile ? (
        <>
          <AppBar
            position="fixed"
            style={{ background: "transparent", boxShadow: "none" }}
          >
            <Toolbar>
              <IconButton
                color="primary"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
              <p style={{ display: !admin ? "block" : "none" }}>
                Welcome, Admin!
              </p>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="temporary"
            open={isDrawerOpen}
            onClose={handleDrawerToggle}
            ModalProps={{ keepMounted: true }}
          >
            <UserLeftContainer />
          </Drawer>
        </>
      ) : (
        <UserLeftContainer
          userName={userName}
          isAdmin={isAdmin()}
          handleLogout={handleLogout}
        />
      )}
      {/* Popup Snackbar for missing DOB */}
      <Snackbar
        open={isDobMissing}
        onClose={handleClosePopup}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          severity="warning"
          action={
            <>
              {/* Input field for new DOB */}
              <TextField
                label="Enter your Date of Birth"
                type="date"
                value={newDob}
                onChange={(e) => setNewDob(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ marginRight: 10 }}
              />

              {/* Button to update DOB */}
              <Button
                variant="contained"
                color="primary"
                onClick={updateDob}
              >
                Update DOB
              </Button>
            </>
          }
        >
          Please fill in your Date of Birth!
        </Alert>
      </Snackbar>
       {/* Success Toast Snackbar */}
       <Snackbar
        open={openSuccessToast}
        autoHideDuration={6000}
        onClose={handleCloseSuccessToast}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSuccessToast} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>
      <div
        className="right-container"
        style={{
          padding: 0,
        }}
      >
        <div
          className="right-container-table"
          style={{
            marginTop: 0,
          }}
        >
          <TableComponent
            data={{
              assignedCount: assignedCount,
              pendingCount: pendingCount,
              completedCount: completedCount,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  useMediaQuery,
  IconButton,
  Drawer,
  AppBar,
  Toolbar,
  Badge,
  Box,
  Button,
  MenuItem,
  Menu,
  Dialog,
  DialogTitle,
  DialogContent,
  InputLabel,
  Select,
  DialogActions,
  Snackbar,
  Alert,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useNotificationContext } from "../../utils/NotificationContext";
import axios from "axios";
import { GET_ALL_USERS_URL } from "../../api/user";
import {
  ON_BOARDING_PROCESSES_URL,
  ON_BOARDING_PROCESS_SEND_URL,
} from "../../api/onBoarding";
import LeftContainer from "../UI/LeftContainer";
import "../DashboardPage.css";

function OnBoardingAdmin() {
  const [data, setData] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");
  const [selectProcess, setSelectProcess] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  const { state, dispatch } = useNotificationContext();
  const isMobile = useMediaQuery("(max-width: 768px)");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(ON_BOARDING_PROCESSES_URL);
        setData(response.data);
      } catch (error) {
        console.error("Error fetching onboarding processes:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchTeamMembers = async () => {
      try {
        const response = await axios.get(GET_ALL_USERS_URL, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 200) {
          const filteredUsers = response.data.filter(
            (user) => !user.isAdmin && user.isApproved && !user.isDeactivated
          );
          setTeamMembers(filteredUsers);
        }
      } catch (error) {
        console.error("Error fetching team members:", error);
      }
    };

    fetchTeamMembers();
  }, [token]);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("isAdmin");
    window.location.href = "/login";
  };

  const handleDrawerToggle = () => {
    setIsDrawerOpen((prev) => !prev);
  };

  const handleCreateProcessClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCreateTask = () => {
    setIsCreateDialogOpen(true);
  };

  const handleProcessMenuItemClick = (process) => {
    navigate(`/adminProcess/${process}`);
    setAnchorEl(null);
  };

  const handleCloseCreateDialog = () => {
    setIsCreateDialogOpen(false);
    setSelectedUser("");
    setSelectProcess("");
  };

  const handleCreateTaskSubmit = async () => {
    try {
      const res = await axios.post(
        ON_BOARDING_PROCESS_SEND_URL(selectProcess),
        { id: selectedUser }
      );
      setSnackbarMessage(res.data.message);
      setIsSnackbarOpen(true);
      handleCloseCreateDialog();
    } catch (error) {
      console.error("Error creating process task:", error);
    }
  };

  const handleUserSelect = (event) => {
    setSelectedUser(event.target.value);
  };

  return (
    <div className="container">
      {isMobile ? (
        <>
          <AppBar
            position="fixed"
            style={{ background: "transparent", boxShadow: "none" }}
          >
            <Toolbar>
              <IconButton
                color="primary"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="temporary"
            open={isDrawerOpen}
            onClose={handleDrawerToggle}
            ModalProps={{ keepMounted: true }}
          >
            <LeftContainer handleLogout={handleLogout} />
          </Drawer>
        </>
      ) : (
        <LeftContainer handleLogout={handleLogout} />
      )}

      <div className="right-container">
        <div className="right-container-top">
          <p>Welcome, Admin!</p>
          <div className="onboarding-title">
            <h2>Onboarding Board - Admin</h2>
          </div>

          <Dialog
            open={isCreateDialogOpen}
            onClose={handleCloseCreateDialog}
            PaperProps={{
              style: {
                maxWidth: "600px",
                width: "100%",
              },
            }}
          >
            <DialogTitle>Create Process</DialogTitle>
            <DialogContent>
              <div>
                <InputLabel htmlFor="user-select">User</InputLabel>
                <Select
                  labelId="user-select"
                  id="user-select"
                  value={selectedUser}
                  onChange={handleUserSelect}
                  fullWidth
                >
                  {teamMembers.map((member) => (
                    <MenuItem key={member._id} value={member._id}>
                      {member.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div>
                <InputLabel htmlFor="process-select">Process</InputLabel>
                <Select
                  labelId="process-select"
                  id="process-select"
                  value={selectProcess}
                  onChange={(e) => setSelectProcess(e.target.value)}
                  fullWidth
                >
                  {data
                    .filter((process) => !process.userId)
                    .map((process) => (
                      <MenuItem key={process._id} value={process.name}>
                        {process.name}
                      </MenuItem>
                    ))}
                </Select>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseCreateDialog} color="primary">
                Cancel
              </Button>
              <Button onClick={handleCreateTaskSubmit} color="primary">
                Send
              </Button>
            </DialogActions>
          </Dialog>
        </div>

        <div
          className="button-container"
          style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            justifyContent: "center",
            alignItems: "center",
            gap: "50px",
            margin: "120px 0",
          }}
        >
          <Button
            className="submit-button"
            style={{
              backgroundColor: "#6A58C6",
              color: "white",
              padding: "12px 24px",
              fontSize: "16px",
            }}
            onClick={handleCreateProcessClick}
          >
            Create Process
          </Button>

          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            {data
              .filter((process) => !process.userId)
              .map((process) => (
                <MenuItem
                  key={process._id}
                  onClick={() => handleProcessMenuItemClick(process.name)}
                >
                  {process.name}
                </MenuItem>
              ))}
            <MenuItem onClick={() => handleProcessMenuItemClick("Add new Process")}>
              Add new Process
            </MenuItem>
          </Menu>

          <Button
            className="submit-button"
            style={{
              backgroundColor: "#6A58C6",
              color: "white",
              padding: "12px 24px",
              fontSize: "16px",
            }}
            onClick={handleCreateTask}
          >
            Send Process
          </Button>
          <Button
            className="submit-button"
            style={{
              backgroundColor: "#6A58C6",
              color: "white",
              padding: "12px 24px",
              fontSize: "16px",
            }}
            onClick={() => navigate("/viewProgress")}
          >
            View Progress
          </Button>
          <Button
            className="submit-button"
            style={{
              backgroundColor: "#6A58C6",
              color: "white",
              padding: "12px 24px",
              fontSize: "16px",
            }}
            onClick={() => navigate("/completedProgress")}
          >
            Completed
          </Button>
        </div>

        <Snackbar
          open={isSnackbarOpen}
          autoHideDuration={3000}
          onClose={() => setIsSnackbarOpen(false)}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert
            onClose={() => setIsSnackbarOpen(false)}
            severity="success"
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
}

export default OnBoardingAdmin;

import { Alert, Snackbar } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FORGET_PASSWORD_URL } from "../../api/auth";

const BASE_URL = process.env.REACT_APP_BASE_URL;
function ForgetEmail() {
  const navigate = useNavigate();
  const [emailError, setEmailError] = useState("");
  const [email, setEmail] = useState("");
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false); // State for Snackbar
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const forgetmail = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(FORGET_PASSWORD_URL, {
        email,
      });
      if (res.data.status === true) {
        setSnackbarMessage(res.data.message);
        setIsSnackbarOpen(true);
        navigate("/login");
      } else {
        setSnackbarMessage(res.data.message);
        setIsSnackbarOpen(true);
      }
    } catch (error) {
      if (error.response.status === 400) {
        // If the error is a validation error, handle multiple validation errors
        if (error.response.data && error.response.data.details) {
          const validationErrors = Object.values(
            error.response.data.details
          ).flat();
          setSnackbarMessage(
            `Validation error: ${validationErrors.join(", ")}`
          );
        } else {
          // If no details provided, show a generic error message
          setSnackbarMessage("Validation error: Something went wrong.");
        }
        // Open the Snackbar
        setIsSnackbarOpen(true);
      }
    }
  };
  const isValidEmail = (email) => {
    // Regular expression pattern for a valid email address
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };
  const formIsValid = !emailError;

  return (
    <>
      <div className="parentContainer">
        <div className="right-section childContainer">
          <form>
            <h1>Forget Password ?</h1>
            <p>Enter your Email Address</p>
            <label>
              Email:
              <input
                type="email"
                name="email"
                onChange={(e) => {
                  const email = e.target.value;
                  setEmail(email);
                  if (!isValidEmail(email)) {
                    setEmailError("Invalid email address");
                  } else {
                    setEmailError("");
                  }
                }}
                required
              />
              {emailError && <div className="error">{emailError}</div>}
            </label>
            <button disabled={!formIsValid} type="submit" onClick={forgetmail}>
              Reset Password
            </button>
            <p>
              If you don't have an account, you can register it{" "}
              <Link to="/" style={{ color: "blue" }}>
                here
              </Link>
              .
            </p>
          </form>
        </div>
      </div>
      {/* Snackbar for displaying login errors */}
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={3000}
        onClose={() => setIsSnackbarOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          severity={
            snackbarMessage.includes("successfully") ? "success" : "error"
          }
          onClose={() => setIsSnackbarOpen(false)}
        >
          {snackbarMessage.includes("successfully") ? (
            <>{snackbarMessage}</>
          ) : (
            <>{snackbarMessage}</>
          )}
        </Alert>
      </Snackbar>
    </>
  );
}

export default ForgetEmail;

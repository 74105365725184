import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../DashboardPage.css";
import TableComponent from "../Dashboard/TableComponent";
import axios from "axios";
import { GET_ALL_USERS_URL } from "../../api/user";
import { FETCH_USER_DATA_URL } from "../../api/profile";
import {
  Snackbar,
  Alert,
  Button,
  CircularProgress,
  Paper,
  Typography,
} from "@mui/material"; // Import Snackbar and Alert
import ErrorIcon from "@mui/icons-material/Error";
import {
  useMediaQuery,
  IconButton,
  Drawer,
  AppBar,
  Toolbar,
  TextField,
  Box,
} from "@mui/material";

import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import moment from "moment"; // Import the moment library
import { Calendar, DateObject } from "react-multi-date-picker";
import "react-datepicker/dist/react-datepicker.css";
import { DataGrid } from "@mui/x-data-grid";
import { FETCH_LEAVE_BY_ID, SUBMIT_LEAVE } from "../../api/leave";
import UserLeftContainer from "../UI/UserLeftContainer"; 
function Leave() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [onBoardingStatus, setOnBoardingStatus] = useState(false);
  const [leaveRequest, setLeaveRequest] = useState("");
  const [noShow, setNoShow] = useState("");
  const [total, setTotal] = useState("");

  // Assuming you have data for tasks completed and pending for each month

  const columns = [
    { field: "name", headerName: "Name", flex: 1 },
    { field: "dates", headerName: "Dates", flex: 1 },
    {
      field: "reason",
      headerName: "Reason",
      flex: 1,
      cellClassName: (params) =>
        params.value === "No Show" ? "no-show-cell" : "",
    },
    {
      field: "decision",
      headerName: "Decision",
      flex: 1,
      cellClassName: (params) =>
        params.value === "Accept" ? "accepted-cell" : "rejected-cell",
    },
  ];

  const getRowId = (row) => row._id; // Assuming _id is a unique identifier in your data
  useEffect(() => {
    fetchData();
  }, []);
  const [isLoading, setIsLoading] = useState(false); // New loading state
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false); // State for Snackbar
  const [snackbarMessage, setSnackbarMessage] = useState(""); // Message for the Snackbar
  const [selectedDates, setSelectedDates] = useState([``]); // New state for selected dates
  const onChange = (dates) => {
    const formattedDates = dates.map((value) => {
      const formattedDate = new Intl.DateTimeFormat("en-US", {
        day: "numeric",
        month: "short",
        year: "numeric",
      }).format(new Date(value.year, value.month - 1, value.day));
      return formattedDate;
    });

    setSelectedDates(formattedDates);
  };

  const [leaveReason, setLeaveReason] = useState(""); // New state for leave reason

  const handleLeaveReasonChange = (event) => {
    setLeaveReason(event.target.value);
  };

  const handleSubmitLeave = async () => {
    if (selectedDates.length === 0 || selectedDates[0] === "") {
      // Set Snackbar message for mandatory date selection
      setSnackbarMessage("Please select at least one date.");
      setIsSnackbarOpen(true); // Open the Snackbar
      return; // Exit the function without proceeding
    }
    try {
      setIsLoading(true); // Set loading to true when submitting
      const res = await axios.post(
        SUBMIT_LEAVE,
        { reason: leaveReason, dates: selectedDates },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setIsLoading(false); // Set loading to false regardless of success or error
      setLeaveReason("");
      setSelectedDates([``]);

      setSnackbarMessage(res.data.message);
      // Open the Snackbar
      setIsSnackbarOpen(true);
    } catch (error) {
      if (error.response.status === 400) {
        // If the error is a validation error, handle multiple validation errors
        if (error.response.data && error.response.data.details) {
          const validationErrors = Object.values(
            error.response.data.details
          ).flat();
          setSnackbarMessage(
            `Validation error: ${validationErrors.join(", ")}`
          );
        } else {
          // If no details provided, show a generic error message
          setSnackbarMessage("Validation error: Something went wrong." + error);
        }
        // Open the Snackbar
        setIsSnackbarOpen(true);
      }
    } finally {
      setIsLoading(false); // Set loading to false regardless of success or error
    }
    // Handle leave submission logic here
    // Additional logic can be added based on your requirements
  };

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    const verified = await localStorage.getItem("verified");
    setOnBoardingStatus(verified);
    const storedToken = await localStorage.getItem("token");
    setToken(storedToken);
    const storedAdmin = await localStorage.getItem("isAdmin");
    setUserName(localStorage.getItem("username"))
    setAdmin(storedAdmin);
    const id = await localStorage.getItem("Name");
    try {
      const res = await axios.get(FETCH_LEAVE_BY_ID(id, storedAdmin), {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
   
      // Sort the data array based on the updatedAt field
      const sortedData = res.data.data.sort(
        (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
      );

      const noshowlength = sortedData.filter(
        (data) => data.reason === "No Show"
      );

      setTotal(noshowlength.length);
      setNoShow(noshowlength.length);
      setLeaveRequest(sortedData.length - noshowlength.length);
      setData(sortedData);
    } catch (error) {
      console.error(error);
    }
  };
  const [token, setToken] = useState("");
  const [admin, setAdmin] = useState("false");
  const [userName, setUserName] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const isMobile = useMediaQuery("(max-width: 768px)");
  const handleLogout = () => {
    // Remove the token from local storage
    localStorage.removeItem("token");
    localStorage.removeItem("isAdmin");
    localStorage.removeItem("verified");
    // localStorage.removeItem("id");
    // Redirect the user to the login page or any other desired page
    window.location.href = "/login";
  };
  return (
    <div className="container">
      {isMobile ? (
        <>
          <AppBar
            position="fixed"
            style={{ background: "transparent", boxShadow: "none" }}
          >
            <Toolbar>
              <IconButton
                color="primary"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
              <p>Welcome, Admin!</p>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="temporary"
            open={isDrawerOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <UserLeftContainer />
          </Drawer>
        </>
      ) : (
        <>
          <UserLeftContainer />
        </>
      )}
      <div
        className="right-container"
        style={{
          backgroundColor:"#DAEDE3",
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          //  alignItems: "center",
        }}
      >
        <div className="right-container-top">
          <p>
            Hi {userName}
            {admin === "true" ? " admin for overview 👋" : null}
          </p>
          <Button
            variant="contained"
            color="primary"
            className="createTask"
            style={{
              marginLeft: "80%",
              marginBottom: "20px",
            }}
            onClick={() => navigate("/noshow")}
          >
            No Show
          </Button>
          <div className="status-boxes">
            <div className="status-box assigned">Requested: {leaveRequest}</div>
            <div className="status-box pending">No Show: {noShow}</div>
            <div className="status-box completed">Total: {}</div>
          </div>
        </div>

        {admin === "true" ? null : (
          <>
            <Typography
              variant="body1"
              paragraph
              style={{
                fontSize: "30px",
              }}
            >
              Select Date for Leave Apply
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                justifyContent: "space-around",
                alignContent: "center",
              }}
            >
              <Paper
                elevation={5}
                style={{
                  padding: "20px",
                  marginBottom: "2%",
                  boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)",
                  width: "70%",
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  justifyContent: "space-evenly",
                  paddingLeft: "-10%",
                }}
              >
                <div className="calendar-container">
                  <Calendar multiple onChange={onChange} />
                </div>

                <div className="selected-dates">
                  {selectedDates.length > 0 && (
                    <>
                      <h2>Selected Dates:</h2>
                      <div className="selected-dates-list">
                        {selectedDates.map((selectedDate) => (
                          <span key={selectedDate}>
                            {" "}
                            <h3>
                              {selectedDate}
                              <br />
                            </h3>
                          </span>
                        ))}
                      </div>
                    </>
                  )}
                </div>
              </Paper>
              <div className="right-container-bottom">
                <Typography
                  variant="body1"
                  paragraph
                  style={{
                    fontSize: "30px",
                  }}
                >
                  Leave Details
                </Typography>
                <TextField
                  label="Reason for Leave"
                  variant="outlined"
                  fullWidth
                  value={leaveReason}
                  onChange={handleLeaveReasonChange}
                />
                <Box mt={2} marginLeft={3}>
                  {!isLoading ? (
                    <Button
                      className="submit-button"
                      onClick={handleSubmitLeave}
                      disabled={isLoading} // Disable the button when loading
                      style={{
                        backgroundColor: "#6A58C6",
                        color: "white",
                        fontWeight: "bold",
                        height: "45px",
                        width: "150px",
                      }}
                    >
                      Submit
                    </Button>
                  ) : (
                    <CircularProgress
                      size={20}
                      style={{
                        color: "#6A58C6",
                      }}
                    />
                  )}
                </Box>
              </div>
            </div>
          </>
        )}

        <div className="right-container-table" style={{ marginTop: "50px" }}>
          <Typography
            variant="body1"
            paragraph
            style={{
              fontSize: "30px",
            }}
          >
            Leave
          </Typography>
          <div style={{ height: 400, width: "100%" }}>
            <DataGrid
              rows={data}
              columns={columns}
              pageSize={10}
              getRowId={getRowId}
              style={{
                width: "100%",
                marginBottom: "30%",

                boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)",
                borderRadius: "20px",
                backgroundColor: "white",
              }}
            />
          </div>
        </div>
      </div>
      {/* Dialog for creating No Show Leave */}

      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={3000}
        onClose={() => setIsSnackbarOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          severity={
            snackbarMessage.includes("successfully") ? "success" : "error"
          }
          onClose={() => setIsSnackbarOpen(false)}
        >
          {snackbarMessage.includes("successfully") ? (
            <>{snackbarMessage}</>
          ) : (
            <>{snackbarMessage}</>
          )}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Leave;

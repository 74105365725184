import React, { useState, useEffect } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Menu,
  MenuItem,
  useMediaQuery,
  IconButton,
  Drawer,
  AppBar,
  Toolbar,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import { UserLeftContainer } from "../UI/index";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { Link, Route, Routes } from "react-router-dom";
import {
  CREATE_TASK_URL,
  GET_ALL_USERS_URL,
  GET_ALL_TEAMS_URL,
} from "../../api/user";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const NewTeam = () => {
  const [isCreateTaskOpen, setIsCreateTaskOpen] = useState(false);
  const [userName, setUserName] = useState("");
  const [data, setData] = useState([]);
  const [id, setId] = useState("");
  const [admin, setAdmin] = useState("false");
  const [taskName, setTaskName] = useState("");
  const [taskDescription, setTaskDescription] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [teams, setTeams] = useState([]);
  const [users, setUsers] = useState([]);
  const token = localStorage.getItem("token");
  useEffect(() => {
    fetchTeams();
    fetchUsers();
  }, []);

  const isAdmin = () => {
    return admin === "true";
  };

  const handleLogout = () => {
    // Remove the token from local storage
    localStorage.removeItem("token");
    localStorage.removeItem("isAdmin");
    localStorage.removeItem("verified");
    // localStorage.removeItem("id");
    // Redirect the user to the login page or any other desired page
    window.location.href = "/login";
  };
  const userId = localStorage.getItem("id");
  const fetchUsers = async () => {
    try {
      const response = await axios.get(GET_ALL_USERS_URL, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 200) {
        const userFilter = response.data.filter(
          (value) => !value.isDeactivated && value.isApproved
        );
        setUsers(userFilter);
      }
    } catch (error) {
      console.error("Error fetching users", error);
    }
  };
  const fetchTeams = async () => {
    try {
      const response = await axios.get(GET_ALL_TEAMS_URL, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 200) {
        const filteredTeams = response.data.filter(
          (team) => team.manager === userId && team.members.includes(userId)
        );
        setTeams(filteredTeams);
      }
    } catch (error) {
      console.error("Error fetching teams:", error);
    }
  };
  const handleCreateTask = () => {
    setIsCreateTaskOpen(true);
  };

  const handleCloseCreateTask = () => {
    setIsCreateTaskOpen(false);
    setTaskName("");
    setTaskDescription("");
    setSelectedUsers([]);
  };

  const handleAddUser = (user) => {
    setSelectedUsers([...selectedUsers, user]);
    setAnchorEl(null);
  };

  const handleDeleteUser = (user) => {
    setSelectedUsers(selectedUsers.filter((u) => u !== user));
  };

  const handleCreateTaskSubmit = async () => {
    const newTask = {
      name: taskName,
      description: taskDescription,
      assignedTo: selectedUsers.map((user) => user._id),
    };

    try {
      const response = await axios.post(CREATE_TASK_URL, newTask); // Replace with your backend API endpoint
      const createdTask = response.data;
      // Handle created task
      handleCloseCreateTask();
    } catch (error) {
      console.error("Error creating task:", error);
    }
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const isMobile = useMediaQuery("(max-width: 768px)");
  return (
    <div className="container">
      {isMobile ? (
        <>
          <AppBar
            position="fixed"
            style={{ background: "transparent", boxShadow: "none" }}
          >
            <Toolbar>
              <IconButton
                color="primary"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
              <p>Welcome, Admin!</p>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="temporary"
            open={isDrawerOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <UserLeftContainer />
          </Drawer>
        </>
      ) : (
        <>
          <UserLeftContainer
            userName={userName}
            isAdmin={isAdmin()}
            handleLogout={handleLogout}
          />
        </>
      )}
      <div
        className="right-container"
        style={{
          padding: 0,
        }}
      >
        <div
          className="right-container-table"
          style={{
            marginTop: 0,
          }}
        >
          <div className="new-team-container">
            <h2>Your Teams</h2>
            <div className="team-box-container">
              {teams.map((team, index) => (
                <Link
                  to={`/team/${team.name}/${team._id}`}
                  key={index}
                  className="link-no-underline"
                >
                  <Paper className="team-box">
                    <h3>{team.name}</h3>
                    <p>Total Members: {team.members.length}</p>
                  </Paper>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewTeam;

import React, { useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Card, CardContent } from "@mui/material";

const NonLinearStepper = ({
  activeStep,
  completed,
  handleStep,
  handleNext,
  handleBack,
  handleComplete,
  handleReset,
  formInstances,
  responsive,
}) => {
  const steps = formInstances.map(
    (instance, index) => instance.field1 || `Step ${index + 1}`
  );
  const link = formInstances.map(
    (instance, index) => instance.field2 || `Step ${index + 1}`
  );
  // console.log(link);
  const totalSteps = () => {
    return steps.length;
  };
  const handleViewLink = (link) => {
    setViewLink(viewLink === link ? null : link);
  };
  const [viewLink, setViewLink] = useState(null);
  const allStepsCompleted = () => {
    return Object.keys(completed).length === totalSteps();
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper
        nonLinear
        activeStep={activeStep}
        orientation={responsive ? "vertical" : "horizontal"}
      >
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepButton color="inherit" onClick={handleStep(index)}>
              {label}
            <Button onClick={() => handleViewLink(link[index])}>
              {viewLink === link[index] ? "Close" : "View"}
            </Button>
            </StepButton>
            {viewLink === link[index] && (
              <Card>
                <CardContent>
                  <a
                    href={link[index]}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Typography>{link[index]}</Typography>
                  </a>
                </CardContent>
              </Card>
            )}
          </Step>
        ))}
      </Stepper>
      <div>
        {allStepsCompleted() ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
              Step {activeStep + 1}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: responsive ? "column" : "row",
                pt: 2,
              }}
            >
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: responsive ? 0 : 1, mb: responsive ? 1 : 0 }}
              >
                Back
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleNext} sx={{ mr: 1 }}>
                Next
              </Button>
              {activeStep !== steps.length &&
                (completed[activeStep] ? (
                  <Typography
                    variant="caption"
                    sx={{
                      display: "inline-block",
                      ml: responsive ? 0 : 1,
                      mb: responsive ? 1 : 0,
                    }}
                  >
                    Step {activeStep + 1} already completed
                  </Typography>
                ) : (
                  <Button onClick={handleComplete}>
                    {Object.keys(completed).length === totalSteps() - 1
                      ? "Finish"
                      : "Complete Step"}
                  </Button>
                ))}
            </Box>
          </React.Fragment>
        )}
      </div>
    </Box>
  );
};

export default NonLinearStepper;

import React, { useState, useEffect } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  InputLabel,
} from "@mui/material";
import {
  useMediaQuery,
  IconButton,
  Drawer,
  AppBar,
  Toolbar,
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import axios from "axios";
import { Link, Navigate, useHistory, useNavigate } from "react-router-dom";
import uuid from "react-uuid";
import { ISSUE_CERTIFICATE_URL, ISSUE_LOR_URL } from "../../api/document";
import {
  DEACTIVATE_ACCESS_REQ,
  GET_ALL_TEAMS_URL,
  GET_ALL_USERS_URL,
} from "../../api/user";
import LeftContainer from "../UI/LeftContainer";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const TeamTableView = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const isMobile = useMediaQuery("(max-width: 768px)");
  const navigate = useNavigate();
  const [isCertificateDialogOpen, setCertificateDialogOpen] = useState(false);
  const [isLORDialogOpen, setLORDialogOpen] = useState(false);
  const [isDeactivateDialogOpen, setDeactivateDialogOpen] = useState(false);
  const [selectedUserName, setSelectedUserName] = useState("");
  const [expandedUsers, setExpandedUsers] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [teamList, setTeamList] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState("");

  useEffect(() => {}, []);
  const downloadLOR = async (userId) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/documentRequest/generate-pdf/${userId}/LOR`,
        {
          responseType: "blob", // Set the responseType to 'blob' to receive a Blob object
        }
      );

      if (response.status === 200) {
        // Create a Blob object containing the LOR data
        const blob = new Blob([response.data], { type: "application/pdf" });

        // Create a URL for the Blob object
        const url = window.URL.createObjectURL(blob);

        // Create an anchor element to trigger the download
        const a = document.createElement("a");
        a.href = url;
        a.download = "lor.pdf"; // Set the desired file name

        // Trigger a click event on the anchor element to initiate the download
        a.click();

        // Release the URL object
        window.URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.error("Error generating or downloading LOR:", error);
    }
  };
  const viewCertPage = async (id) => {
    navigate(`/certificate/${id}`);
  };
  const viewLorPage = async (id) => {
    navigate(`/lor/${id}`);
  };
  // const getCertificate = async () => {
  //   try {
  //     // Replace BASE_URL with the actual base URL of your server
  //     const response = await axios.get(
  //       `${BASE_URL}/api/documentRequest/show-pdf`,
  //       {
  //         headers: {
  //           "Content-Type": "application/json", // Ensure the correct Content-Type header
  //         },
  //         responseType: "blob", // Set the responseType to 'blob' to receive a Blob object
  //       }
  //     );
  //     console.error(response.data);
  //     // Create a Blob object from the response data with the correct MIME type
  //     const blob = new Blob([response.data], { type: "application/pdf" });

  //     // Create a URL for the Blob object
  //     const url = window.URL.createObjectURL(blob);

  //     // Create an anchor element to trigger the download
  //     const a = document.createElement("a");
  //     a.href = url;
  //     a.download = "certificate.pdf"; // Set the desired file name

  //     // Trigger a click event on the anchor element to initiate the download
  //     a.click();

  //     // Release the URL object
  //     window.URL.revokeObjectURL(url);
  //   } catch (error) {
  //     console.error("Error downloading PDF:", error);
  //     // Handle any errors, such as network issues or server errors
  //   }
  // };

  // const issueCertificate = async (userId, name, days, position) => {
  //   try {
  //     // Send a POST request to generate the PDF certificate
  //     const response = await axios.post(
  //       `${BASE_URL}/api/documentRequest/generate-pdf/${userId}/Certificate`,
  //       { name, days, position }, // Request payload as an object
  //       {
  //         headers: {
  //           "Content-Type": "application/json", // Ensure the correct Content-Type header
  //         },
  //         responseType: "blob", // Set the responseType to 'blob' to receive a Blob object
  //       }
  //     );
  //     if (response.status === 200) {
  //       // Create a Blob object containing the certificate data
  //       console.log(response.data);
  //       const blob = new Blob([response.data], { type: "application/pdf" });

  //       // Create a URL for the Blob object
  //       const url = window.URL.createObjectURL(blob);

  //       // Create an anchor element to trigger the download
  //       const a = document.createElement("a");
  //       a.href = url;
  //       a.download = "certificate.pdf"; // Set the desired file name

  //       // Trigger a click event on the anchor element to initiate the download
  //       a.click();
  //       console.log(url);
  //       // Release the URL object
  //       window.URL.revokeObjectURL(url);
  //     }
  //   } catch (error) {
  //     console.error("Error generating or downloading certificate:", error);
  //   }
  // };

  const issueCertificate = async (
    userId,
    name,
    days,
    position,
    startDate,
    endDate
  ) => {
    try {
      // Send a POST request to generate the PDF certificate

      // Construct the API endpoint for issuing a certificate

      const issueCertificateUrl = ISSUE_CERTIFICATE_URL(userId);

      // Send a POST request to generate the PDF certificate
      const response = await axios.post(issueCertificateUrl, {
        name,
        days,
        position,
        startDate,
        endDate,
        certId: uuid(),
      });
      if (response.status === 200) {
        // Create a Blob object containing the certificate data
        navigate(`/certificate/${response.data.cert.userid}`);
      }
    } catch (error) {
      console.error("Error generating or downloading certificate:", error);
    }
  };

  const issueLOR = async (
    userId,
    name,
    joiningDate,
    finishingDate,
    position,
    months,
    stories,
    tasks
  ) => {
    try {
      const issueLorUrl = ISSUE_LOR_URL(userId);

      // Send a POST request to generate the LOR
      const response = await axios.post(issueLorUrl, {
        name,
        certId: uuid(),
        joiningDate,
        finishingDate,
        position,
        months,
        stories,
        tasks,
      });
      if (response.status === 200) {
        navigate(`/lor/${response.data.lor.userid}`);
      }
    } catch (error) {
      console.error("Error generating LOR:", error);
    }
  };
  // const issueLOR = async (
  //   userId,
  //   name,
  //   joiningDate,
  //   finishingDate,
  //   position,
  //   months,
  //   stories,
  //   tasks
  // ) => {
  //   try {
  //     const response = await axios.post(
  //       `${BASE_URL}/api/documentRequest/generate-pdf/${userId}/LOR`,
  //       {
  //         name,
  //         joiningDate,
  //         finishingDate,
  //         position,
  //         months,
  //         stories,
  //         tasks,
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json", // Ensure the correct Content-Type header
  //         },
  //         responseType: "blob", // Set the responseType to 'blob' to receive a Blob object
  //       }
  //     );
  //     if (response.status === 200) {
  //       // Create a Blob object containing the LOR data
  //       const blob = new Blob([response.data], { type: "application/pdf" });

  //       // Create a URL for the Blob object
  //       const url = window.URL.createObjectURL(blob);

  //       // Create an anchor element to trigger the download
  //       const a = document.createElement("a");
  //       a.href = url;
  //       a.download = "lor.pdf"; // Set the desired file name (you can change it to 'lor.pdf')

  //       // Trigger a click event on the anchor element to initiate the download
  //       a.click();

  //       // Release the URL object
  //       window.URL.revokeObjectURL(url);
  //     }
  //   } catch (error) {
  //     console.error("Error generating LOR:", error);
  //   }
  // };

  const handleLogout = () => {
    localStorage.removeItem("token");
    window.location.href = "/login";
  };

  // const handleExpand = (userId) => {
  //   if (expandedUsers.includes(userId)) {
  //     setExpandedUsers(expandedUsers.filter((id) => id !== userId));
  //   } else {
  //     setExpandedUsers([...expandedUsers, userId]);
  //   }
  // };

  const openCertificateDialog = (userName, userId) => {
    setSelectedUserId(userId);
    setSelectedUserName(userName);
    setCertificateDialogOpen(true);
  };

  const openLORDialog = (userName, userId) => {
    setSelectedUserId(userId);
    setSelectedUserName(userName);
    setLORDialogOpen(true);
  };

  const openDeactivateDialog = (userName, userId) => {
    setSelectedUserId(userId);
    setSelectedUserName(userName);
    setDeactivateDialogOpen(true);
  };
  const deactivateHandle = async (id) => {
    try {
      const storedToken = await localStorage.getItem("token");
      const response = await axios.get(DEACTIVATE_ACCESS_REQ(id), {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
  
      if (response.status === 200) {
        // console.log('Deactivation successful:', response.data);
        setDeactivateDialogOpen(false);
        // Optionally refresh data or update state here
      } else {
        console.error('Unexpected response status:', response.status);
      }
    } catch (error) {
      console.error('Error during deactivation:', error);
      // Optionally show an error message to the user here
    }
  };
  
  const confirmAction = (
    actionType,
    name,
    joiningDate,
    finishingDate,
    position,
    months,
    stories,
    tasks,
    days
  ) => {
    switch (actionType) {
      case "certificate":
        // Call the issueCertificate function with the entered values
        issueCertificate(
          selectedUserId,
          name,
          joiningDate,
          finishingDate,
          position,
          months
        );
        break;
      case "lor":
        // Call the issueLOR function
        issueLOR(
          selectedUserId,
          name,
          joiningDate,
          finishingDate,
          position,
          months,
          stories,
          tasks
        );
        break;
      case "deactivate":
        deactivateHandle(selectedUserId);

        break;
      default:
        break;
    }

    setCertificateDialogOpen(false);
    setLORDialogOpen(false);
    setDeactivateDialogOpen(false);
    setSelectedUserName("");
  };

  useEffect(() => {
    fetchData();
    fetchTeams();
  }, []);

  // const fetchTeamDetails = async (teamId) => {
  //   try {
  //     const response = await axios.get(`${BASE_URL}/api/teamRequest/${teamId}`);
  //     if (response.status === 200) {
  //       return response.data.name;
  //     }
  //   } catch (error) {
  //     console.error("Error fetching team details:", error);
  //     return "";
  //   }
  // };

  const fetchData = async () => {
    try {
      const storedToken = await localStorage.getItem("token");
      const response = await axios.get(GET_ALL_USERS_URL, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
  
      if (response.status === 200) {
        const currentDate = new Date();
        const users = response.data.filter(
          (user) => user.isApproved && !user.isAdmin
        );
  
        const formattedMembers = [];
        const usersById = {};
  
        users.forEach(user => {
          const dateOfJoining = new Date(user.dateOfJoining);
          const durationOfInternship = user.durationOfInternship;
          const endDate = new Date(dateOfJoining);
          endDate.setMonth(endDate.getMonth() + durationOfInternship);
  
          let daysLeft = Math.floor((endDate - currentDate) / (1000 * 60 * 60 * 24));
          if (daysLeft <= 0) {
            daysLeft = "Tenure Over";
          }
  
          if (!usersById[user._id]) {
            usersById[user._id] = {
              _id: user._id,
              name: user.name,
              role: user.positionOfJoining,
              daysLeft: daysLeft,
              position: user.isManager ? "Manager" : "User",
              certificate: user.certificate,
              lor: user.lor,
              startDate: user.startDate,
              endDate: user.endDate,
              isDeactivated: user.isDeactivated,
            };
          } else {
            usersById[user._id].position += `, ${user.isManager ? "Manager" : "User"}`;
          }
        });
  
        const usersArray = Object.values(usersById);
        // console.log(usersArray);
        setTeamMembers(usersArray);
      }
    } catch (error) {
      console.error("Error fetching team members:", error);
      if (error.response && error.response.status === 401) {
        console.error("Unauthorized access - redirecting to login");
        // Add your redirect logic here, e.g., window.location.href = '/login';
      }
    }
  };
  
  const fetchTeams = async () => {
    try {
      const token = localStorage.getItem('token'); // Or however you store your auth token
      const response = await axios.get(GET_ALL_TEAMS_URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        setTeamList(response.data);
      }
    } catch (error) {
      console.error("Error fetching teams", error);
      if (error.response && error.response.status === 401) {
        // Handle 401 Unauthorized error (e.g., redirect to login page)
        console.error("Unauthorized access - redirecting to login");
        // Add your redirect logic here, e.g., window.location.href = '/login';
      }
    }
  };
  

  // Certificate Dialog
  // Certificate Dialog
  const CertificateDialog = ({ isOpen, onClose, onConfirm }) => {
    const [name, setName] = useState("");
    const [days, setDays] = useState("");
    const [position, setPosition] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const handleConfirm = () => {
      // Pass the entered values to the confirmAction function
      confirmAction("certificate", name, days, position, startDate, endDate);
    };

    return (
      <Dialog open={isOpen} onClose={onClose}>
        <DialogTitle>Issue Certificate</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the details for the certificate:
          </DialogContentText>
          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
            margin="normal"
          />
          <InputLabel>Starting Date</InputLabel>
          <TextField
            // label="Starting Date"
            variant="outlined"
            type="date"
            fullWidth
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            margin="normal"
          />
          <InputLabel>Ending Date</InputLabel>
          <TextField
            // label="Ending Date"
            variant="outlined"
            type="date"
            fullWidth
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            margin="normal"
          />
          <TextField
            label="Position"
            variant="outlined"
            fullWidth
            value={position}
            onChange={(e) => setPosition(e.target.value)}
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  // LORDialog
  const LORDialog = ({ isOpen, onClose, userName, onConfirm }) => {
    const [name, setName] = useState("");
    const [joiningDate, setJoiningDate] = useState("");
    const [finishingDate, setFinishingDate] = useState("");
    const [position, setPosition] = useState("");
    const [months, setMonths] = useState("");
    const [stories, setStories] = useState("");
    const [tasks, setTasks] = useState("");

    const handleConfirm = () => {
      // Pass the entered values to the confirmAction function
      confirmAction(
        "lor",
        name,
        joiningDate,
        finishingDate,
        position,
        months,
        stories,
        tasks
      );
    };

    return (
      <Dialog open={isOpen} onClose={onClose}>
        <DialogTitle>Issue LOR</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the details for the Letter of Recommendation:
          </DialogContentText>
          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
            margin="normal"
          />
          <TextField
            label="Joining Date"
            type="date"
            variant="outlined"
            fullWidth
            value={joiningDate}
            onChange={(e) => setJoiningDate(e.target.value)}
            margin="normal"
          />
          <TextField
            label="Finishing Date"
            type="date"
            variant="outlined"
            fullWidth
            value={finishingDate}
            onChange={(e) => setFinishingDate(e.target.value)}
            margin="normal"
          />
          <TextField
            label="Position"
            variant="outlined"
            fullWidth
            value={position}
            onChange={(e) => setPosition(e.target.value)}
            margin="normal"
          />
          <TextField
            label="Months Worked"
            variant="outlined"
            fullWidth
            value={months}
            onChange={(e) => setMonths(e.target.value)}
            margin="normal"
          />
          <TextField
            label="Number of Stories"
            variant="outlined"
            fullWidth
            value={stories}
            onChange={(e) => setStories(e.target.value)}
            margin="normal"
          />
          <TextField
            label="Tasks"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            value={tasks}
            onChange={(e) => setTasks(e.target.value)}
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleConfirm()} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  // Deactivate Account Dialog
  const DeactivateDialog = ({ isOpen, onClose, userName, onConfirm }) => {
    return (
      <Dialog open={isOpen} onClose={onClose}>
        <DialogTitle>Deactivate Account</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you want to deactivate the account of {userName}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button onClick={() => confirmAction("deactivate")} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  return (
    <div className="container">
      {isMobile ? (
        <>
          <AppBar
            position="fixed"
            style={{ background: "transparent", boxShadow: "none" }}
          >
            <Toolbar>
              <IconButton
                color="primary"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
              {/* <LeftContainer handleLogout={handleLogout} /> */}
            </Toolbar>
          </AppBar>
          <Drawer
            variant="temporary"
            open={isDrawerOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {/* ... Other code ... */}
            <LeftContainer handleLogout={handleLogout} />
            {/* ... Other code ... */}
          </Drawer>
        </>
      ) : (
        <>
          <LeftContainer handleLogout={handleLogout} />
          {/* ... Other code ... */}
        </>
      )}

      <div className="right-container">
        <div className="right-container-top">
          <p>Welcome, Admin!</p>
        </div>
        <div className="right-container-table">
          <h1>User Management</h1>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell>Position</TableCell>
                  <TableCell>Days Left</TableCell>
                  <TableCell>Certificate</TableCell>
                  <TableCell>LOR</TableCell>
                  <TableCell>Deactivate</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {teamMembers.map((member) => (
                  <TableRow key={member._id}>
                    <TableCell>{member.name}</TableCell>
                    <TableCell>{member.role}</TableCell>
                    <TableCell>{member.position}</TableCell>
                    <TableCell>{member.daysLeft}</TableCell>
                    <TableCell>
                      {member.certificate === true ? (
                        <>
                          <Button onClick={() => viewCertPage(member._id)}>
                            View
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            onClick={() =>
                              openCertificateDialog(member.name, member._id)
                            }
                          >
                            Certificate
                          </Button>
                        </>
                      )}
                    </TableCell>
                    <TableCell>
                      {member.lor === true ? (
                        <>
                          <Button onClick={() => viewLorPage(member._id)}>
                            View
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            onClick={() =>
                              openLORDialog(member.name, member._id)
                            }
                          >
                            LOR
                          </Button>
                        </>
                      )}
                    </TableCell>
                    <TableCell>
                      <Button
                        onClick={() =>
                          openDeactivateDialog(member.name, member._id)
                        }
                        disabled={member.isDeactivated}
                      >
                        Deactivate
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <CertificateDialog
          isOpen={isCertificateDialogOpen}
          onClose={() => setCertificateDialogOpen(false)}
          userName={selectedUserName}
        />
        <LORDialog
          isOpen={isLORDialogOpen}
          onClose={() => setLORDialogOpen(false)}
          userName={selectedUserName}
        />
        <DeactivateDialog
          isOpen={isDeactivateDialogOpen}
          onClose={() => setDeactivateDialogOpen(false)}
          userName={selectedUserName}
        />
      </div>
    </div>
  );
};

export default TeamTableView;

import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Snackbar, Alert } from "@mui/material";
import { RESET_PASSWORD_URL } from "../../api/auth";

const BASE_URL = process.env.REACT_APP_BASE_URL;
function ResetPassword() {
  const naviagte = useNavigate();
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false); // State for Snackbar
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const data = useParams();
  const resetpassword = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(RESET_PASSWORD_URL(data.id, data.token), {
        password,
        confirmPassword,
      });

      if (res.data.status === true) {
        setSnackbarMessage(res.data.message);
        setIsSnackbarOpen(true);
        naviagte("/login");
      }
    } catch (error) {
      if (error.response.status === 400) {
        // If the error is a validation error, handle multiple validation errors
        if (error.response.data && error.response.data.details) {
          const validationErrors = Object.values(
            error.response.data.details
          ).flat();
          setSnackbarMessage(
            `Validation error: ${validationErrors.join(", ")}`
          );
        } else {
          // If no details provided, show a generic error message
          setSnackbarMessage("Validation error: Something went wrong.");
        }
        // Open the Snackbar
        setIsSnackbarOpen(true);
      }
      console.error(error);
    }
  };
  const formIsValid = !passwordError && !confirmPasswordError;

  return (
    <>
      <div className="parentContainer">
        <div className="right-section childContainer">
          <form>
            <h1>Reset Password ?</h1>
            <label>
              Password:
              <input
                type="password"
                name="password"
                // value={}
                onChange={(e) => {
                  const newPassword = e.target.value;
                  setPassword(newPassword);

                  if (newPassword.length < 6) {
                    setPasswordError(
                      "Password must be at least 6 characters long"
                    );
                  } else {
                    setPasswordError("");
                  }
                }}
                required
              />
              {passwordError && <div className="error">{passwordError}</div>}
            </label>
            <label>
              Confirm Password:
              <input
                type="password"
                name="confirmPassword"
                // value={}
                onChange={(e) => {
                  const newConfirmPassword = e.target.value;
                  setConfirmPassword(newConfirmPassword);

                  if (newConfirmPassword !== password) {
                    setConfirmPasswordError("Passwords do not match");
                  } else {
                    setConfirmPasswordError("");
                  }
                }}
                required
              />
              {confirmPasswordError && (
                <div className="error">{confirmPasswordError}</div>
              )}
            </label>
            <button
              disabled={!formIsValid}
              type="submit"
              onClick={resetpassword}
            >
              Reset Password
            </button>
            <p>
              If you don't have an account, you can register it{" "}
              <Link to="/" style={{ color: "blue" }}>
                here
              </Link>
              .
            </p>
          </form>
        </div>
      </div>
      {/* Snackbar for displaying login errors */}
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={3000}
        onClose={() => setIsSnackbarOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          severity={
            snackbarMessage.includes("successfully") ? "success" : "error"
          }
          onClose={() => setIsSnackbarOpen(false)}
        >
          {snackbarMessage.includes("successfully") ? (
            <>{snackbarMessage}</>
          ) : (
            <>{snackbarMessage}</>
          )}
        </Alert>
      </Snackbar>
    </>
  );
}

export default ResetPassword;

import React, { useState } from "react";
import "./Home.css";
import { Link } from "react-router-dom";
import axios from "axios";
import { SUBMIT_USER } from "../../api/auth";
import { Alert, Snackbar } from "@mui/material";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const HomePage = () => {
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    college: "",
    positionOfJoining: "",
    dateOfJoining: "",
    durationOfInternship: "",
    dob: "", // Date of Birth added
    address: "",
    city: "",
    state: "",
    contactNumber: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    college: "",
    positionOfJoining: "",
    dateOfJoining: "",
    durationOfInternship: "",
    dob: "", // Error for Date of Birth added
    address: "",
    city: "",
    state: "",
    contactNumber: "",
  });

  const [notification, setNotification] = useState(""); // Notification state
  const [isSubmitting, setIsSubmitting] = useState(false); // Activity indicator state
  const [isFormValid, setIsFormValid] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;

    let error = "";
    if (name === "name" && value.trim() === "") {
      error = "Name is required";
    } else if (name === "email" && !isValidEmail(value)) {
      error = "Invalid email address";
    } else if (name === "college" && (value.length < 4 || value.length > 30)) {
      error = "College must be between 4 and 30 characters";
    } else if (name === "contactNumber" && !isValidPhoneNumber(value)) {
      error = "Invalid phone number";
    } else if (name === "dob" && !isValidDOB(value)) {
      error = "Invalid Date of Birth";
    } else if (name === "address" && (value.length < 4 || value.length > 100)) {
      error = "Address must be between 4 and 100 characters";
    } else if (name === "city" && (value.length < 2 || value.length > 50)) {
      error = "City must be between 2 and 50 characters";
    } else if (name === "state" && (value.length < 2 || value.length > 30)) {
      error = "State must be between 2 and 30 characters";
    } else if (
      name === "positionOfJoining" &&
      (value.length < 4 || value.length > 30)
    ) {
      error = "Position of Joining must be between 4 and 30 characters";
    }

    const formIsValid = Object.values(errors).every((error) => error === "");

    setIsFormValid(formIsValid);
    // Update the errors state
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const isValidEmail = (email) => {
    // Regular expression pattern for a valid email address
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };

  const isValidPhoneNumber = (phone) => {
    // Regular expression pattern for a valid US phone number (10 digits)
    const phonePattern = /^\d{10}$/;
    return phonePattern.test(phone);
  };

  const isValidDOB = (dob) => {
    const currentDate = new Date();
    const selectedDate = new Date(dob);
    return selectedDate < currentDate; // Ensure DOB is in the past
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setIsSubmitting(true); // Start activity indicator
      setNotification(""); // Clear previous notification

      await axios.post(SUBMIT_USER, formData);

      setFormData({
        name: "",
        email: "",
        college: "",
        positionOfJoining: "",
        dateOfJoining: "",
        durationOfInternship: "",
        dob: "",
        address: "",
        city: "",
        state: "",
        contactNumber: "",
      });

      setNotification("Request submitted successfully");
    } catch (error) {
      if (error.response.status === 400) {
        // If the error is a validation error, handle multiple validation errors
        if (error.response.data && error.response.data.details) {
          const validationErrors = Object.values(
            error.response.data.details
          ).flat();
          setSnackbarMessage(
            `Validation error: ${validationErrors.join(", ")}`
          );
        } else {
          // If no details provided, show a generic error message
          setSnackbarMessage("Validation error: Something went wrong.");
        }
        // Open the Snackbar
        setIsSnackbarOpen(true);
      }
      console.error(
        "Error submitting user request:",
        error.response.data.message
      );
      setNotification(error.response.data.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <div className="section">
        <div className="left-section">
          <p>Welcome to the IMS Portal</p>
          <p>by Markoknow</p>
        </div>
        <div className="right-section">
          <form onSubmit={handleSubmit}>
            <p>Fill in the credentials and click on Submit:</p>
            <label>
              Name:
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
              {errors.name && <div className="error">{errors.name}</div>}
            </label>
            <label>
              Email:
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              {errors.email && <div className="error">{errors.email}</div>}
            </label>
            <label>
              College:
              <input
                type="text"
                name="college"
                value={formData.college}
                onChange={handleChange}
                required
              />
              {errors.college && <div className="error">{errors.college}</div>}
            </label>
            <label>
              Position of Joining:
              <input
                type="text"
                name="positionOfJoining"
                value={formData.positionOfJoining}
                onChange={handleChange}
                required
              />
              {errors.positionOfJoining && (
                <div className="error">{errors.positionOfJoining}</div>
              )}
            </label>
            <label>
              Date of Joining:
              <input
                type="date"
                name="dateOfJoining"
                value={formData.dateOfJoining}
                onChange={handleChange}
                required
              />
            </label>
            <label>
              Date of Birth:
              <input
                type="date"
                name="dob"
                value={formData.dob}
                onChange={handleChange}
                required
              />
            </label>
            <label>
              Duration of Internship (in months):
              <input
                type="number"
                name="durationOfInternship"
                value={formData.durationOfInternship}
                onChange={handleChange}
                required
              />
            </label>
            <label>
              Address:
              <input
                type="text"
                name="address"
                value={formData.address}
                onChange={handleChange}
                required
              />
              {errors.address && <div className="error">{errors.address}</div>}
            </label>
            <label>
              City:
              <input
                type="text"
                name="city"
                value={formData.city}
                onChange={handleChange}
                required
              />
              {errors.city && <div className="error">{errors.city}</div>}
            </label>
            <label>
              State:
              <input
                type="text"
                name="state"
                value={formData.state}
                onChange={handleChange}
                required
              />
              {errors.state && <div className="error">{errors.state}</div>}
            </label>
            <label>
              Contact Number:
              <input
                type="tel"
                name="contactNumber"
                value={formData.contactNumber}
                onChange={handleChange}
                required
              />
              {errors.contactNumber && (
                <div className="error">{errors.contactNumber}</div>
              )}
            </label>
            <button type="submit" disabled={isSubmitting || !isFormValid}>
              {isSubmitting ? "Submitting..." : "Submit Request"}
            </button>{" "}
            {notification && (
              <p
                className={
                  notification === "Request submitted successfully"
                    ? "success"
                    : "error"
                }
              >
                {notification}
              </p>
            )}
            <p>
              If your account is verified, click on{" "}
              <Link to="/login" style={{ color: "blue" }}>
                Login
              </Link>
              .
            </p>
          </form>
        </div>
        <Snackbar
          open={isSnackbarOpen}
          autoHideDuration={3000}
          onClose={() => setIsSnackbarOpen(false)}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert
            severity={
              snackbarMessage.includes("successfully") ? "success" : "error"
            }
            onClose={() => setIsSnackbarOpen(false)}
          >
            {snackbarMessage.includes("successfully") ? (
              <>{snackbarMessage}</>
            ) : (
              <>{snackbarMessage}</>
            )}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
};

export default HomePage;

import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AdminDashboardIcon from "@mui/icons-material/SupervisorAccount";
import NotificationIcon from "@mui/icons-material/Notifications";
import LeadIcon from "@mui/icons-material/Leaderboard";
import TasksIcon from "@mui/icons-material/Assignment";
import LeaveIcon from "@mui/icons-material/EventBusy";
import OnBoardingIcon from "@mui/icons-material/EmojiPeople";
import ProfileIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import AccessIcon from "@mui/icons-material/AccessTime";
import StarIcon from "@mui/icons-material/Star";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import ChatSupportIcon from "@mui/icons-material/Chat";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import StairsIcon from "@mui/icons-material/Stairs";
import axios from "axios";
import "./UserLeftContainer.css";
import { GET_ALL_RATINGS_URL, GET_ALL_USER_ID, GET_TEAM_DETAILS_URL } from "../../api/user";

function UserLeftContainer() {

  const location = useLocation();
  const currentPath = location.pathname;
  const handleColumnSelect = (column) => {
    setSelectedColumn((prevSelectedColumn) =>
      prevSelectedColumn === column ? null : column
    );
  };

  const handleLogout = () => {
    // Remove the token from local storage
    localStorage.removeItem("token");
    localStorage.removeItem("isAdmin");
    localStorage.removeItem("verified");
    localStorage.removeItem("username");
    localStorage.removeItem("id");
    // Redirect the user to the login page or any other desired page
    window.location.href = "/login";
  };
  const admin = localStorage.getItem("isAdmin") === "true";
  // const manager = localStorage.getItem("isManager") === "true";

  const name = admin
    ? localStorage.getItem("Name")
    : localStorage.getItem("username");

  const [selectedColumn, setSelectedColumn] = useState(null);
  const [onBoardingStatus, setOnBoardingStatus] = useState(null);
  const [rating, setRating] = useState(0); // Step 1: Add rating state
  const [managedTeam, setManagedTeam] = useState("");
  const [manager,setManager] =useState("");


  useEffect(() => {
    const clickedUserId = localStorage.getItem("clickedUserId");
    const verified = localStorage.getItem("verified");
    const token = localStorage.getItem("token"); // Retrieve the token from localStorage
    // Fetch or set the onBoardingStatus based on your requirements
    // For now, using a dummy value, replace this with your actual logic
    setOnBoardingStatus(verified);
    // Fetch ratings specific to the individual user from backend
    const fetchUserData = async () => {
      try {
        const response = await axios.get(GET_ALL_USER_ID, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data) {
          // console.log(response.data.isManager);
           setManager(response.data.isManager)
          const { verified, managedTeams } = response.data;
          setOnBoardingStatus(verified);

          if (managedTeams && managedTeams.length > 0) {
            const teamId = managedTeams[0];
            const teamResponse = await axios.get(`${GET_TEAM_DETAILS_URL}/${teamId}`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            if (teamResponse.data && teamResponse.data.name) {
              setManagedTeam(teamResponse.data.name);
            } else {
              console.error("Invalid team data received from the API");
              setManagedTeam("Unknown Team");
            }
          }
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    const fetchRatings = async () => {
      try {
        const response = await axios.get(GET_ALL_RATINGS_URL, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data && response.data.rating) {
          const userRating = response.data.rating;
          const adjustedRating = (userRating / 5) * 10; // Convert rating out of 5 to out of 10
          setRating(adjustedRating.toString()); // Convert the number to a string without rounding off
          localStorage.setItem("User-Rating",adjustedRating);
        } else {
          console.error("Invalid rating data received from the API");
          setRating(0); // Set rating to 0 if no valid data is received
        }
      } catch (error) {
        console.error("Error fetching ratings:", error);
        setRating(0); // Set rating to 0 if there is an error
      }
    };
    const fetchRatings2 = async () => {
      try {
        const response = await axios.get(`${GET_ALL_RATINGS_URL}/${clickedUserId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data && response.data.rating) {
          const userRating = response.data.rating;
          const adjustedRating = (userRating / 5) * 10; // Convert rating out of 5 to out of 10
          setRating(adjustedRating.toString()); // Convert the number to a string without rounding off
          localStorage.setItem("User-Rating",adjustedRating);
        } else {
          console.error("Invalid rating data received from the API");
          setRating(0); // Set rating to 0 if no valid data is received
        }
      } catch (error) {
        console.error("Error fetching ratings:", error);
        setRating(0); // Set rating to 0 if there is an error
      }
    };
    if (token) {
      fetchUserData();
      if(!admin){
      fetchRatings();
      }
      if(admin){
        fetchRatings2();
      }
    }
  }, []);

  const columns = [
    // {
    //   name: "access",
    //   path: "/access",
    //   icon: <AccessIcon />,
    //   label: "Access",
    //   adminOnly: admin, // Specify if it's an admin-only tab
    // },
    // {
    //   name: "chatSupport",
    //   path: "/chatSupport",
    //   icon: <ChatSupportIcon />,
    //   label: "Chat Support",
    //   adminOnly: true, // Specify if it's an admin-only tab
    // },
    {
      name: "dashboard",
      path: "/dashboard",
      icon: <DashboardIcon />,
      label: "Dashboard",
      adminOnly: true,
    },
    {
      name: "adminDashboard",
      path: "/adminDashboard",
      icon: <AdminDashboardIcon />,
      label: "Admin Dashboard",
      adminOnly: admin,
    },
    {
      name: "userNotification",
      path: "/userNotification",
      icon: <NotificationIcon />,
      label: "Notification",
      adminOnly: !admin,
    },
    // {
    //   name: "lead",
    //   path: "/lead",
    //   icon: <LeadIcon />,
    //   label: "Lead",
    //   adminOnly: !admin,
    // },
    {
      name: "lead",
      path: "/newlead",
      icon: <LeadIcon />,
      label: "New Lead",
      adminOnly: !admin,
    },
    {
      name: "tasks",
      path: "/tasks",
      icon: <TasksIcon />,
      label: "Tasks",
      adminOnly: true,
    },
    {
      name: "leave",
      path: "/leave",
      icon: <LeaveIcon />,
      label: "Leave",
      adminOnly: true,
    },
    // {
    //   name: "userOnBoarding",
    //   path: "/userOnBoarding",
    //   icon: <OnBoardingIcon />,
    //   label: "OnBoarding",
    //   adminOnly: !admin,
    // },
    // {
    //   name: "profile",
    //   path: "/profile",
    //   icon: <ProfileIcon />,
    //   label: "Profile",
    //   adminOnly: true,
    // },
    {
      name: "Teams",
      path: "/teams",
      icon: <EmojiPeopleIcon />,
      label: "Teams",
      adminOnly: manager,
    },
    {
      name: "Level Achieved",
      path: "/levelAchieved",
      icon: <StairsIcon />,
      label: "Level Achieved",
      adminOnly: true,
      // adminOnly: !admin,
    },
  ];
  return (
    <div className="user-left-container">
      <div className="logo-user">
        <h1>Markoknow</h1>
        <div className="profile-icon">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Link to="/profile">
              <ProfileIcon style={{ fontSize: 120 }} />
            </Link>
            <div>
              <h4
                style={{
                  color: "#fffff",
                  textDecoration: "none",
                  marginTop: "-1%",
                }}
              >
                {name}
              </h4>
              <p
                style={{
                  color: "#DAEDE3",
                  textDecoration: "none",
                  textAlign:"center",
                  marginTop: "-5%",
                  // border:"1px solid red"
                }}
              >
                {managedTeam}
              </p>
            </div>
            <div
              style={{
                border: "2px", // Set border style here
                borderRadius: "10px",
                display: "flex",
                flexDirection: "row",
                padding: "4px", // Add padding if needed,
                paddingLeft: "10px",
                paddingRight: "10px",
                backgroundColor: "white",
                fontSize: "20px",
                display:"flex",
                justifyContent:"center",
                alignItems:"center",
                gap: "8px",
                fontWeight: "500",
                boxShadow: "inherit",
             
                marginTop: "-5%",
                color:"black"
              }}
            >
              <StarIcon
                style={{
                  color: "gold",
                  fontSize: "22px",
                }}
              />{" "}
              {rating} {/* Display dynamic rating value */}
            </div>
          </div>
        </div>
      </div>
      <div className="divider"></div>
      <div className="icons">
        {columns.map((column) =>
          column.adminOnly ? (
            <div key={column.name} className="icon">
              <Link
                to={column.path}
                className={`icon-link ${
                  currentPath === column.path ? "active" : ""
                }`}
                onClick={() => handleColumnSelect(column.name)}
              >
                {column.icon}
                {column.name === "userOnBoarding" &&
                onBoardingStatus === "true" ? (
                  <>
                    <span
                      style={{
                        color: currentPath === column.path ? "green" : "",
                      }}
                    >
                      {column.label}
                    </span>
                    <CheckIcon style={{ color: "green" }} />
                  </>
                ) : column.name === "userOnBoarding" &&
                  onBoardingStatus === "false" ? (
                  <>
                    <span
                      style={{
                        color: currentPath === column.path ? "green" : "",
                      }}
                    >
                      {column.label}
                    </span>
                    <CloseIcon style={{ color: "red" }} />
                  </>
                ) : (
                  <span
                    style={{
                      color: currentPath === column.path ? "green" : "",
                    }}
                  >
                    {column.label}
                  </span>
                )}
              </Link>
            </div>
          ) : null
        )}
        {admin ? null : (
          <div className="icon" onClick={handleLogout}>
            <Link to="/" className="icon-link">
              <LogoutIcon />
              Logout
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}

export default UserLeftContainer;

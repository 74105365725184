import React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import axios from "axios";
import { useState, useEffect } from "react";
import { MenuItem, Paper, Select, Typography } from "@mui/material";
import { FETCH_APPLY_COURSE_ID_URL } from "../../api/lead";
import { CHANGE_AFTER_STATUS_URL } from "../../api/sales";
import {
  useMediaQuery,
  IconButton,
  Drawer,
  AppBar,
  Toolbar,
} from "@mui/material";
import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts";
import MenuIcon from "@mui/icons-material/Menu";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import UserLeftContainer from "../UI/UserLeftContainer";
import { easeQuadInOut } from "d3-ease";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import AnimatedProgressProvider from "../UI/AnimatedProgressProvider";

function Lead() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [onBoardingStatus, setOnBoardingStatus] = useState(false);

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [id, setId] = useState("");
  const [admin, setAdmin] = useState("false");
  const [manager, setManager] = useState("");
  const [status, setStatus] = useState("");
  useEffect(() => {
    getData();
  }, [id]);

  const handleAfterStatusChange = async (selectedValue, rowId, courseId) => {
    try {
      const changeAfterStatusUrl = CHANGE_AFTER_STATUS_URL(courseId, id);

      const res = await axios.patch(changeAfterStatusUrl, {
        userStatus: selectedValue,
      });

      setStatus(res.data.message.userStatus);
    } catch (error) {
      console.error(error);
    }

    if (rowId >= 0) {
      setRows((prevRows) => {
        const updatedRows = [...prevRows];
        updatedRows[rowId].userStatus = selectedValue;
        return updatedRows;
      });
    }
  };

  const getData = async () => {
    const id = localStorage.getItem("id");
    const verified = await localStorage.getItem("verified");
    setOnBoardingStatus(verified);
    const storedAdmin = await localStorage.getItem("isAdmin");
    const storedManager = await localStorage.getItem("isManager");
    setId(id);
    setAdmin(storedAdmin);
    setManager(storedManager);
    try {
      const getData1 = FETCH_APPLY_COURSE_ID_URL(id);

      const res = await axios.get(getData1);
      const add = await res.data.data.map((item, index) => ({
        courseId: item._id,
        id: index + 1,
        fullname: item.fName + " " + item.lName,
        date: new Date(item.date),
        description: item.description,
        email: item.email,
        wNumber: item.wNumber,
        year: item.year,
        status: item.status,
        assignedTo: item.assignedTo,
        userStatus: item.userStatus,
      }));
      setRows(add);

      const col = [
        { field: "fullname", headerName: "Full Name", width: 120 },
        { field: "date", headerName: "Date", width: 150 },
        { field: "description", headerName: "Description", width: 200 },
        { field: "email", headerName: "Email", width: 220 },

        {
          field: "userStatus",
          headerName: "Status",
          width: 150,
          renderCell: (params) => {
            const rowIndex = params.row.id - 1; // Adjusting the index based on the id
            return (
              <Select
                value={add[rowIndex].userStatus} // Use the row-specific value
                onChange={(event) => {
                  const newValue = event.target.value;
                  handleAfterStatusChange(
                    newValue,
                    rowIndex,
                    params.row.courseId,
                    add[rowIndex].assignedTo
                  ); // Pass row id for identifying the row
                }}
                placeholder="Status"
              >
                <MenuItem value="contact 1">contact 1</MenuItem>
                <MenuItem value="contact 2">contact 2</MenuItem>
                <MenuItem value="purchased">purchased</MenuItem>
                <MenuItem value="not purchased">not purchased</MenuItem>
              </Select>
            );
          },
        },

        { field: "wNumber", headerName: "Number", width: 120 },
        { field: "year", headerName: "Year", width: 100 },
      ];
      setColumns(col);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  
  const tasksData = [
    { month: "Jan", completed: 10, pending: 5 },
    { month: "Feb", completed: 15, pending: 7 },
    { month: "Mar", completed: 8, pending: 3 },
    { month: "Apr", completed: 12, pending: 8 },
    // Add more data for other months
  ];
  const [filterModel, setFilterModel] = React.useState({ items: [] });

  const handleFilterModelChange = (model) => {
    setFilterModel(model);
  };
  const handleLogout = () => {
    // Remove the token from local storage
    localStorage.removeItem("token");
    localStorage.removeItem("isAdmin");
    localStorage.removeItem("verified");
    // localStorage.removeItem("id");
    // Redirect the user to the login page or any other desired page
    window.location.href = "/login";
  };

  const calculateStatusCounts = () => {
    let assignedCount = 0;
    let completedCount = 0;
    let points = 0;

    rows.forEach((task) => {
      if (task.status === "Assigned") {
        assignedCount++;
      } else {
        completedCount++;
        points += 50; // Assuming each completed task earns 50 points
      }
    });

    const totalTasks = assignedCount + completedCount;
    const totalPossiblePoints = totalTasks * 50; // Assuming each task is worth 50 points
    const percentage = (points / totalPossiblePoints) * 100;

    return { assignedCount, points, completedCount, percentage };
  };

  const chartSetting = {
    yAxis: [
      {
        label: "Tasks",
      },
    ],
    width: isMobile ? 300 : 500,
    height: isMobile ? 200 : 300,
    sx: {
      [`.${axisClasses.left} .${axisClasses.label}`]: {
        transform: "translate(-20px, 0)",
      },
    },
  };

  const { assignedCount, points, completedCount, percentage } =
    calculateStatusCounts();
  return (
    <div className="container">
      {isMobile ? (
        <>
          <AppBar
            position="fixed"
            style={{ background: "transparent", boxShadow: "none" }}
          >
            <Toolbar>
              <IconButton
                color="primary"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
              <p>Welcome, Admin!</p>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="temporary"
            open={isDrawerOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <UserLeftContainer />
          </Drawer>
        </>
      ) : (
        <>
          <UserLeftContainer />
        </>
      )}

      <div
        className="right-container"
        style={{
          backgroundColor:"#DAEDE3",
          padding: "25px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div className="right-container-top">
          <p>Welcome in Lead</p>

          <div className="status-boxes">
            <div className="status-box assigned">
              Assigned: {assignedCount}{" "}
            </div>
            <div className="status-box completed">
              Completed: {completedCount}
            </div>
            <div className="status-box pending">Points: {points} </div>
          </div>
        </div>

        <div className="right-container-table">
          <div style={{ height: 600, width: "100%" }}>
            {/* DataGrid with a toolbar */}
            <Typography
              variant="body1"
              paragraph
              style={{
                fontSize: "35px",
              }}
            >
              Statistical Analysis of Lead
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                alignItems: "center",
                gap: isMobile ? "50px" : "100px",
              }}
            >
              <Paper
                elevation={5}
                style={{
                  padding: "20px",
                  marginBottom: isMobile ? "0%" : "2%",
                  boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)",
                  width: isMobile ? "90%" : "50%",
                }}
              >
                <h2>Lead Progress</h2>
                <div
                  style={{
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                    justifyContent: "flex-start",
                  }}
                >
                  <BarChart
                    dataset={tasksData}
                    xAxis={[{ scaleType: "band", dataKey: "month" }]}
                    series={[
                      { dataKey: "completed", label: "Completed" },
                      { dataKey: "pending", label: "Pending" },
                    ]}
                    {...chartSetting}
                  />
                  <div>
                    <Typography
                      variant="body1"
                      paragraph
                      style={{
                        fontSize: "25px",
                      }}
                    >
                      {points}/{(assignedCount + completedCount) * 50}
                    </Typography>

                    <Typography variant="body1" paragraph>
                      Total Points
                    </Typography>
                  </div>
                </div>
              </Paper>
              <Paper
                elevation={5}
                style={{
                  padding: "20px",
                  marginBottom: "2%",
                  boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)",
                  width: isMobile ? "80%" : "25%",
                  left: "-20%",
                }}
              >
                <Typography variant="h6" gutterBottom>
                  Progress Lead Bar
                </Typography>
                <AnimatedProgressProvider
                  valueStart={0}
                  valueEnd={percentage}
                  duration={1.4}
                  easingFunction={easeQuadInOut}
                  repeat
                >
                  {(value) => (
                    <CircularProgressbar
                      value={value}
                      text={`${percentage}%`}
                    />
                  )}
                </AnimatedProgressProvider>
              </Paper>
            </div>

            <Typography
              variant="body1"
              paragraph
              style={{
                fontSize: "35px",
                marginTop: "5%",
              }}
            >
              Leads
            </Typography>
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5, 10, 20]}
              checkboxSelection={false}
              filterModel={filterModel}
              onFilterModelChange={handleFilterModelChange}
              components={{
                Toolbar: GridToolbar,
              }}
              style={{
                width: "100%",
                marginBottom: "5%",

                boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)",
                borderRadius: "20px",
                backgroundColor: "white",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Lead;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import "../DashboardPage.css";
// /verifiedProcess/:id
import {
  useMediaQuery,
  IconButton,
  Drawer,
  AppBar,
  Toolbar,
  Badge,
  Select,
  MenuItem,
  Hidden,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

import MenuIcon from "@mui/icons-material/Menu";
import {
  FETCH_ALL_ON_BOARDING_DATA_URL,
  ON_BOARDING_VERIFIED_URL,
} from "../../api/onBoarding";
import LeftContainer from "../UI/LeftContainer";
function CompleteProgress() {
  useEffect(() => {
    fetchData();
  }, []);
  const [data, setData] = useState([]);
  const handleVerification = async (id, isVerified) => {
    try {
      const response = await axios.patch(ON_BOARDING_VERIFIED_URL(id), {
        userId: id,
        verified: isVerified,
      });

      if (response.status === 200) {
        // Update the UI or trigger a data refetch if needed
        fetchData();
      }
    } catch (error) {
      console.error("Error updating verification status:", error);
    }
  };
  const fetchData = async () => {
    try {
      const response = await axios.get(FETCH_ALL_ON_BOARDING_DATA_URL);

      if (response.status === 200) {
        const formattedData = response.data.viewProgress.map((item) => ({
          ...item.user,
          onBoardingSteps: item.steps,
          verified: item.verified,
          userId: item.userId,
          status: {
            isCompleted:
              item.steps.filter((step) => step.bool).length ===
              item.steps.length,
            completedSteps: item.steps.filter((step) => step.bool).length,
            totalSteps: item.steps.length,
          },
          id: item._id,
        }));
      

        // Filter only completed data
        const completedData = formattedData.filter(
          (item) => item.status.isCompleted
        );

        setData(completedData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const columns = [
    { field: 'name', headerName: 'Name', width: 100 },
    { field: 'email', headerName: 'Email', width: 180 },
    { field: 'address', headerName: 'Address', width: 230  },
    { field: 'college', headerName: 'College', width: 100 },
    { field: 'contactNumber', headerName: 'Contact Number', width: 150 },
    {
      field: 'status',
      headerName: 'Status',
      width: 180,
      renderCell: (params) => {
        const { isCompleted, completedSteps, totalSteps } = params.value;
        const isVerified = params.row.verified;
  
        return (
          <div>
            {isCompleted ? (
              <>
                {isVerified ? (
                  <>
                    <CheckIcon style={{ color: 'green' }} />
                    <CheckIcon style={{ color: 'green' }} />
                  </>
                ) : (
                  <CheckIcon style={{ color: 'green' }} />
                )}
                Completed {completedSteps} / {totalSteps} steps
              </>
            ) : (
              <>
                <CloseIcon style={{ color: 'red' }} />
                Incomplete {completedSteps} / {totalSteps} steps
              </>
            )}
          </div>
        );
      },
    },
    {
      field: 'verificationStatus',
      headerName: 'Verification Status',
      // Assign a specific width to the column
      width: 120,
      renderCell: (params) => (
        <div>
          <Hidden mdUp>
            {/* Content for mobile view */}
            <Select
              value={params.row.verified ? 'verified' : 'notVerified'}
              onChange={(event) =>
                handleVerification(
                  params.row.userId,
                  event.target.value === 'verified'
                )
              }
            >
              <MenuItem value="verified">Verified</MenuItem>
              <MenuItem value="notVerified">Not Verified</MenuItem>
            </Select>
          </Hidden>
          <Hidden smDown>
            {/* Content for larger screens */}
            <Select
              value={params.row.verified ? 'verified' : 'notVerified'}
              onChange={(event) =>
                handleVerification(
                  params.row.userId,
                  event.target.value === 'verified'
                )
              }
            >
              <MenuItem value="verified">Verified</MenuItem>
              <MenuItem value="notVerified">Not Verified</MenuItem>
            </Select>
          </Hidden>
        </div>
      ),
    },
  ];

  const handleLogout = () => {
    // Remove the token from local storage
    localStorage.removeItem("token");
    localStorage.removeItem("isAdmin");
    // Redirect the user to the login page or any other desired page
    window.location.href = "/login";
  };
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const isMobile = useMediaQuery("(max-width: 768px)");
  return (
    <div className="container">
     {isMobile ? (
          <>
            <AppBar
              position="fixed"
              style={{ background: "transparent", boxShadow: "none" }}
            >
              <Toolbar>
                <IconButton
                  color="primary"
                  aria-label="open drawer"
                  onClick={handleDrawerToggle}
                  edge="start"
                >
                  <MenuIcon />
                </IconButton>
                {/* <LeftContainer handleLogout={handleLogout} /> */}
              </Toolbar>
            </AppBar>
            <Drawer
              variant="temporary"
              open={isDrawerOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true,
              }}
            >
              {/* ... Other code ... */}
              <LeftContainer handleLogout={handleLogout} />
              {/* ... Other code ... */}
            </Drawer>
          </>
        ) : (
          <>
            <LeftContainer handleLogout={handleLogout} />
            {/* ... Other code ... */}
          </>
        )}

      <div className="right-container">
        <div className="right-container-top">
          <p>Welcome, Admin!</p>
          <h1>Completed </h1>
        </div>
        <div style={{ height: 500, width: "100%" }}>
          <DataGrid
            rows={data}
            columns={columns}
            pageSize={10}
            checkboxSelection
            disableSelectionOnClick
          />
        </div>
      </div>
    </div>
  );
}

export default CompleteProgress;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Box, Typography, Paper } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import LogoutIcon from '@mui/icons-material/Logout';
import { IconButton, Drawer, AppBar, Toolbar, Select, MenuItem, useMediaQuery } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Bar } from 'react-chartjs-2';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import "./MainContent.css";
import LeftContainer from "../UI/LeftContainer";

// Import the constants
import { FETCH_LEAVE } from '../../api/leave';
import { GET_ALL_USERS_URL, GET_ALL_TASKS_URL, GET_ALL_TEAMS_URL, GET_ALL_TARGETS_URL } from '../../api/user'; // Update path accordingly

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const AdminDashboard = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [team, setTeam] = useState("");
  const [timeRange, setTimeRange] = useState("This Month");
  const [timeRange2, setTimeRange2] = useState("This Month");
  const [userData, setUserData] = useState([]);
  const [teams, setTeams] = useState([]);
  const [targets, setTargets] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [taskCount, setTaskCount] = useState(0);
  const [recentAchievements, setRecentAchievements] = useState([]);
  const token = localStorage.getItem("token");
  const [topTeam, setTopTeam] = useState(null); // State for top team
  const [leaveRequests, setLeaveRequests] = useState([]); // State for leave requests
  const [teamRating, setTeamRating] = useState(0);
  useEffect(() => {
    // Retrieve the selected team from localStorage
    const savedTeam = localStorage.getItem("selectedTeam");
    if (savedTeam) {
      setTeam(savedTeam);
    } else {
      // If no team is saved, set the default team
      setTeam(teams.length > 0 ? teams[0].name : "");
    }
  }, [teams]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        // Fetch teams data
        const teamsResponse = await fetch(GET_ALL_TEAMS_URL, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const teamsData = await teamsResponse.json();
        setTeams(teamsData);
   // Calculate top team
   const topTeam = teamsData.reduce((maxTeam, currentTeam) => {
    return currentTeam.members.length > (maxTeam?.members.length || 0) ? currentTeam : maxTeam;
  }, null);

  setTopTeam(topTeam);
        // Fetch targets data
        const targetsResponse = await fetch(GET_ALL_TARGETS_URL, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const targetsData = await targetsResponse.json();
        setTargets(targetsData.data);

        // Fetch task data
        const tasksResponse = await fetch(GET_ALL_TASKS_URL, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const tasksData = await tasksResponse.json();
        setTasks(tasksData);

        // Filter out the selected team's members
        const selectedTeam = teamsData.find(teamItem => teamItem.name === team);
        if (selectedTeam) {
          const teamMembers = selectedTeam.members;

          // Fetch user data for team members
          const usersResponse = await fetch(GET_ALL_USERS_URL, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          const usersData = await usersResponse.json();

          // Filter users based on team members
          const teamUsersData = usersData.filter(user => teamMembers.includes(user._id));

          // Calculate completed and validated tasks count for each user
          const userDataWithStoriesCount = teamUsersData.map(user => {
            const completedAndValidatedCount = tasksData.filter(task =>
              task.assignedTo._id === user._id &&
              (task.status === "Completed" || task.status === "Completed and Validated")
            ).length;
            return {
              ...user,
              completedAndValidatedCount: completedAndValidatedCount
            };
          });

          // Sort userDataWithStoriesCount in decreasing order based on completedAndValidatedCount
          userDataWithStoriesCount.sort((a, b) => b.completedAndValidatedCount - a.completedAndValidatedCount);

          setUserData(userDataWithStoriesCount);
        }
      } catch (error) {
        console.error("Failed to fetch user data", error);
      }
    };

    fetchUserData();
    const fetchLeaveRequests = async () => {
      try {
        // Fetch leave requests data
        const leaveResponse = await fetch(FETCH_LEAVE, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const leaveData = await leaveResponse.json();
        // console.log('All leave data:', leaveData);
    
        // Filter leaves with pending decision
        const pendingLeaves = leaveData.data.filter(leave => leave.decision === 'Pending');
    
        // Sort the pending leave requests by updatedAt in descending order
        const sortedPendingLeaves = pendingLeaves.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
    
        // console.log('Pending leave requests:', sortedPendingLeaves);
    
        // Update the state with all pending leave requests
        setLeaveRequests(sortedPendingLeaves);
    
        // Log the updated state (Note: This might not immediately reflect the updated state due to the asynchronous nature of state updates)
        // console.log('Updated leave requests state:', leaveRequests);
      } catch (error) {
        console.error('Failed to fetch leave requests', error);
      }
    };

    fetchLeaveRequests();

  }, [team, timeRange, token]);

  useEffect(() => {
    const filterTasks = () => {
      const now = new Date();
      let filteredTasks = tasks.filter(
        task => (task.status === "Completed" || task.status === "Completed and Validated")
      );

      switch (timeRange2) {
        case "This Month":
          filteredTasks = filteredTasks.filter(task => {
            const taskDate = new Date(task.completedDate);
            return taskDate.getMonth() === now.getMonth() && taskDate.getFullYear() === now.getFullYear();
          });
          break;
        case "This Year":
          filteredTasks = filteredTasks.filter(task => {
            const taskDate = new Date(task.completedDate);
            return taskDate.getFullYear() === now.getFullYear();
          });
          break;
        case "This Week":
          filteredTasks = filteredTasks.filter(task => {
            const taskDate = new Date(task.completedDate);
            const startOfWeek = new Date(now.setDate(now.getDate() - now.getDay()));
            const endOfWeek = new Date(now.setDate(now.getDate() - now.getDay() + 6));
            return taskDate >= startOfWeek && taskDate <= endOfWeek;
          });
          break;
        default:
          break;
      }

      setTaskCount(filteredTasks.length);
      const rating = (filteredTasks.length / tasks.length) * 10;
      setTeamRating(rating.toFixed(2));
    };

    filterTasks();
  }, [tasks, timeRange2]);

  useEffect(() => {
    const calculateRecentAchievements = () => {
      const levels = { 1: 50, 2: 100 };
      const now = new Date();
  
      const recentAchievements = userData.flatMap(user => {
        const userTasks = tasks.filter(task =>
          task.assignedTo._id === user._id &&
          (task.status === "Completed" || task.status === "Completed and Validated")
        ).sort((a, b) => new Date(b.completedDate) - new Date(a.completedDate));
  
        return Object.entries(levels).map(([level, count]) => {
          const completedTaskCount = userTasks.length;
          const isRecent = (now - new Date(userTasks[0]?.completedDate)) / (1000 * 60 * 60 * 24) <= 30; // within the last 30 days
  
          if (completedTaskCount >= count) {
            return { level, isRecent, user, completedTaskCount };
          }
          return null;
        }).filter(achievement => achievement !== null);
      });
  
      // Filter to keep only the highest level for each user
      const uniqueAchievements = [];
      const seenUsers = new Set();
      for (const achievement of recentAchievements) {
        if (!seenUsers.has(achievement.user._id)) {
          seenUsers.add(achievement.user._id);
          uniqueAchievements.push(achievement);
        }
      }
  
      // Sort and take top 2 achievements
      uniqueAchievements.sort((a, b) => b.completedTaskCount - a.completedTaskCount);
      setRecentAchievements(uniqueAchievements.slice(0, 2));
    };
  
    calculateRecentAchievements();
  }, [userData, tasks]);
  

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("isAdmin");
    window.location.href = "/login";
  };

  const handleTeamChange = (event) => {
    const selectedTeam = event.target.value;
    setTeam(selectedTeam);
    // Save the selected team to localStorage
    localStorage.setItem("selectedTeam", selectedTeam);
  };

  const isMobile = useMediaQuery("(max-width: 768px)");

  const data = {
    labels: userData.map(user => user.name),
    datasets: [
      {
        label: 'Count',
        data: userData.map(user => user.completedAndValidatedCount),
        backgroundColor: 'rgba(0, 128, 0, 0.6)',
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: 'Performers of the Month',
        font: {
          size: 22,
        },
        padding: 20, // Added padding to separate the title from the chart
      },
      tooltip: {
        enabled: true,
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        titleFont: {
          size: 14,
        },
        bodyFont: {
          size: 12,
        },
        callbacks: {
          label: function(context) {
            let label = context.dataset.label || '';
           
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += context.parsed.y;
            }
            return label;
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: 'Users',
          font: {
            size: 14,
          },
        },
        ticks: {
          font: {
            size: 12,
          },
          color: '#333',
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          color: 'rgba(200, 200, 200, 0.3)',
        },
        title: {
          display: true,
          text: 'Tasks Completed',
          font: {
            size: 14,
          },
        },
        ticks: {
          font: {
            size: 12,
          },
          color: '#333',
        },
      },
    },
  };

  // Assuming teamsData is an array containing team objects
  const teamTargets = targets.filter(target => !target.assignedTo);

  // Merge team target data with team names
  const mergedTargets = teamTargets
    .filter(target => target.selectedTeam)
    .map(target => {
      const teamName = teams.find(team => team._id === target.selectedTeam)?.name;
      return {
        ...target,
        teamName: teamName || "Unknown Team",
      };
    });

  // Create an array of unique target durations
  const targetDurations = [...new Set(mergedTargets.map(target => target.targetDuration))];

  // State for selected target duration
  const [selectedTargetDuration, setSelectedTargetDuration] = useState("");
  
  // Handle target duration change
  const handleTargetDurationChange = (event) => {
    setSelectedTargetDuration(event.target.value);
  };

  return (
    <div className="container">
      {/* Mobile View */}
      {isMobile ? (
        <>
          <AppBar position="fixed" style={{ background: "transparent", boxShadow: "none" }}>
            <Toolbar>
              <IconButton
                color="primary"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="temporary"
            open={isDrawerOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <LeftContainer handleLogout={handleLogout} />
          </Drawer>
        </>
      ) : (
        <LeftContainer handleLogout={handleLogout} />
      )}
      <div className="container-new">
        <div className="right-containerTop">
          <div className="header">
            <p>Hi Admin,</p>
            <p style={{ fontSize: isMobile ? "12px" : "18px" }}>Here is the report of the entire team performance!</p>
          </div>
          <div className="nav-menu2">
            <div className="avatar-container" onClick={toggleMenu}>
              <span className="user-name">Admin</span>
              <span className={`arrow ${isOpen ? 'open' : ''}`}>▼</span>
            </div>
            {isOpen && (
              <ul className="menu-items">
                <li>
                  <Link to="/profile" className="li-link">
                    <PersonIcon style={iconStyle} />
                    Profile
                  </Link>
                </li>
                <li>
                  <Link to="/lead" className="li-link">
                    <LeaderboardIcon style={iconStyle} />
                    Lead
                  </Link>
                </li>
                <li>
                  <Link to="/userOnBoarding" className="li-link">
                    <EmojiPeopleIcon style={iconStyle} />
                    Onboarding
                  </Link>
                </li>
                <li onClick={handleLogout}>
                  <Link to="/" className="li-link">
                    <LogoutIcon style={iconStyle} />
                    Logout
                  </Link>
                </li>
              </ul>
            )}
          </div>
        </div>
        <div className="main-content">
          <div className="box-container">
            <div className="box">
              <div className="bar-container">
                <div className="chart-header">
                  <div className="filters">
                    <Select
                      value={team}
                      onChange={handleTeamChange}
                      displayEmpty
                    >
                      {teams.map(team => (
                        <MenuItem key={team._id} value={team.name}>{team.name}</MenuItem>
                      ))}
                    </Select>
                    <Select
                      value={timeRange}
                      onChange={(e) => setTimeRange(e.target.value)}
                      displayEmpty
                    >
                      <MenuItem value="This Month">This Month</MenuItem>
                      <MenuItem value="This Week">This Week</MenuItem>
                      <MenuItem value="This Year">This Year</MenuItem>
                    </Select>
                  </div>
                </div>
                <div className="barchart">
                  <Bar data={data} options={options} />
                </div>
              </div>
            </div>
            <div className="box">
              <div className="target-container">
                <div className="target-carousel">
                  {mergedTargets.length > 0 ? (
                    <Slider dots={true} infinite={false} speed={500} slidesToShow={1} slidesToScroll={1}>
                      {mergedTargets
                        .filter(target => selectedTargetDuration === "" || target.targetDuration === selectedTargetDuration)
                        .map(target => (
                          <div key={target._id} className="target-item">
                            <h3>Selected Team: {target.teamName}</h3>
                            <p className="target-figure">{target.targetFigure}</p>
                            <p className="target-description">{target.targetDescription}</p>
                          </div>
                        ))}
                    </Slider>
                  ) : (
                    <h3>No targets found....</h3>
                  )}
                </div>
                <div className="target-duration-selector">
                  <Select
                    value={selectedTargetDuration}
                    onChange={handleTargetDurationChange}
                    displayEmpty
                  >
                    <MenuItem value="">All Durations</MenuItem>
                    {targetDurations.map(duration => (
                      <MenuItem key={duration} value={duration}>{duration}</MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
            <div className="TaskCount">
              <div className="TaskTitle">Task Count</div>
              <div className="TaskNumber">{taskCount}</div>
              <div className="TaskPeriod">
                <Select
                  value={timeRange2}
                  onChange={(e) => setTimeRange2(e.target.value)}
                  displayEmpty
                
                >
                  <MenuItem value="This Month">This Month</MenuItem>
                  <MenuItem value="This Week">This Week</MenuItem>
                  <MenuItem value="This Year">This Year</MenuItem>
                </Select>
              </div>
            </div>
          </div>


          <div className="box-container2">
         
          <Box className="smallBox" sx={{ textAlign: 'center', padding: 2 }}>
      {topTeam ? (
        <>
          <Paper sx={{ backgroundColor: 'orange', padding: 1, marginBottom: 1 }}>
            <Typography variant="h6" sx={{ color: 'black', fontWeight: 'bold' }}>
              {topTeam.name}
            </Typography>
          </Paper>
          <Paper sx={{ backgroundColor: 'green', padding: 1, marginBottom: 1 }}>
            <Typography variant="h6" sx={{ color: 'black', fontWeight: 'bold' }}>
              {topTeam.name}
            </Typography>
          </Paper>
        </>
      ) : (
        <Typography variant="h6" sx={{ color: 'black', fontWeight: 'bold' }}>
          Loading...
        </Typography>
      )}
      <Typography variant="subtitle1" sx={{ color: 'black', fontWeight: 'bold' }}>
        Top Team This Month
      </Typography>
    </Box>
 
     
    <Box className="smallBox" sx={{ textAlign: 'center', padding: 2, maxWidth: '300px', margin: 'auto' }}>
      {leaveRequests.length > 0 ? (
        <Slider dots={true} infinite={false} speed={500} slidesToShow={1} slidesToScroll={1}>
          {leaveRequests.map((leave, index) => (
            <div key={leave._id}>
                <Link to="/leaveAdminHandle" className="icon-link">       
              <Paper
                sx={{
                  backgroundColor: leave.decision === 'Pending' ? 'orange' : 'green',
                  padding: 2,
                  margin: '0 5px',
                  height: '120px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '10px',
                  boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                }}
              >
                <Typography variant="h6" sx={{ color: 'white', fontWeight: 'bold', marginBottom: 1 }}>
                  {leave.name}
                </Typography>
                <Typography variant="body2" sx={{ color: 'red',fontWeight: 'bold' }}>
                  Status: {leave.decision}
                </Typography>
                {leave.date && (
                  <Typography variant="body2" sx={{ color: 'white', marginTop: 1 }}>
                    Date: {new Date(leave.date).toLocaleDateString()}
                  </Typography>
                )}
              </Paper>
              </Link>
            </div>
          ))}
        </Slider>
      ) : (
        <Typography variant="h6" sx={{ color: 'black', fontWeight: 'bold' }}>
          No leave requests
        </Typography>
      )}
      <Typography variant="subtitle1" sx={{ color: 'black', fontWeight: 'bold', marginTop: 2 }}>
        Leave Requests
      </Typography>
    </Box>
   
                 
    <Box className="smallBox" sx={{ textAlign: 'center', padding: 7}}>
          <Typography variant="h2" sx={{ color: 'green', fontWeight: 'bold' }}>
            {teamRating}
          </Typography>
          <Typography variant="subtitle1" sx={{ color: 'black', fontWeight: 'bold' }}>
            Overall Team Rating This Month
          </Typography>
        </Box>
            <div className="smallBox">
  {recentAchievements.length > 0 ? (
    recentAchievements.map(achievement => (
      <div key={`${achievement.user._id}-${achievement.level}`} className="custom-box">
        <div className={`custom-box-header ${achievement.isRecent ? 'orange-bg' : 'green-bg'} black-text`}>
          <div className="number">{achievement.level} ({achievement.completedTaskCount})</div>
          <div className="name">{achievement.user.name}</div>
        </div>
        <div className="custom-box-footer">New Level Crossed</div>
      </div>
    ))
  ) : (
    <div>No level</div>
  )}
</div>



          </div>
        </div>
      </div>
    </div>
  );
};

const iconStyle = {
  marginRight: "10px",
};

export default AdminDashboard;

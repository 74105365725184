import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import TargetComponent from "./TargetComponent";
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import "../DashboardPage.css";
import {
  useMediaQuery,
  IconButton,
  Drawer,
  AppBar,
  Toolbar,
  Modal,
  TextField,
  Button,
  Box,
  MenuItem
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import LeftContainer from "../UI/LeftContainer";
import 'react-toastify/dist/ReactToastify.css';
import {
  GET_ALL_TEAMS_URL,
  GET_ALL_USERS_URL,
  CREATE_TARGET_URL,
  GET_ALL_TARGETS_URL,
  UPDATE_TARGET_URL,
  DELETE_TARGET_URL
} from "../../api/user";

const AllTarget = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [userList, setUserList] = useState([]);
  const [teamList, setTeamList] = useState([]);
  const [userTargets, setUserTargets] = useState([]);
  const [teamTargets, setTeamTargets] = useState([]);
  const [selectedTarget, setSelectedTarget] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const isMobile = useMediaQuery("(max-width: 768px)");

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("isAdmin");
    window.location.href = "/login";
  };

  useEffect(() => {
    fetchUser();
    fetchTeams();
    fetchTargets();
  }, []);

  const fetchUser = async () => {
    try {
      const storedToken = localStorage.getItem("token");
      const response = await axios.get(GET_ALL_USERS_URL, {
        headers: {
          Authorization: `Bearer ${storedToken}`
        }
      });
      if (response.status === 200) {
        const filteredUsers = response.data.filter(
          (user) =>
            !user.isAdmin &&
            user.isApproved &&
            !user.isDeactivated
        );
        setUserList(filteredUsers);
      }
    } catch (error) {
      console.error("Error fetching users", error);
      toast.error("Failed to fetch users. Please try again later.");
    }
  };

  const fetchTeams = async () => {
    try {
      const storedToken = localStorage.getItem("token");
      const response = await axios.get(GET_ALL_TEAMS_URL, {
        headers: {
          Authorization: `Bearer ${storedToken}`
        }
      });
      if (response.status === 200) {
        setTeamList(response.data);
      }
    } catch (error) {
      console.error("Error fetching teams", error);
      toast.error("Failed to fetch teams. Please try again later.");
    }
  };

  const fetchTargets = async () => {
    try {
      const storedToken = localStorage.getItem("token");
      const response = await fetch(GET_ALL_TARGETS_URL, {
        headers: {
          Authorization: `Bearer ${storedToken}`
        }
      });
      const targetsData = await response.json();

      if (response.status === 200) {
        const allTargets = targetsData.data;
        const userTargets = allTargets.filter(target => target.assignedTo);
        const teamTargets = allTargets.filter(target => target.selectedTeam);

        setUserTargets(userTargets);
        setTeamTargets(teamTargets);
      }
    } catch (error) {
      console.error("Error fetching targets", error);
      toast.error("Failed to fetch targets. Please try again later.");
    }
  };

  const handleDelete = async (targetId) => {
    try {
      const storedToken = localStorage.getItem("token");
      const response = await axios.delete(`${DELETE_TARGET_URL}/${targetId}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`
        }
      });
      if (response.status === 200) {
        toast.success("Target deleted successfully!");
        fetchTargets(); // Fetch updated targets list
      }
    } catch (error) {
      console.error("Error deleting target", error);
      toast.error("Failed to delete target. Please try again later.");
    }
  };

  const handleUpdate = (target) => {
    setSelectedTarget(target);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedTarget(null);
  };

  const handleSaveChanges = async () => {
  if(selectedTarget.targetDescription.length < 5){
    toast.error("Target description must be at least 5 characters long");
    return
  }

    try {
      const storedToken = localStorage.getItem("token");
      const response = await axios.put(`${UPDATE_TARGET_URL}/${selectedTarget._id}`, selectedTarget, {
        headers: {
          Authorization: `Bearer ${storedToken}`
        }
      });
      if (response.status === 200) {
        toast.success("Target updated successfully!");
        fetchTargets(); // Fetch updated targets list
        handleCloseModal();
      }
    } catch (error) {
      console.error("Error updating target", error);
      toast.error("Failed to update target. Please try again later.");
    }
  };

  return (
    <div className="container">
      {isMobile ? (
        <>
          <AppBar
            position="fixed"
            style={{ background: "transparent", boxShadow: "none" }}
          >
            <Toolbar>
              <IconButton
                color="primary"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="temporary"
            open={isDrawerOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <LeftContainer handleLogout={handleLogout} />
          </Drawer>
        </>
      ) : (
        <LeftContainer handleLogout={handleLogout} />
      )}

      <div className="right-container">
        <div className="right-container-top" style={{ marginBottom: "0px" }}>
          <p>Welcome, Admin!</p>
        </div>
        <div className="right-container-table" style={{ marginTop: "0px" }}>
          <div className="target-modal__content">
            <h2>All Targets</h2>
            <div className="target-modal__boxes">
              <div className="target-modal__box">
                <h3>User Targets</h3>
                <ul className="target-modal__list">
                  {userTargets.map((target) => {
                    const user = userList.find(u => u._id === target.assignedTo);
                    return (
                      <li key={target._id} className="target-modal__item">
                        <p><strong>User:</strong> {user ? user.name : "Unknown User"}</p>
                        <p><strong>Target Description:</strong> {target.targetDescription}</p>
                        <p><strong>Target Figure:</strong> {target.targetFigure}</p>
                        <p><strong>Target Duration:</strong> {target.targetDuration}</p>
                        <div className="target-modal__button-container">
                        <button className="target-modal__button" onClick={() => handleUpdate(target)}>Update</button>
                          <button className="target-modal__button" onClick={() => handleDelete(target._id)}>Delete</button>
                        </div>
                      </li>
                    )
                  })}
                </ul>
              </div>
              <div className="target-modal__box">
                <h3>Team Targets</h3>
                <ul className="target-modal__list">
                  {teamTargets.map((target) => {
                    const team = teamList.find(t => t._id === target.selectedTeam);
                    return (
                      <li key={target._id} className="target-modal__item">
                        <p><strong>Team:</strong> {team ? team.name : "Unknown Team"}</p>
                        <p><strong>Target Description:</strong> {target.targetDescription}</p>
                        <p><strong>Target Figure:</strong> {target.targetFigure}</p>
                        <p><strong>Target Duration:</strong> {target.targetDuration}</p>
                        <div className="target-modal__button-container">
                          <button className="target-modal__button" onClick={() => handleDelete(target._id)}>Delete</button>
                          <button className="target-modal__button" onClick={() => handleUpdate(target)}>Update</button>
                        </div>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            backgroundColor: 'white',
            boxShadow: 24,
            padding: '16px',
          }}
        >
          <h2>Edit Target</h2>
          {selectedTarget && (
            <>
              <TextField
                margin="normal"
                label="Target Description"
                fullWidth
                value={selectedTarget.targetDescription}
                onChange={(e) => setSelectedTarget({ ...selectedTarget, targetDescription: e.target.value })}
              />
              <TextField
                margin="normal"
                label="Target Figure"
                fullWidth
                value={selectedTarget.targetFigure}
                onChange={(e) => setSelectedTarget({ ...selectedTarget, targetFigure: e.target.value })}
              />
             <TextField
             margin="normal"
             label="Target Duration"
             fullWidth
            select
             value={selectedTarget.targetDuration}
             onChange={(e) => setSelectedTarget({ ...selectedTarget, targetDuration: e.target.value })}
              >
  <MenuItem value="This Month">This Month</MenuItem>
  <MenuItem value="This Week">This Week</MenuItem>
  <MenuItem value="This Year">This Year</MenuItem>
</TextField>
              <Box mt={2}>
                <Button variant="contained" color="primary" onClick={handleSaveChanges}>
                  Save Changes
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default AllTarget;

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Snackbar, Alert } from "@mui/material"; // Import Snackbar and Alert
import { LOGIN_URL } from "../../api/auth";
// import './Login.css';

const Login = () => {
  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [isFormValid, setIsFormValid] = useState(false);

  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false); // State for Snackbar
  const [snackbarMessage, setSnackbarMessage] = useState(""); // Message for the Snackbar

  const handleChange = (event) => {
    const { name, value } = event.target;

    // Add validation rules for each field
    let error = "";

    if (name === "email") {
      if (!isValidEmail(value)) {
        error = "Invalid email address";
      }
    } else if (name === "password") {
      if (value.length < 6) {
        error = "Password must be at least 6 characters long";
      }
    }

    // Update the errors state
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));

    // Check form validity
    setIsFormValid(Object.values(errors).every((error) => error === ""));

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  // Validation function for email
  const isValidEmail = (email) => {
    // Regular expression pattern for a valid email address
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    return emailPattern.test(email);
  };

  // You can use this validation function to check the password length
  // if needed, or implement custom validation logic

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrors({
      email: "",
      password: "",
    });
    try {
      // Send the login data to the server for validation
      const response = await axios.post(LOGIN_URL, formData);
      const { token } = response.data;
      const { isAdmin } = response.data;
      const {isSuperAdmin}  =response.data;
      const { id } = response.data;
      localStorage.setItem("token", token);
      localStorage.setItem("id", id);
      localStorage.setItem("isAdmin", isAdmin);
      localStorage.setItem("isSuperAdmin", isSuperAdmin);

      if (isAdmin) {
       window.location.href='/adminDashboard'
      } else {
        // Redirect to the Dashboard page for regular users
        window.location.href='/dashboard'
      }
    } catch (error) {
      console.error("Error logging in:", error.response.data.message);

      // Set the error message for the Snackbar
      setSnackbarMessage("Error logging in: " + error.response.data.message);

      // Open the Snackbar
      setIsSnackbarOpen(true);
    }
  };

  return (
    <>
      <div className="section">
        <div className="left-section">
          <p>Welcome to the IMS Portal</p>
          <p>by Markoknow</p>
        </div>
        <div className="right-section">
          <form onSubmit={handleSubmit}>
            <p>Login to your account:</p>
            <label>
              Email:
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              {errors.email && <div className="error">{errors.email}</div>}
            </label>
            <label>
              Password:
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
              />
              {errors.password && (
                <div className="error">{errors.password}</div>
              )}
            </label>
            <button type="submit" disabled={!isFormValid}>
              Login
            </button>
            <p>
              <Link
                to="/Forgetpassword"
                style={{ color: "blue", fontSize: 14 }}
              >
                Forget Password ?
              </Link>
            </p>
            <p>
              If you don't have an account, you can register it{" "}
              <Link to="/" style={{ color: "blue" }}>
                here
              </Link>
              .
            </p>
          </form>
        </div>
      </div>
      {/* Snackbar for displaying login errors */}
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={3000} // Adjust the duration as needed (in milliseconds)
        onClose={() => setIsSnackbarOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert severity="error" onClose={() => setIsSnackbarOpen(false)}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Login;

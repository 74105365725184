// NotificationContext.js

import React, { createContext, useContext, useReducer } from "react";

const initialState = {
  notificationCount: 0,
  notifications: [],
};

const actionTypes = {
  INCREMENT: "INCREMENT",
  DECREMENT: "DECREMENT",
  CLEAR: "CLEAR",
  ADD_NOTIFICATION: "ADD_NOTIFICATION",
  MARK_NOTIFICATIONS_AS_READ: "MARK_NOTIFICATIONS_AS_READ",
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.INCREMENT:
      return {
        ...state,
        notificationCount: state.notificationCount + 1,
      };
    case actionTypes.DECREMENT:
      return {
        ...state,
        notificationCount: state.notificationCount - 1,
      };
    case actionTypes.CLEAR:
      return {
        ...state,
        notificationCount: 0,
        notifications: [],
      };
    case actionTypes.ADD_NOTIFICATION:
      return {
        ...state,
        notifications: [action.payload, ...state.notifications],
      };
    case actionTypes.MARK_NOTIFICATIONS_AS_READ:
      return {
        ...state,
        notificationCount: 0,
        notifications: [],
      };
    default:
      return state;
  }
};

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <NotificationContext.Provider value={{ state, dispatch }}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotificationContext = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error(
      "useNotificationContext must be used within a NotificationProvider"
    );
  }
  return context;
};

import React, { useState, useEffect } from "react";
import { CardContent, Card, Typography } from "@mui/material";
import axios from "axios";
import { Chart } from "react-google-charts";
import { GET_ALL_TASKS_URL } from "../../api/user";
import "../DashboardPage.css";

import {
  useMediaQuery,
  IconButton,
  Drawer,
  AppBar,
  Toolbar,
  Badge,
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";

import LeftContainer from "../UI/LeftContainer";
import { PieChart, BarChart } from "@mui/x-charts";
import { makeStyles } from "@mui/styles";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const useStyles = makeStyles({
  card: {
    maxWidth: "50%",
    margin: "auto",
    marginTop: 20,
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
  },
  chartContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
  },
});
function GraphicalView() {
  const classes = useStyles();
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);
  const handleLogout = () => {
    // Remove the token from local storage
    localStorage.removeItem("token");
    localStorage.removeItem("isAdmin");
    // Redirect the user to the login page or any other desired page
    window.location.href = "/login";
  };
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const isMobile = useMediaQuery("(max-width: 768px)");
  // Use a single formData object to handle form data

  const [formData, setFormData] = useState({
    name: "",
    taskDetails: "",
    team: "",
    status: "Assigned",
    assignedDate: "",
    completedDate: "",
    rating: "",
  });

  const [data, setData] = useState([]);
  const [barChartData, setBarChartData] = useState([]);
  const [teamData, setTeamData] = useState([]);
  const [barchart, setBarChart] = useState([]);
  const fetchData = async () => {
    try {
      const response = await axios.get(GET_ALL_TASKS_URL);
      if (response.status === 200) {
        const modifiedData = response.data.map((item) => ({
          Name: item.assignedTo.name,
          TaskDetails: item.taskDetails,
          Team: item.team.name,
          AssignedDate: item.assignedDate,
          CompleteDate: item.completedDate,
          Status: item.status,
          Rating: item.rating,
          Points: item.rating * 10,
          _id: item._id,
        }));
        const sortedData = modifiedData.sort((a, b) => b.Points - a.Points);
        const topPerformers = sortedData.slice(0, 5);
        const barChartData1 = topPerformers.map((performer) => ({
          Name: performer.Name,
          Points: performer.Points,
        }));
        const barchart2 = sortedData.map((performer) => ({
          Name: performer.Name,
          Points: performer.Points,
        }));

        setBarChart(barchart2);

        setBarChartData(barChartData1);
        const tasksPer = modifiedData.reduce((acc, cur) => {
          acc[cur.Name] =
            (acc[cur.Name] || 0) +
            (cur.Status === "Completed and Validated" ? 1 : 0);
          return acc;
        }, {});

        const pieChartData = Object.keys(tasksPer).map((name, index) => ({
          id: index,
          value: tasksPer[name],
          label: name,
        }));

        // Now you have top 5 performers for each team in topPerformersByTeam object

        setData(pieChartData);
        const tasksPerUser = [];
        const teamPoints = {};
        modifiedData.forEach((task) => {
          const { Team, Points } = task;
          if (!teamPoints[Team]) {
            teamPoints[Team] = 0;
          }
          teamPoints[Team] += Points;
        });

        // Sort the teams based on the total points
        const sortedTeams = Object.entries(teamPoints).sort(
          ([, pointsA], [, pointsB]) => pointsB - pointsA
        );

        // Select the top 3 teams
        const top3Teams = sortedTeams.slice(0, 3);

        // Prepare the data for the graph
        const graphData = [
          ["Team", "Points"],
          ...top3Teams.map(([team, points]) => [team, points]),
        ];

        setTeamData(graphData);

        const distinctNameValues = [
          ...new Set(modifiedData.map((task) => task.Name)),
        ];
        distinctNameValues.forEach((person) => {
          const taskCount = modifiedData.filter(
            (task) => task.Name === person
          ).length;
          tasksPerUser.push({ name: person, taskCount });
        });

        setTasks(modifiedData);
      }
    } catch (error) {
      console.error("Error fetching tasks", error);
    }
  };

  const getGreeting = () => {
    const now = new Date();
    const hours = now.getHours();

    if (hours < 12) return "Good Morning";
    if (hours < 17) return "Good Afternoon";
    return "Good Evening";
  };
  return (
    <div className="container">
      {isMobile ? (
        <>
          <AppBar
            position="fixed"
            style={{ background: "transparent", boxShadow: "none" }}
          >
            <Toolbar>
              <IconButton
                color="primary"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
              {/* <LeftContainer handleLogout={handleLogout} /> */}
            </Toolbar>
          </AppBar>
          <Drawer
            variant="temporary"
            open={isDrawerOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {/* ... Other code ... */}
            <LeftContainer handleLogout={handleLogout} />
            {/* ... Other code ... */}
          </Drawer>
        </>
      ) : (
        <>
          <LeftContainer handleLogout={handleLogout} />
          {/* ... Other code ... */}
        </>
      )}

      <div className="right-container">
        <div className="right-container-top"></div>

        <div className="right-container-table" style={{ marginTop: "-50px" }}>
          <div
            style={{
              marginTop: "2%",
            }}
          >
            <Typography variant="h4" gutterBottom>
              {getGreeting()},
            </Typography>
            <Typography variant="h4" gutterBottom>
              Hi ,Admin
            </Typography>
            <Typography variant="body1" paragraph>
              Here you can track records and accuracy of Users.
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Card className={classes.card}>
              <CardContent>
                <Typography variant="h5" component="h2" gutterBottom>
                  Performance Completed Tasks per User
                </Typography>
                <div className={classes.chartContainer}>
                  <PieChart
                    series={[
                      {
                        data: data,
                        highlightScope: {
                          faded: "global",
                          highlighted: "item",
                        },
                        faded: {
                          innerRadius: 30,
                          additionalRadius: -30,
                          color: "gray",
                        },
                      },
                    ]}
                    width={400}
                    height={300}
                  />
                </div>
              </CardContent>
            </Card>
            <Card className={classes.card}>
              <CardContent>
                <Typography variant="h5" component="h2" gutterBottom>
                  Top 5 Performance On the Basis of Points
                </Typography>
                <div className={classes.chartContainer}>
                  {barChartData && (
                    <div style={{ width: "100%", height: "400px" }}>
                      <Chart
                        width={"100%"}
                        height={"100%"}
                        chartType="BarChart"
                        loader={<div>Loading Chart</div>}
                        data={[
                          ["Name", "Points"],
                          ...barChartData.map(({ Name, Points }) => [
                            Name,
                            Points,
                          ]),
                        ]}
                        options={{
                          title: "Horizontal Bar Chart",
                          chartArea: { width: "50%" },
                          hAxis: {
                            title: "Points",
                            minValue: 0,
                          },
                          vAxis: {
                            title: "Name",
                          },
                          animation: {
                            startup: true,
                            duration: 1000,
                            easing: "out",
                          },
                          colors: ["#3366CC"], // Change color here
                        }}
                      />
                    </div>
                  )}
                </div>
              </CardContent>
            </Card>
          </div>
          <Card className={classes.card}>
            <CardContent>
              <Typography variant="h5" component="h2" gutterBottom>
                Top 3 Teams Performance
              </Typography>
              <div className={classes.chartContainer}>
                <div>
                  <Chart
                    width={"100%"}
                    height={"400px"}
                    chartType="BarChart"
                    loader={<div>Loading Chart</div>}
                    data={teamData}
                    options={{
                      title: "Top 3 Teams Performance",
                      chartArea: { width: "50%" },
                      hAxis: {
                        title: "Team",
                      },
                      vAxis: {
                        title: "Points",
                      },
                      bars: "vertical",
                    }}
                  />
                </div>
              </div>
            </CardContent>
          
          </Card>
         
          <Card style={{
            marginTop:"20px"
          }}>
              <CardContent>
                <Typography variant="h5" component="h2" gutterBottom>
                  All Users Performance On the Basis of Points
                </Typography>
                <div className={classes.chartContainer}>
                  {barChartData && (
                    <div style={{ width: "120%", height: "400px" }}>
                      <Chart
                        width={"100%"}
                        height={"100%"}
                        chartType="Bar"
                        loader={<div>Loading Chart</div>}
                        data={[
                          ["Name", "Points"],
                          ...barchart.map(({ Name, Points }) => [

                            Name,
                            Points,
                          ]),
                        ]}
                        options={{
                          title: "Horizontal Bar Chart",
                          chartArea: { width: "70%" },
                          hAxis: {
                            title: "Points",
                            minValue: 0,
                          },
                          vAxis: {
                            title: "Name",
                          },
                          animation: {
                            startup: true,
                            duration: 1000,
                            easing: "out",
                          },
                          colors: ["#3366CC"], // Change color here
                        }}
                      />
                    </div>
                  )}
                </div>
              </CardContent>
            </Card>
        </div>
      </div>
    </div>
  );
}
export default GraphicalView;

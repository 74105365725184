import React, { useState, useEffect } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
  useMediaQuery,
  IconButton,
  Drawer,
  AppBar,
  Toolbar,
  Card,
  CardContent,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import axios from "axios";
import { Link } from "react-router-dom";
import "../DashboardPage.css";
import { PROCESS_RECEIVE_URL, UPDATE_STEPS_URL } from "../../api/onBoarding";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import UserLeftContainer from "../UI/UserLeftContainer";

// import "./UserOnBoarding.css";

function UserOnBoarding() {
  const [userName, setUserName] = useState("");
  const [userId, setUserId] = useState("");
  const [data, setData] = useState([]);
  const [admin, setAdmin] = useState("false");
  const [token, setToken] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [onBoardingStatus, setOnBoardingStatus] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const storedToken = await localStorage.getItem("token");
      setToken(storedToken);
      console.log(storedToken)
      // const headers = 

      const response = await axios.get(PROCESS_RECEIVE_URL, {
        Authorization: `Bearer ${storedToken}`,
      });
      if (response.status === 200) {
        setData(response.data.steps);
        setUserName(response.data.name);
        setUserId(response.data.userId);
        // Check if the current active step is already completed
        const data1 = response.data.steps.filter((data) => data.bool);
        setActiveStep(data1.length);
        localStorage.removeItem("verified");
        localStorage.setItem("verified", response.data.verified);
        setOnBoardingStatus(response.data.verified);
        
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleNext = async () => {
    const arePrevStepsCompleted = data
      .slice(0, activeStep)
      .every((step) => step.bool);

    if (arePrevStepsCompleted) {
      const updatedSteps = [...data];
      updatedSteps[activeStep].bool = true;

      // Send Axios request to update steps on the server
      try {
        const response = await axios.patch(
          UPDATE_STEPS_URL(userId),
          { steps: updatedSteps },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
        } else {
          console.error("Failed to update steps:", response.data.message);
        }
      } catch (error) {
        console.error("Error updating steps:", error.message);
      }
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = async () => {
    // Reset the status of the current step to false
    const updatedSteps = [...data];
    updatedSteps[activeStep - 1].bool = false;
    setData(updatedSteps);

    // Send Axios request to update steps on the server
    try {
      const response = await axios.patch(
        UPDATE_STEPS_URL(userId),
        { steps: updatedSteps },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
      } else {
        console.error("Failed to update steps:", response.data.message);
      }
    } catch (error) {
      console.error("Error updating steps:", error.message);
    }

    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const [viewLink, setViewLink] = useState(null);

  const handleViewLink = (link) => {
    setViewLink(viewLink === link ? null : link);
  };
  const handleCloseView = () => {
    setViewLink(null);
  };
  const handleReset = async () => {
    // Reset all steps to false
    const resetSteps = data.map((step) => ({ ...step, bool: false }));
    setData(resetSteps);

    // Send Axios request to update steps on the server
    try {
      const response = await axios.patch(
        UPDATE_STEPS_URL(userId),
        { steps: resetSteps },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setActiveStep(0);
      } else {
        console.error("Failed to reset steps:", response.data.message);
      }
    } catch (error) {
      console.error("Error resetting steps:", error.message);
    }
  };
  const isMobile = useMediaQuery("(max-width: 768px)");
  return (
    <div className="container">
      {isMobile ? (
        <>
          <AppBar
            position="fixed"
            style={{ background: "transparent", boxShadow: "none" }}
          >
            <Toolbar>
              <IconButton
                color="primary"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
              <p>Welcome, Admin!</p>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="temporary"
            open={isDrawerOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <UserLeftContainer />
          </Drawer>
        </>
      ) : (
        <>
          <UserLeftContainer />
        </>
      )}
      <div className="right-container" style={{
         backgroundColor:"#DAEDE3",
         padding: "20px",
         display: "flex",
         flexDirection: "column",
         
      }}>
        <div className="right-container-top">
          <h1>OnBoarding Process</h1>
          <h1>
            Hi {userName}
            {admin === "true" ? " admin for overview 👋" : null}
          </h1>
        </div>
        <div
          className="right-container-table"
          style={{
            marginTop: "5%",
          }}
        >
          {data.length === 0 ? <h1>NO Boarding assign you yet!</h1> : null}
          <Stepper
            activeStep={activeStep}
            orientation={isMobile ? "vertical" : "horizontal"}
          >
            {data.map((step, index) => (
              <Step key={step.title}>
                <StepLabel>
                  <Typography>{step.title}</Typography>
                  <Button onClick={() => handleViewLink(step.link)}>
                    {viewLink === step.link ? "Close" : "View"}
                  </Button>
                  {viewLink === step.link && (
                    <Card>
                      <CardContent>
                        <a
                          href={step.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Typography>{step.link}</Typography>
                        </a>
                      </CardContent>
                    </Card>
                  )}
                </StepLabel>
              </Step>
            ))}
          </Stepper>

          <div
            style={{
              marginLeft: isMobile ? "20%" : "60%",
              marginTop: "5%",
              display: "flex",
              flexDirection: "row",
              gap: "5px",
            }}
          >
            {data.length !== 0 ? (
              activeStep === data.length ? (
                <div>
                  <Typography>All steps completed</Typography>
                </div>
              ) : (
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  <Button variant="contained" onClick={handleNext}>
                    {activeStep === data.length - 1 ? "Finish" : "Next"}
                  </Button>
                </div>
              )
            ) : null}

            {activeStep > 0 && (
              <Button variant="outlined" onClick={handleReset} sx={{ ml: 1 }}>
                Reset All
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserOnBoarding;

import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Select,
  InputLabel,
  TextareaAutosize,
  Snackbar,
  Alert,
  DialogContentText,
  CardContent,
  Card,
  Box,
  Typography,
  IconButton,
  Badge,
  Avatar,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from "@mui/icons-material";
import { red, yellow, blue } from "@mui/material/colors";
import FlagIcon from "@mui/icons-material/Flag";
import {
  CREATE_TASK_URL,
  DELETE_TASK_URL,
  GET_ALL_TASKS_URL,
  GET_ALL_TEAMS_URL,
  GET_ALL_USERS_URL,
  UPDATE_STATUS_URL,
  UPDATE_TASK_DETAILS_URL,
  UPDATE_URL,
} from "../../api/user";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { PieChart } from "@mui/x-charts";
import { makeStyles } from "@mui/styles";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const useStyles = makeStyles({
  card: {
    maxWidth: 400,
    margin: "auto",
    marginTop: 20,
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
  },
  chartContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
  },
});

function NewProfileAdminView() {
  const { id } = useParams();
 
  const navigate = useNavigate();
  const classes = useStyles();
  const [tasks, setTasks] = useState([]);
  const [token, setToken] = useState("");
  const [assignedTasks, setAssignedTasks] = useState([]);
  const [pendingTasks, setPendingTasks] = useState([]);
  const [completedAndValidatedTasks, setCompletedAndValidatedTasks] = useState(
    []
  );

  const [updateFormData, setUpdateFormData] = useState({
    id: "",
    taskDetails: "",
    assignedDate: "",
    completedDate: "",
    status: "",
    rating: "",
    name: "",
    assignedTo: "",
    comments: "",
    priority: "",
  });
  const [isUpdateDialogOpen, setIsUpdateDialogOpen] = useState(false);

  // Define the onClose function
  const handleClose = () => {
    setIsUpdateDialogOpen(false); // Close the create dialog
    // Additional cleanup or state updates if needed
  };
  const handleUpdateTask = async () => {
    try {
      const response = await axios.put(
        UPDATE_URL(updateFormData.id),
        updateFormData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        // Update the UI with the updated task data
        fetchData();

        // Close the update dialog
        setIsUpdateDialogOpen(false);

        // Show success snackbar message
        setSnackbarMessage("Task updated successfully");
        setIsSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error updating task", error);
    }
  };
  const handleUpdateFormChange = (event) => {
    const { name, value } = event.target;
    setUpdateFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  useEffect(() => {
    fetchData();
  }, []);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [taskIdToDelete, setTaskIdToDelete] = useState(null);

  const [userList, setUserList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [teamList, setTeamList] = useState([]);

  const [selectedUser, setSelectedUser] = useState("");
  const [selectedTeam, setSelectedTeam] = useState("");

  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [isCompleteDialogOpen, setIsCompleteDialogOpen] = useState(false);

  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [showFilters, setShowFilters] = useState(false);
  const [filterText, setFilterText] = useState("");

  // Use a single formData object to handle form data
  const [selectedColumn, setSelectedColumn] = useState("");
  const [columns, setColumns] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    taskDetails: "",
    team: "",
    status: "Assigned",
    assignedDate: "",
    completedDate: "",
    rating: "",
  });

  const [assignedDateFilter, setAssignedDateFilter] = useState({
    startDate: "",
    endDate: "",
  });
  const [completedDateFilter, setCompletedDateFilter] = useState({
    startDate: "",
    endDate: "",
  });

  useEffect(() => {
    fetchUser();
    fetchTeams();
  }, []);

  const fetchUser = async () => {
    try {
      const response = await axios.get(GET_ALL_USERS_URL);
      if (response.status === 200) {
        // Filter users based on admin status and account approval
        const filteredUsers = response.data.filter(
          (user) =>
            user.isAdmin === false &&
            user.isApproved === true &&
            user.isDeactivated === false
        );
        setUserList(filteredUsers);
      }
    } catch (error) {
      console.error("Error fetching tasks", error);
    }
  };
  const [data, setData] = useState([]);
  const fetchTeams = async () => {
    try {
      const response = await axios.get(GET_ALL_TEAMS_URL);
      if (response.status === 200) {
        setTeamList(response.data);
      }
    } catch (error) {
      console.error("Error fetching tasks", error);
    }
  };

  const calculateDaysDifference = (startDate, endDate) => {
    const oneDay = 1000 * 60 * 60 * 24; // Milliseconds in a day
    const start = new Date(startDate).getTime();
    const end = new Date(endDate).getTime();
    const difference = Math.abs(end - start);
    return Math.ceil(difference / oneDay);
  };

  const handleCreateAssignment = () => {
    setIsCreateDialogOpen(true);
  };

  const handleCloseCreateDialog = () => {
    setIsCreateDialogOpen(false);

    // Clear the formData object
    setFormData({
      name: "",
      taskDetails: "",
      team: "",
      status: "Assigned",
      assignedDate: "",
      completedDate: "",
      rating: "",
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    // Update the formData object
    setUpdateFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleInputChangeCreate = (event) => {
    const { name, value } = event.target;

    // Update the formData object
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleUserSelect = (event) => {
    const selectedUserId = event.target.value;
    setSelectedUser(selectedUserId);

    // Update the formData object with the selected user
    setFormData((prevData) => ({
      ...prevData,
      name: selectedUserId,
    }));
  };

  const handleTeamSelect = (event) => {
    const selectedTeamId = event.target.value;
    setSelectedTeam(selectedTeamId);

    // Update the formData object with the selected team
    setFormData((prevData) => ({
      ...prevData,
      team: selectedTeamId,
    }));
  };

  const handleCreateTaskSubmit = async () => {
    try {
      setLoader(true);
      const newTaskData = {
        assignedTo: selectedUser,
        taskDetails: formData.taskDetails,
        team: selectedTeam,
        status: formData.status,
        assignedDate: formData.assignedDate,
      };

      const response = await axios.post(CREATE_TASK_URL, newTaskData);

      const newTask = [
        {
          _id: response.data._id,
          assignedTo: userList.find((user) => user._id === selectedUser),
          taskDetails: formData.taskDetails,
          team: teamList.find((team) => team._id === selectedTeam),
          status: formData.status,
          assignedDate: formData.assignedDate,
        },
      ];

      const modifiedData = newTask.map((item) => ({
        Name: item.assignedTo.name,
        TaskDetails: item.taskDetails,
        Team: item.team.name,
        AssignedDate: item.assignedDate,
        CompleteDate: "-",
        Status: item.status,
        Rating: 0,
        Points: 0,
        _id: item._id,
      }));
      setData(data.concat(modifiedData));

      // Update the data state with the new task

      // Close the create task dialog
      setLoader(false);
      setIsCreateDialogOpen(false);

      // Clear/reset the selectedUser and selectedTeam state
      setSelectedUser("");
      setSelectedTeam("");

      // Clear the formData object
      setFormData({
        name: "",
        taskDetails: "",
        team: "",
        assignedDate: "",
        status: "Assigned",
      });

      setSnackbarMessage("Task created successfully");
      setIsSnackbarOpen(true);
    } catch (error) {
      if (error.response.status === 400) {
        // If the error is a validation error, handle multiple validation errors
        if (error.response.data && error.response.data.details) {
          const validationErrors = Object.values(
            error.response.data.details
          ).flat();
          setSnackbarMessage(
            `Validation error: ${validationErrors.join(", ")}`
          );
        } else {
          // If no details provided, show a generic error message
          setSnackbarMessage("Validation error: Something went wrong.");
        }
        // Open the Snackbar
        setIsSnackbarOpen(true);
      }
      console.error("Error creating task", error);
    }
  };

  const resetFilters = () => {
    setFilterText("");
    setSelectedColumn("");
    setAssignedDateFilter({ startDate: "", endDate: "" });
    setCompletedDateFilter({ startDate: "", endDate: "" });
  };
  const handleDeleteTask = async () => {
    try {
      await axios.delete(DELETE_TASK_URL(taskIdToDelete));
      const updatedData = data.filter((task) => task._id !== taskIdToDelete);
      setData(updatedData);
      fetchData();
      setTaskIdToDelete(null);
      setOpenDeleteDialog(false); // Close the delete dialog
    } catch (error) {
      console.error("Error deleting task:", error);
    }
  };

  const confirmDelete = (taskId) => {
    setTaskIdToDelete(taskId);
    setOpenDeleteDialog(true); // Open the delete dialog
  };

  const cancelDelete = () => {
    setTaskIdToDelete(null);
    setOpenDeleteDialog(false); // Close the delete dialog
  };
  const handleOpen = (id) => {
    setIsUpdateDialogOpen(true); // Open the create dialog

    const taskToUpdate = tasks.find((task) => task._id === id);

    if (taskToUpdate) {
      const taskToUpdate = tasks.find((task) => task._id === id);
      // If the task is found, update the specific key-value pair in the updateFormData state
      const value = {
        id: taskToUpdate._id,
        taskDetails: taskToUpdate.TaskDetails,
        assignedDate: taskToUpdate.AssignedDate,
        completedDate: taskToUpdate.CompleteDate,
        status: taskToUpdate.Status,
        rating: taskToUpdate.Rating,
        name: taskToUpdate.Name,
        assignedTo: taskToUpdate.AssignedTo,
        comments: taskToUpdate.Comments,
        priority: taskToUpdate.Priority,
      };

      setUpdateFormData(value);

      // Additional cleanup or state updates if needed
    }
  };
  const handleCompleteDialogClose = () => {
    setIsCompleteDialogOpen(false);

    // Clear the formData object
    setFormData({
      name: "",
      taskDetails: "",
      team: "",
      assignedDate: "",
      status: "Assigned",
      completedDate: "",
      rating: "",
    });
  };
  const [newDroppableTasks, setNewDroppableTasks] = useState([]);
  const [editModes, setEditModes] = useState(
    new Array(newDroppableTasks.length).fill(false)
  );

  const handleAddNewDroppableTasks = () => {
    const newDroppableTask = {
      id: Math.random().toString(),
      title: "",
      tasks: [],
    };
    setStatusValue([...statusValue, newDroppableTask.title]);
    // setNewDroppableTasks([...newDroppableTasks, newDroppableTask]);
  };

  const toggleEditMode = (index) => {
    const updatedEditModes = [...editModes];
    updatedEditModes[index] = !editModes[index];
    setEditModes(updatedEditModes);
  };

  const handleTitleChange = (event, index) => {
    const updatedDroppableTasks = [...statusValue];
    updatedDroppableTasks[index] = event.target.value;
    setStatusValue(updatedDroppableTasks);
  };

  //   const filteredData = data.filter((item) =>
  //   !filterText || item[selectedColumn].toLowerCase().includes(filterText.toLowerCase())
  // );
  // Inside your component, add the following function to check if a value is numeric:

  const handleStartDateChange = (event) => {
    const newStartDate = event.target.value;

    // Determine the appropriate date filter state to update based on the selected column
    if (selectedColumn === "AssignedDate") {
      setAssignedDateFilter((prevFilter) => ({
        ...prevFilter,
        startDate: newStartDate,
      }));
    } else if (selectedColumn === "CompleteDate") {
      setCompletedDateFilter((prevFilter) => ({
        ...prevFilter,
        startDate: newStartDate,
      }));
    }
  };
  const handleCompleteAssignmentSubmit = async () => {
    try {
      const completedTaskData = {
        _id: formData.id,
        completedDate: formData.completedDate,
        rating: formData.rating,
        status: "Completed and Validated",
      };

      const response = await axios.put(
        UPDATE_TASK_DETAILS_URL(formData.id),
        completedTaskData
      );
      if (response.status === 200) {
        // Task completed successfully, update the UI with the completed task
        const updatedData = data.map((item) =>
          item._id === formData.id
            ? {
                ...item,
                Status: "Completed and Validated",
                CompleteDate: formData.completedDate,
                Rating: formData.rating,
              }
            : item
        );

        // Update the data state with the updated task
        setData(updatedData);

        // Close the complete task dialog
        setIsCompleteDialogOpen(false);

        // Clear/reset the formData state
        setFormData({
          taskDetails: "",
          team: "",
          assignedDate: "",
          status: "Assigned",
          completedDate: "",
          rating: "",
        });
      }
    } catch (error) {
      console.error("Error completing task", error);
    }
  };
  const handleEndDateChange = (event) => {
    const newEndDate = event.target.value;

    // Determine the appropriate date filter state to update based on the selected column
    if (selectedColumn === "AssignedDate") {
      setAssignedDateFilter((prevFilter) => ({
        ...prevFilter,
        endDate: newEndDate,
      }));
    } else if (selectedColumn === "CompleteDate") {
      setCompletedDateFilter((prevFilter) => ({
        ...prevFilter,
        endDate: newEndDate,
      }));
    }
  };
  const [statusValue, setStatusValue] = useState([]);
  const [selectAssignee, setSelectAssignee] = useState("None");

  const [assignePerson, setAssignePerson] = useState([]);
  const [selectedPerson, setSelectedPerson] = useState("");
  const [notask, setNoTask] = useState([]);
  const [newProfile, setNewProfile] = useState([]);
  const [statusCounts, setStatusCounts] = useState([]);
  const fetchData = async () => {
    try {
      const token1 = localStorage.getItem("token");
      setToken(token1);
      const response = await axios.get(GET_ALL_TASKS_URL);

      if (response.status === 200) {
        const modifiedData = response.data.map((item) => ({
          AssignedTo: item.assignedTo._id,
          Name: item.assignedTo.name,
          TaskDetails: item.taskDetails,
          Team: item.team.name,
          AssignedDate: item.assignedDate,
          CompleteDate: item.completedDate,
          Status: item.status,
          Rating: item.rating,
          Points: item.rating * 10,
          _id: item._id,
          Comments: item.comments,
          Priority: item.priority,
          NewProfile: item.newProfile,
        }));

        const tasksPer = modifiedData.reduce((acc, cur) => {
          acc[cur.Name] =
            (acc[cur.Name] || 0) +
            (cur.Status === "Completed and Validated" ? 1 : 0);
          return acc;
        }, {});
        const pieChartData = Object.keys(tasksPer).map((name, index) => ({
          id: index,
          value: tasksPer[name],
          label: name,
        }));

        setData(modifiedData);
        const tasksPerUser = [];
        const distinctStatusValues = [
          "Todo", // Default status
          ...new Set(modifiedData.map((task) => task.Status)),
        ];
        const distinctNewProfileValues = [
          ...new Set(modifiedData.map((task) => task.NewProfile)),
        ];
       
        const distinctNameValues = [
          ...new Set(modifiedData.map((task) => task.Name)),
        ];
        distinctNameValues.forEach((person) => {
          const taskCount = modifiedData.filter(
            (task) => task.Name === person
          ).length;
          tasksPerUser.push({ name: person, taskCount });
        });
        const filteredData = modifiedData.filter(
          (item) => item.NewProfile === id
        );

        // Count of each distinct status from filteredData
        const statusCounts = filteredData.reduce((acc, cur) => {
          acc[cur.Status] = (acc[cur.Status] || 0) + 1;
          return acc;
        }, {});

        const statusCountsInOrder = distinctStatusValues.map((status) => ({
          status,
          count: statusCounts[status] || 0,
        }));
      
        // Set the state with the counts in order
        setStatusCounts(statusCountsInOrder);
        const amazingdata = modifiedData.filter(
          (task) => task.NewProfile === id
        );
       
        setProfiles(distinctNewProfileValues);
        setNoTask(tasksPerUser);
        setAssignePerson(distinctNameValues);
        setStatusValue(distinctStatusValues);
        setTasks(amazingdata);
      }
    } catch (error) {
      console.error("Error fetching tasks", error);
    }
  };
  const handlePersonSelect = (event) => {
    setSelectedPerson(event.target.value);
  };

  // Function to filter tasks by person's name
  const filterTasksByPerson = (status) => {
    if (status === "Todo") {
      const filterData = data.filter(
        (task) => task.NewProfile !== id && !task.NewProfile
      );
      return filterData;
    }
    const value = tasks.filter((task) => task.Name === selectedPerson);
   
    return tasks.filter(
      (task) =>
        // task.Name === selectedPerson &&
        task.Status === status
      // task.Name === task.name
    );
  };
  const [openDialog, setOpenDialog] = useState(false);
  const [profileName, setProfileName] = useState("");
  const [profiles, setProfiles] = useState([]);

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };
  const handleAddProfile = () => {
    if (profileName.trim() !== "") {
      setProfiles([...profiles, profileName]);
      setProfileName("");
      setOpenDialog(false);
    }
  };
  const handleProfileChange = async(event) => {
    if (event.target.value === "ALL") setProfileName(event.target.value);
    else if (event.target.value !== "newProfile") {
      await fetchData();
      navigate(`/newProfileAdminView/${event.target.value}`);
    }
  };
  const handleDragEnd = async (result) => {
   
    if (result.destination.droppableId === "Todo") return;
    if (!result.destination) return; // dropped outside the list

    const taskId = result.draggableId;

    const findTask = data.find((task) => task._id === taskId);
    
    let newStatus;
    let newTitle;

    // Determine the new status based on the destination droppable
    switch (result.destination.droppableId) {
      case "completedAndValidatedTasks":
        newStatus = "Completed and Validated";
        break;
      case "assignedTasks":
        newStatus = "Assigned";
        break;
      case "pendingTasks":
        newStatus = "Pending";
        break;
      default:
        // Handle dynamically added droppable tasks

        newStatus = result.destination.droppableId; // Extract status from droppableId

        break;
    }

    try {
      if (findTask.Status !== newStatus) {
        await axios.put(UPDATE_STATUS_URL(taskId), {
          status: newStatus,
          newProfile: id,
        });
        fetchData(); // Refresh tasks after status or title update
      }
    } catch (error) {
      console.error("Error updating task status", error);
    }
  };
  const [isWrapped, setIsWrapped] = useState(true);

  const toggleWrap = () => {
    setIsWrapped(!isWrapped);
  };
  const handleAssigne = (e) => {
    setSelectAssignee(e.target.value);
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="inherit" component="span" fontSize={24}>
          Dashboard : {id}
        </Typography>
        <div style={{ display: "flex", alignItems: "center", marginTop: "3%" }}>
          {/* Select for filtering by person */}
          <Button
            variant="contained"
            color="primary"
            className="createTask"
            style={{
              width: "150px",
              marginRight: "20px",
            }}
            onClick={handleCreateAssignment}
          >
            Create Task
          </Button>
          <Select
            value={selectedPerson}
            onChange={handlePersonSelect}
            style={{ marginRight: "20px" }}
          >
            <MenuItem value="">
              <Typography variant="inherit" component="span">
                All
              </Typography>
            </MenuItem>
            {Array.from(new Set(tasks.map((task) => task.Name))).map(
              (person) => (
                <MenuItem key={person} value={person}>
                  <Box display="flex" alignItems="center">
                    <Avatar style={{ marginRight: "8px" }}>
                      {person.charAt(0).toUpperCase()}
                    </Avatar>
                    <Typography variant="inherit" component="span">
                      {person}
                    </Typography>
                  </Box>
                </MenuItem>
              )
            )}
          </Select>

          {/* Badge showing number of tasks for selected person */}
          {selectedPerson && (
            <Badge
              badgeContent={filterTasksByPerson().length}
              color="primary"
              style={{
                fontSize: "20px",
                marginRight: "20px",
              }}
            >
              Tasks for {selectedPerson}
            </Badge>
          )}

          {/* Select for filtering by assignee */}
          <Select
            value={selectAssignee}
            onChange={handleAssigne}
            style={{
              marginLeft: "40%",
            }}
          >
            <MenuItem value="None">
              <Typography variant="inherit" component="span">
                None
              </Typography>
            </MenuItem>
            <MenuItem value="Assigne">
              <Typography variant="inherit" component="span">
                Assigne
              </Typography>
            </MenuItem>
          </Select>
          {/* <div>
            <Select value={profileName} onChange={handleProfileChange}>
              {profiles.map((profile, index) => (
                <MenuItem key={index} value={profile}>
                  {profile}
                </MenuItem>
              ))}
              <MenuItem value="ALL">All</MenuItem>
              <MenuItem
                value="newProfile"
                onClick={handleDialogOpen}
                style={{
                  backgroundColor: "#00bfff",
                }}
              >
                Create New Profile
              </MenuItem>
            </Select>

            <Dialog open={openDialog} onClose={handleDialogClose}>
              <DialogTitle>New Profile</DialogTitle>
              <DialogContent>
                <TextField
                  type="text"
                  value={profileName}
                  onChange={(e) => setProfileName(e.target.value)}
                  placeholder="Enter profile name"
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleDialogClose}>Cancel</Button>
                <Button onClick={handleAddProfile}>Add</Button>
              </DialogActions>
            </Dialog>
          </div> */}
        </div>
      </div>
      <Box
        display="flex"
        style={{
          marginTop: "30px",
          marginBottom: "50px",
        }}
      >
        <DragDropContext onDragEnd={handleDragEnd}>
          {statusValue.map((status, index) => (
            <>
              {selectAssignee === "Assigne" ? (
                <Droppable key={index} droppableId={status}>
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={{
                        marginRight: "10px",
                        border: "2px solid rgb(160 156 156 / 48%)",
                        padding: "2px",
                        borderRadius: "10px",
                        width: "18%",
                        background: "#c7b4b426",
                        overflowY: "auto",
                        height: "calc(110vh - 200px)",
                        scrollbarWidth: "none",
                        WebkitOverflowScrolling: "touch",
                        "::-webkit-scrollbar": {
                          display: "none",
                        },

                        msOverflowStyle: "none",
                      }}
                    >
                      <Typography
                        variant="h2"
                        component="h2"
                        fontSize={20}
                        style={{ margin: "10px" }}
                      >
                        {editModes[index] ? (
                          <TextField
                            value={status}
                            onChange={(event) =>
                              handleTitleChange(event, index)
                            }
                          />
                        ) : (
                          status
                        )}
                        <IconButton onClick={() => toggleEditMode(index)}>
                          {editModes[index] ? <SaveIcon /> : <EditIcon />}
                        </IconButton>
                        <Badge
                          badgeContent={statusCounts[index].count}
                          color="primary"
                          style={{
                            fontSize: "20px",
                            marginLeft: "20px",
                          }}
                        ></Badge>
                      </Typography>
                      {/* Add your draggable components here */}

                      {notask.map((value, index) => (
                        <div key={index}>
                          <Card
                            variant="outlined"
                            style={{
                              background: "#e0deec",
                              padding: "0px",
                              margin: "10px",
                            }}
                          >
                            <CardContent>
                              <Typography
                                variant="h6"
                                component="div"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {value.name} issue{" "}
                                <Badge
                                  badgeContent={value.taskCount}
                                  color="primary"
                                  style={{
                                    fontSize: "50px",
                                    marginLeft: "15px",
                                  }}
                                ></Badge>
                                <IconButton onClick={toggleWrap}>
                                  {isWrapped ? (
                                    <ExpandMoreIcon />
                                  ) : (
                                    <ExpandLessIcon />
                                  )}
                                </IconButton>
                              </Typography>
                            </CardContent>
                          </Card>
                          {filterTasksByPerson(status).map(
                            (task, taskIndex) => (
                              <Draggable
                                key={taskIndex}
                                draggableId={task._id}
                                index={taskIndex}
                              >
                                {(provided) => (
                                  <div>
                                    {isWrapped ? (
                                      <div
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        ref={provided.innerRef}
                                        style={{
                                          marginBottom: "5px",
                                          background: "#cecbcb5c",
                                          padding: "2px",
                                          borderRadius: "5px",
                                          ...provided.draggableProps.style,
                                        }}
                                      >
                                        <Card
                                          style={{
                                            borderRadius: "10px",
                                            boxShadow:
                                              "0 4px 6px rgba(0,0,0,0.1)",
                                            margin: "10px",
                                          }}
                                        >
                                          <CardContent
                                            style={{ padding: "5px" }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                              }}
                                            >
                                              <Typography
                                                variant="h5"
                                                component="h5"
                                                fontSize={20}
                                              >
                                                {task.Name}
                                              </Typography>
                                              <div>
                                                <IconButton
                                                  aria-label="edit task"
                                                  onClick={() =>
                                                    handleOpen(task._id)
                                                  }
                                                >
                                                  <EditIcon />
                                                </IconButton>
                                                <IconButton
                                                  aria-label="delete task"
                                                  onClick={() =>
                                                    confirmDelete(task._id)
                                                  }
                                                >
                                                  <DeleteIcon />
                                                </IconButton>
                                              </div>
                                            </div>
                                            <Typography
                                              variant="body1"
                                              component="p"
                                            >
                                              {isWrapped ? (
                                                <div
                                                  style={{
                                                    overflowY: "scroll",
                                                    whiteSpace: "normal",
                                                  }}
                                                >
                                                  {task.TaskDetails}
                                                </div>
                                              ) : (
                                                <div>{task.TaskDetails}</div>
                                              )}
                                            </Typography>
                                            <Typography
                                              variant="body2"
                                              component="p"
                                              style={{
                                                color: "rgba(0,0,0,0.6)",
                                              }}
                                            >
                                              Team: {task.Team}
                                            </Typography>
                                            <Typography
                                              variant="body2"
                                              component="p"
                                              style={{
                                                color: "rgba(0,0,0,0.6)",
                                              }}
                                            >
                                              Assigned Date:{" "}
                                              {new Date(
                                                task.AssignedDate
                                              ).toLocaleDateString()}
                                            </Typography>
                                          </CardContent>
                                        </Card>
                                      </div>
                                    ) : null}
                                  </div>
                                )}
                              </Draggable>
                            )
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </Droppable>
              ) : (
                <Droppable key={index} droppableId={status}>
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={{
                        marginRight: "10px",
                        border: "2px solid rgb(160 156 156 / 48%)",
                        padding: "2px",
                        borderRadius: "10px",
                        width: "18%",
                        background: "#c7b4b426",
                        overflowY: "auto",
                        height: "calc(110vh - 200px)",
                        scrollbarWidth: "none",
                        WebkitOverflowScrolling: "touch",
                        "::-webkit-scrollbar": {
                          display: "none",
                        },
                        msOverflowStyle: "none",
                      }}
                    >
                      <Typography
                        variant="h2"
                        component="h2"
                        fontSize={20}
                        style={{ margin: "10px" }}
                      >
                        {editModes[index] ? (
                          <TextField
                            value={status}
                            onChange={(event) =>
                              handleTitleChange(event, index)
                            }
                          />
                        ) : (
                          status
                        )}
                        <IconButton onClick={() => toggleEditMode(index)}>
                          {editModes[index] ? <SaveIcon /> : <EditIcon />}
                        </IconButton>
                        <Badge
                          badgeContent={statusCounts[index].count}
                          color="primary"
                          style={{
                            fontSize: "20px",
                            marginLeft: "20px",
                          }}
                        ></Badge>
                      </Typography>
                      {/* Add your draggable components here */}
                      {filterTasksByPerson(status)
                        // .filter((task) => task.Status === status)
                        .map((task, taskIndex) => (
                          <>
                            <Draggable
                              key={taskIndex}
                              draggableId={task._id}
                              index={taskIndex}
                            >
                              {(provided) => (
                                <>
                                  <div>
                                    <div
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      ref={provided.innerRef}
                                      style={{
                                        marginBottom: "5px",
                                        background: "#cecbcb5c",
                                        padding: "2px",
                                        borderRadius: "5px",
                                        ...provided.draggableProps.style,
                                      }}
                                    >
                                      <Card
                                        style={{
                                          borderRadius: "10px",
                                          boxShadow:
                                            "0 4px 6px rgba(0,0,0,0.1)",
                                        }}
                                      >
                                        <CardContent style={{ padding: "5px" }}>
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              alignItems: "center",
                                            }}
                                          >
                                            <Typography
                                              variant="h5"
                                              component="h5"
                                              fontSize={20}
                                            >
                                              {task.Name}
                                            </Typography>
                                            <div>
                                              <IconButton
                                                aria-label="edit task"
                                                onClick={() =>
                                                  handleOpen(task._id)
                                                }
                                              >
                                                <EditIcon />
                                              </IconButton>
                                              <IconButton
                                                aria-label="delete task"
                                                onClick={() =>
                                                  confirmDelete(task._id)
                                                }
                                              >
                                                <DeleteIcon />
                                              </IconButton>
                                              {task.Priority === "High" ? (
                                                <FlagIcon
                                                  style={{ color: red[500] }}
                                                />
                                              ) : task.Priority === "Medium" ? (
                                                <FlagIcon
                                                  style={{ color: yellow[500] }}
                                                />
                                              ) : (
                                                <FlagIcon
                                                  style={{ color: blue[500] }}
                                                />
                                              )}
                                            </div>
                                          </div>
                                          <Typography
                                            variant="body1"
                                            component="p"
                                          >
                                            {task.TaskDetails}
                                          </Typography>
                                          <Typography
                                            variant="body2"
                                            component="p"
                                            style={{ color: "rgba(0,0,0,0.6)" }}
                                          >
                                            Team: {task.Team}
                                          </Typography>
                                          <Typography
                                            variant="body2"
                                            component="p"
                                            style={{ color: "rgba(0,0,0,0.6)" }}
                                          >
                                            Assigned Date:{" "}
                                            {new Date(
                                              task.AssignedDate
                                            ).toLocaleDateString()}
                                          </Typography>
                                        </CardContent>
                                      </Card>
                                    </div>
                                  </div>
                                </>
                              )}
                            </Draggable>
                          </>
                        ))}
                    </div>
                  )}
                </Droppable>
              )}
            </>
          ))}

          <Dialog
            open={openDeleteDialog}
            onClose={cancelDelete}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Confirm Deletion</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete this task?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={cancelDelete} color="primary">
                No
              </Button>
              <Button onClick={handleDeleteTask} color="primary" autoFocus>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={isCreateDialogOpen}
            onClose={handleCloseCreateDialog}
            PaperProps={{
              style: {
                maxWidth: "600px", // Adjust the width as needed
                width: "100%",
              },
            }}
          >
            <DialogTitle>Create Task</DialogTitle>
            <DialogContent>
              <div>
                <InputLabel htmlFor="name">User</InputLabel>
                <Select
                  labelId="name-label"
                  id="name"
                  name="name"
                  value={selectedUser}
                  onChange={handleUserSelect}
                  fullWidth
                >
                  {userList.map((user) => (
                    <MenuItem key={user._id} value={user._id}>
                      {user.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div>
                <InputLabel htmlFor="taskDetails">
                  Task Details (can include links)
                </InputLabel>
                <TextareaAutosize
                  id="taskDetails"
                  name="taskDetails"
                  value={formData.taskDetails}
                  onChange={handleInputChangeCreate}
                  minRows={3}
                  style={{
                    width: "100%",
                    maxWidth: "100%",
                  }}
                />
              </div>
              <div>
                <InputLabel htmlFor="team">Team</InputLabel>
                <Select
                  labelId="team-label"
                  id="team"
                  name="team"
                  value={selectedTeam}
                  onChange={handleTeamSelect}
                  fullWidth
                >
                  {teamList.map((team) => (
                    <MenuItem key={team._id} value={team._id}>
                      {team.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div>
                <InputLabel htmlFor="assignedDate">Assigned Date</InputLabel>
                <TextField
                  type="date"
                  name="assignedDate"
                  value={formData.assignedDate}
                  onChange={handleInputChangeCreate}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div>
                <InputLabel htmlFor="status">Status</InputLabel>
                <Select
                  labelId="status-label"
                  id="status"
                  name="status"
                  value={formData.status}
                  onChange={handleInputChangeCreate}
                  fullWidth
                >
                  <MenuItem value="Assigned">Assigned</MenuItem>
                  <MenuItem value="Pending">Pending</MenuItem>
                  <MenuItem value="Completed">Completed</MenuItem>
                  <MenuItem value="Completed and Validated">
                    Completed and Validated
                  </MenuItem>
                </Select>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseCreateDialog} color="primary">
                Cancel
              </Button>

              {loader ? (
                <CircularProgress />
              ) : (
                <Button onClick={handleCreateTaskSubmit} color="primary">
                  Create
                </Button>
              )}
            </DialogActions>
          </Dialog>
          <Dialog
            open={isCompleteDialogOpen}
            onClose={handleCompleteDialogClose}
          >
            <DialogTitle>Complete Task</DialogTitle>
            <DialogContent>
              <TextField
                label="Completed Date"
                type="date"
                name="completedDate"
                value={formData.completedDate}
                onChange={handleInputChange}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                label="Rating ( out of 5 )"
                type="number"
                name="rating"
                value={formData.rating}
                onChange={handleInputChange}
                fullWidth
                inputProps={{
                  min: 1,
                  max: 5,
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCompleteDialogClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleCompleteAssignmentSubmit} color="primary">
                Complete and Validate
              </Button>
            </DialogActions>
          </Dialog>
          <Snackbar
            open={isSnackbarOpen}
            autoHideDuration={3000} // Adjust the duration as needed (in milliseconds)
            onClose={() => setIsSnackbarOpen(false)}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            <Alert severity="success" onClose={() => setIsSnackbarOpen(false)}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
          <div>
            <Button onClick={handleAddNewDroppableTasks}>+</Button>
          </div>
        </DragDropContext>

        <Dialog
          open={isUpdateDialogOpen}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxWidth: "600px", // Adjust the width as needed
              width: "100%",
            },
          }}
        >
          <DialogTitle>Update Task</DialogTitle>
          <DialogContent>
            <div>
              <InputLabel htmlFor="taskDetails">Task Details</InputLabel>
              <TextField
                id="taskDetails"
                name="taskDetails"
                value={updateFormData.taskDetails}
                onChange={handleInputChange}
                fullWidth
              />
            </div>
            <div>
              <InputLabel htmlFor="assignedTo">Assign To</InputLabel>
              <Select
                id="assignedTo"
                name="assignedTo"
                value={updateFormData.assignedTo}
                onChange={handleInputChange}
                fullWidth
              >
                {userList.map((user) => (
                  <MenuItem key={user._id} value={user._id}>
                    {user.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div>
              <InputLabel htmlFor="status">Status</InputLabel>
              <Select
                id="status"
                name="status"
                value={updateFormData.status}
                onChange={handleInputChange}
                fullWidth
              >
                <MenuItem value="Assigned">Assigned</MenuItem>
                <MenuItem value="Pending">Pending</MenuItem>
                <MenuItem value="Completed">Completed</MenuItem>
                <MenuItem value="Completed and Validated">
                  Completed and Validated
                </MenuItem>
              </Select>
            </div>
            <div>
              <InputLabel htmlFor="completedDate">Completed Date</InputLabel>
              <TextField
                type="date"
                id="completedDate"
                name="completedDate"
                value={updateFormData.completedDate}
                onChange={handleInputChange}
                fullWidth
              />
            </div>
            <div>
              <InputLabel htmlFor="completedDate">Assigned Date</InputLabel>
              <TextField
                type="date"
                id="assignedDate"
                name="assignedDate"
                value={updateFormData.assignedDate}
                onChange={handleInputChange}
                fullWidth
              />
            </div>
            <div>
              <InputLabel htmlFor="rating">Rating(out of 5)</InputLabel>
              <TextField
                type="number"
                name="rating"
                value={updateFormData.rating}
                onChange={handleInputChange}
                fullWidth
                inputProps={{
                  min: 1,
                  max: 5,
                }}
              />
            </div>
            <div>
              <InputLabel htmlFor="rating">Comments</InputLabel>
              <TextField
                type="text"
                name="comments"
                value={updateFormData.comments}
                onChange={handleInputChange}
                fullWidth
                inputProps={{
                  min: 1,
                  max: 5,
                }}
              />
            </div>
            <div>
              <InputLabel htmlFor="priority">Priority</InputLabel>
              <Select
                id="priority"
                name="priority"
                value={updateFormData.priority}
                onChange={handleInputChange}
                fullWidth
              >
                <MenuItem value="High">
                  <FlagIcon style={{ color: red[500] }} /> High
                </MenuItem>
                <MenuItem value="Medium">
                  <FlagIcon style={{ color: yellow[500] }} /> Medium
                </MenuItem>
                <MenuItem value="Low">
                  <FlagIcon style={{ color: blue[500] }} /> Low
                </MenuItem>
              </Select>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleUpdateTask} color="primary">
              Update
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
}

export default NewProfileAdminView;

import React, { useState, useEffect } from "react";
import "./Overview.css";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Collapse,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";

import {
  useMediaQuery,
  IconButton,
  Drawer,
  AppBar,
  Toolbar,
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";

import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import {
  GET_ALL_TASKS_URL,
  GET_ALL_TEAMS_URL,
  GET_ALL_USERS_URL,
  ACTIVATE_ACCESS_REQ
} from "../../api/user";
import LeftContainer from "../UI/LeftContainer";

const BASE_URL = process.env.REACT_APP_BASE_URL;
function PastTable() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const isMobile = useMediaQuery("(max-width: 768px)");

  const navigation = useNavigate();
  const [data, setData] = useState([]);

  const [userList, setUserList] = useState([]);
  const [teamList, setTeamList] = useState([]);
  const [currentSize, setCurrentSize] = useState(0); // New state for storing the size
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [isPastTableOpen, setIsPastTableOpen] = useState(false);
  const [status, setStatus] = useState("");
  const [deactivatedUsers, setDeactivatedUsers] = useState([]);
  const [isActivateDialogOpen, setIsActivateDialogOpen] = useState(false);
  const [selectedDeactivatedUser, setSelectedDeactivatedUser] = useState("");

  const togglePastTable = () => {
    setIsPastTableOpen(!isPastTableOpen);
  };

  useEffect(() => {
    fetchData();
    fetchUser();
    fetchTeams();
    // Calculate the size of the array where isCurrent is true
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(GET_ALL_TASKS_URL, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (response.status === 200) {
        const modifiedData = {};
  
        response.data.forEach((task) => {
          if (task.assignedTo && task.team) {  // Check if assignedTo and team are not null
            const name = task.assignedTo.name;
            const assignedTo_id = task.assignedTo._id;
            const team = task.team.name;
            const team_id = task.team._id;
            const isCurrent = task.isCurrent;
            const key = `${name}-${team}`;
  
            if (!modifiedData[key]) {
              modifiedData[key] = {
                assignedTo_id: assignedTo_id,
                team_id: team_id,
                assignedTo: name,
                team: team,
                completedTasks: 0,
                currentAssignedTasks: 0,
                currentAssignedTaskList: [],
                averageRating: 0,
                isCurrent: isCurrent,
              };
            }
  
            if (
              task.status === "Assigned" ||
              task.status === "Pending" ||
              task.status === "Completed"
            ) {
              modifiedData[key].currentAssignedTasks++;
  
              const currentAssignedTask = {
                assignedDate: task.assignedDate,
                taskDetails: task.taskDetails,
                taskId: task._id,
                rating: task.rating || 0,
              };
  
              modifiedData[key].currentAssignedTaskList.push(currentAssignedTask);
            } else if (task.status === "Completed and Validated") {
              modifiedData[key].completedTasks++;
  
              const completedRating = task.rating || 0;
              modifiedData[key].averageRating += completedRating;
            }
          } else {
            console.warn('Task with null assignedTo or team encountered:', task);
          }
        });
  
        Object.values(modifiedData).forEach((entry) => {
          const totalRating =
            entry.currentAssignedTaskList.reduce(
              (sum, task) => sum + task.rating,
              0
            ) + entry.averageRating;
  
          entry.averageRating = (
            totalRating /
            (entry.currentAssignedTaskList.length + entry.completedTasks)
          ).toFixed(1);
        });
  
        const result = Object.values(modifiedData);
  
        const sortedData = [...result].sort((a, b) => {
          if (a.isCurrent && b.isCurrent) return 0;
          if (a.isCurrent) return -1;
          return 1;
        });
        const newSize = sortedData.filter((row) => row.isCurrent).length;
        // Update the state with the new size
        setCurrentSize(newSize);
        // Sort the data to show "Current" status at the top and "Not Current" status at the end
  
        setData(sortedData);
      }
    } catch (error) {
      console.error("Error fetching tasks", error);
    }
  };
  

  const handleStatusChange = (rowIndex, isCurrent) => {
    // Open the dialog and store the selected row index
    setIsDialogOpen(true);
    setStatus(isCurrent ? "Resume" : "Pause");
    setSelectedRowIndex({ rowIndex, isCurrent });
  };
  const handleDialogStatusChange = async () => {
    try {
      // Update the status in the data
      setData((prevData) => {
        const updatedData = [...prevData];
        updatedData[selectedRowIndex.rowIndex] = {
          ...updatedData[selectedRowIndex.rowIndex],
          isCurrent: selectedRowIndex.isCurrent,
        };

        const newSize = updatedData.filter((row) => row.isCurrent).length;
        // Update the state with the new size
        setCurrentSize(newSize);

        return updatedData;
      });

      // Call the backend API to update the status in the database
      const { team_id, assignedTo_id } = data[selectedRowIndex.rowIndex];
      const res = await axios.put(
        `${BASE_URL}/api/taskRequest/tasks/${team_id}/${assignedTo_id}`,
        {
          isCurrent: selectedRowIndex.isCurrent,
        }
      );

      setIsDialogOpen(false); // Close the dialog
      navigation("/past");
    } catch (error) {
      console.error("Error updating task status:", error);
      // Handle error, show a message, or roll back the update on error
    }
  };
  const handlePastStatusChange = (rowIndex) => {
    // Open the dialog and store the selected row index
    setIsDialogOpen(true);
    setSelectedRowIndex(rowIndex);
  };

  const MarkStatusDialog = ({ open, onClose, onPause, onExit, button }) => {
    const handlePauseClick = async () => {
      // Call the onPause function passed from the parent component
      await onPause("pause");

      // Close the dialog
      onClose();
    };

    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Mark Status</DialogTitle>
        <DialogActions>
          <Button onClick={handlePauseClick} color="primary">
            {button}
          </Button>
          <Button onClick={onExit} color="primary">
            Exit
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const fetchUser = async () => {
    try {
      const response = await axios.get(GET_ALL_USERS_URL,{
        headers:{
          'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
      });
      if (response.status === 200) {
        const filteredUsers = response.data.filter(
          (user) => user.isAdmin === false && user.isApproved === true
        );
        setUserList(filteredUsers);

        const deactivated = response.data.filter(
          (user) => user.isDeactivated === true && user.isApproved === true
        );
        setDeactivatedUsers(deactivated);
      }
    } catch (error) {
      console.error("Error fetching tasks", error);
    }
  };

  const fetchTeams = async () => {
    try {
      const response = await axios.get(GET_ALL_TEAMS_URL,{
        headers:{
          'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
      });
      if (response.status === 200) {
        setTeamList(response.data);
      }
    } catch (error) {
      console.error("Error fetching tasks", error);
    }
  };
  const handleActivateUser = (user) => {
    setSelectedDeactivatedUser(user._id);
    setIsActivateDialogOpen(true);
  };
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("isAdmin");
    window.location.href = "/login";
  };
  const handleConfirmActivateUser = async (id) => {
   
    try {
      const storedToken = localStorage.getItem("token");
      const response = await axios.get(ACTIVATE_ACCESS_REQ(id), {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
  
      if (response.status === 200) {
        // console.log('Deactivation successful:', response.data);
        setIsActivateDialogOpen(false);
        // setSelectedDeactivatedUser(null);
        setSnackbarMessage("User reactivated successfully.");
        setIsSnackbarOpen(true);
        // Optionally refresh data or update state here

        // Refresh the page
      window.location.reload();
      } else {
        console.error('Unexpected response status:', response.status);
      }
    } catch (error) {
      console.error("Error activating user", error);
      setSnackbarMessage("Failed to reactivate user.");
      setIsSnackbarOpen(true);
      // Optionally show an error message to the user here
    }
  };
  return (
    <>
      <div className="container">
        {isMobile ? (
          <>
            <AppBar
              position="fixed"
              style={{ background: "transparent", boxShadow: "none" }}
            >
              <Toolbar>
                <IconButton
                  color="primary"
                  aria-label="open drawer"
                  onClick={handleDrawerToggle}
                  edge="start"
                >
                  <MenuIcon />
                </IconButton>
                {/* <LeftContainer handleLogout={handleLogout} /> */}
              </Toolbar>
            </AppBar>
            <Drawer
              variant="temporary"
              open={isDrawerOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true,
              }}
            >
              {/* ... Other code ... */}
              <LeftContainer handleLogout={handleLogout} />
              {/* ... Other code ... */}
            </Drawer>
          </>
        ) : (
          <>
            <LeftContainer handleLogout={handleLogout} />
            {/* ... Other code ... */}
          </>
        )}
        <div className="right-container">
          <div className="right-container-top">
            <p>Welcome, Admin!</p>
          </div>

          <div
            style={{ maxHeight: "1000px", overflowY: "auto" }}
            className="right-container-table"
          >
            <h1>Past</h1>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Team</TableCell>
                    <TableCell>Total Tasks Completed</TableCell>
                    <TableCell>Current Task Assigned</TableCell>
                    <TableCell>Rating</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data
                    .filter((row) => !row.isCurrent)
                    .map((row, index) => (
                      <TableRow key={row.assignedTo}>
                        <TableCell>{row.assignedTo}</TableCell>
                        <TableCell>{row.team}</TableCell>
                        <TableCell>{row.completedTasks}</TableCell>
                        <TableCell>{row.currentAssignedTasks}</TableCell>
                        <TableCell>{row.averageRating}</TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            color="primary"
                            className="createTask"
                            onClick={() =>
                              handleStatusChange(index + currentSize, true)
                            }
                          >
                            mark status
                          </Button>
                        </TableCell>
                        <MarkStatusDialog
                          open={isDialogOpen}
                          onClose={() => setIsDialogOpen(false)}
                          onPause={handleDialogStatusChange}
                          button={status}
                          onExit={() => setIsDialogOpen(false)}
                        />
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div>
      <h1>Deactivated Users</h1>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Reactivate</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {deactivatedUsers.map((user) => (
              <TableRow key={user._id}>
                <TableCell>{user.name}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleActivateUser(user)}
                  >
                    Reactivate
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
  open={isActivateDialogOpen}
  onClose={() => setIsActivateDialogOpen(false)}
>
  <DialogTitle>Reactivate User</DialogTitle>
  <DialogActions>
    <Button
      onClick={() => handleConfirmActivateUser(selectedDeactivatedUser)}
      color="primary"
    >
      Confirm
    </Button>
    <Button
      onClick={() => setIsActivateDialogOpen(false)}
      color="secondary"
    >
      Cancel
    </Button>
  </DialogActions>
</Dialog>

      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={3000}
        onClose={() => setIsSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setIsSnackbarOpen(false)}
          severity={snackbarMessage.includes("success") ? "success" : "error"}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PastTable;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import TargetComponent from "./TargetComponent"
import "../DashboardPage.css";
import {
  useMediaQuery,
  IconButton,
  Drawer,
  AppBar,
  Toolbar,
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import LeftContainer from "../UI/LeftContainer";
const Target = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const isMobile = useMediaQuery("(max-width: 768px)");
  // Function to handle logout
  const handleLogout = () => {
    // Remove the token from local storage
    localStorage.removeItem("token");
    localStorage.removeItem("isAdmin");

    // Redirect the user to the login page or any other desired page
    window.location.href = "/login";
  };
  return (
    <div className="container">
    {isMobile ? (
         <>
           <AppBar
             position="fixed"
             style={{ background: "transparent", boxShadow: "none" }}
           >
             <Toolbar>
               <IconButton
                 color="primary"
                 aria-label="open drawer"
                 onClick={handleDrawerToggle}
                 edge="start"
               >
                 <MenuIcon />
               </IconButton>
               {/* <LeftContainer handleLogout={handleLogout} /> */}
             </Toolbar>
           </AppBar>
           <Drawer
             variant="temporary"
             open={isDrawerOpen}
             onClose={handleDrawerToggle}
             ModalProps={{
               keepMounted: true,
             }}
           >
             {/* ... Other code ... */}
             <LeftContainer handleLogout={handleLogout} />
             {/* ... Other code ... */}
           </Drawer>
         </>
       ) : (
         <>
           <LeftContainer handleLogout={handleLogout} />
           {/* ... Other code ... */}
         </>
       )}

     <div className="right-container">
       <div className="right-container-top" style={{marginBottom:"0px"}}>
         <p>Welcome, Admin!</p>
       </div>
       <div className="right-container-table" style={{marginTop:"0px"}}>
         <TargetComponent/>
       </div>
     </div>
   </div>
  )
}

export default Target

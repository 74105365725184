import React from "react";
import { TypeAnimation } from "react-type-animation";
function TypeText() {
  return (
    <div>
     <TypeAnimation
  sequence={[
    "The one place to get end-to-end team management with",
    500,
    "The one place to get end-to-end team management with onboarding",
    500,
    "The one place to get end-to-end team management with onboarding till",
    500,
    "The one place to get end-to-end team management with onboarding till offboarding",
    500,
    "The one place to get end-to-end team management with onboarding till offboarding in",
    500,
    "The one place to get end-to-end team management with onboarding till offboarding in seamless",
    500,
    "The one place to get end-to-end team management with onboarding till offboarding in seamless format",
    500,
    "The one place to get end-to-end team management with onboarding till offboarding in seamless format and",
    500,
    "The one place to get end-to-end team management with onboarding till offboarding in seamless format and team",
    500,
    "The one place to get end-to-end team management with onboarding till offboarding in seamless format and team personal",
    500,
    "The one place to get end-to-end team management with onboarding till offboarding in seamless format and team personal dashboards",
    500,
    "The one place to get end-to-end team management with onboarding till offboarding in seamless format and team personal dashboards to",
    500,
    "The one place to get end-to-end team management with onboarding till offboarding in seamless format and team personal dashboards to track",
    500,
    "The one place to get end-to-end team management with onboarding till offboarding in seamless format and team personal dashboards to track and",
    500,
    "The one place to get end-to-end team management with onboarding till offboarding in seamless format and team personal dashboards to track and progress",
    500,
    "The one place to get end-to-end team management with onboarding till offboarding in seamless format and team personal dashboards to track and progress in",
    500,
    "The one place to get end-to-end team management with onboarding till offboarding in seamless format and team personal dashboards to track and progress in their",
    500,
    "The one place to get end-to-end team management with onboarding till offboarding in seamless format and team personal dashboards to track and progress in their personal",
    500,
    "The one place to get end-to-end team management with onboarding till offboarding in seamless format and team personal dashboards to track and progress in their personal journeys",
    500,
    "The one place to get end-to-end team management with onboarding till offboarding in seamless format and team personal dashboards to track and progress in their personal journeys to",
    500,
    "The one place to get end-to-end team management with onboarding till offboarding in seamless format and team personal dashboards to track and progress in their personal journeys to growth",
    500,
    "The one place to get end-to-end team management with onboarding till offboarding in seamless format and team personal dashboards to track and progress in their personal journeys to growth.",
    500,
  ]}
  wrapper="span"
  speed={70}
  style={{ fontSize: "1.5em", display: "inline-block" }}
  repeat={Infinity}
/>

    </div>
  );
}

export default TypeText;

import React, { useState, useEffect } from "react";
import { useMediaQuery, IconButton, Drawer, AppBar, Toolbar, Hidden, Snackbar, Alert } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import axios from "axios";
import "../DashboardPage.css";
import LeftContainer from "../UI/LeftContainer";
import { FETCH_ALL_ON_BOARDING_DATA_URL } from "../../api/onBoarding";

const fetchOnboardingData = async (setData, setError) => {
  try {
    // console.log("Fetching data from:", FETCH_ALL_ON_BOARDING_DATA_URL);
    const response = await axios.get(FETCH_ALL_ON_BOARDING_DATA_URL);

    if (response.status === 200) {
      const formattedData = response.data.viewProgress.map((item) => {
        // Ensure the data item is valid and has necessary fields
        if (item && item.user && item.steps && item._id) {
          return {
            ...item.user,
            onBoardingSteps: item.steps,
            status: {
              isCompleted: item.steps.filter((step) => step.bool).length === item.steps.length,
              completedSteps: item.steps.filter((step) => step.bool).length,
              totalSteps: item.steps.length,
            },
            id: item._id,
          };
        }
        return null; // Return null if the item is not valid
      }).filter(item => item !== null); // Filter out any null items

      // console.log("Formatted data:", formattedData);
      setData(formattedData);
    } else {
      console.error("Unexpected response status:", response.status);
      setError("Failed to fetch onboarding data.");
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    setError("Failed to fetch onboarding data.");
  }
};

function ViewProgress() {
  const [data, setData] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [error, setError] = useState(null);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);

  useEffect(() => {
    fetchOnboardingData(setData, setError);
  }, []);

  useEffect(() => {
    if (error) {
      setIsSnackbarOpen(true);
    }
  }, [error]);

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("isAdmin");
    window.location.href = "/login";
  };

  const isMobile = useMediaQuery("(max-width: 768px)");

  const columns = [
    { field: "name", headerName: "Name", width: 100 },
    { field: "email", headerName: "Email", width: 180 },
    { field: "address", headerName: "Address", width: 250 },
    { field: "college", headerName: "College", width: 100 },
    { field: "contactNumber", headerName: "Contact Number", width: 180 },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      renderCell: (params) => {
        const { isCompleted, completedSteps, totalSteps } = params.value;
        return (
          <div>
            <Hidden mdUp>
              {isCompleted ? (
                <CheckIcon style={{ color: "green" }} />
              ) : (
                <CloseIcon style={{ color: "red" }} />
              )}
              {completedSteps} / {totalSteps} steps
            </Hidden>
            <Hidden smDown>
              {isCompleted ? (
                <>
                  <CheckIcon style={{ color: "green" }} />
                  Completed {completedSteps} / {totalSteps} steps
                </>
              ) : (
                <>
                  <CloseIcon style={{ color: "red" }} />
                  Incomplete {completedSteps} / {totalSteps} steps
                </>
              )}
            </Hidden>
          </div>
        );
      },
    },
  ];

  return (
    <div className="container">
      {isMobile ? (
        <>
          <AppBar position="fixed" style={{ background: "transparent", boxShadow: "none" }}>
            <Toolbar>
              <IconButton color="primary" aria-label="open drawer" onClick={handleDrawerToggle} edge="start">
                <MenuIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="temporary"
            open={isDrawerOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <LeftContainer handleLogout={handleLogout} />
          </Drawer>
        </>
      ) : (
        <LeftContainer handleLogout={handleLogout} />
      )}

      <div className="right-container">
        <div className="right-container-top">
          <p>Welcome, Admin!</p>
          <h1>View Progress</h1>
        </div>
        <div className="right-container-table" style={{ marginTop: "30px" }}>
          <div style={{ height: 500, width: "100%" }}>
            <DataGrid
              rows={data}
              columns={columns}
              pageSize={10}
              checkboxSelection
              disableSelectionOnClick
            />
          </div>
        </div>
      </div>

      <Snackbar open={isSnackbarOpen} autoHideDuration={6000} onClose={() => setIsSnackbarOpen(false)}>
        <Alert onClose={() => setIsSnackbarOpen(false)} severity="error">
          {error}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default ViewProgress;

// AdminDashboard.js

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  IconButton,
  Drawer,
  AppBar,
  Toolbar,
  Button,
  useMediaQuery,
  FormControlLabel,
  Switch,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AdminTableComponent from "./AdminTableComponent";
import LeftContainer from "../UI/LeftContainer";

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [switchState, setSwitchState] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const handleChange = () => {
    setSwitchState((prev) => !prev);
    navigate(!switchState ? "/oldAdminDashboard" : "/AdminDashboard");
  };
  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  // Function to handle logout
  const handleLogout = () => {
    // Remove the token from local storage
    localStorage.removeItem("token");
    localStorage.removeItem("isAdmin");
    // Redirect the user to the login page or any other desired page
    window.location.href = "/login";
  };

  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <div className="container">
      {isMobile ? (
        <>
          <AppBar
            position="fixed"
            style={{ background: "transparent", boxShadow: "none" }}
          >
            <Toolbar>
              <IconButton
                color="primary"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
              {/* <LeftContainer handleLogout={handleLogout} /> */}
            </Toolbar>
          </AppBar>
          <Drawer
            variant="temporary"
            open={isDrawerOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {/* ... Other code ... */}
            <LeftContainer handleLogout={handleLogout} />
            {/* ... Other code ... */}
          </Drawer>
        </>
      ) : (
        <>
          <LeftContainer handleLogout={handleLogout} />
          {/* ... Other code ... */}
        </>
      )}
      <div className="right-container">
      <p>Welcome, Admin!</p>
        <div
          className="right-container-top"
          style={{
            display: "flex",
            flexDirection: "row",
           gap:"10px",
           marginLeft:"40%"
          }}
        >
        <FormControlLabel
            control={<Switch checked={switchState} onChange={handleChange} />}
            label={
              switchState
                ? "Navigate to New Admin Dashboard":"Navigate to Old Admin Dashboard"
                
            }
            labelPlacement="start"
           
          />
          <Button
            variant="contained"
            color="primary"
            className="createTask"
            onClick={() => navigate("/completed")}
          >
            Completed Task
          </Button>
          
          <Button
            variant="contained"
            color="primary"
            className="createTask"
            onClick={() => navigate("/graphicalView")}
          >
            Graphical Analysis
          </Button>
        </div>
        <div className="right-container-table" style={{ marginTop: "-50px" }}>
          <AdminTableComponent />
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;

import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
  Snackbar,
  useMediaQuery,
  IconButton,
  Drawer,
  AppBar,
  Toolbar,
  Alert,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { UserLeftContainer } from "../UI/index";
import {
  ADD_MEMBER_TO_TEAM_URL,
  FETCH_TEAM_MEMBERS_URL,
  MAKE_MANAGER_URL,
  FETCH_USER_TASKS_URL,
} from "../../api/user";

const NewTeamDetails = () => {
  const navigation = useNavigate();
  const [admin, setAdmin] = useState("false");
  const [userName, setUserName] = useState("");
  const { teamName, teamId } = useParams();
  const [teamMembers, setTeamMembers] = useState([]);
  const [userTasks, setUserTasks] = useState([]);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const token = localStorage.getItem("token");
  const isAdmin = () => {
    return admin === "true";
  };

  useEffect(() => {
    fetchData();
  }, [teamName]);

  useEffect(() => {
    fetchUserTasks();
  }, [teamMembers]);

  const fetchData = async () => {
    try {
      const fetchTeamMembersUrl = FETCH_TEAM_MEMBERS_URL(teamName);
      const response = await axios.get(fetchTeamMembersUrl, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setTeamMembers(response.data);
    } catch (error) {
      console.error("Error fetching team members", error);
    }
  };
  const fetchUserTasks = async () => {
    try {
      const promises = teamMembers.map(async (member) => {
        try {
          const fetchUserTasksUrl = FETCH_USER_TASKS_URL(member._id);
          const response = await axios.get(fetchUserTasksUrl, {
            headers: { Authorization: `Bearer ${token}` },
          });

          const tasks = response.data.filter(
            (task) => task.team.name === teamName
          ).map(task => ({
            ...task,
            team: { name: teamName, _id: teamId },
          }));

          return { memberId: member._id, tasks };
        } catch (error) {
          console.error(`Error fetching tasks for ${member.name}:`, error);
          return { memberId: member._id, tasks: [] };
        }
      });

      const userTasksData = await Promise.all(promises);
      setUserTasks(userTasksData);
    } catch (error) {
      console.error("Error fetching user tasks", error);
    }
  };
  const handleMakeManagerClick = (member) => {
    // Functionality to make a member a manager can be added here
    console.log("Make Manager Clicked:", member);
  };
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const handleLogout = () => {
    // Remove the token from local storage
    localStorage.removeItem("token");
    localStorage.removeItem("isAdmin");
    localStorage.removeItem("verified");
    // localStorage.removeItem("id");
    // Redirect the user to the login page or any other desired page
    window.location.href = "/login";
  };
  const calculateTaskCounts = (userId) => {
    const userTasksData = userTasks.find(
      (userTask) => userTask.memberId === userId
    );
    if (userTasksData) {
      const tasks = userTasksData.tasks;
      const totalAssigned = tasks.length;
      const pending = tasks.filter((task) => task.status === "Assigned").length;
      const completed = tasks.filter(
        (task) => task.status === "Completed and Validated"
      ).length;
      return { totalAssigned, pending, completed };
    }
    return { totalAssigned: 0, pending: 0, completed: 0 };
  };
  const handleCreateTask = () => {
    navigation(`/taskAssign/${teamName}/${teamId}`);
  };
  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <div className="container">
      {isMobile ? (
        <>
          <AppBar
            position="fixed"
            style={{ background: "transparent", boxShadow: "none" }}
          >
            <Toolbar>
              <IconButton
                color="primary"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
              <p>Welcome, Admin!</p>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="temporary"
            open={isDrawerOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <UserLeftContainer />
          </Drawer>
        </>
      ) : (
        <>
          <UserLeftContainer
            userName={userName}
            isAdmin={isAdmin()}
            handleLogout={handleLogout}
          />
        </>
      )}
      <div className="right-container">
        <Typography variant="h4" gutterBottom>
          Team: {teamName}
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "45px",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateTask}
          >
            Task Panel
          </Button>
        </div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Total Assigned</TableCell>
                <TableCell>Pending</TableCell>
                <TableCell>Completed</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {teamMembers.map((member) => {
                const { totalAssigned, pending, completed } =
                  calculateTaskCounts(member._id);

                const managerTeam = member.managedTeams.includes(teamId);
                return (
                  <TableRow key={member._id}>
                    <TableCell>{member.name}</TableCell>
                    <TableCell>{totalAssigned}</TableCell>
                    <TableCell>{pending}</TableCell>
                    <TableCell>{completed}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <br />
        <br />
        <Snackbar
          open={isSnackbarOpen}
          autoHideDuration={3000}
          onClose={() => setIsSnackbarOpen(false)}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert severity="success" onClose={() => setIsSnackbarOpen(false)}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
};

export default NewTeamDetails;



import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import "../DashboardPage.css";
import AdminTableComponent from "./AdminTableComponent";
import {
  useMediaQuery,
  IconButton,
  Drawer,
  AppBar,
  Toolbar,
  Badge,
  FormControlLabel,
  Switch,
  Button,
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import { useNotificationContext } from "../../utils/NotificationContext";
import CompletedTask from "./CompletedTask";
import LeftContainer from "../UI/LeftContainer";
import OLDAdminTableComponent from "./OLDAdminDashboardTable";

function OLDAdminDashboard() {
    const navigate = useNavigate();
    const [switchState, setSwitchState] = useState(true);
  const { state, dispatch } = useNotificationContext(); // Use the context hook
  // Function to handle logout
  const handleLogout = () => {
    // Remove the token from local storage
    localStorage.removeItem("token");
    localStorage.removeItem("isAdmin");
    // Redirect the user to the login page or any other desired page
    window.location.href = "/login";
  };
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const handleChange = () => {
    setSwitchState((prev) => !prev);
    navigate(!switchState ? "/oldAdminDashboard" : "/AdminDashboard");
  };
  const isMobile = useMediaQuery("(max-width: 768px)");
  return (
    <div className="container">
      {isMobile ? (
        <>
          <AppBar
            position="fixed"
            style={{ background: "transparent", boxShadow: "none" }}
          >
            <Toolbar>
              <IconButton
                color="primary"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
              {/* <LeftContainer handleLogout={handleLogout} /> */}
            </Toolbar>
          </AppBar>
          <Drawer
            variant="temporary"
            open={isDrawerOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {/* ... Other code ... */}
            <LeftContainer handleLogout={handleLogout} />
            {/* ... Other code ... */}
          </Drawer>
        </>
      ) : (
        <>
          <LeftContainer handleLogout={handleLogout} />
          {/* ... Other code ... */}
        </>
      )}

      <div className="right-container">
        <div className="right-container-top">
        <p>Welcome, Admin!</p>
        <div
          className="right-container-top"
          style={{
            display: "flex",
            flexDirection: "row",
           gap:"10px",
           marginLeft:"40%"
          }}
        >
        <FormControlLabel
            control={<Switch checked={switchState} onChange={handleChange} />}
            label={
              switchState
                ?"Navigate to New Admin Dashboard": "Navigate to Old Admin Dashboard"
                
            }
            labelPlacement="start"
           
          />
          <Button
            variant="contained"
            color="primary"
            className="createTask"
            onClick={() => navigate("/completed")}
          >
            Completed Task
          </Button>
          
          <Button
            variant="contained"
            color="primary"
            className="createTask"
            onClick={() => navigate("/graphicalView")}
          >
            Graphical Analysis
          </Button>
        </div>
        </div>
        <div className="right-container-table" style={{ marginTop: "-50px" }}>
          <OLDAdminTableComponent />
        </div>
      </div>
    </div>
  );
}

export default OLDAdminDashboard;

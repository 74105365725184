import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../DashboardPage.css";
import ProfileComponent from "./ProfileComponent";
import axios from "axios";
import { GET_ALL_USERS_URL } from "../../api/user";
import {
  useMediaQuery,
  IconButton,
  Drawer,
  AppBar,
  Toolbar,
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import UserLeftContainer from "../UI/UserLeftContainer";

const Profile = () => {
  const [admin, setAdmin] = useState("false");
  const [manager, setManager] = useState("");
  const [manageTeams, setManageTeams] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [token, setToken] = useState("");
  const [onBoardingStatus, setOnBoardingStatus] = useState(false);

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const verified = await localStorage.getItem("verified");
      setOnBoardingStatus(verified);
      const storedToken = await localStorage.getItem("token");
      const storedAdmin = await localStorage.getItem("isAdmin");
      setToken(storedToken);
      setAdmin(storedAdmin);
      const tokenPayload = storedToken
        ? JSON.parse(atob(storedToken.split(".")[1]))
        : {};
      const currentUserId = tokenPayload.userId;

      // Fetch the user data using the currentUserId
      const response = await axios.get(GET_ALL_USERS_URL);
      if (response.status === 200) {
        const allUsers = response.data;
        const currentUser = allUsers.find((user) => user._id === currentUserId);
        setCurrentUser(currentUser); // Set the current user object
        setManager(currentUser.isManager);
        setManageTeams(currentUser.managedTeams);
      }
    } catch (error) {
      console.error("Error fetching user data: ", error);
    }
  };
  // Function to handle logout
  const handleLogout = () => {
    // Remove the token from local storage
    localStorage.removeItem("token");
    localStorage.removeItem("isAdmin");
    localStorage.removeItem("verified");
    // localStorage.removeItem("id");
    // Redirect the user to the login page or any other desired page
    window.location.href = "/login";
  };

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <div className="container">
      {isMobile ? (
        <>
          <AppBar
            position="fixed"
            style={{ background: "transparent", boxShadow: "none" }}
          >
            <Toolbar>
              <IconButton
                color="primary"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
              <p>Welcome, Admin!</p>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="temporary"
            open={isDrawerOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <UserLeftContainer />
          </Drawer>
        </>
      ) : (
        <>
          <UserLeftContainer />
        </>
      )}
      <div className="right-container" style={{
         backgroundColor:"#DAEDE3",
         padding: "20px",
         display: "flex",
         flexDirection: "column",
         justifyContent:"inherit"
        
      }}>
        <div className="right-container-top"></div>
        <div className="right-container-table">
          <ProfileComponent />
        </div>
      </div>
    </div>
  );
};

export default Profile;

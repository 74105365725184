import { BASE_URL } from "./client";
export const SUBMIT_LEAVE = `${BASE_URL}/api/taskRequest/createLeave`;
export const FETCH_LEAVE_BY_ID = (id, isAdmin) =>
  isAdmin === "true"
    ? `${BASE_URL}/api/taskRequest/fetchLeaveById/${id}`
    : `${BASE_URL}/api/taskRequest/fetchLeaveById`;

export const UPDATE_DECISION = `${BASE_URL}/api/taskRequest/updateDecision`;
export const FETCH_LEAVE = `${BASE_URL}/api/taskRequest/fetchLeave`;
export const DELETE_LEAVE_ID = (id) => `${BASE_URL}/api/taskRequest/${id}`;

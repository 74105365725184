import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../DashboardPage.css";
import AdminProfileComponent from "./AdminProfileComponent";
import { GET_ALL_USERS_URL } from "../../api/user";
import {
  useMediaQuery,
  IconButton,
  Drawer,
  AppBar,
  Toolbar,
  Box,
  Typography,
  Link
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import LeftContainer from "../UI/LeftContainer";

const UserProfile = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const token = await localStorage.getItem("token");
    try {
      const response = await axios.get(GET_ALL_USERS_URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        const filteredUsers = response.data.filter(
          (user) =>
            user.isAdmin === false &&
            user.isApproved === true &&
            user.isDeactivated === false
        );

        setUsers(filteredUsers);
      }
    } catch (error) {
      console.error("Error fetching team members", error);
    }
  };

  const isMobile = useMediaQuery("(max-width: 768px)");

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("clickedUserId");
    localStorage.removeItem("id");
    localStorage.removeItem("isAdmin");
    window.location.href = "/login";
  };

  const handleRowClick = (user) => {
    const clickedUserName = user.name;
    localStorage.setItem("Name", clickedUserName);
    console.log('Clicked user name:', clickedUserName);

    const matchedUser = users.find(
      (u) => u.name.toLowerCase() === clickedUserName.toLowerCase()
    );

    // console.log('Matched user:', matchedUser);

    if (matchedUser) {
      const userId = matchedUser._id;
      localStorage.setItem("clickedUserId", userId);
      // console.log('Navigating to /dashboard with user ID:', userId);
      navigate("/dashboard");
    } else {
      console.log('User not found');
    }
  };

  return (
    <div className="container">
      {isMobile ? (
        <>
          <AppBar
            position="fixed"
            style={{ background: "transparent", boxShadow: "none" }}
          >
            <Toolbar>
              <IconButton
                color="primary"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="temporary"
            open={isDrawerOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <LeftContainer handleLogout={handleLogout} />
          </Drawer>
        </>
      ) : (
        <LeftContainer handleLogout={handleLogout} />
      )}

      <div className="right-container">
        <div className="right-container-top">
          <p>Welcome, Admin!</p>
        </div>
        <div className="right-container-table">
          {users.map((user, index) => (
            <Link
              key={index}
              onClick={() => handleRowClick(user)}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Box className="profile-box">
                <Typography variant="subtitle1" className="profile-name">
                  {user.name}
                </Typography>
              </Box>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UserProfile;

import React, { useEffect, useState } from "react";
import "./certificate.css";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import axios from "axios";
import { useParams } from "react-router-dom";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faDownload, faShare } from "@fortawesome/free-solid-svg-icons";
import { GET_CERT_DATA_URL, generateCertificateUrl } from "../../api/document";
import { certificateUrl } from "../../utils/constants";

const BASE_URL = process.env.REACT_APP_BASE_URL;

function Certificate() {
  const { userId } = useParams();
  const [data, setData] = useState("");
  const [url, setUrl] = useState("https://www.example.com");
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    getCertData();
  }, []);

  const copyURL = () => {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        setCopied(true);
      })
      .catch((error) => {
        console.error("Copy failed: ", error);
      });
  };

  const getCertData = async () => {
    try {
      const getCertDataUrl = GET_CERT_DATA_URL(userId);

      const res = await axios.get(getCertDataUrl);

      const certificateUrl = generateCertificateUrl(
        res.data.userCertificate.userid
      );
      setUrl(certificateUrl);
      setData(res.data.userCertificate);
    } catch (error) {
      console.error(error);
    }
  };
  const shareOnEmail = () => {
    const emailSubject = "Check out my certificate";
    const emailBody = `I've earned a certificate! Check it out: ${url}`;
    const emailLink = `mailto:?subject=${emailSubject}&body=${emailBody}`;
    window.open(emailLink);
  };
  const formatDate = (inputDate) => {
    const dateOptions = { month: "long", day: "numeric", year: "numeric" };
    const date = new Date(inputDate);
    const formattedDate = date.toLocaleDateString("en-US", dateOptions);

    const day = date.getDate();
    const daySuffix =
      day === 1 || day === 21 || day === 31
        ? "st"
        : day === 2 || day === 22
        ? "nd"
        : day === 3 || day === 23
        ? "rd"
        : "th";

    return (
      <span className="stl_18 stl_11 stl_17" style={{ wordSpacing: "-0em" }}>
        {formattedDate.replace(/\b\d+\b/, (match) => match + daySuffix)}
      </span>
    );
  };
  const shareOnLinkedIn = () => {
    const linkedInShareLink = `https://www.linkedin.com/sharing/share-offsite/?url=${url}`;
    window.open(linkedInShareLink);
  };

  const shareOnWhatsApp = () => {
    const whatsAppShareLink = `https://api.whatsapp.com/send?text=${url}`;
    window.open(whatsAppShareLink);
  };

  const downloadpdf = async () => {
    const capture = document.querySelector(".stl_02");

    html2canvas(capture).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      // Specify custom dimensions for landscape orientation
      const pdfWidth = 1806; // Width of an A4 page in landscape mode
      const pdfHeight = 1264; // Height of an A4 page in landscape mode

      const doc = new jsPDF({
        orientation: "l", // "l" for landscape
        unit: "mm",
        format: [pdfWidth, pdfHeight],
      });

      doc.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      doc.save("certificate.pdf");
    });
  };

  return (
    <div className="certificate-container">
      <div className="stl_ stl_02">
        <div className="stl_03">
          <img src={certificateUrl} alt="" className="stl_04" />
        </div>
        <div className="stl_view">
          <div className="stl_05 stl_06">
            <div
              className="stl_01"
              style={{ left: "22.3131em", top: "12.3644em" }}
            >
              <span
                className="stl_07 stl_08 stl_09"
                style={{ wordSpacing: "0em" }}
              >
                Certificate of Completion &nbsp;
              </span>
            </div>
            <div
              className="stl_01"
              style={{ left: "28.4931em", top: "16.1741em" }}
            >
              <span
                className="stl_10 stl_11 stl_12"
                style={{ wordSpacing: "0.0002em" }}
              >
                THIS IS AWARDED TO &nbsp;
              </span>
            </div>
            <div className="stl_01" style={{ left: "30em", top: "19.3363em" }}>
              <span
                className="stl_13 stl_14 stl_15 center-align"
                style={{ wordSpacing: "-0em" }}
              >
                {data.name} &nbsp;
              </span>
            </div>
            <div
              className="stl_01"
              style={{ left: "10.0914em", top: "27.1181em" }}
            >
              <span
                className="stl_16 stl_11 stl_17"
                style={{ wordSpacing: "0.0001em" }}
              >
                For successfully completing his internship with Markoknow from{" "}
              </span>
              <span
                className="stl_18 stl_11 stl_17"
                style={{ wordSpacing: "-0em" }}
              >
                {formatDate(data.startDate)} to
              </span>
            </div>
            <div
              className="stl_01"
              style={{ left: "30.1369em", top: "28.43em" }}
            >
              <span
                className="stl_18 stl_11 stl_17"
                style={{ wordSpacing: "0em" }}
              >
                {formatDate(data.endDate)} &nbsp;
              </span>
              <span
                className="stl_16 stl_11 stl_17"
                style={{ wordSpacing: "0em" }}
              >
                as a &nbsp;
              </span>
            </div>
            <div
              className="stl_01"
              style={{ left: "31.4137em", top: "30.0827em" }}
            >
              <span className="stl_19 stl_11 stl_20">
                {data.position} &nbsp;
              </span>
            </div>
            <div
              className="stl_01 center-align"
              style={{ left: "21em", top: "31.8756em" }}
            >
              <span
                className="stl_21 stl_22 stl_23"
                style={{ wordSpacing: "0em" }}
              >
                Certificate ID {data.certId} &nbsp;
              </span>
            </div>
            <div
              className="stl_01"
              style={{ left: "32.6972em", top: "40.7791em" }}
            >
              <span className="stl_24 stl_11 stl_25">KAUSHIKI &nbsp;</span>
            </div>
            <div
              className="stl_01"
              style={{ left: "29.328em", top: "42.3641em" }}
            >
              <span
                className="stl_26 stl_11 stl_27"
                style={{ wordSpacing: "0.0004em" }}
              >
                Founder &amp; CEO, Markoknow &nbsp;
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="action-buttons">
        {/* Download button */}

        {/* Sharing icons */}

        {/* Copy link with icon */}
        <div className="copy-link">
          <div className="copy-link-header">
            <div className="copy-link-text">Copy Link:</div>
            <button onClick={copyURL}>
              <FontAwesomeIcon icon={faCopy} />
            </button>
          </div>
          <input type="text" value={url} readOnly />
          {copied && (
            <div className="copy-message">URL copied to clipboard!</div>
          )}
        </div>

        <button
          onClick={downloadpdf}
          style={{
            height: "50px",
            width: "240px",
            fontSize: "20px",
          }}
        >
          <FontAwesomeIcon icon={faDownload} /> Download
        </button>
        <div className="sharing-icons">
          <div className="share-with">Share with:</div>
          <button className="share-icon" onClick={shareOnEmail}>
            <EmailIcon color="primary" style={{ height: 100, width: 50 }} />
          </button>
          <button className="share-icon" onClick={shareOnLinkedIn}>
            <LinkedInIcon color="primary" style={{ height: 100, width: 50 }} />
          </button>
          <button className="share-icon" onClick={shareOnWhatsApp}>
            <WhatsAppIcon color="primary" style={{ height: 100, width: 50 }} />
          </button>
        </div>
      </div>
    </div>
  );
}

export default Certificate;

import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  TextField,
  useMediaQuery,
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
} from "@mui/material";
import { motion } from "framer-motion";
import MenuIcon from "@mui/icons-material/Menu";
import UserLeftContainer from "../Components/UI/UserLeftContainer";

const levels = [
  { level: 1, stories: 25 },
  { level: 2, stories: 50 },
  { level: 3, stories: 100 },
  { level: 4, stories: 200 },
  { level: 5, stories: 300 },
  { level: 6, stories: 400 },
];

const StoriesLevelsPage = () => {
  const [currentStories, setCurrentStories] = useState(0);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [storiesCount, setStoriesCount] = useState(0);

  useEffect(() => {
    const count = localStorage.getItem("storiesCount");
    if (count) {
      setCurrentStories(Number(count));
      setStoriesCount(Number(count));
    }
  }, []);

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <div className="container">
      {isMobile ? (
        <>
          <AppBar
            position="fixed"
            style={{ background: "transparent", boxShadow: "none" }}
          >
            <Toolbar>
              <IconButton
                color="primary"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap>
                Welcome, Admin!
              </Typography>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="temporary"
            open={isDrawerOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <UserLeftContainer />
          </Drawer>
        </>
      ) : (
        <>
          <UserLeftContainer />
        </>
      )}

      <div
        style={{
          backgroundColor:"#DAEDE3",
          padding: "50px 20px",
          display: "flex",
          alignItems: "center",
          width: "75%",
          flexDirection: "column",
          // border: "1px solid red",
        }}
      >
        <Typography variant="h4" gutterBottom>
          Stories Levels
        </Typography>

        <Grid container spacing={3} justifyContent="center" alignItems="center">
          {levels.map((level) => (
            <Grid item key={level.level}>
              <motion.div
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "30px",
                  backgroundColor:
                    storiesCount >= level.stories ? "#027148" : "#f0f0f0",
                  borderRadius: "12px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  width: "300px",
                }}
              >
                <Typography variant="h6" gutterBottom>
                  Stories Level
                </Typography>
                <motion.div
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    backgroundColor: "#e0e0e0",
                    padding: "20px",
                    borderRadius: "8px",
                    width: "100%",
                    marginBottom: "20px",
                  }}
                >
                  <span
                    style={{
                      fontSize: "28px",
                      fontWeight: "bold",
                      color: "#333",
                    }}
                  >
                    {`Level ${level.level}`}
                  </span>
                  <span
                    style={{
                      fontSize: "16px",
                      color: "#666",
                      marginTop: "10px",
                      textAlign: "center",
                    }}
                  >
                    {`On completion of ${level.stories} stories`}
                  </span>
                </motion.div>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
};

export default StoriesLevelsPage;

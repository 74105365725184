import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  Select,
  MenuItem,
  TextareaAutosize,
  TextField,
  Snackbar,
  Alert,
} from "@mui/material";

import {
  useMediaQuery,
  IconButton,
  Drawer,
  AppBar,
  Toolbar,
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  ADD_MEMBER_TO_TEAM_URL,
  CREATE_TASK_URL,
  FETCH_TEAM_MEMBERS_URL,
  FETCH_USER_TASKS_URL,
  GET_ALL_TASKS_URL,
  GET_ALL_USERS_URL,
  MAKE_MANAGER_URL,
  REMOVE_MANAGER_URL,
} from "../../api/user";

const TeamDetails = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const isMobile = useMediaQuery("(max-width: 768px)");
  const navigation = useNavigate();
  const { teamName, teamId } = useParams();
  const [data, setData] = useState([]);
  const [users, setUsers] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [userTasks, setUserTasks] = useState([]);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    taskDetails: "",
    team: teamName, // Set the default team name
    status: "Assigned",
    assignedDate: "",
    completedDate: "",
    rating: "",
  });

  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  // State for the Make Manager confirmation dialog
  const [isMakeManagerDialogOpen, setIsMakeManagerDialogOpen] = useState(false);
  const [isRemoveManagerDialogOpen, setIsRemoveManagerDialogOpen] =
    useState(false);
  const [selectedMemberForManager, setSelectedMemberForManager] =
    useState(null);
  // Create state to manage available members to add to the team
  const [availableMembers, setAvailableMembers] = useState([]);

  const [isAddMemberDialogOpen, setIsAddMemberDialogOpen] = useState(false);

  const [admin, setAdmin] = useState("");
  const [manager, setManager] = useState("");
  const [manageTeams, setManageTeams] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [token, setToken] = useState("");
  // const [adminMail, setAdminMail] =useState("");

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
        const storedToken = localStorage.getItem("token");
        if (!storedToken) {
            console.error("Token not found in localStorage");
            return;
        }

        // Set the token state
        setToken(storedToken);

        // Decode the token to extract user information
        const tokenPayload = JSON.parse(atob(storedToken.split(".")[1]));
        const currentUserId = tokenPayload.userId;

        // Fetch the user data using the currentUserId
        const response = await axios.get(GET_ALL_USERS_URL, {
            headers: {
                Authorization: `Bearer ${storedToken}`
            }
        });

        if (response.status === 200) {
          const allUsers = response.data;
          const currentUser = allUsers.find(user => user._id === currentUserId);
      
          // const filteredSuperAdmin = allUsers.filter(
          //     (user) =>
          //         user.isAdmin === true &&
          //         user.isApproved === true &&
          //         user.isDeactivated === false &&
          //         user?.email === "markoknow@gmail.com" //markoknow@gmail.com
          // );
      
          // Log the filtered results
          // console.log(filteredSuperAdmin);
      
          // if (filteredSuperAdmin.length > 0) {
          //     console.log(filteredSuperAdmin[0].email);
          //     setAdminMail(filteredSuperAdmin[0].email);
          // } else {
          //     console.error("No super admin found with the specified criteria.");
          // }
      
          if (currentUser) {
              setCurrentUser(currentUser); // Set the current user object
              setAdmin(currentUser.isAdmin);
              setManager(currentUser.isManager);
              setManageTeams(currentUser.managedTeams);
          } else {
              console.error("Current user not found in response data");
          }
      } else {
          console.error("Unexpected status code:", response.status);
      }
      
    } catch (error) {
        console.error("Error fetching user data: ", error);
    }
};

  // Function to open the dialog
  const openAddMemberDialog = () => {
    setIsAddMemberDialogOpen(true);
  };

  // Function to close the dialog
  const closeAddMemberDialog = () => {
    setIsAddMemberDialogOpen(false);
  };
  // Function to handle logout
  const handleLogout = () => {
    // Remove the token from local storage
    localStorage.removeItem("token");
    localStorage.removeItem("isAdmin");
    // Redirect the user to the login page or any other desired page
    window.location.href = "/login";
  };
  useEffect(() => {
    fetchData();
  }, [teamName]);

  useEffect(() => {
    fetchUserTasks();
  }, [teamMembers]);

  useEffect(() => {
    fetchTaskData();
  }, []);
  useEffect(() => {
    fetchUsers();
  }, []);
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const fetchTaskData = async () => {
    try {
        const storedToken = localStorage.getItem("token");
        if (!storedToken) {
            console.error("Token not found in localStorage");
            return;
        }

        const response = await axios.get(GET_ALL_TASKS_URL, {
            headers: {
                Authorization: `Bearer ${storedToken}`
            }
        });

        if (response.status === 200) {
            const teamTasks = response.data || [];
            const filteredTasks = teamTasks.filter(
                (task) => task && task.team && task.team._id === teamId
            );
            setData(filteredTasks);
        } else {
            console.error("Unexpected status code:", response.status);
        }
    } catch (error) {
        console.error("Error fetching tasks", error);
    }
};


  const fetchUsers = async () => {
    try {
        const token = localStorage.getItem('token'); // Assuming you're storing the token in localStorage
        const response = await axios.get(GET_ALL_USERS_URL, {
            headers: {
                Authorization: `Bearer ${token}` // Add the token to the Authorization header
            }
        });
        if (response.status === 200) {
            setUsers(response.data);
        } else {
            console.error("Unexpected status code:", response.status);
        }
    } catch (error) {
        if (error.response) {
            // Error response from the server
            if (error.response.status === 401) {
                console.error("Unauthorized: Please check your authentication credentials.");
                // You might want to redirect the user to a login page or display a message
            } else {
                console.error("Request failed with status code:", error.response.status);
            }
        } else if (error.request) {
            // No response received
            console.error("No response received:", error.request);
        } else {
            // Error setting up the request
            console.error("Error setting up request:", error.message);
        }
    }
};


  const fetchData = async () => {
    try {
      // Construct the URL for fetching team members
      const fetchTeamMembersUrl = FETCH_TEAM_MEMBERS_URL(teamName);
  
      // Retrieve the authentication token from local storage
      const storedToken = localStorage.getItem('token');
  
      // Make a GET request to fetch team members
      const response = await axios.get(fetchTeamMembersUrl, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
  
      // Extract team members from the response data
      const members = response.data;
  
      // Update the state with the fetched team members
      setTeamMembers(members);
    } catch (error) {
      // Handle errors by logging them
      console.error("Error fetching team members:", error);
  
      // Optionally, you can set an error message to display to the user
      setSnackbarMessage("Error fetching team members");
      setIsSnackbarOpen(true);
  
      // Handle unauthorized access specifically
      if (error.response && error.response.status === 401) {
        setSnackbarMessage("Unauthorized access - please log in again");
        // Optionally, redirect to login page
        // window.location.href = '/login';
      }
    }
  };
  
  const fetchUserTasks = async () => {
    try {
        const storedToken = localStorage.getItem("token");
        if (!storedToken) {
            console.error("Token not found in localStorage");
            return;
        }

        // Map teamMembers to fetch tasks for each member
        const promises = teamMembers.map(async (member) => {
            try {
                const fetchUserTasksUrl = FETCH_USER_TASKS_URL(member._id);
                const taskResponse = await axios.get(fetchUserTasksUrl, {
                    headers: {
                        Authorization: `Bearer ${storedToken}`
                    }
                });
                const tasks = taskResponse.data || [];

                // Filter tasks based on the team name
                const filteredTasks = tasks.filter(
                    (task) => task && task.team && task.team.name === teamName
                );

                // Map tasks to include the team _id
                const updatedTasks = filteredTasks.map((task) => ({
                    ...task,
                    team: {
                        name: teamName,
                        _id: teamId,
                    },
                }));
                return { memberId: member._id, tasks: updatedTasks };
            } catch (error) {
                console.error(`Error fetching tasks for ${member.name}:`, error);
            }
        });

        const userTasksData = await Promise.all(promises);
        setUserTasks(userTasksData);
    } catch (error) {
        console.error("Error fetching user tasks", error);
    }
};


  // Fetch available members to add to the team
  useEffect(() => {
    // Fetch members who are not already in the team
    const membersNotInTeam = users.filter((user) => {
      return !teamMembers.some((member) => member._id === user._id);
    });
    setAvailableMembers(membersNotInTeam);
  }, [users, teamMembers]);

  const handleAddMemberToTeam = async (userId) => {
    try {
      const storedToken = localStorage.getItem("token");
      const addMemberToTeamUrl = ADD_MEMBER_TO_TEAM_URL(teamId, userId);
  
      const response = await axios.post(addMemberToTeamUrl, {}, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
  
      if (response.status === 200) {
        // Handle the success response here
        console.log('Member added successfully:', response.data);
  
        const newMember = availableMembers.find((member) => member._id === userId);
        if (newMember) {
          setTeamMembers((prevTeamMembers) => [...prevTeamMembers, newMember]);
        }
  
        // Close the dialog after adding the member
        closeAddMemberDialog();
        setSnackbarMessage("Member added successfully to the team");
      } else {
        // Handle the response when adding member was not successful
        console.error('Failed to add member to the team:', response.data.message);
        setSnackbarMessage("Failed to add member to the team");
      }
    } catch (error) {
      console.error('Error adding member to the team:', error);
      // Handle the error response here
      if (error.response && error.response.status === 401) {
        setSnackbarMessage("Unauthorized access - please log in again");
        // Optionally, redirect to login page
        // window.location.href = '/login';
      } else {
        setSnackbarMessage("Error adding member to the team");
      }
    } finally {
      setIsSnackbarOpen(true);
    }
  };
  

  const calculateTaskCounts = (userId) => {
    const userTasksData = userTasks.find(
      (userTask) => userTask && userTask.memberId === userId
    );
    if (userTasksData) {
      const tasks = userTasksData.tasks;
      const totalAssigned = tasks.length;
      const pending = tasks.filter((task) => task.status === "Assigned").length;
      const completed = tasks.filter(
        (task) => task.status === "Completed and Validated"
      ).length;
      return { totalAssigned, pending, completed };
    }
    return { totalAssigned: 0, pending: 0, completed: 0 };
  };

  const handleCreateTask = () => {
    navigation(`/taskAssign/${teamName}/${teamId}`);
  };

  const handleCloseCreateDialog = () => {
    setIsCreateDialogOpen(false);
    setSelectedUser("");
    setFormData({
      name: "",
      taskDetails: "",
      team: teamName, // Set the default team name
      status: "Assigned",
      assignedDate: "",
      completedDate: "",
      rating: "",
    });
  };

  const handleUserSelect = (event) => {
    const selectedUserName = event.target.value;
    setSelectedUser(selectedUserName);
    setFormData((prevData) => ({
      ...prevData,
      name: selectedUserName,
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCreateTaskSubmit = async () => {
    try {
      const selectedMember = teamMembers.find(
        (member) => member.name === selectedUser
      );

      if (selectedMember) {
        const newTaskData = {
          assignedTo: selectedMember._id,
          taskDetails: formData.taskDetails,
          team: teamId, // Use the actual teamId
          status: formData.status,
          assignedDate: formData.assignedDate,
        };

        const response = await axios.post(CREATE_TASK_URL, newTaskData);

        const newTask = {
          _id: response.data._id,
          assignedTo: { _id: selectedMember._id }, // Use the actual _id value for assignedTo
          taskDetails: formData.taskDetails,
          team: { _id: teamId }, // Use the actual _id value for team
          status: formData.status,
          assignedDate: formData.assignedDate,
        };

        // Close the create task dialog
        setIsCreateDialogOpen(false);

        // Clear/reset the selectedUser state
        setSelectedUser("");

        // Clear the formData object
        setFormData({
          name: "",
          taskDetails: "",
          team: teamName, // Set the default team name
          assignedDate: "",
          status: "Assigned",
        });

        // Display a success message
        setSnackbarMessage("Task created successfully");
        setIsSnackbarOpen(true);

        // You may want to update the userTasks state here to reflect the newly created task
        // and update the UI accordingly.
      }
    } catch (error) {
      console.error("Error creating task", error);
    }
  };
  const handleMakeManagerClick = (member) => {
    setSelectedMemberForManager(member);
    setIsMakeManagerDialogOpen(true);
  };

  const handleRemoveManagerClick = (member) => {
    // Create a dialog to confirm the removal of manager status
    setSelectedMemberForManager(member);
    setIsRemoveManagerDialogOpen(true);
  };
  // Function to confirm making a user a manager

  const handleConfirmMakeManager = async () => {
    try {
        const storedToken = localStorage.getItem("token");
        if (!storedToken) {
            console.error("Token not found in localStorage");
            return;
        }

        const makeManagerUrl = MAKE_MANAGER_URL(
            selectedMemberForManager._id,
            teamId
        );

        const response = await axios.put(makeManagerUrl, null, {
            headers: {
                Authorization: `Bearer ${storedToken}`
            }
        });

        if (response.status === 200) {
            setIsMakeManagerDialogOpen(false);
            setSnackbarMessage(
                `Made ${selectedMemberForManager?.name} the manager successfully`
            );
            setIsSnackbarOpen(true);
            fetchData(); // Assuming fetchData() is a function to refresh team members data
        }
    } catch (error) {
        console.error("Error making manager", error);
    }
};

const handleConfirmRemoveManager = async () => {
  try {
      const storedToken = localStorage.getItem("token");
      if (!storedToken) {
          console.error("Token not found in localStorage");
          return;
      }

      const removeManagerUrl = REMOVE_MANAGER_URL(
          selectedMemberForManager._id,
          teamId
      );

      const response = await axios.post(removeManagerUrl, null, {
          headers: {
              Authorization: `Bearer ${storedToken}`
          }
      });

      if (response.status === 200) {
          setIsRemoveManagerDialogOpen(false);
          setSnackbarMessage(
              `Removed manager status from ${selectedMemberForManager?.name} successfully`
          );
          setIsSnackbarOpen(true);
          fetchData(); // Refresh team members data
      }
  } catch (error) {
      console.error("Error removing manager", error);
  }
};

  return (
    <div className="container">
      {isMobile ? (
        <>
          <AppBar
            position="fixed"
            style={{ background: "transparent", boxShadow: "none" }}
          >
            <Toolbar>
              <IconButton
                color="primary"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
              <p>Welcome, Admin!</p>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="temporary"
            open={isDrawerOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <div className="left-container">
              <div className="logo">
                <h2>Markoknow</h2>
                {admin && <h2>Admin</h2>}
              </div>
              <div className="divider"></div>
              <div className="icons">
                {admin && (
                  <div className="icon">
                    <Link to="/access" className="icon-link">
                      Access
                    </Link>
                  </div>
                )}

                {admin && (
                  <div className="icon">
                    <Link to="/overview" className="icon-link">
                      Overview
                    </Link>
                  </div>
                )}
                {admin && (
                  <div className="icon">
                    <Link to="/notification" className="icon-link">
                      Notification
                    </Link>
                  </div>
                )}
                {admin && (
                  <div className="icon">
                    <Link to="/adminDashboard" className="icon-link">
                      Admin Dashboard
                    </Link>
                  </div>
                )}
                {admin && (
                  <div className="icon">
                    <Link to="/sales" className="icon-link">
                      Sales
                    </Link>
                  </div>
                )}
                {admin && (
                  <div className="icon">
                    <Link to="/teamsDashboard" className="icon-link">
                      Manage Users
                    </Link>
                  </div>
                )}
                {manager && (
                  <div className="icon">
                    <Link to="/dashboard" className="icon-link">
                      Dashboard
                    </Link>
                  </div>
                )}
                {manager && (
                  <div className="icon">
                    <Link to="/tasks" className="icon-link">
                      Tasks
                    </Link>
                  </div>
                )}
                <div className="icon">
                  <Link to="/teams" className="icon-link">
                    Teams
                  </Link>
                </div>
                {admin && (
                  <div className="icon">
                    <Link to="/onBoardingAdmin" className="icon-link">
                      OnBoarding
                    </Link>
                  </div>
                )}
                {admin && (
                  <div className="icon">
                    <Link to="/Adminprofile" className="icon-link">
                      Profile
                    </Link>
                  </div>
                )}
                {manager && (
                  <div className="icon">
                    <Link to="/profile" className="icon-link">
                      Profile
                    </Link>
                  </div>
                )}

                {admin === "true" ? null : (
                  <>
                    <div className="icon">
                      <Link to="/past" className="icon-link">
                        Not Current
                      </Link>
                    </div>
                  </>
                )}
                <div className="icon">
                  <Link to="/" className="icon-link" onClick={handleLogout}>
                    Logout
                  </Link>
                </div>
              </div>
            </div>
          </Drawer>
        </>
      ) : (
        <>
          <div className="left-container">
            <div className="logo">
              <h2>Markoknow</h2>
              {admin && <h2>Admin</h2>}
            </div>
            <div className="divider"></div>
            <div className="icons">
              {admin && (
                <div className="icon">
                  <Link to="/access" className="icon-link">
                    Access
                  </Link>
                </div>
              )}

              {admin && (
                <div className="icon">
                  <Link to="/overview" className="icon-link">
                    Overview
                  </Link>
                </div>
              )}
              {admin && (
                <div className="icon">
                  <Link to="/notification" className="icon-link">
                    Notification
                  </Link>
                </div>
              )}
              {admin && (
                <div className="icon">
                  <Link to="/adminDashboard" className="icon-link">
                    Admin Dashboard
                  </Link>
                </div>
              )}
              {admin && (
                <div className="icon">
                  <Link to="/sales" className="icon-link">
                    Sales
                  </Link>
                </div>
              )}
              {admin && (
                <div className="icon">
                  <Link to="/teamsDashboard" className="icon-link">
                    Manage Users
                  </Link>
                </div>
              )}
              {manager && (
                <div className="icon">
                  <Link to="/dashboard" className="icon-link">
                    Dashboard
                  </Link>
                </div>
              )}
              {manager && (
                <div className="icon">
                  <Link to="/tasks" className="icon-link">
                    Tasks
                  </Link>
                </div>
              )}
              <div className="icon">
                <Link to="/teams" className="icon-link">
                  Teams
                </Link>
              </div>
              {admin && (
                <div className="icon">
                  <Link to="/onBoardingAdmin" className="icon-link">
                    OnBoarding
                  </Link>
                </div>
              )}
              {admin && (
                <div className="icon">
                  <Link to="/Adminprofile" className="icon-link">
                    Profile
                  </Link>
                </div>
              )}
              {manager && (
                <div className="icon">
                  <Link to="/profile" className="icon-link">
                    Profile
                  </Link>
                </div>
              )}

              {admin === "true" ? null : (
                <>
                  <div className="icon">
                    <Link to="/past" className="icon-link">
                      Not Current
                    </Link>
                  </div>
                </>
              )}
              <div className="icon">
                <Link to="/" className="icon-link" onClick={handleLogout}>
                  Logout
                </Link>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="right-container">
        <Typography variant="h4" gutterBottom>
          Team: {teamName}
        </Typography>
        {admin && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "45px",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
           
        <Button
          variant="contained"
          color="primary"
          onClick={openAddMemberDialog}
        >
          Add Member
        </Button>
  
            <Button
              variant="contained"
              color="primary"
              style={{}}
              onClick={handleCreateTask}
            >
              Task Panel
            </Button>
          </div>
        )}
        {admin && (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Total Assigned</TableCell>
                  <TableCell>Pending</TableCell>
                  <TableCell>Completed</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {teamMembers.map((member) => {
                  const { totalAssigned, pending, completed } =
                    calculateTaskCounts(member._id);

                  const managerTeam = member.managedTeams.includes(teamId);

                  return (
                    <TableRow key={member._id}>
                      <TableCell>{member.name}</TableCell>
                      <TableCell>{totalAssigned}</TableCell>
                      <TableCell>{pending}</TableCell>
                      <TableCell>{completed}</TableCell>
                      <TableCell>
                        {!member.isAdmin? (
                          <>
                            {managerTeam ? (
                              <Button
                                variant="outlined"
                                color="secondary"
                                onClick={() => handleRemoveManagerClick(member)}
                              >
                                Remove Manager
                              </Button>
                            ) : (
                              <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => handleMakeManagerClick(member)}
                              >
                                Make Manager
                              </Button>
                            )}
                          </>
                        ) : (
                          <Button variant="outlined" color="primary" disabled>
                            {member.isAdmin ? "Admin" : "Manager"}
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <br />
        <br />
        <Snackbar
          open={isSnackbarOpen}
          autoHideDuration={3000} // Adjust the duration as needed (in milliseconds)
          onClose={() => setIsSnackbarOpen(false)}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert severity="success" onClose={() => setIsSnackbarOpen(false)}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <Dialog
          open={isMakeManagerDialogOpen}
          onClose={() => setIsMakeManagerDialogOpen(false)}
        >
          <DialogTitle>Make Manager Confirmation</DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure you want to make {selectedMemberForManager?.name} the
              manager of this team?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setIsMakeManagerDialogOpen(false)}
              color="primary"
            >
              Cancel
            </Button>
            <Button onClick={handleConfirmMakeManager} color="primary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={isRemoveManagerDialogOpen}
          onClose={() => setIsRemoveManagerDialogOpen(false)}
        >
          <DialogTitle>Remove Manager Confirmation</DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure you want to remove the manager status from{" "}
              {selectedMemberForManager?.name}?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setIsRemoveManagerDialogOpen(false)}
              color="primary"
            >
              Cancel
            </Button>
            <Button onClick={handleConfirmRemoveManager} color="primary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={isAddMemberDialogOpen} // Control the visibility of the dialog
          onClose={closeAddMemberDialog} // Close the dialog when needed
        >
          <DialogTitle>Add Member to Team</DialogTitle>
          <DialogContent style={{ width: "400px" }}>
            {/* Map through available members and display them */}
            {availableMembers.map((member) => (
              <div key={member._id} style={{ marginBottom: "8px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography>{member.name}</Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      // Implement logic to add the member to the team here
                      handleAddMemberToTeam(member._id);
                      // Close the dialog after adding the member
                      closeAddMemberDialog();
                    }}
                  >
                    Add
                  </Button>
                </div>
              </div>
            ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={closeAddMemberDialog} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default TeamDetails;

import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  Select,
  MenuItem,
  TextareaAutosize,
  TextField,
  Snackbar,
  Alert,
} from "@mui/material";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  ADD_MEMBER_TO_TEAM_URL,
  CREATE_TASK_URL,
  FETCH_TEAM_MEMBERS_URL,
  FETCH_USER_TASKS_URL,
  GET_ALL_TASKS_URL,
  GET_ALL_USERS_URL,
  MAKE_MANAGER_URL,
} from "../../api/user";

import {
  useMediaQuery,
  IconButton,
  Drawer,
  AppBar,
  Toolbar,
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";

const TaskAssigned = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const isMobile = useMediaQuery("(max-width: 768px)");
  const { teamName, teamId } = useParams();
  const [data, setData] = useState([]);
  const [users, setUsers] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [userTasks, setUserTasks] = useState([]);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    taskDetails: "",
    team: teamName, // Set the default team name
    status: "Assigned",
    assignedDate: "",
    completedDate: "",
    rating: "",
  });

  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  // State for the Make Manager confirmation dialog
  const [isMakeManagerDialogOpen, setIsMakeManagerDialogOpen] = useState(false);
  const [selectedMemberForManager, setSelectedMemberForManager] =
    useState(null);
  // Create state to manage available members to add to the team
  const [availableMembers, setAvailableMembers] = useState([]);

  const [isAddMemberDialogOpen, setIsAddMemberDialogOpen] = useState(false);

  const [admin, setAdmin] = useState("");
  const [manager, setManager] = useState("");
  const [manageTeams, setManageTeams] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [token, setToken] = useState("");

  useEffect(() => {
    fetchUserData();
  }, []);
  const [taskDetails, setTaskDetails] = useState("");
  const [status, setStatus] = useState("");
  const [assignedDate, setAssignedDate] = useState("");

  const handleAssignTaskToAll = async () => {
    try {
      // Filter out admin members
      const nonAdminMembers = teamMembers.filter(member => member.isAdmin !=true);
      console.log(teamMembers)
      // Log the non-admin members for debugging
      console.log('Non-admin Team Members:', nonAdminMembers);
  
      const newTaskData = {
        taskDetails,
        team: teamId,
        status: "Assigned",
        assignedDate,
      };
  
      const assignTasksPromises = nonAdminMembers.map((member) => {
        const memberTaskData = {
          ...newTaskData,
          assignedTo: member._id,
        };
  
        // Log the memberTaskData to debug the request body
        console.log('Assigning task to member:', memberTaskData);
  
        return axios.post(CREATE_TASK_URL, memberTaskData);
      });
  
      await Promise.all(assignTasksPromises);
  
      // Clear the task details after successful assignment
      setTaskDetails('');
      setAssignedDate('');
      setStatus('');
  
      handleCloseDialog();
      fetchTaskData();
    } catch (error) {
      console.error('Error assigning tasks to all members', error);
  
      // Log additional details about the error
      if (error.response) {
        // The request was made and the server responded with a status code that falls out of the range of 2xx
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
        console.error('Response headers:', error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        console.error('Request data:', error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error message:', error.message);
      }
    }
  };
  
  
  
  

  const fetchUserData = async () => {
    try {
      const storedToken = await localStorage.getItem("token");
      setToken(storedToken);
      const tokenPayload = storedToken
        ? JSON.parse(atob(storedToken.split(".")[1]))
        : {};
      const currentUserId = tokenPayload.userId;

      // Fetch the user data using the currentUserId
      const response = await axios.get(GET_ALL_USERS_URL, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
      });
      if (response.status === 200) {
        const allUsers = response.data;
        const currentUser = allUsers.find((user) => user._id === currentUserId);
        setCurrentUser(currentUser); // Set the current user object
        setAdmin(currentUser.isAdmin);
        setManager(currentUser.isManager);
        setManageTeams(currentUser.managedTeams);
      }
    } catch (error) {
      console.error("Error fetching user data: ", error);
    }
  };
  // Function to open the dialog
  const openAddMemberDialog = () => {
    setIsAddMemberDialogOpen(true);
  };

  // Function to close the dialog
  const closeAddMemberDialog = () => {
    setIsAddMemberDialogOpen(false);
  };
  // Function to handle logout
  const handleLogout = () => {
    // Remove the token from local storage
    localStorage.removeItem("token");
    localStorage.removeItem("isAdmin");
    // Redirect the user to the login page or any other desired page
    window.location.href = "/login";
  };
  useEffect(() => {
    fetchData();
  }, [teamName]);

  useEffect(() => {
    fetchUserTasks();
  }, [teamMembers]);

  useEffect(() => {
    fetchTaskData();
  }, []);
  useEffect(() => {
    fetchUsers();
  }, []);
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const fetchTaskData = async () => {
    try {
      const response = await axios.get(GET_ALL_TASKS_URL);
      if (response.status === 200) {
        const teamTasks = response.data.filter(
          (task) => task.team && task.team._id === teamId
        );
        

        // Set the filtered data using setData
        setData(teamTasks);
      }
    } catch (error) {
      console.error("Error fetching tasks", error);
    }
  };
  
  const fetchUsers = async () => {
    console.log("Token:", token); // Log the token to ensure it's correct
  
    try {
      const response = await axios.get(GET_ALL_USERS_URL, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
      });
      if (response.status === 200) {
        setUsers(response.data);
      } else {
        console.error(`Unexpected status code: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching users", error);
      if (error.response) {
        console.error("Response error data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      }
    }
  };
  

  const fetchData = async () => {
    try {
      const storedToken = localStorage.getItem("token");
      if (!storedToken) {
        // Handle case where token is not available
        console.error("Authentication token not found.");
        return;
      }
  
      const fetchTeamMembersUrl = FETCH_TEAM_MEMBERS_URL(teamName);
      const response = await axios.get(fetchTeamMembersUrl, {
        headers: {
          Authorization: `Bearer ${storedToken}`, // Include the token in the request headers
        },
      });
  
      if (response.status === 200) {
        const members = response.data;
        setTeamMembers(members);
      } else {
        // Handle unexpected status codes
        console.error("Unexpected response status:", response.status);
      }
    } catch (error) {
      // Handle network errors or other exceptions
      console.error("Error fetching team members:", error);
    }
  };
  
  const fetchUserTasks = async () => {
    try {
      const promises = teamMembers.map(async (member) => {
        try {
          const fetchUserTasksUrl = FETCH_USER_TASKS_URL(member._id);

          const taskResponse = await axios.get(fetchUserTasksUrl);
          const tasks = taskResponse.data;

          // Filter tasks based on the team name
          const filteredTasks = tasks.filter(
            (task) => task.team.name === teamName
          );

          // Map tasks to include the team _id
          const updatedTasks = filteredTasks.map((task) => ({
            ...task,
            team: {
              name: teamName,
              _id: teamId, // Use the extracted teamId
            },
          }));

          return { memberId: member._id, tasks: updatedTasks };
        } catch (error) {
          console.error(`Error fetching tasks for ${member.name}:`, error);
        }
      });

      const userTasksData = await Promise.all(promises);
      setUserTasks(userTasksData);
    } catch (error) {
      console.error("Error fetching user tasks", error);
    }
  };

  // Fetch available members to add to the team
  useEffect(() => {
    // Fetch members who are not already in the team
    const membersNotInTeam = users.filter((user) => {
      return !teamMembers.some((member) => member._id === user._id);
    });
    setAvailableMembers(membersNotInTeam);
  }, [users, teamMembers]);

  const handleAddMemberToTeam = (userId) => {
    // Make a POST request to add the selected member to the team
    const addMemberToTeamUrl = ADD_MEMBER_TO_TEAM_URL(teamId, userId);
    
    const config = {
      headers: {
        Authorization: `Bearer ${token}` // Assuming token is defined in the component's scope
      }
    };
  
    axios.post(addMemberToTeamUrl, null, config)
      .then((response) => {
        if (response.status === 200) {
          // Handle the success response here
          // Update the team members list with the new member
          setTeamMembers(prevMembers => {
            const newMember = availableMembers.find(member => member._id === userId);
            if (newMember) {
              return [...prevMembers, newMember];
            } else {
              return prevMembers;
            }
          });
  
          // Close the dialog after adding the member
          closeAddMemberDialog();
          
          // Display a success message
          setSnackbarMessage("Member added to the team successfully");
          setIsSnackbarOpen(true);
        } else {
          // Handle the response when adding member was not successful
          console.error("Failed to add member to the team:", response.data.message);
          
          // Display an error message to the user
          setSnackbarMessage("Failed to add member to the team");
          setIsSnackbarOpen(true);
        }
      })
      .catch((error) => {
        console.error("Error adding member to the team:", error);
        
        // Handle the error response here
        // Display an error message to the user
        setSnackbarMessage("Error adding member to the team");
        setIsSnackbarOpen(true);
      });
  };
  
  const calculateTaskCounts = (userId) => {
    const userTasksData = userTasks.find(
      (userTask) => userTask.memberId === userId
    );
    if (userTasksData) {
      const tasks = userTasksData.tasks;
      const totalAssigned = tasks.length;
      const pending = tasks.filter((task) => task.status === "Assigned").length;
      const completed = tasks.filter(
        (task) => task.status === "Completed and Validated"
      ).length;
      return { totalAssigned, pending, completed };
    }
    return { totalAssigned: 0, pending: 0, completed: 0 };
  };

  const handleCreateTask = () => {
    setIsCreateDialogOpen(true);
  };
  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleCloseCreateDialog = () => {
    setIsCreateDialogOpen(false);
    setSelectedUser("");
    setFormData({
      name: "",
      taskDetails: "",
      team: teamName, // Set the default team name
      status: "Assigned",
      assignedDate: "",
      completedDate: "",
      rating: "",
    });
  };

  const handleUserSelect = (event) => {
    const selectedUserName = event.target.value;
    setSelectedUser(selectedUserName);
    setFormData((prevData) => ({
      ...prevData,
      name: selectedUserName,
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCreateTaskSubmit = async () => {
    try {
      setIsCreateDialogOpen(false);
      const selectedMember = teamMembers.find(
        (member) => member.name === selectedUser
      );

      if (selectedMember) {
        const newTaskData = {
          assignedTo: selectedMember._id,
          taskDetails: formData.taskDetails,
          team: teamId, // Use the actual teamId
          status: formData.status,
          assignedDate: formData.assignedDate,
        };

        const response = await axios.post(CREATE_TASK_URL, newTaskData);

        const newTask = {
          _id: response.data._id,
          assignedTo: { _id: selectedMember._id }, // Use the actual _id value for assignedTo
          taskDetails: formData.taskDetails,
          team: { _id: teamId }, // Use the actual _id value for team
          status: formData.status,
          assignedDate: formData.assignedDate,
        };

        // Close the create task dialog

        // Clear/reset the selectedUser state
        setSelectedUser("");

        // Clear the formData object
        setFormData({
          name: "",
          taskDetails: "",
          team: teamName, // Set the default team name
          assignedDate: "",
          status: "Assigned",
        });

        // Display a success message
        setSnackbarMessage("Task created successfully");
        setIsSnackbarOpen(true);

        // You may want to update the userTasks state here to reflect the newly created task
        // and update the UI accordingly.
      }
    } catch (error) {
      if (error.response.status === 400) {
        // If the error is a validation error, handle multiple validation errors
        if (error.response.data && error.response.data.details) {
          const validationErrors = Object.values(
            error.response.data.details
          ).flat();
          setSnackbarMessage(
            `Validation error: ${validationErrors.join(", ")}`
          );
        } else {
          // If no details provided, show a generic error message
          setSnackbarMessage("Validation error: Something went wrong.");
        }
        // Open the Snackbar
        setIsSnackbarOpen(true);
      }
      console.error("Error creating task", error);
    }
  };
  const handleMakeManagerClick = (member) => {
    setSelectedMemberForManager(member);
    setIsMakeManagerDialogOpen(true);
  };
  // Function to confirm making a user a manager

  const handleConfirmMakeManager = async () => {
    try {
      // Send a request to the server to make the user a manager
      const makeManagerUrl = MAKE_MANAGER_URL(
        selectedMemberForManager._id,
        teamId
      );

      // Send a request to the server to make the user a manager
      const response = await axios.put(makeManagerUrl);

      if (response.status === 200) {
        // Close the confirmation dialog
        setIsMakeManagerDialogOpen(false);

        // Display a success message
        setSnackbarMessage(
          `Made ${selectedMemberForManager?.name} the manager successfully`
        );
        setIsSnackbarOpen(true);

        // Refresh the team members data to reflect the new manager
        fetchData();
      }
    } catch (error) {
      console.error("Error making manager", error);
    }
  };
  
  return (
    <div className="container">
      {isMobile ? (
        <>
          <AppBar
            position="fixed"
            style={{ background: "transparent", boxShadow: "none" }}
          >
            <Toolbar>
              <IconButton
                color="primary"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
              <p>Welcome, Admin!</p>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="temporary"
            open={isDrawerOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <div className="left-container">
              <div className="logo">
                <h2>Markoknow</h2>
                {admin && <h2>Admin</h2>}
              </div>
              <div className="divider"></div>
              <div className="icons">
                {admin && (
                  <div className="icon">
                    <Link to="/overview" className="icon-link">
                      Overview
                    </Link>
                  </div>
                )}
                {admin && (
                  <div className="icon">
                    <Link to="/notification" className="icon-link">
                      Notification
                    </Link>
                  </div>
                )}
                {admin && (
                  <div className="icon">
                    <Link to="/adminDashboard" className="icon-link">
                      Admin Dashboard
                    </Link>
                  </div>
                )}
                {admin && (
                  <div className="icon">
                    <Link to="/sales" className="icon-link">
                      Sales
                    </Link>
                  </div>
                )}
                {admin && (
                  <div className="icon">
                    <Link to="/teamsDashboard" className="icon-link">
                      Manage Users
                    </Link>
                  </div>
                )}
                {manager && (
                  <div className="icon">
                    <Link to="/dashboard" className="icon-link">
                      Dashboard
                    </Link>
                  </div>
                )}
                {manager && (
                  <div className="icon">
                    <Link to="/tasks" className="icon-link">
                      Tasks
                    </Link>
                  </div>
                )}
                <div className="icon">
                  <Link to="/teams" className="icon-link">
                    Teams
                  </Link>
                </div>
                {admin && (
                  <div className="icon">
                    <Link to="/onBoardingAdmin" className="icon-link">
                      OnBoarding
                    </Link>
                  </div>
                )}
                {admin && (
                  <div className="icon">
                    <Link to="/Adminprofile" className="icon-link">
                      Profile
                    </Link>
                  </div>
                )}
                {manager && (
                  <div className="icon">
                    <Link to="/profile" className="icon-link">
                      Profile
                    </Link>
                  </div>
                )}

                {admin === "true" ? null : (
                  <>
                    <div className="icon">
                      <Link to="/leaveAdminHandle" className="icon-link">
                        Leave
                      </Link>
                    </div>
                  </>
                )}
                {admin === "true" ? null : (
                  <>
                    <div className="icon">
                      <Link to="/past" className="icon-link">
                        Not Current
                      </Link>
                    </div>
                  </>
                )}
                <div className="icon">
                  <Link to="/" className="icon-link" onClick={handleLogout}>
                    Logout
                  </Link>
                </div>
              </div>
            </div>
          </Drawer>
        </>
      ) : (
        <>
          <div className="left-container">
            <div className="logo">
              <h2>Markoknow</h2>
              {admin && <h2>Admin</h2>}
            </div>
            <div className="divider"></div>
            <div className="icons">
              {admin && (
                <div className="icon">
                  <Link to="/overview" className="icon-link">
                    Overview
                  </Link>
                </div>
              )}
              {admin && (
                <div className="icon">
                  <Link to="/notification" className="icon-link">
                    Notification
                  </Link>
                </div>
              )}
              {admin && (
                <div className="icon">
                  <Link to="/adminDashboard" className="icon-link">
                    Admin Dashboard
                  </Link>
                </div>
              )}
              {admin && (
                <div className="icon">
                  <Link to="/sales" className="icon-link">
                    Sales
                  </Link>
                </div>
              )}
              {admin && (
                <div className="icon">
                  <Link to="/teamsDashboard" className="icon-link">
                    Manage Users
                  </Link>
                </div>
              )}
              {manager && (
                <div className="icon">
                  <Link to="/dashboard" className="icon-link">
                    Dashboard
                  </Link>
                </div>
              )}
              {manager && (
                <div className="icon">
                  <Link to="/tasks" className="icon-link">
                    Tasks
                  </Link>
                </div>
              )}
              <div className="icon">
                <Link to="/teams" className="icon-link">
                  Teams
                </Link>
              </div>
              {admin && (
                <div className="icon">
                  <Link to="/onBoardingAdmin" className="icon-link">
                    OnBoarding
                  </Link>
                </div>
              )}
              {admin && (
                <div className="icon">
                  <Link to="/Adminprofile" className="icon-link">
                    Profile
                  </Link>
                </div>
              )}
              {manager && (
                <div className="icon">
                  <Link to="/profile" className="icon-link">
                    Profile
                  </Link>
                </div>
              )}

              {admin === "true" ? null : (
                <>
                  <div className="icon">
                    <Link to="/leaveAdminHandle" className="icon-link">
                      Leave
                    </Link>
                  </div>
                </>
              )}
              {admin === "true" ? null : (
                <>
                  <div className="icon">
                    <Link to="/past" className="icon-link">
                      Not Current
                    </Link>
                  </div>
                </>
              )}
              <div className="icon">
                <Link to="/" className="icon-link" onClick={handleLogout}>
                  Logout
                </Link>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="right-container">
        <Typography variant="h4" gutterBottom>
          Team: {teamName}
        </Typography>

        <Button
          variant="contained"
          color="primary"
          style={{ marginBottom: "16px", width: "150px" }}
          onClick={handleCreateTask}
        >
          Create Task
        </Button>
        <Dialog
          open={isCreateDialogOpen}
          onClose={handleCloseCreateDialog}
          PaperProps={{
            style: {
              maxWidth: "600px", // Adjust the width as needed
              width: "100%",
            },
          }}
        >
          <DialogTitle>Create Task</DialogTitle>
          <DialogContent>
            <div>
              <InputLabel htmlFor="name">User</InputLabel>
              <Select
                labelId="name-label"
                id="name"
                name="name"
                value={selectedUser}
                onChange={handleUserSelect}
                fullWidth
              >
                {teamMembers.map((member) => (
                  <MenuItem key={member._id} value={member.name}>
                    {member.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div>
              <InputLabel htmlFor="taskDetails">
                Task Details (can include links)
              </InputLabel>
              <TextareaAutosize
                id="taskDetails"
                name="taskDetails"
                value={formData.taskDetails}
                onChange={handleInputChange}
                minRows={3}
                style={{
                  width: "100%",
                  maxWidth: "100%",
                }}
              />
            </div>
            <div>
              <InputLabel htmlFor="team">Team</InputLabel>
              <Select
                labelId="team-label"
                id="team"
                name="team"
                value={teamName}
                disabled
                fullWidth
              >
                <MenuItem value={teamName}>{teamName}</MenuItem>
              </Select>
            </div>
            <div>
              <InputLabel htmlFor="assignedDate">Assigned Date</InputLabel>
              <TextField
                type="date"
                name="assignedDate"
                value={formData.assignedDate}
                onChange={handleInputChange}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            {/* <div>
              <InputLabel htmlFor="status">Status</InputLabel>
              <Select
                labelId="status-label"
                id="status"
                name="status"
                value={formData.status}
                onChange={handleInputChange}
                fullWidth
              >
                <MenuItem value="Assigned">Assigned</MenuItem>
                <MenuItem value="Pending">Pending</MenuItem>
                <MenuItem value="Completed">Completed</MenuItem>
                <MenuItem value="Completed and Validated">Completed and Validated</MenuItem>
              </Select>
            </div> */}
            {/* Other form fields */}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseCreateDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={handleCreateTaskSubmit} color="primary">
              Create
            </Button>
          </DialogActions>
        </Dialog>

        <Button
        variant="contained"
        color="primary"
        onClick={handleOpenDialog}
      >
        Assign Task to All Members
      </Button>

        <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Assign Task to All Members</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Task Details"
            type="text"
            fullWidth
            variant="standard"
            value={taskDetails}
            onChange={(e) => setTaskDetails(e.target.value)}
          />
          
          <div>
            <InputLabel htmlFor="assignedDate">Assigned Date</InputLabel>
            <TextField
              type="date"
              fullWidth
              variant="standard"
              value={assignedDate}
              InputLabelProps={{
                shrink: true
              }}
              onChange={(e) => setAssignedDate(e.target.value)}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleAssignTaskToAll}>Assign Task</Button>
        </DialogActions>
      </Dialog>
        <div style={{ maxHeight: "500px", overflowY: "auto" }}>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Task Details</TableCell>
                  <TableCell>Team</TableCell>
                  <TableCell>Assigned Date</TableCell>
                  <TableCell>Completed Date</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row) => (
                  <TableRow key={row._id}>
                    <TableCell>{row.assignedTo.name}</TableCell>
                    <TableCell style={{ width: "60px" }}>
                      {row.taskDetails}
                    </TableCell>
                    <TableCell>{row.team.name}</TableCell>
                    <TableCell>{row.assignedDate.substring(0, 10)}</TableCell>
                    <TableCell>
                      {row.status === "Assigned" || !row.completedDate
                        ? "-"
                        : row.completedDate.substring(0, 10)}
                    </TableCell>

                    <TableCell>{row.status}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Snackbar
          open={isSnackbarOpen}
          autoHideDuration={3000} // Adjust the duration as needed (in milliseconds)
          onClose={() => setIsSnackbarOpen(false)}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert severity="success" onClose={() => setIsSnackbarOpen(false)}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <Dialog
          open={isMakeManagerDialogOpen}
          onClose={() => setIsMakeManagerDialogOpen(false)}
        >
          <DialogTitle>Make Manager Confirmation</DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure you want to make {selectedMemberForManager?.name} the
              manager of this team?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setIsMakeManagerDialogOpen(false)}
              color="primary"
            >
              Cancel
            </Button>
            <Button onClick={handleConfirmMakeManager} color="primary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={isAddMemberDialogOpen} // Control the visibility of the dialog
          onClose={closeAddMemberDialog} // Close the dialog when needed
        >
          <DialogTitle>Add Member to Team</DialogTitle>
          <DialogContent style={{ width: "400px" }}>
            {/* Map through available members and display them */}
            {availableMembers.map((member) => (
              <div key={member._id} style={{ marginBottom: "8px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography>{member.name}</Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      // Implement logic to add the member to the team here
                      handleAddMemberToTeam(member._id);
                      // Close the dialog after adding the member
                      closeAddMemberDialog();
                    }}
                  >
                    Add
                  </Button>
                </div>
              </div>
            ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={closeAddMemberDialog} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={3000}
        onClose={() => setIsSnackbarOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          severity={
            snackbarMessage.includes("successfully") ? "success" : "error"
          }
          onClose={() => setIsSnackbarOpen(false)}
        >
          {snackbarMessage.includes("successfully") ? (
            <>{snackbarMessage}</>
          ) : (
            <>{snackbarMessage}</>
          )}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default TaskAssigned;

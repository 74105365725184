import { BASE_URL } from "./client";

export const GET_ALL_Chat_Data = `${BASE_URL}/api/chatSupport/fetchChatData`;
export const GET_ALL_Chat_Data_Id = (id) =>
  `${BASE_URL}/api/chatSupport/fetchChatData/${id}`;
export const REPLY_CHAT_ID = (id) =>
  `${BASE_URL}/api/chatSupport/replyChat/${id}`;
export const CREATE_Data_Chat = (id) =>
  `${BASE_URL}/api/chatSupport/storeChatData/${id}`;
export const Patch_Status_Change_Chat = (chatSupportId, assignedTo) =>
  `${BASE_URL}/api/chatSupport/${chatSupportId}/${assignedTo}`;

import { BASE_URL } from "./client";

export const GET_ALL_TASKS_URL = `${BASE_URL}/api/taskRequest/getAll`;
export const CREATE_TASK_URL = `${BASE_URL}/api/taskRequest/create`;
export const GET_ALL_RATINGS_URL = `${BASE_URL}/api/taskRequest/getAllRatings`;
export const UPDATE_STATUS_URL = (taskId) =>
  `${BASE_URL}/api/taskRequest/updateStatus/${taskId}`;
export const UPDATE_URL = (taskId) =>
  `${BASE_URL}/api/taskRequest/task/${taskId}`;
export const DELETE_TASK_URL = (taskId) =>
  `${BASE_URL}/api/taskRequest/delete/${taskId}`;
export const GET_ALL_USERS_URL = `${BASE_URL}/api/userRequest/all`;
export const GET_ALL_USERS_URL_BY_USER = `${BASE_URL}/api/userRequest/allUser`;
export const GET_ALL_USER_ID = `${BASE_URL}/api/userRequest/user`;
export const UPDATE_ACCESS_REQ = (id) =>
  `${BASE_URL}/api/userRequest/access/${id}`;
export const DEACTIVATE_ACCESS_REQ = (id) =>
  `${BASE_URL}/api/userRequest/deactivate/${id}`;

export const ACTIVATE_ACCESS_REQ = (id) =>
  `${BASE_URL}/api/userRequest/activate/${id}`;

export const GET_ALL_TEAMS_URL = `${BASE_URL}/api/teamRequest/getAll`;
export const GET_TEAM_DETAILS_URL = `${BASE_URL}/api/teamRequest`;
export const CREATE_TEAM_URL = `${BASE_URL}/api/teamRequest/create`;
export const DELETE_TEAM_URL = (teamId) =>
  `${BASE_URL}/api/teamRequest/delete/${teamId}`; 
export const UPDATE_TASK_DETAILS_URL = (taskId) =>
  `${BASE_URL}/api/taskRequest/updateTaskStatusAndDetails/${taskId}`;

export const FETCH_TEAM_MEMBERS_URL = (teamName) =>
  `${BASE_URL}/api/teamRequest/members/${teamName}`;

export const FETCH_USER_TASKS_URL = (memberId) =>
  `${BASE_URL}/api/taskRequest/getUserTasks/${memberId}`;

export const ADD_MEMBER_TO_TEAM_URL = (teamId, userId) =>
  `${BASE_URL}/api/teamRequest/addMember/${teamId}/${userId}`;

export const MAKE_MANAGER_URL = (userId, teamId) =>
  `${BASE_URL}/api/userRequest/make-manager/${userId}/${teamId}`;
export const REMOVE_MANAGER_URL = (userId, teamId) =>
  `${BASE_URL}/api/userRequest/removeManager/${userId}/${teamId}`;
export const GET_ALL_NOTIFICATION = `${BASE_URL}/api/taskRequest/notification`;

export const DELETE_NOTIFICATION_ID = (id) =>
  `${BASE_URL}/api/taskRequest/deleteNotification/${id}`;

export const DELETE_NOTIFICATION_ALL = `${BASE_URL}/api/taskRequest/notification/deleteNotification`;
export const CREATE_NOTIFICATION_ID = (id) =>
  `${BASE_URL}/api/taskRequest/createNotification/${id}`;


// Define the URL for creating a target
export const CREATE_TARGET_URL = `${BASE_URL}/api/target/create`;

// Define the URL for getting a particular target for a user
export const GET_TARGET_FOR_USER_URL = (userId) =>
  `${BASE_URL}/api/target/user/${userId}`;


export const GET_ALL_TARGETS_URL = `${BASE_URL}/api/target/`;

export const DELETE_TARGET_URL = `${BASE_URL}/api/target/`;

export const UPDATE_TARGET_URL = `${BASE_URL}/api/target/`;
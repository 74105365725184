import React, { useState, useEffect } from 'react';
import axios from 'axios';
import TablePagination from '@mui/material/TablePagination';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Box
} from '@mui/material';
import { styled } from '@mui/system';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FETCH_LEADS, CREATE_LEADS } from '../../src/api/lead';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const styles = {
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
    gap: '20px',
    marginBottom: '20px',
  },
  card: {
    padding: '20px',
    borderRadius: '8px',
    textAlign: 'center',
    minWidth: '100px',
  },
  number: {
    margin: 0,
    fontSize: '2rem',
  },
  label: {
    margin: 0,
    fontSize: '1rem',
  },
};

const LeadsOverview = ({ totalLeads, convertedLeads }) => (
  <div style={styles.container}>
    <div style={{ ...styles.card, backgroundColor: '#FFB74D' }}>
      <h1 style={styles.number}>{totalLeads}</h1>
      <p style={styles.label}>Total Leads</p>
    </div>
    <div style={{ ...styles.card, backgroundColor: '#9575CD' }}>
      <h1 style={styles.number}>{convertedLeads}</h1>
      <p style={styles.label}>Converted Leads</p>
    </div>
    <div style={{ ...styles.card, backgroundColor: '#FFB74D' }}>
      <h1 style={styles.number}>{0}</h1>
      <p style={styles.label}>New Leads</p>
    </div>
    <div style={{ ...styles.card, backgroundColor: '#9575CD' }}>
      <h1 style={styles.number}>{0}</h1>
      <p style={styles.label}>New Leads</p>
    </div>
    <div style={{ ...styles.card, backgroundColor: '#FFB74D' }}>
      <h1 style={styles.number}>{0}</h1>
      <p style={styles.label}>New Leads</p>
    </div>
  </div>
);

const TableStyled = styled(Table)(({ theme }) => ({
  minWidth: 1200,
  width: '100%', // Ensure table takes full width
}));

const TableContainerStyled = styled(TableContainer)(({ theme }) => ({
  width: '100%', // Ensure container takes full width
}));

const stageOptions = [
  "1st Call",
  "Message",
  "Explanation Call",
  "Doubt Call",
  "1st Follow Up",
  "2nd Follow Up",
  "Converted",
  "Next Month",
  "Not Interested",
];

const DataTable = ({ userOptions }) => {
  const [data, setData] = useState([]);
  const [stages, setStages] = useState({});
  const [assignedUsers, setAssignedUsers] = useState({});
  const [notes, setNotes] = useState({});
  const [editMode, setEditMode] = useState({});
  const [showNotInterested, setShowNotInterested] = useState(false);
  
  // Single date picker for filtering
  const [filterDate, setFilterDate] = useState(null);
  
  // Filter states
  const [fullNameFilter, setFullNameFilter] = useState('');
  const [phoneFilter, setPhoneFilter] = useState('');
  const [emailFilter, setEmailFilter] = useState('');
  const [campaignFilter, setCampaignFilter] = useState('');
  const [stageFilter, setStageFilter] = useState('');
  const [assignedUserFilter, setAssignedUserFilter] = useState('');

  // Pagination state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(45);

  // Calculating total and converted leads
  const totalLeads = data.length;
  const convertedLeads = data.filter(item => stages[item.id] === 'Converted').length;
  
  // Fetch leads from the server
  useEffect(() => {
    const fetchLeads = async () => {
      try {
        const response = await axios.get(FETCH_LEADS);
        const leads = response.data;
        const initialStages = leads.reduce((acc, item) => {
          acc[item.id] = item.stage || '';
          return acc;
        }, {});
        const initialAssignedUsers = leads.reduce((acc, item) => {
          acc[item.id] = item.assigned_user || {};
          return acc;
        }, {});
        const initialNotes = leads.reduce((acc, item) => {
          acc[item.id] = item.notes || '';
          return acc;
        }, {});
        setData(leads);
        setStages(initialStages);
        setAssignedUsers(initialAssignedUsers);
        setNotes(initialNotes);
      } catch (error) {
        console.error('Error fetching leads:', error);
        toast.error('Failed to fetch leads.');
      }
    };

    fetchLeads();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const toggleEditMode = (id) => {
    setEditMode((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const handleSave = async (id) => {
    const item = data.find((d) => d.id === id);

    if (!item) return;

    // Validate required fields
    if (!stages[id] || !assignedUsers[id]?._id || !notes[id]) {
      toast.error('Please fill in all required fields.');
      return;
    }

    const leadData = {
      id: item.id,
      full_name: item.full_name,
      phone_number: item.phone_number,
      email: item.email,
      created_time: item.created_time,
      campaign_id: item.campaign_id,
      stage: stages[id],
      assigned_user: {
        id: assignedUsers[id]?._id,
        name: assignedUsers[id]?.name
      },
      notes: notes[id]
    };

    try {
      await axios.post(CREATE_LEADS, leadData);
      toast.success('Lead updated successfully.');
      toggleEditMode(id);
    } catch (error) {
      toast.error('Failed to update lead.');
      console.error('Error updating lead:', error);
    }
  };

  // Filter leads based on the selected filters and "Not Interested" stage
  const filteredLeads = data.filter(item => {
    const connectDate = new Date(item.created_time);
    const withinDateRange = !filterDate || (
      connectDate.getFullYear() === filterDate.getFullYear() &&
      connectDate.getMonth() === filterDate.getMonth() &&
      connectDate.getDate() === filterDate.getDate()
    );
    
    const matchesFullName = item.full_name.toLowerCase().includes(fullNameFilter.toLowerCase());
    const matchesPhone = item.phone_number.toLowerCase().includes(phoneFilter.toLowerCase());
    const matchesEmail = item.email.toLowerCase().includes(emailFilter.toLowerCase());
    const matchesCampaign = item.campaign_id.toLowerCase().includes(campaignFilter.toLowerCase());
    const matchesStage = !stageFilter || stages[item.id] === stageFilter;
    const matchesAssignedUser = !assignedUserFilter || assignedUsers[item.id]?._id === assignedUserFilter;

    return withinDateRange && matchesFullName && matchesPhone && matchesEmail && matchesCampaign && matchesStage && matchesAssignedUser && (showNotInterested ? stages[item.id] === 'Not Interested' : stages[item.id] !== 'Not Interested');
  });

  const paginatedLeads = filteredLeads.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <>
      <ToastContainer />
      <LeadsOverview totalLeads={totalLeads} convertedLeads={convertedLeads} /> 
      
      <Box mb={2} display="flex" alignItems="center" gap={2}>
        <DatePicker
          selected={filterDate}
          onChange={date => setFilterDate(date)}
          placeholderText="Filter Date"
        />
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setFilterDate(null)}
        >
          Reset Date
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setShowNotInterested(prev => !prev)}
        >
          {showNotInterested ? 'Hide Not Interested Leads' : 'Show Not Interested Leads'}
        </Button>
      </Box>
      
      <TableContainerStyled component={Paper}>
        <div style={{ overflowX: 'auto', width: '100%' }}>
          <TableStyled>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>
                  <TextField
                    label="Full Name"
                    variant="outlined"
                    size="small"
                    value={fullNameFilter}
                    onChange={e => setFullNameFilter(e.target.value)}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    label="Mobile No"
                    variant="outlined"
                    size="small"
                    value={phoneFilter}
                    onChange={e => setPhoneFilter(e.target.value)}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    label="Email"
                    variant="outlined"
                    size="small"
                    value={emailFilter}
                    onChange={e => setEmailFilter(e.target.value)}
                  />
                </TableCell>
                <TableCell>Time</TableCell>
                <TableCell>
                  <TextField
                    label="Campaign"
                    variant="outlined"
                    size="small"
                    value={campaignFilter}
                    onChange={e => setCampaignFilter(e.target.value)}
                  />
                </TableCell>
                <TableCell>
                  <FormControl variant="outlined" size="small" fullWidth>
                    <InputLabel>Stage</InputLabel>
                    <Select
                      value={stageFilter}
                      onChange={e => setStageFilter(e.target.value)}
                      label="Stage"
                    >
                      <MenuItem value="">
                        <em>All Stages</em>
                      </MenuItem>
                      {stageOptions.map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell>
                  <FormControl variant="outlined" size="small" fullWidth>
                    <InputLabel>User</InputLabel>
                    <Select
                      value={assignedUserFilter}
                      onChange={e => setAssignedUserFilter(e.target.value)}
                      label="User"
                    >
                      <MenuItem value="">
                        <em>All Users</em>
                      </MenuItem>
                      {userOptions.map(user => (
                        <MenuItem key={user.id} value={user.id}>
                          {user.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell>Notes</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedLeads.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.id}</TableCell>
                  <TableCell>{row.full_name}</TableCell>
                  <TableCell>{row.phone_number}</TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{new Date(row.created_time).toLocaleString()}</TableCell>
                  <TableCell>{row.campaign_id}</TableCell>
                  <TableCell>
                    {editMode[row.id] ? (
                      <FormControl variant="outlined" size="small" fullWidth>
                        <Select
                          value={stages[row.id] || ''}
                          onChange={(e) => setStages({ ...stages, [row.id]: e.target.value })}
                        >
                          {stageOptions.map((option, index) => (
                            <MenuItem key={index} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : (
                      stages[row.id]
                    )}
                  </TableCell>
                  <TableCell>
                    {editMode[row.id] ? (
                      <FormControl variant="outlined" size="small" fullWidth>
                        <Select
                          value={assignedUsers[row.id]?._id || ''}
                          onChange={(e) => setAssignedUsers({ ...assignedUsers, [row.id]: { _id: e.target.value, name: userOptions.find(user => user.id === e.target.value)?.name } })}
                        >
                          {userOptions.map((user) => (
                            <MenuItem key={user.id} value={user.id}>
                              {user.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : (
                      assignedUsers[row.id]?.name || ''
                    )}
                  </TableCell>
                  <TableCell>
                    {editMode[row.id] ? (
                      <TextField
                        value={notes[row.id]}
                        onChange={(e) => setNotes({ ...notes, [row.id]: e.target.value })}
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                    ) : (
                      notes[row.id] || ''
                    )}
                  </TableCell>
                  <TableCell>
                    {editMode[row.id] ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleSave(row.id)}
                      >
                        Save
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => toggleEditMode(row.id)}
                      >
                        Edit
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </TableStyled>
        </div>
        <TablePagination
          component="div"
          count={filteredLeads.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainerStyled>
    </>
  );
};

export default DataTable;

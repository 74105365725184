import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import "../DashboardPage.css";
import AdminTableComponent from "../AdminDashboard/AdminTableComponent";
import { Calendar, DateObject } from "react-multi-date-picker";
import {
  useMediaQuery,
  IconButton,
  Drawer,
  AppBar,
  Toolbar,
  Badge,
  Select,
  MenuItem,
  Button,
  DialogActions,
  TextField,
  DialogTitle,
  DialogContent,
  Dialog,
  InputLabel,
  FormControl,
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import { useNotificationContext } from "../../utils/NotificationContext";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { Snackbar, Alert } from "@mui/material"; // Import Snackbar and Alert
import {
  DELETE_LEAVE_ID,
  FETCH_LEAVE,
  SUBMIT_LEAVE,
  UPDATE_DECISION,
} from "../../api/leave";
import { GET_ALL_USERS_URL } from "../../api/onBoarding";
import LeftContainer from "../UI/LeftContainer";

function LeaveAdminHandleScreen() {
  const [teamMembers, setTeamMembers] = useState([]);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false); // State for Snackbar
  const [snackbarMessage, setSnackbarMessage] = useState(""); // Message for the Snackbar
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isCreatingLeave, setIsCreatingLeave] = useState(false); // State for creating leave
  const [dialogData, setDialogData] = useState({
    reason: "No Show",
    dates: "",
    selectedUserId: "",
    adminDecision: "Pending",
  });
  const [selectedDates, setSelectedDates] = useState([``]); // New state for selected dates
  const onChange = (dates) => {
    const formattedDates = dates.map((value) => {
      const formattedDate = new Intl.DateTimeFormat("en-US", {
        day: "numeric",
        month: "short",
        year: "numeric",
      }).format(new Date(value.year, value.month - 1, value.day));
      return formattedDate;
    });
    setSelectedDates(formattedDates);
  };
  const deleteLeave = async (id) => {
    try {
      const res = await axios.delete(DELETE_LEAVE_ID(id), {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      setSnackbarMessage(res.data.message);
      setIsSnackbarOpen(true);
      fetchData();
    } catch (error) {
      console.error(error);
    }
  };
  const columns = [
    { field: "name", headerName: "Name", flex: 1 },
    { field: "dates", headerName: "Dates", flex: 1 },
    {
      field: "reason",
      headerName: "Reason",
      flex: 1,
      cellClassName: (params) =>
        params.value === "No Show" ? "no-show-cell" : "",
    },
    {
      field: "decision",
      headerName: "Decision",
      flex: 1,
      cellClassName: (params) =>
        params.value === "Accept" ? "accepted-cell" : "rejected-cell",
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <Select
          value={params.row.decision}
          onChange={(e) =>
            handleStatusChange(params.id, e.target.value, params)
          }
        >
          <MenuItem value="Accept">Accept</MenuItem>
          <MenuItem value="Pending">Pending</MenuItem>
          <MenuItem value="Rejected">Rejected</MenuItem>
        </Select>
      ),
    },
    {
      field: "delete",
      headerName: "Delete",
      width: 120,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="error"
          onClick={() => deleteLeave(params.row._id)}
        >
          Delete
        </Button>
      ),
    },
  ];

  const handleDialogInputChange = (e) => {
    const { name, value } = e.target;
    setDialogData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleCreateNoShowLeave = async () => {
    setIsCreatingLeave(true);
    try {
      const res = await axios.post(
        SUBMIT_LEAVE,
        {
          reason: dialogData.reason,
          dates: selectedDates,
          decision: dialogData.adminDecision,
          userId: dialogData.selectedUserId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      setDialogData({
        reason: "No Show",
        dates: "",
        selectedUserId: "",
        adminDecision: "Pending",
      });

      setIsSnackbarOpen(true);
      setIsDialogOpen(false);
      fetchData();
      setSnackbarMessage("No Show Leave created successfully");
    } catch (error) {
      console.error(error);
    }
  };

  const getRowId = (row) => row._id; // Assuming _id is a unique identifier in your data
  const [data, setData] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(GET_ALL_USERS_URL,{ 
          headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }});
        if (response.status === 200) {
          // Filter users based on admin status and account approval
          const filteredUsers = response.data.filter(
            (user) => user.isAdmin === false && user.isApproved === true
          );

          setTeamMembers(filteredUsers);
        }
      } catch (error) {
        console.error("Error fetching team members", error);
      }
    };

    fetchUserData();
  }, []);

  const handleStatusChange = async (id, newStatus, params) => {
    try {
      const res = await axios.put(UPDATE_DECISION, {
        userId: id,
        decision: newStatus,
      });

      setSnackbarMessage(res.data.message);
      // Open the Snackbar
      setIsSnackbarOpen(true);
      fetchData();
    } catch (error) {
      console.error(error);
    }
    setData((prevData) => {
      const updatedData = [...prevData];
      const index = updatedData.findIndex((row) => getRowId(row) === id);
      if (index !== -1) {
        updatedData[index] = { ...updatedData[index], status: newStatus };
      }
      return updatedData;
    });
  };
  const fetchData = async () => {
    try {
      const res = await axios.get(FETCH_LEAVE);
  
      const sortedData = res.data.data.sort((a, b) => {
        // Assuming 'dates' is always an array and has at least one date
        const dateA = new Date(a.dates[0]);
        const dateB = new Date(b.dates[0]);
  
        // Compare the dates
        return dateB - dateA;
      });
  
      setData(sortedData);
      setIsCreatingLeave(false);
    } catch (error) {
      console.error(error);
    }
  };
  
  // Function to handle logout
  const handleLogout = () => {
    // Remove the token from local storage
    localStorage.removeItem("token");
    localStorage.removeItem("isAdmin");
    // Redirect the user to the login page or any other desired page
    window.location.href = "/login";
  };
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };
  const isMobile = useMediaQuery("(max-width: 768px)");
  return (
    <div className="container">
      {isMobile ? (
        <>
          <AppBar
            position="fixed"
            style={{ background: "transparent", boxShadow: "none" }}
          >
            <Toolbar>
              <IconButton
                color="primary"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
              {/* <LeftContainer handleLogout={handleLogout} /> */}
            </Toolbar>
          </AppBar>
          <Drawer
            variant="temporary"
            open={isDrawerOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {/* ... Other code ... */}
            <LeftContainer handleLogout={handleLogout} />
            {/* ... Other code ... */}
          </Drawer>
        </>
      ) : (
        <>
          <LeftContainer handleLogout={handleLogout} />
          {/* ... Other code ... */}
        </>
      )}

      <div className="right-container">
        <div className="right-container-top">
          <p>Welcome, Admin!</p>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenDialog}
          >
            Create No Show Leave
          </Button>
        </div>
        <h2>Leaves</h2>
        <div className="right-container-table" style={{ marginTop: "50px" }}>
          <div style={{ height: 500, width: "100%" }}>
            <DataGrid
              rows={data}
              columns={columns}
              pageSize={10}
              getRowId={getRowId}
            />
          </div>
        </div>
      </div>
      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle style={{ marginBottom: "20px" }}>
          Create No Show Leave
        </DialogTitle>
        <DialogContent>
          {/* Your existing content */}
          {/* <TextField
      name="reason"
      label="Reason"
      variant="outlined"
      fullWidth
      value={dialogData.reason}
      onChange={handleDialogInputChange}
      margin="normal"
    /> */}
          <div className="calendar-container">
            <Calendar multiple onChange={onChange} />
          </div>
          <FormControl fullWidth variant="outlined" margin="normal">
            <InputLabel>Select User</InputLabel>
            <Select
              name="selectedUserId"
              value={dialogData.selectedUserId}
              onChange={handleDialogInputChange}
              label="Select User"
            >
              {/* Add options for users */}
              {teamMembers.map((member) => (
                <MenuItem key={member._id} value={member._id}>
                  {member.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth variant="outlined" margin="normal">
            <InputLabel>Admin Decision</InputLabel>
            <Select
              name="adminDecision"
              value={dialogData.adminDecision}
              onChange={handleDialogInputChange}
              label="Admin Decision"
            >
              <MenuItem value="Accept">Accept</MenuItem>
              <MenuItem value="Pending">Pending</MenuItem>
              <MenuItem value="Rejected">Rejected</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleCreateNoShowLeave}
            color="primary"
            variant="contained"
            disabled={isCreatingLeave} // Disable the button when isCreatingLeave is true
          >
            {isCreatingLeave ? <span className="loader"></span> : "Create"}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={3000} // Adjust the duration as needed (in milliseconds)
        onClose={() => setIsSnackbarOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert severity="success" onClose={() => setIsSnackbarOpen(false)}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default LeaveAdminHandleScreen;
// AdminDashboardPage.js

import * as React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useRoutes,
} from "react-router-dom";
import axios from "axios";
import { useState, useEffect } from "react";
import { Button, MenuItem, Select } from "@mui/material";
import {
  CHANGE_AFTER_STATUS_URL,
  CHANGE_ASSIGNED_STATUS_URL,
  FETCH_APPLY_COURSE_URL,
} from "../../api/sales";
import { GET_ALL_USERS_URL } from "../../api/user";
import {
  useMediaQuery,
  IconButton,
  Drawer,
  AppBar,
  Toolbar,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MenuIcon from "@mui/icons-material/Menu";
import LeftContainer from "../UI/LeftContainer";
function SalesStatus() {
  const navigate = useNavigate();
  const location = useLocation();
  const statusSales = location.state.statusSales;

  const [purchasedCount, setPurchasedCount] = useState(0);
  const [notPurchasedCount, setNotPurchasedCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [data, setData] = useState();
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [assigned, setAssignedTo] = useState();
  const [status, setStatus] = useState();
  const [id, setId] = useState("");

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const isMobile = useMediaQuery("(max-width: 768px)");

  useEffect(() => {
    getData();
  }, [id]);

  const getData = async () => {
    try {
      const res = await axios.get(FETCH_APPLY_COURSE_URL);

      const add = res.data.data.map((item, index) => ({
        courseId: item._id,
        id: index + 1,
        fullname: item.fName + " " + item.lName,
        date: new Date(item.date),
        description: item.description,
        email: item.email,
        wNumber: item.wNumber,
        year: item.year,
        assignedTo: item.assignedTo,
        afterStatus: item.status,
        userStatus: item.userStatus,
      }));

      // Filter the data based on statusSales
      const filteredData =
        statusSales === "purchased"
          ? add.filter((item) => item.userStatus === "purchased")
          : statusSales === "not purchased"
          ? add.filter((item) => item.userStatus === "not purchased")
          : add;

      const purchasedCount = filteredData.filter(
        (item) => item.userStatus === "purchased"
      ).length;
      const notPurchasedCount = filteredData.filter(
        (item) => item.userStatus === "not purchased"
      ).length;
      const totalCount =
        filteredData.length - purchasedCount - notPurchasedCount;

      setPurchasedCount(purchasedCount);
      setNotPurchasedCount(notPurchasedCount);
      setTotalCount(totalCount);

      setRows(filteredData);

      const response = await axios.get(GET_ALL_USERS_URL);
      if (response.status === 200) {
        const filteredUsers = response.data.filter(
          (user) => user.isAdmin === false && user.isApproved === true
        );

        setData(filteredUsers);

        const col = [
          { field: "fullname", headerName: "Full Name", width: 120 },
          { field: "date", headerName: "Date", width: 150 },
          { field: "description", headerName: "Description", width: 210 },
          { field: "email", headerName: "Email", width: 200 },
          { field: "userStatus", headerName: "User Status", width: 200 },
          { field: "wNumber", headerName: "Number", width: 120 },
          { field: "year", headerName: "Year" },
        ];
        setColumns(col);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Create an array of rows using data

  const [filterModel, setFilterModel] = React.useState({ items: [] });

  const handleFilterModelChange = (model) => {
    setFilterModel(model);
  };

  const handleLogout = () => {
    // Remove the token from local storage
    localStorage.removeItem("token");
    localStorage.removeItem("isAdmin");
    // Redirect the user to the login page or any other desired page
    window.location.href = "/login";
  };

  return (
    <div className="container">
      {isMobile ? (
        <>
          <AppBar
            position="fixed"
            style={{ background: "transparent", boxShadow: "none" }}
          >
            <Toolbar>
              <IconButton
                color="primary"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
              {/* <LeftContainer handleLogout={handleLogout} /> */}
            </Toolbar>
          </AppBar>
          <Drawer
            variant="temporary"
            open={isDrawerOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {/* ... Other code ... */}
            <LeftContainer handleLogout={handleLogout} />
            {/* ... Other code ... */}
          </Drawer>
        </>
      ) : (
        <>
          <LeftContainer handleLogout={handleLogout} />
          {/* ... Other code ... */}
        </>
      )}

      <div className="right-container">
        <div className="right-container-top">
          <IconButton
            onClick={() => {
              navigate("/sales");
            }}
          >
            <ArrowBackIcon
              style={{
                height: "30px",
                width: "80px",
                color: "#6A58C6",
              }}
            />
          </IconButton>
          <p>Welcome, Admin!</p>
        </div>

        <div className="right-container-table" style={{ marginTop: "-30px" }}>
          <h1>{statusSales}</h1>
          <div style={{ height: 600, width: "100%" }}>
            {/* DataGrid with a toolbar */}
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5, 10, 20]}
              checkboxSelection={false}
              filterModel={filterModel}
              disableColumnSelector // Disable column selector
              showCellVerticalBorder={false}
              onFilterModelChange={handleFilterModelChange}
              components={{
                Toolbar: GridToolbar,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SalesStatus;

import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { GET_ALL_USER_ID } from "../../api/user";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AdminDashboardIcon from "@mui/icons-material/SupervisorAccount";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import AssignmentIcon from "@mui/icons-material/Assignment";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import BlockIcon from "@mui/icons-material/Block";
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import ProfileIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import AccessIcon from "@mui/icons-material/AccessTime";
import StarIcon from "@mui/icons-material/Star";
import ChatSupportIcon from "@mui/icons-material/Chat";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import "./leftContainer.css"
const LeftContainer = ({ handleLogout }) => {
  const location = useLocation();
  const currentPath = location.pathname;
  const handleColumnSelect = (column) => {
    setSelectedColumn((prevSelectedColumn) =>
      prevSelectedColumn === column ? null : column
    );
  };
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [onBoardingStatus, setOnBoardingStatus] = useState(null);
  const admin = localStorage.getItem("isAdmin") === "true";
  const SuperAdmin = localStorage.getItem("isSuperAdmin");
  const name = localStorage.getItem("username");
  const [data, setData] = useState([]);
  useEffect(() => {
    const token = localStorage.getItem("token");

    const fetchUserDataById = async () => {
      try {
        const res = await axios.get(GET_ALL_USER_ID, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setData(res.data);
      } catch (error) {
        console.error(error);
      }
    };

    if (token) {
      fetchUserDataById();
    }
  }, []);

  const columns = [
    {
      name: "Access",
      path: "/access",
      icon: <AccessTimeIcon />,
      label: "Access",
      superAdminOnly:SuperAdmin
     
    },
    {
      name: "Overview",
      path: "/overview",
      icon: <AdminDashboardIcon />,
      label: "Overview",
      adminOnly: data.overview,
    },
    {
      name: "Admin Dashboard",
      path: "/adminDashboard",
      icon: <AdminDashboardIcon />,
      label: "Admin Dashboard",
      adminOnly: data.adminDashboard,
    },
    {
      name: "New Dashboard",
      path: "/newDashboard",
      icon: <AdminDashboardIcon />,
      label: "New Dashboard",
      adminOnly: data.adminDashboard,
    },
    {
      name: "Notification",
      path: "/notification",
      icon: <NotificationsIcon />,
      label: "Notification",
      adminOnly: data.notification,
    },
    {
      name: "Lead",
      path: "/lead",
      icon: <LeaderboardIcon />,
      label: "Lead",
      adminOnly: data.lead,
    },
    {
      name: "New Lead",
      path: "/newlead",
      icon: <LeaderboardIcon />,
      label: "New Lead",
      adminOnly: true,
    },
    {
      name: "Sales",
      path: "/sales",
      icon: <AssignmentIcon />,
      label: "Sales",
      adminOnly: data.sales,
    },
    {
      name: "Manage Users",
      path: "/teamsDashboard",
      icon: <EventBusyIcon />,
      label: "Manage Users",
      adminOnly: data.manageUsers,
    },
    {
      name: "Teams",
      path: "/teams",
      icon: <EmojiPeopleIcon />,
      label: "Teams",
      adminOnly: data.teams,
    },
    {
      name: "Target",
      path: "/target-set",
      icon: <CrisisAlertIcon  />,
      label: "Target",
      adminOnly: true,
    },
    {
      name: "OnBoarding",
      path: "/onBoardingAdmin",
      icon: <CheckCircleIcon />,
      label: "OnBoarding",
      adminOnly: data.onBoarding,
    },
    {
      name: "Profile",
      path: "/Adminprofile",
      icon: <AccountCircleIcon />,
      label: "Profile",
      adminOnly: true,
    },
  
    {
      name: "Users",
      path: "/users",
      icon: <EmojiPeopleIcon />,
      label: "Users",
      adminOnly: admin,
    },
    {
      name: "Leave",
      path: "/leaveAdminHandle",
      icon: <EventBusyIcon />,
      label: "Leave",
      adminOnly: data.leave,
    },
    {
      name: "Not Current",
      path: "/past",
      icon: <BlockIcon />,
      label: "Not Current",
      adminOnly: data.notCurrent,
    },
  ];

  return (
    <div className="leftContainer">
    <div className="logo-user">
      <h1>Markoknow</h1>
      <div className="profile-icon">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Link to="/AdminProfile">
            <ProfileIcon style={{ fontSize: 120 }} />
          </Link>
          <div>
            <h4
              style={{
                color: "#fffff",
                textDecoration: "none",
                marginTop: "-1%",
              }}
            >
              {data.name}
            </h4>
            {/* <p
              style={{
                color: "#DAEDE3",
                textDecoration: "none",
                textAlign:"center",
                marginTop: "-5%",
                // border:"1px solid red"
              }}
            >
              {managedTeam}
            </p> */}
          </div>
          <div
            style={{
              border: "2px", // Set border style here
              borderRadius: "10px",
              display: "flex",
              flexDirection: "row",
              padding: "4px", // Add padding if needed,
              paddingLeft: "10px",
              paddingRight: "10px",
              backgroundColor: "white",
              fontSize: "20px",
              display:"flex",
              justifyContent:"center",
              alignItems:"center",
              gap: "8px",
              fontWeight: "500",
              boxShadow: "inherit",
           
              marginTop: "-5%",
              color:"black"
            }}
          >
            <StarIcon
              style={{
                color: "gold",
                fontSize: "22px",
              }}
            />{" "}
          7.5 {/* Display dynamic rating value */}
          </div>
        </div>
      </div>
    </div>
    <div className="divider"></div>
    <div className="icons">
    {columns.map((column) =>
          column.superAdminOnly==="true"? (
            <div key={column.name} className="icon">
              <Link
                to={column.path}
                className={`icon-link ${
                  currentPath === column.path ? "active" : ""
                }`}
                onClick={() => handleColumnSelect(column.name)}
              >
                {column.icon}
                {column.name === "userOnBoarding" &&
                onBoardingStatus === "true" ? (
                  <>
                    <span
                      style={{
                        color: currentPath === column.path ? "green" : "",
                      }}
                    >
                      {column.label}
                    </span>
                    <CheckIcon style={{ color: "green" }} />
                  </>
                ) : column.name === "userOnBoarding" &&
                  onBoardingStatus === "false" ? (
                  <>
                    <span
                      style={{
                        color: currentPath === column.path ? "green" : "",
                      }}
                    >
                      {column.label}
                    </span>
                    <CloseIcon style={{ color: "red" }} />
                  </>
                ) : (
                  <span
                    style={{
                      color: currentPath === column.path ? "green" : "",
                    }}
                  >
                    {column.label}
                  </span>
                )}
              </Link>
            </div>
          ) : null
        )}
        {columns.map((column) =>
          column.adminOnly ? (
            <div key={column.name} className="icon">
              <Link
                to={column.path}
                className={`icon-link ${
                  currentPath === column.path ? "active" : ""
                }`}
                onClick={() => handleColumnSelect(column.name)}
              >
                {column.icon}
                {column.name === "userOnBoarding" &&
                onBoardingStatus === "true" ? (
                  <>
                    <span
                      style={{
                        color: currentPath === column.path ? "green" : "",
                      }}
                    >
                      {column.label}
                    </span>
                    <CheckIcon style={{ color: "green" }} />
                  </>
                ) : column.name === "userOnBoarding" &&
                  onBoardingStatus === "false" ? (
                  <>
                    <span
                      style={{
                        color: currentPath === column.path ? "green" : "",
                      }}
                    >
                      {column.label}
                    </span>
                    <CloseIcon style={{ color: "red" }} />
                  </>
                ) : (
                  <span
                    style={{
                      color: currentPath === column.path ? "green" : "",
                    }}
                  >
                    {column.label}
                  </span>
                )}
              </Link>
            </div>
          ) : null
        )}

        <div
          className="icon"
          onClick={handleLogout}
          style={{ marginBottom: "30px" }}
        >
          <Link to="/" className="icon-link">
            <LogoutIcon />
            Logout
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LeftContainer;

import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import { motion } from "framer-motion";

const StoriesLevel = ({ completedTasks }) => {
  const levels = [
    { level: 1, stories: 25 },
    { level: 2, stories: 50 },
    { level: 3, stories: 100 },
    { level: 4, stories: 200 },
    { level: 4, stories: 300 },
  ];

  const [reachedLevel, setReachedLevel] = useState(null);

  const motivationalQuotes = [
    "Every accomplishment starts with the decision to try.",
  ];

  useEffect(() => {
    const newReachedLevel = levels.reduce((acc, level) => {
      if (completedTasks >= level.stories) {
        return {
          ...level,
          reachedDate: new Date().toLocaleDateString(),
        };
      }
      return acc;
    }, null);

    setReachedLevel(newReachedLevel);
  }, [completedTasks]);

  return (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent:"center",
        // padding: "30px",
        // backgroundColor: "#f0f0f0",
        // borderRadius: "12px",
        // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        // border:"1px solid red",
        width: "100%",
      }}
    >
      <Typography variant="h5" gutterBottom>
        Stories Level
      </Typography>
      <motion.div
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: reachedLevel ? "#027148" : "#e0e0e0",
          
          padding: "20px",
          borderRadius: "8px", 
          width: "90%",
      
        }}
      >
        <motion.span
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3, duration: 0.5 }}
          style={{ fontSize: "28px", fontWeight: "bold", color:reachedLevel ? "#fff":"#333", }}
        >
          {reachedLevel ? `Level ${reachedLevel.level}` : "Level - 0"}
        </motion.span>
        <motion.span
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 0.5 }}
          style={{
            fontSize: "16px",
            color:reachedLevel ? "#fff":"#666",
            marginTop: "10px",
            textAlign: "center",
          }}
        >
          {reachedLevel
            ? `Achieved on ${reachedLevel.reachedDate}`
            : motivationalQuotes[
                Math.floor(Math.random() * motivationalQuotes.length)
              ]}
        </motion.span>
      </motion.div>
    </motion.div>
  );
};

export default StoriesLevel;

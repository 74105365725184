import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Modal from 'react-modal';
import 'react-toastify/dist/ReactToastify.css';
import {
  GET_ALL_TEAMS_URL,
  GET_ALL_USERS_URL,
  CREATE_TARGET_URL,
  GET_ALL_TARGETS_URL,
  UPDATE_TARGET_URL,
  DELETE_TARGET_URL
} from "../../api/user";

const TargetComponent = () => {
  const [targetFigure, setTargetFigure] = useState('');
  const [targetDuration, setTargetDuration] = useState('This Month');
  const [selectedUser, setSelectedUser] = useState('');
  const [selectedTeam, setSelectedTeam] = useState('');
  const [selectionType, setSelectionType] = useState('');
  const [userList, setUserList] = useState([]);
  const [teamList, setTeamList] = useState([]);
  const [formData, setFormData] = useState({
    targetFigure: "",
    name: "",
    targetDetails: "",
    team: "",
    targetDuration: "",
  });
  const [targetList, setTargetList] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [editingTarget, setEditingTarget] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    fetchUser();
    fetchTeams();
  }, []);

  const fetchUser = async () => {
    try {
      const storedToken = localStorage.getItem("token");
      const response = await axios.get(GET_ALL_USERS_URL, {
        headers: {
          Authorization: `Bearer ${storedToken}`
        }
      });
      if (response.status === 200) {
        const filteredUsers = response.data.filter(
          (user) =>
            !user.isAdmin &&
            user.isApproved &&
            !user.isDeactivated
        );
        
        // console.log(response.data);
        setUserList(filteredUsers);
      }
    } catch (error) {
      console.error("Error fetching users", error);
      toast.error("Failed to fetch users. Please try again later.");
    }
  };

  const fetchTeams = async () => {
    try {
      const storedToken = localStorage.getItem("token");
      const response = await axios.get(GET_ALL_TEAMS_URL, {
        headers: {
          Authorization: `Bearer ${storedToken}`
        }
      });
      if (response.status === 200) {
        setTeamList(response.data);
      }
    } catch (error) {
      console.error("Error fetching teams", error);
      toast.error("Failed to fetch teams. Please try again later.");
    }
  };

  // const [userTargets, setUserTargets] = useState([]);
  // const [teamTargets, setTeamTargets] = useState([]);
  
  // const fetchTargets = async () => {
  //   try {
  //     const storedToken = localStorage.getItem("token");
  //     const response = await fetch(GET_ALL_TARGETS_URL, {
  //       headers: {
  //         Authorization: `Bearer ${storedToken}`
  //       }
  //     });
  //     const targetsData = await response.json();

  //     if (response.status === 200) {
  //       const allTargets = targetsData.data;
  //       const userTargets = allTargets.filter(target => target.assignedTo);
  //       const teamTargets = allTargets.filter(target => target.selectedTeam);
  
  //       setUserTargets(userTargets);
  //       setTeamTargets(teamTargets);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching targets", error);
  //     toast.error("Failed to fetch targets. Please try again later.");
  //   }
  // };
  

  const handleInputChangeCreate = (event) => {
    const { name, value } = event.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleUserSelect = (event) => {
    const selectedUserId = event.target.value;
    setSelectedUser(selectedUserId);

    setFormData((prevData) => ({
      ...prevData,
      name: selectedUserId,
    }));
  };

  const handleTeamSelect = (event) => {
    const selectedTeamId = event.target.value;
    setSelectedTeam(selectedTeamId);

    setFormData((prevData) => ({
      ...prevData,
      team: selectedTeamId,
    }));
  };

  const handleSelectionTypeChange = (event) => {
    setSelectionType(event.target.value);
    setSelectedUser('');
    setSelectedTeam('');
    setFormData((prevData) => ({
      ...prevData,
      name: '',
      team: ''
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const storedToken = localStorage.getItem("token");
      const response = await axios.post(CREATE_TARGET_URL, {
        ...formData,
        targetFigure: targetFigure,
        targetDuration: targetDuration
      }, {
        headers: {
          Authorization: `Bearer ${storedToken}`
        }
      });
      if (response.status === 201) {
        toast.success("Target created successfully!");
        setFormData({
          targetFigure: "",
          name: "",
          targetDetails: "",
          team: "",
          targetDuration: "This Month",
        });
        setTargetFigure('');
        setTargetDuration('This Month');
        setSelectedUser('');
        setSelectedTeam('');
        setSelectionType('');
        // fetchTargets(); // Fetch updated targets list
      }
    } catch (error) {
      console.error("Error creating target", error);
      toast.error("Failed to create target. Please try again later.");
    }
  };

  // const [editingTargetId, setEditingTargetId] = useState(null); // Track which target is being edited
  
  // const handleEdit = (target) => {
  //   setEditingTarget(target);
  //   setFormData({
  //     targetFigure: target.targetFigure,
  //     targetDetails: target.targetDescription,
  //     targetDuration: target.targetDuration,
  //     team: target.selectedTeam ? target.selectedTeam : '',
  //     name: target.assignedTo ? target.assignedTo : '',
  //   });
  //   setSelectionType(target.selectedTeam ? 'team' : 'user');
  //   setEditMode(true);
  //   setModalIsOpen(false); // Close the all targets modal
  // };
  
  // const handleUpdate = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const storedToken = localStorage.getItem("token");
  //     const response = await axios.put(`${UPDATE_TARGET_URL}/${editingTarget._id}`, formData, {
  //       headers: {
  //         Authorization: `Bearer ${storedToken}`
  //       }
  //     });
  //     if (response.status === 200) {
  //       toast.success("Target updated successfully!");
  //       setEditMode(false);
  //       setEditingTarget(null);
  //       setFormData({
  //         targetFigure: "",
  //         name: "",
  //         targetDetails: "",
  //         team: "",
  //         targetDuration: "This Month",
  //       });
  //       fetchTargets(); // Fetch updated targets list
  //     }
  //   } catch (error) {
  //     console.error("Error updating target", error);
  //     toast.error("Failed to update target. Please try again later.");
  //   }
  // };


  return (
    <div className="target-form">
      <div className="target-form__header">
        <h2 className="target-form__title">Target Set Form</h2>
        <Link to="/alltarget" className="li-link"> 
        <button className="target-form__button"style={{position: 'absolute', top: '10px', right: '10px'}}>
          All Targets
        </button>
        </Link>
       
      </div>
      <form className="target-form__body" onSubmit={handleSubmit}>
        <div className="target-form__group">
          <label className="target-form__label">Target Figure</label>
          <input
            className="target-form__input"
            type="number"
            value={targetFigure}
            name="targetFigure"
            onChange={(e) => setTargetFigure(e.target.value)}
            required
          />
        </div>
        <div className="target-form__group">
          <label className="target-form__label">Target Description</label>
          <textarea
            className="target-form__textarea"
            value={formData.targetDetails}
            name="targetDetails"
            onChange={handleInputChangeCreate}
            required
          ></textarea>
        </div>
        <div className="target-form__group">
          <label className="target-form__label">Target Duration</label>
          <select
            className="target-form__select"
            value={targetDuration}
            name="targetDuration"
            onChange={(e) => setTargetDuration(e.target.value)}
          >
            <option value="This Month">This Month</option>
            <option value="This Week">This Week</option>
            <option value="This Year">This Year</option>
          </select>
        </div>
        <div className="target-form__group">
          <label className="target-form__label">Select Type</label>
          <select className="target-form__select" value={selectionType} onChange={handleSelectionTypeChange}>
            <option value="">Select Type</option>
            <option value="user">User</option>
            <option value="team">Team</option>
          </select>
        </div>
        {selectionType === 'user' && (
          <div className="target-form__group">
            <label className="target-form__label">Select User</label>
            <select className="target-form__select" value={selectedUser} onChange={handleUserSelect}>
              <option value="">Select User</option>
              {userList.map((user) => (
                <option key={user._id} value={user._id}>
                  {user.name}
                </option>
              ))}
            </select>
          </div>
        )}
        {selectionType === 'team' && (
          <div className="target-form__group">
            <label className="target-form__label">Select Team</label>
            <select className="target-form__select" value={selectedTeam} onChange={handleTeamSelect}>
              <option value="">Select Team</option>
              {teamList.map((team) => (
                <option key={team._id} value={team._id}>
                  {team.name}
                </option>
              ))}
            </select>
          </div>
        )}
        <button className="target-form__button" type="submit">
          {editMode ? 'Update Target' : 'Create Target'}
        </button>
      </form>
   

{/* <Modal
  isOpen={editMode}
  onRequestClose={() => setEditMode(false)}
  contentLabel="Edit Target"
  className="edit-modal"
  overlayClassName="edit-modal-overlay"
>
  <div className="edit-modal__content">
    <h2>Edit Target</h2>
    <button onClick={() => setEditMode(false)} className="edit-modal__close-button">&times;</button>
    <form className="edit-modal__form" onSubmit={handleUpdate}>
      <div className="edit-modal__group">
        <label className="edit-modal__label">Target Figure</label>
        <input
          className="edit-modal__input"
          type="number"
          value={formData.targetFigure}
          name="targetFigure"
          onChange={handleInputChangeCreate}
          required
        />
      </div>
      <div className="edit-modal__group">
        <label className="edit-modal__label">Target Description</label>
        <textarea
          className="edit-modal__textarea"
          value={formData.targetDetails}
          name="targetDetails"
          onChange={handleInputChangeCreate}
          required
        ></textarea>
      </div>
      <div className="edit-modal__group">
        <label className="edit-modal__label">Target Duration</label>
        <select
          className="edit-modal__select"
          value={formData.targetDuration}
          name="targetDuration"
          onChange={handleInputChangeCreate}
        >
          <option value="This Month">This Month</option>
          <option value="This Week">This Week</option>
          <option value="This Year">This Year</option>
        </select>
      </div>
      <div className="edit-modal__group">
        <label className="edit-modal__label">Select Type</label>
        <select className="edit-modal__select" value={selectionType} onChange={handleSelectionTypeChange}>
          <option value="">Select Type</option>
          <option value="user">User</option>
          <option value="team">Team</option>
        </select>
      </div>
      {selectionType === 'user' && (
        <div className="edit-modal__group">
          <label className="edit-modal__label">Select User</label>
          <select className="edit-modal__select" value={formData.name} onChange={handleUserSelect}>
            <option value="">Select User</option>
            {userList.map((user) => (
              <option key={user._id} value={user._id}>
                {user.name}
              </option>
            ))}
          </select>
        </div>
      )}
      {selectionType === 'team' && (
        <div className="edit-modal__group">
          <label className="edit-modal__label">Select Team</label>
          <select className="edit-modal__select" value={formData.team} onChange={handleTeamSelect}>
            <option value="">Select Team</option>
            {teamList.map((team) => (
              <option key={team._id} value={team._id}>
                {team.name}
              </option>
            ))}
          </select>
        </div>
      )}
      <button className="edit-modal__button" type="submit">Update Target</button>
    </form>
  </div>
</Modal> */}


      <ToastContainer />
    </div>
  );
};

export default TargetComponent;

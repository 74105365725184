import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  useMediaQuery,
  CircularProgress,
  Typography,
  Container,
} from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import MenuIcon from '@mui/icons-material/Menu';
import LeftContainer from './UI/LeftContainer';
import { GET_ALL_USERS_URL } from '../../src/api/user';
import { SAVE_LEAD_DATA_URL } from '../../src/api/lead';
import DataTable from './DataTable'; // Assuming DataTable is in the same directory

const PageContainer = styled('div')({
  display: 'flex',
  width: '100%',
  height: '100%',
  overflow: 'hidden',
});

const ContainerStyled = styled('div')(({ theme }) => ({
  backgroundColor: '#DAEDE3',
  padding: theme.spacing(3),
  flex: 1,
  // height: '100%',
  display: 'flex',
  flexDirection: 'column',
  width: '100%', // Ensure the container takes full width
}));

const LoaderContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexGrow: 1,
  
});

const ZoomOutWrapper = styled('div')({
  transform: 'scale(0.98)',
  transformOrigin: '0 0',
  width: '102%',
  
  // height: '100%',
});

const NewLead = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [stages, setStages] = useState({});
  const [assignedUsers, setAssignedUsers] = useState({});
  const [notes, setNotes] = useState({});
  const [userOptions, setUserOptions] = useState([]);

  const isMobile = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          'https://script.google.com/macros/s/AKfycbw9fC4TttrX4_l33U2uXp7eLRYKZ_y3KCUqrDTjid4Tk88vPbG0G8-YHapStGM-seLYuw/exec'
        );

        const initialStages = response.data.data.reduce((acc, item) => {
          acc[item.id] = '';
          return acc;
        }, {});

        const initialAssignedUsers = response.data.data.reduce((acc, item) => {
          acc[item.id] = '';
          return acc;
        }, {});

        const initialNotes = response.data.data.reduce((acc, item) => {
          acc[item.id] = '';
          return acc;
        }, {});

        setData(response.data.data);
        setStages(initialStages);
        setAssignedUsers(initialAssignedUsers);
        setNotes(initialNotes);
        setLoading(false);

        // Prepare the payload
        const leads = response.data.data.map(item => ({
          id: item.id,
          full_name: item.full_name,
          phone_number: item.phone_number || 'default_phone_number', // Ensure phone_number is not empty
          email: item.email,
          created_time: item.created_time || new Date(), // Ensure created_time is a valid date
          campaign_id: item.campaign_id || 'default_campaign_id', // Ensure campaign_id is not empty
          stage: '',
          assigned_user: { id: null, name: '' }, // Ensure assigned_user.id is null if empty
          notes: ''
        }));

        // console.log('Payload:', leads);

        // Send the payload to the backend
        const saveResponse = await axios.post(SAVE_LEAD_DATA_URL, { leads });
        // console.log('Save response:', saveResponse.data);

      } catch (error) {
        console.error('Error fetching data: ', error);
        setLoading(false);
      }
    };

    fetchData();
    fetchUser();
  }, []);

  const fetchUser = async () => {
    try {
      const storedToken = await localStorage.getItem('token');
      const response = await axios.get(GET_ALL_USERS_URL, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      if (response.status === 200) {
        const filteredUsers = response.data.filter(
          (user) =>
            user.isAdmin === false &&
            user.isApproved === true &&
            user.isDeactivated === false
        );
        setUserOptions(filteredUsers);
        // console.log(userOptions)
      }
    } catch (error) {
      console.error('Error fetching users: ', error);
    }
  };

  const handleStageChange = (id, newStage) => {
    setStages((prevStages) => ({
      ...prevStages,
      [id]: newStage,
    }));
  };

  const handleUserChange = (id, user) => {
    setAssignedUsers((prevAssignedUsers) => ({
      ...prevAssignedUsers,
      [id]: user,
    }));
  };

  const handleNotesChange = (id, newNote) => {
    setNotes((prevNotes) => ({
      ...prevNotes,
      [id]: newNote,
    }));
  };

  const handleSubmit = (id) => {
    const rowData = {
      id,
      stage: stages[id],
      assignedUser: assignedUsers[id] || 'Not Assigned',
      note: notes[id],
    };

    // console.log('Submitting data:', rowData);
    // Add your submission logic here (e.g., make an API call)
  };

  return (
    <ZoomOutWrapper>
      <PageContainer>
        {isMobile ? (
          <>
            <AppBar
              position="fixed"
              style={{ background: 'transparent', boxShadow: 'none' }}
            >
              <Toolbar>
                <IconButton
                  color="primary"
                  aria-label="open drawer"
                  onClick={() => setIsDrawerOpen(!isDrawerOpen)}
                  edge="start"
                >
                  <MenuIcon />
                </IconButton>
                <Typography variant="h6">Welcome, Admin!</Typography>
              </Toolbar>
            </AppBar>
            <Drawer
              variant="temporary"
              open={isDrawerOpen}
              onClose={() => setIsDrawerOpen(false)}
              ModalProps={{ keepMounted: true }}
            >
              <LeftContainer />
            </Drawer>
          </>
        ) : (
          <LeftContainer />
        )}

        <ContainerStyled>
          {loading ? (
            <LoaderContainer>
              <CircularProgress />
            </LoaderContainer>
          ) : (
            <Container style={{ flex: 1, padding: 0, margin: 0}}>
              <Typography variant="h4" gutterBottom>
                Data Table
              </Typography>
              <DataTable
                data={data}
                stages={stages}
                assignedUsers={assignedUsers}
                notes={notes}
                handleStageChange={handleStageChange}
                handleUserChange={handleUserChange}
                handleNotesChange={handleNotesChange}
                userOptions={userOptions}
                handleSubmit={handleSubmit}
                style={{ width: '100%' }} // Ensure the table takes full width
              />
            </Container>
          )}
        </ContainerStyled>
      </PageContainer>
    </ZoomOutWrapper>
  );
};

export default NewLead;

// TaskCalendarComponent.js
import React from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import CheckIcon from "@mui/icons-material/Check";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const TaskCalendarComponent = ({ taskData }) => {
  const events = taskData.map((task) => ({
    title: task.taskDetails,
    start: new Date(task.completedDate), // Adjust as needed
    end: new Date(task.completedDate), // Adjust as needed
    status: task.status,
  }));

  const localizer = momentLocalizer(moment);

  const EventComponent = ({ event }) => {
    return (
      <div>
        {event.status === "Completed and Validated" ? (
          <CheckIcon style={{ color: "green" }} />
        ) : event.status === "Assigned" ? (
          <ErrorOutlineIcon style={{ color: "red" }} />
        ) : (
          <></>
        )}
      </div>
    );
  };

  return (
    <div>
      <div style={{ height: "320px", width: "100%", }}>
        
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          titleAccessor="title"
          style={{ backgroundColor: "white" }}
          components={{
            event: EventComponent,
          }}
        />
      </div>
    </div>
  );
};

export default TaskCalendarComponent;

import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
  Grid,
  Snackbar,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  useMediaQuery,
} from "@mui/material";
import {
  CHANGE_PASSWORD_ADMIN_URL,
  CHANGE_PASSWORD_URL,
  FETCH_USER_DATA_URL,
  UPDATE_PROFILE_URL,
} from "../../api/profile";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const ProfilePage = () => {
  const [isChangePasswordDialogOpen, setChangePasswordDialogOpen] =
    useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [token, setToken] = useState("");
  const [admin, setAdmin] = useState("false");
  const [data, setData] = useState([]);
  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [college, setCollege] = useState("");
  const [address, setAddress] = useState("");
  const [update, setUpdate] = useState(false);
  const [doj, setDoj] = useState("");
  const [doi, setDoi] = useState("");
  const [poj, setPoj] = useState("");
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false); // State for Snackbar
  const [snackbarMessage, setSnackbarMessage] = useState(""); // Message for the Snackbar

  // State variables for validation errors
  const [nameError, setNameError] = useState("");
  const [collegeError, setCollegeError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [positionOfJoiningError, setPositionOfJoiningError] = useState("");
  const [currentPasswordError, setCurrentPasswordError] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  useEffect(() => {
    fetchToken();
  }, []);

  // Fetch token from local storage
  const fetchToken = async () => {
    try {
      const storedToken = localStorage.getItem("token");
      const storedId = localStorage.getItem("Name");
      const storedAdmin = localStorage.getItem("isAdmin");
      setAdmin(storedAdmin);
      setId(storedId);
      setToken(storedToken);
    } catch (error) {
      console.error("Error fetching token: ", error);
    }
  };

  useEffect(() => {
    if (token) {
      fetchData();
    }
  }, [token]);

  // Fetch user data from the server
  const fetchData = async () => {
    try {
      const fetchUserDataUrl = FETCH_USER_DATA_URL(admin, id);

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.get(fetchUserDataUrl, {
        headers,
      });

      if (response.status === 200) {
        setData(response.data);
        setName(response.data.name);
        setAddress(response.data.address);
        setCollege(response.data.college);
        setDoi(response.data.durationOfInternship);
        setPoj(response.data.positionOfJoining);
        setDoj(response.data.dateOfJoining);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const profileUpdate = async (e) => {
    e.preventDefault();
    try {
      const storedToken = await localStorage.getItem("token");
      const updateProfileUrl = UPDATE_PROFILE_URL(admin === "true", id);
      const res = await axios.put(
        updateProfileUrl,
        admin === "true" ? { poj, doi, doj } : { name, college, address },
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      if (res.data.status) {
        setSnackbarMessage("Profile Updated successfully ");

        // Open the Snackbar
        setIsSnackbarOpen(true);
      }
    } catch (error) {
      if (error.response.status === 400) {
        // If the error is a validation error, handle multiple validation errors
        if (error.response.data && error.response.data.details) {
          const validationErrors = Object.values(
            error.response.data.details
          ).flat();
          setSnackbarMessage(
            `Validation error: ${validationErrors.join(", ")}`
          );
        } else {
          // If no details provided, show a generic error message
          setSnackbarMessage("Validation error: Something went wrong.");
        }
        // Open the Snackbar
        setIsSnackbarOpen(true);
      }
      console.error("Error in profile update:", error);
    }
  };

  // Change password handler
  const handleChangePassword = async (e) => {
    e.preventDefault();
    if (!validatePasswordFields()) {
      // If there are validation errors, don't proceed with changing the password
      setMessage(
        "Please fix the password errors before changing your password."
      );
      return;
    }
    if (newPassword !== confirmPassword) {
      setMessage("Passwords do not match.");
      return;
    }

    try {
      const response = await axios.post(CHANGE_PASSWORD_ADMIN_URL, {
        userId: data._id,
        newPassword: newPassword,
      });

      if (response.status === 200) {
        setMessage("Password changed successfully.");
        setCurrentPassword("");
        setNewPassword("");
        setConfirmPassword("");
        setChangePasswordDialogOpen(false);
      } else {
        setMessage("Error changing password.");
      }
    } catch (error) {
      setMessage("Error changing password.");
      console.error("Error:", error);
    }
  };

  // Handle close of change password dialog
  const handleCloseChangePasswordDialog = () => {
    setChangePasswordDialogOpen(false);
    setCurrentPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setMessage("");
  };

  // Function to handle changes in password fields
  const handlePasswordChange = (e, field) => {
    const value = e.target.value;
    switch (field) {
      case "currentPassword":
        setCurrentPassword(value);
        if (value.trim() === "") {
          setCurrentPasswordError("Current Password is required");
        } else {
          setCurrentPasswordError("");
        }
        break;
      case "newPassword":
        setNewPassword(value);
        if (value.length < 8) {
          setNewPasswordError("New Password must be at least 8 characters");
        } else {
          setNewPasswordError("");
        }
        break;
      case "confirmPassword":
        setConfirmPassword(value);
        if (value !== newPassword) {
          setConfirmPasswordError("Passwords do not match");
        } else {
          setConfirmPasswordError("");
        }
        break;
      default:
        break;
    }
  };
  const isMobile = useMediaQuery("(max-width: 768px)");
  // Function to validate password fields
  const validatePasswordFields = () => {
    if (currentPasswordError || newPasswordError || confirmPasswordError) {
      return false;
    }
    return true;
  };

  // Handle changes in input fields
  const handleChange = (e, field) => {
    const value = e.target.value;
    switch (field) {
      case "name":
        setName(value);
        if (!value) {
          setNameError("Name is required");
        } else {
          setNameError("");
        }
        break;
      case "college":
        setCollege(value);
        if (value.length < 4 || value.length > 30) {
          setCollegeError("College must be between 4 and 30 characters");
        } else {
          setCollegeError("");
        }
        break;
      case "address":
        setAddress(value);
        if (value.length < 4 || value.length > 100) {
          setAddressError("Address must be between 4 and 100 characters");
        } else {
          setAddressError("");
        }
        break;
      case "positionOfJoining":
        setPoj(value);
        if (value.length < 4 || value.length > 30) {
          setPositionOfJoiningError(
            "Position of Joining must be between 4 and 30 characters"
          );
        } else {
          setPositionOfJoiningError("");
        }
        break;
      // Add validation logic for other fields as needed
      default:
        break;
    }
  };

  // Toggle update mode
  const toggle = async (e) => {
    if (update) {
      if (validateFields()) {
        setUpdate(!update);
        await profileUpdate(e);
      }
    } else {
      setUpdate(!update);
    }
  };

  // Validate fields before update
  const validateFields = () => {
    if (nameError || collegeError || addressError || positionOfJoiningError) {
      return false;
    }
    return true;
  };

  return (
    <>
      <div>
        <h2>User Profile</h2>
        <div style={{
          marginBottom:"20%"
        }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5">User Information</Typography>
            </Grid>
            <Grid item xs={12}>
              <TableContainer component={Paper} style={{
                borderRadius:"20px",
                boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)",
                width:isMobile?"100%":"55%"
              }}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>Name:</TableCell>
                      <TableCell>
                        {update && admin === "false" ? (
                          <TextField
                            type="text"
                            label="Name"
                            value={name}
                            onChange={(e) => handleChange(e, "name")}
                            required
                          />
                        ) : (
                          <Typography variant="body1">{name}</Typography>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Email:</TableCell>
                      <TableCell>{data.email}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>College:</TableCell>
                      <TableCell>
                        {update && admin === "false" ? (
                          <TextField
                            type="text"
                            label="College"
                            value={college}
                            onChange={(e) => handleChange(e, "college")}
                            required
                            error={!!collegeError}
                            helperText={collegeError}
                          />
                        ) : (
                          <Typography variant="body1">{college}</Typography>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Position of Joining:</TableCell>
                      <TableCell>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                          {update && admin === "true" ? (
                            <TextField
                              type="text"
                              label="Position of Joining"
                              value={poj}
                              onChange={(e) => handleChange(e, "positionOfJoining")}
                              required
                              error={!!positionOfJoiningError}
                              helperText={positionOfJoiningError}
                              style={{
                                padding: "0px",
                                marginTop: 20,
                                width: "50%",
                              }}
                            />
                          ) : (
                            <Typography variant="body1">{poj}</Typography>
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Date of Joining:</TableCell>
                      <TableCell>
                        <Typography variant="body1">
                          {new Date(doj).toDateString()}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Duration of Internship:</TableCell>
                      <TableCell>
                        <Typography variant="body1">{doi} months</Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Address:</TableCell>
                      <TableCell>
                        {update && admin === "false" ? (
                          <TextField
                            type="text"
                            label="Address"
                            value={address}
                            onChange={(e) => handleChange(e, "address")}
                            required
                            error={!!addressError}
                            helperText={addressError}
                          />
                        ) : (
                          <Typography variant="body1">{address}</Typography>
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          {admin === "true" ? null : (
            <>
              <Button
                variant="contained"
                color="primary"
                style={{ margin: "16px" }}
                onClick={() => setChangePasswordDialogOpen(true)}
              >
                Change Password
              </Button>
            </>
          )}
          <Button
            variant="contained"
            color="primary"
            style={{ margin: "16px" }}
            onClick={toggle}
          >
            {update ? "Update" : "Edit"}
          </Button>
        </div>

        <Dialog
          open={isChangePasswordDialogOpen}
          onClose={handleCloseChangePasswordDialog}
          aria-labelledby="change-password-dialog-title"
        >
          <DialogTitle id="change-password-dialog-title">
            Change Password
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              To change your password, please enter your current password, new
              password, and confirm the new password.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              label="Current Password"
              type="password"
              fullWidth
              value={currentPassword}
              onChange={(e) => handlePasswordChange(e, "currentPassword")}
              error={!!currentPasswordError}
              helperText={currentPasswordError}
            />
            <TextField
              margin="dense"
              label="New Password"
              type="password"
              fullWidth
              value={newPassword}
              onChange={(e) => handlePasswordChange(e, "newPassword")}
              error={!!newPasswordError}
              helperText={newPasswordError}
            />
            <TextField
              margin="dense"
              label="Confirm New Password"
              type="password"
              fullWidth
              value={confirmPassword}
              onChange={(e) => handlePasswordChange(e, "confirmPassword")}
              error={!!confirmPasswordError}
              helperText={confirmPasswordError}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseChangePasswordDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={handleChangePassword} color="primary">
              Change Password
            </Button>
          </DialogActions>
          <DialogContent>
            <p>{message}</p>
          </DialogContent>
        </Dialog>
      </div>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={3000}
        onClose={() => setIsSnackbarOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          severity={
            snackbarMessage.includes("successfully") ? "success" : "error"
          }
          onClose={() => setIsSnackbarOpen(false)}
        >
          {snackbarMessage.includes("successfully") ? (
            <>{snackbarMessage}</>
          ) : (
            <>{snackbarMessage}</>
          )}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ProfilePage;

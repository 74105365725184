import React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import axios from "axios";
import { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { FETCH_APPLY_COURSE_ID_URL } from "../../api/lead";
import { CHANGE_AFTER_STATUS_URL } from "../../api/sales";
import {
  useMediaQuery,
  IconButton,
  Drawer,
  AppBar,
  Toolbar,
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { GET_ALL_Chat_Data_Id, REPLY_CHAT_ID } from "../../api/chatSupport";
function ReplyChat() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [onBoardingStatus, setOnBoardingStatus] = useState(false);

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [id, setId] = useState("");
  const [admin, setAdmin] = useState("false");
  const [manager, setManager] = useState("");
  const [status, setStatus] = useState("");
  useEffect(() => {
    getData();
  }, [id]);

  const handleOpenDialog = (id, message, reply) => {
    setChatId(id);
    setSelectedMessage(message);
    setReply(reply);
    setDialogOpen(true);
  };

  // Function to handle closing the dialog
  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedMessage("");
    setReply("");
  };
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState("");
  const [reply, setReply] = useState("");
  const [chatId, setChatId] = useState("");
  // Function to handle submitting the reply
  const handleDialogSubmit = async () => {
    const token = localStorage.getItem("token");
    // You can implement the logic to submit the reply here
    try {
      const res = await axios.post(
        REPLY_CHAT_ID(chatId),
        {
          reply: reply,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      await getData();
    } catch (error) {
      console.error(error);
    }
    console.log("Submitted reply:", reply);
    handleCloseDialog();
  };
  const getData = async () => {
    const id = localStorage.getItem("id");
    const verified = await localStorage.getItem("verified");
    setOnBoardingStatus(verified);
    const storedAdmin = await localStorage.getItem("isAdmin");
    const storedManager = await localStorage.getItem("isManager");
    setId(id);
    setAdmin(storedAdmin);
    setManager(storedManager);
    try {
      const getData1 = GET_ALL_Chat_Data_Id(id);

      const res = await axios.get(getData1);
      const add = await res.data.data.map((item, index) => ({
        chatId: item._id,
        id: index + 1,
        username: item.username,
        date: item.date,
        time: item.time,
        message: item.message,
        email: item.email,
        assignedTo: item.assignedTo,
        afterStatus: item.status,
        userStatus: item.userStatus,
        reply: item.reply,
      }));
      setRows(add);

      const col = [
        { field: "id", headerName: "Id", width: 50 },
        { field: "username", headerName: "UserName", width: 120 },
        { field: "date", headerName: "Date", width: 150 },
        { field: "time", headerName: "Time", width: 150 },
        {
          field: "message",
          headerName: "Message",
          width: 210,
          renderCell: (params) => (
            <>
              <Button
                variant="outlined"
                size="small"
                onClick={() =>
                  handleOpenDialog(
                    params.row.chatId,
                    params.value,
                    params.row.reply
                  )
                }
                style={{ marginLeft: 8 }}
              >
                View Message
              </Button>
            </>
          ),
        },
        { field: "email", headerName: "Email", width: 200 },
        {
          field: "afterStatus",
          headerName: "Status",
          width: 150,
          renderCell: (params) => {
            const rowIndex = params.row.id - 1; // Adjusting the index based on the id

            let statusColor = "";

            // Check the afterStatus value and set the color accordingly
            switch (params.value) {
              case "Send":
                statusColor = "blue";
                break;
              default:
                statusColor = "red";
                break;
            }

            return (
              <Button
                variant="outlined"
                size="small"
                style={{ backgroundColor: statusColor, color: "white" }}
              >
                {params.value}
              </Button>
            );
          },
        },
      ];
      setColumns(col);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [filterModel, setFilterModel] = React.useState({ items: [] });

  const handleFilterModelChange = (model) => {
    setFilterModel(model);
  };
  const handleLogout = () => {
    // Remove the token from local storage
    localStorage.removeItem("token");
    localStorage.removeItem("isAdmin");
    localStorage.removeItem("verified");
    // localStorage.removeItem("id");
    // Redirect the user to the login page or any other desired page
    window.location.href = "/login";
  };

  const calculateStatusCounts = () => {
    let assignedCount = 0;
    let points = 0;
    let completedCount = 0;

    rows.forEach((task) => {
      if (task.status === "Assigned") {
        assignedCount++;
      } else {
        completedCount++;
        points = completedCount * 50;
      }
    });

    return { assignedCount, points, completedCount };
  };

  const { assignedCount, points, completedCount } = calculateStatusCounts();
  return (
    <div className="container">
      {isMobile ? (
        <>
          <AppBar
            position="fixed"
            style={{ background: "transparent", boxShadow: "none" }}
          >
            <Toolbar>
              <IconButton
                color="primary"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
              <p>Welcome, Admin!</p>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="temporary"
            open={isDrawerOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <div className="left-container">
              <div className="logo">
                <h1>Markoknow</h1>
              </div>
              <div className="divider"></div>
              <div className="icons">
                {admin === "false" ? (
                  <>
                    <div className="icon">
                      <Link to="/userNotification" className="icon-link">
                        Notification
                      </Link>
                    </div>
                  </>
                ) : null}
                {admin === "true" ? (
                  <>
                    <div className="icon">
                      <Link to="/adminDashboard" className="icon-link">
                        AdminDashboard
                      </Link>
                    </div>
                  </>
                ) : null}
                <div className="icon">
                  <Link to="/dashboard" className="icon-link">
                    Dashboard
                  </Link>
                </div>{" "}
                {admin === "true" ? null : (
                  <>
                    <div className="icon">
                      <Link to="/lead" className="icon-link">
                        Lead
                      </Link>
                    </div>
                  </>
                )}
                <div className="icon">
                  <Link to="/tasks" className="icon-link">
                    Tasks
                  </Link>
                </div>
                {admin === "true" ? null : (
                  <>
                    <div className="icon">
                      <Link to="/leave" className="icon-link">
                        Leave
                      </Link>
                    </div>
                  </>
                )}
                {admin === "true" ? null : (
                  <>
                    <div className="icon">
                      <Link to="/userOnBoarding" className="icon-link">
                        OnBoarding
                      </Link>
                      {onBoardingStatus === "true" ? (
                        <CheckIcon style={{ color: "green" }} />
                      ) : (
                        <CloseIcon style={{ color: "red" }} />
                      )}
                    </div>
                  </>
                )}
                <div className="icon">
                  <Link to="/profile" className="icon-link">
                    Profile
                  </Link>
                </div>
                {admin === "true" ? null : (
                  <>
                    <div className="icon">
                      <Link to="/" className="icon-link" onClick={handleLogout}>
                        Logout
                      </Link>
                    </div>
                  </>
                )}
              </div>
            </div>
          </Drawer>
        </>
      ) : (
        <>
          <div className="left-container">
            <div className="logo">
              <h1>Markoknow</h1>
            </div>
            <div className="divider"></div>
            <div className="icons">
              {admin === "false" ? (
                <>
                  <div className="icon">
                    <Link to="/userNotification" className="icon-link">
                      Notification
                    </Link>
                  </div>
                </>
              ) : null}
              {admin === "true" ? (
                <>
                  <div className="icon">
                    <Link to="/adminDashboard" className="icon-link">
                      AdminDashboard
                    </Link>
                  </div>
                </>
              ) : null}
              <div className="icon">
                <Link to="/dashboard" className="icon-link">
                  Dashboard
                </Link>
              </div>{" "}
              {admin === "true" ? null : (
                <>
                  <div className="icon">
                    <Link to="/lead" className="icon-link">
                      Lead
                    </Link>
                  </div>
                </>
              )}
              <div className="icon">
                <Link to="/tasks" className="icon-link">
                  Tasks
                </Link>
              </div>
              {admin === "true" ? null : (
                <>
                  <div className="icon">
                    <Link to="/leave" className="icon-link">
                      Leave
                    </Link>
                  </div>
                </>
              )}
              {admin === "true" ? null : (
                <>
                  <div className="icon">
                    <Link to="/userOnBoarding" className="icon-link">
                      OnBoarding
                    </Link>
                    {onBoardingStatus === "true" ? (
                      <CheckIcon style={{ color: "green" }} />
                    ) : (
                      <CloseIcon style={{ color: "red" }} />
                    )}
                  </div>
                </>
              )}
              <div className="icon">
                <Link to="/profile" className="icon-link">
                  Profile
                </Link>
              </div>
              {admin === "true" ? null : (
                <>
                  <div className="icon">
                    <Link to="/" className="icon-link" onClick={handleLogout}>
                      Logout
                    </Link>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}

      <div className="right-container">
        <div className="right-container-top">
          <p>Welcome in VSC aChat Reply Dashboard</p>

          <div className="status-boxes">
            <div className="status-box assigned">
              Assigned: {assignedCount}{" "}
            </div>
            <div className="status-box completed">
              Completed: {completedCount}
            </div>
            <div className="status-box pending">Points: {points} </div>
          </div>
        </div>
        <div className="right-container-table">
          <div style={{ height: 600, width: "100%" }}>
            {/* DataGrid with a toolbar */}
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5, 10, 20]}
              checkboxSelection={false}
              filterModel={filterModel}
              onFilterModelChange={handleFilterModelChange}
              components={{
                Toolbar: GridToolbar,
              }}
            />
          </div>
        </div>
      </div>

      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>View Message</DialogTitle>
        <DialogContent>
          <div>
            <p>{selectedMessage}</p>
          </div>
          <TextField
            label="Reply"
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            value={reply}
            onChange={(e) => setReply(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>

          <Button onClick={handleDialogSubmit}>Submit</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ReplyChat;

import React from "react";

const CustomProgressBar = ({ percentage, size = 200 }) => {
  // Calculate radius and circumference
  const radius = size / 2 - 10; // Adjust the radius as needed
  const circumference = 2 * Math.PI * radius;

  // Calculate the stroke dash offset based on the percentage
  const strokeDashoffset = circumference - (percentage / 100) * circumference;

  return (
    <div style={{ width: size, height: size, position: 'relative' }}>
      <svg width={size} height={size}>
        {/* Background circle */}
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          fill="none"
          stroke="#d6d6d6"
          strokeWidth="20"
          style={{ filter: 'drop-shadow(0 0 5px rgba(0,0,0,0.1))' }}
        />
        {/* Progress circle */}
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          fill="none"
          stroke="url(#gradient)"
          strokeWidth="20"
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
          style={{ transition: "stroke-dashoffset 0.5s ease-in-out" }}
        />
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" style={{ stopColor: "#3e98c7", stopOpacity: 1 }} />
            <stop offset="100%" style={{ stopColor: "#3ec7a6", stopOpacity: 1 }} />
          </linearGradient>
        </defs>
        {/* Percentage text */}
        <text
          x="50%"
          y="50%"
          dy=".3em"
          textAnchor="middle"
          fontSize="28px"
          fontWeight="bold"
          fill="#fff"  // Change to white
          style={{ filter: 'drop-shadow(0 0 3px rgba(0,0,0,0.2))' }}
        >
          {`${Math.round(percentage)}%`}
        </text>
      </svg>
    </div>
  );
};

export default CustomProgressBar;

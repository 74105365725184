import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CustomProgressBar from "./CustomProgressBar.js";
import { motion } from "framer-motion";
import StoriesLevel from "./StoriesLevel.js";
import PersonIcon from '@mui/icons-material/Person';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import LogoutIcon from '@mui/icons-material/Logout';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
  Badge,
} from "@mui/material";
import { easeQuadInOut } from "d3-ease";
import axios from "axios";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import AnimatedProgressProvider from "../UI/AnimatedProgressProvider";
import { FETCH_USER_DATA_URL } from "../../api/profile";
import TaskCalendarComponent from "../UI/TaskCalender";
import SearchIcon from "@mui/icons-material/Search";
import { BarChart } from "@mui/x-charts/BarChart";
import InputAdornment from "@mui/material/InputAdornment";
import Chart from "react-google-charts";
import { FETCH_LEAVE_BY_ID } from "../../api/leave";
import {GET_TARGET_FOR_USER_URL} from "../../api/user";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import PainScale from "../UI/PainScale";
import NotificationsIcon from '@mui/icons-material/Notifications';

const TableComponent = (val) => {


  const handleLogout = () => {
    // Remove the token from local storage
    localStorage.removeItem("token");
    localStorage.removeItem("isAdmin");
    localStorage.removeItem("verified");
    localStorage.removeItem("username");
    // Redirect the user to the login page or any other desired page
    window.location.href = "/login";
  };



  const [data, setData] = useState([]);
  const [targetData,setTargetData] = useState([]);
  const [name, setName] = useState("");
  const [filter, setFilter] = useState("");
  const [token, setToken] = useState("");
  const [admin, setAdmin] = useState("false");
  const [id, setId] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");
  const [sortColumn, setSortColumn] = useState("");
  const [barChartData1, setBarChartData1] = useState("");
  const [totalRating, setTotalRating] = useState(0);
  const [isAnimationCompleted, setIsAnimationCompleted] = useState(false);
  const [averageRating, setAverageRating] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    fetchToken();
    const storedNotificationCount = localStorage.getItem('notificationCount');
    if (storedNotificationCount) {
      setNotificationCount(parseInt(storedNotificationCount));
    }
  }, []);


  const isMobile = useMediaQuery("(max-width: 768px)");
  const userrating = localStorage.getItem("User-Rating")
  const fetchToken = async () => {
    try {
      const storedToken = await localStorage.getItem("token");
      const storedAdmin = await localStorage.getItem("isAdmin");
      const storedId = await localStorage.getItem("clickedUserId");
      setToken(storedToken);
      setAdmin(storedAdmin);
      setId(storedId);
    } catch (error) {
      console.error("Error fetching token: ", error);
    }
  };

  useEffect(() => {
    if (token) {
      fetchData();
      fetchLeave();
      
    }
    if(!admin){
      fetchTargetForUser();
    }else{
      fetchTargetForUserbyAdmin();
    }
  }, [token]);

  const [dataTime, setDataTime] = useState([]);
  const [leave, setLeave] = useState();

  const fetchTargetForUserbyAdmin = async () => {
    try {
      const userId = localStorage.getItem("clickedUserId"); // Assuming 'Name' stores the user ID
      const response = await axios.get(GET_TARGET_FOR_USER_URL(userId), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setTargetData(response.data);
        // console.log("Target data for user:", response.data);
      }
    } catch (error) {
      console.error("Error fetching target data for user:", error);
    }
  };
  const fetchTargetForUser = async () => {
    try {
      const userId = localStorage.getItem("id"); // Assuming 'Name' stores the user ID
      const response = await axios.get(GET_TARGET_FOR_USER_URL(userId), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setTargetData(response.data);
        // console.log("Target data for user:", response.data);
      }
    } catch (error) {
      console.error("Error fetching target data for user:", error);
    }
  };

  const fetchLeave = async () => {
    try {
      const res = await axios.get(FETCH_LEAVE_BY_ID(id, admin), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Get the current month
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth() + 1; // JavaScript months are 0-indexed

      // Flatten the array of leave dates and filter for accepted leaves in the present month
      const acceptedLeavesForCurrentMonth = res.data.data.reduce(
        (acc, leave) => {
          return acc.concat(
            leave.dates.filter((dateString) => {
              const date = new Date(dateString);
              return (
                date.getMonth() + 1 === currentMonth &&
                leave.decision === "Accept"
              );
            })
          );
        },
        []
      );

      // Count the number of accepted leaves taken in the present month
      const acceptedLeavesCount = acceptedLeavesForCurrentMonth.length;

      setLeave(acceptedLeavesCount);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchData = async () => {
    try {
      const fetchUserDataUrl = FETCH_USER_DATA_URL(admin, id);
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.get(fetchUserDataUrl, {
        headers,
      });

      if (response.status === 200) {
        setName(response.data.name);
        setData(response.data.tasks);
        // console.log(data);

        const completedTasksByDate = response.data.tasks.reduce((acc, task) => {
          const date = new Date(task.completedDate);
          if (!isNaN(date) && task.status === "Completed and Validated") {
            const formattedDate = new Date(
              date.getFullYear(),
              date.getMonth(),
              date.getDate()
            );
            acc[formattedDate] = (acc[formattedDate] || 0) + 1;
          }
          return acc;
        }, {});

        // Prepare data for timeline chart
        const timelineData = Object.entries(completedTasksByDate).map(
          ([date, count]) => {
            return [new Date(date), count];
          }
        );

        setDataTime(timelineData);
        const months = response.data.tasks.map((row) =>
          row.assignedDate.substring(0, 7)
        ); // Assuming 'assignedDate' is in the format 'YYYY-MM-DD'
        const ratings = response.data.tasks.map((row) =>
          row.rating? row.rating * 2 : 0
                );
                const pendingTasks = response.data.tasks.filter(
                  (row) => row.status === "Pending"
                );
        
                // Group ratings by months
                const ratingsByMonth = months.reduce((acc, month, index) => {
                  acc[month] = (acc[month] || 0) + ratings[index];
                  return acc;
                }, {});
        
                // Group pending tasks by months
                const pendingTasksByMonth = months.reduce((acc, month) => {
                  acc[month] = (acc[month] || 0) + 1;
                  return acc;
                }, {});
        
                // Get the last 5 months
                const last5Months = Array.from(new Set(months)).slice(-5);
        
                // Populate bar chart data
                const totalCompletedRatings = last5Months.map(
                  (month) => ratingsByMonth[month] || 0
                );
                const pendingTasksCount = last5Months.map(
                  (month) => pendingTasksByMonth[month] || 0
                );
                const totalRatingForAllMonths = ratings.reduce(
                  (acc, rating) => acc + rating,
                  0
                );
                setTotalRating(totalRatingForAllMonths);
        
                // Calculate average rating for each month
                const averageRatingsByMonth = last5Months.map((month) => {
                  const totalRatingForMonth = ratingsByMonth[month] || 0;
                  const totalTasksForMonth = pendingTasksByMonth[month] || 0;
                  return totalRatingForMonth / (totalTasksForMonth || 1); // Avoid division by zero
                });
        
                // Calculate overall average rating
                const overallAverageRating =
                  totalRatingForAllMonths / (ratings.length || 1); // Avoid division by zero
                setAverageRating(overallAverageRating);
        
                setBarChartData1(
                  last5Months.map((month, index) => ({
                    month,
                    totalCompletedRatings: totalCompletedRatings[index],
                    pendingTasksCount: pendingTasksCount[index],
                  }))
                );
              }
            } catch (error) {
              console.error("Error fetching user data:", error);
            }
          };
          // console.log(data);
          // Calculate the status counts
  const calculateStatusCounts = () => {
    console.log(data);
    let assignedCount = 0;
    let pendingCount = 0;
    let completedCount = 0;

    data.forEach((task) => {
      if (task.status === "Assigned") {
        assignedCount++;
      } else if (task.status === "Pending") {
        pendingCount++;
      } else if (
        task.status === "Completed" ||
        task.status === "Completed and Validated"
      ) {
        completedCount++;
      }
    });

    return { assignedCount, pendingCount, completedCount };
  };
  const { assignedCount, pendingCount, completedCount } =
    calculateStatusCounts();



  console.log(assignedCount)
    // const { userId, targetId } = useParams();
    const totalTasks = (val.data.pendingCount || pendingCount) + (val.data.completedCount || completedCount) + (val.data.assignedCount || assignedCount);
    const pendingTasks = val.data.pendingCount || pendingCount;
    const completedTasks = val.data.completedCount || completedCount;
    useEffect(() => {
      // Set completedTasks to localStorage
      localStorage.setItem("storiesCount", completedTasks);
    }, [completedTasks]);
          const options = {
            title: "Completed Task Status Calendar",
            calendar: {
              cellColor: {
                stroke: "#76a7fa", // Blue
                strokeOpacity: 0.5,
                strokeWidth: 1,
              },
              focusedCellColor: {
                stroke: "#d3362d", // Red
                strokeOpacity: 1,
                strokeWidth: 2,
              },
              monthOutlineColor: {
                stroke: "#333",
                strokeOpacity: 0.8,
                strokeWidth: 1,
              },
            },
            colorAxis: {
              colors: ["#b7e1cd", "#76a7fa"], // Light green to light blue gradient
            }, // Change colors here
          };
        
          const chartSetting1 = {
            height: isMobile ? 150 : 300,
            width: isMobile ? 250 : 600,
            margin: { top: 40, right: 30, left: 20, bottom: 5 },
          };
        
          const barChartData = [
            { category: "Assigned", count: val.data.assignedCount  },
            { category: "Pending", count: val.data.pendingCount },
          ];
        
          const valueFormatter = (value) => `${value}`;
          // Calculate total score
          const totalScore = data.reduce(
            (acc, row) => acc + (row.rating ? row.rating * 2 : 0),
            0
          );
        
          // Calculate percentage based on total score and max marks
          const maxMarks = data.length * 10;
          const percentage = maxMarks ? (totalScore / maxMarks) * 100 : 0;
        
          const roundedPercentage = isNaN(percentage) ? 0 : Math.round(percentage);
        
          const handleFilterChange = (event) => {
            setFilter(event.target.value);
          };
        
          const handleSort = (column) => {
            if (column === sortColumn) {
              setSortDirection(sortDirection === "asc" ? "desc" : "asc");
            } else {
              setSortColumn(column);
              setSortDirection("asc");
            }
          };
        
          const getSortIcon = (column) => {
            if (column === sortColumn) {
              return sortDirection === "asc" ? "↑" : "↓";
            }
            return null;
          };
        
          // Greeting message based on the time of day
          const getGreeting = () => {
            const now = new Date();
            const hours = now.getHours();
        
            if (hours < 12) return "Good Morning";
            if (hours < 17) return "Good Afternoon";
            return "Good Evening";
          };
        
          const notificationIconVariants = {
            visible: {
              x: [0, -10, 0],
              transition: {
                x: {
                  repeat: Infinity,
                  duration: 0.5,
                  ease: "easeInOut",
                },
              },
            },
            hidden: {
              x: 0,
            },
          };

  const target = targetData?.data?.[0] || {};
  const targetDuration = target.targetDuration|| 'This Month';
  const targetFigure = target.targetFigure || 0;
  const description = target.targetDescription || 'Not Assigned yet';

  return (
    <div
      style={{
        backgroundColor: "#DAEDE3",
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        alignItems: isMobile ? "flex-start" : "center",
        // border: "1px solid red",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: isMobile ? "column-reverse" : "row",
          justifyContent: "space-between",
          gap: "20px",
          width: "100%",
          // alignSelf: "flex-start",
          // border: "1px solid red",
        }}
      >
        <div
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            gap: "20px",
            alignSelf: "flex-start",
            // border: "1px solid red",
          }}
        >
          <div
            style={{
              marginLeft: isMobile ? "0%" : "0%",
              marginTop: "2%",

              // border: "1px solid red",
            }}
          >
            <Typography variant="h4" gutterBottom>
              {getGreeting()},
            </Typography>
            <Typography variant="h4" gutterBottom>
              Hi ,{name}
            </Typography>
            <Typography variant="body1" paragraph>
              Here you can track records and accuracy.
            </Typography>
          </div>
        </div>
        <Link to="/userNotification" className="li-link" >  
    <div
  style={{
    display: "flex",
    alignItems: "center",
    gap: "10px",
    position: "fixed",
   
  right: isMobile ?"50px":"250px",
    top: "20px",
    zIndex: 1000,
    // border:"1px solid red"
  }}
>
  <motion.div
    variants={notificationIconVariants}
    animate={notificationCount > 0 ? "visible" : "hidden"}
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: notificationCount > 0 ? "yellow" : undefined,
      borderRadius: "50%",
      padding: "8px",
      boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
    }}
  >
    <Badge
      badgeContent={notificationCount}
      color={notificationCount > 0 ? "warning" : "primary"}
      style={{
        fontSize: "1.2rem",
        fontWeight: "bold",
      }}
    >
      <NotificationsIcon
        style={{
          fontSize: "2rem",
          color: notificationCount > 0 ? "white" : "inherit",
        }}
      />
    </Badge>
  </motion.div>
</div></Link>

        <div className="nav-menu">
      <div className="avatar-container" onClick={toggleMenu}>
        {/* <img src={avatar} alt="Avatar" className="avatar" /> */}
        <span className="user-name">{name}</span>
        <span className={`arrow ${isOpen ? 'open' : ''}`}>▼</span>
      </div>
      {isOpen && (
        <ul className="menu-items">
          <li><Link to="/profile" className="li-link" ><PersonIcon style={{marginRight:"10px"}}/>Profile</Link></li>
          <li><Link to="/lead"className="li-link" ><LeaderboardIcon style={{marginRight:"10px"}}/>Lead</Link></li>
          <li><Link to="/userOnBoarding" className="li-link"><EmojiPeopleIcon style={{marginRight:"10px"}}/>Onboarding</Link></li>
         
          <li onClick={handleLogout}> 
          <Link to="/" className="li-link" style={{
          
            
          }}><LogoutIcon style={{marginRight:"10px"}}/>Logout</Link></li>
        </ul>
      )}
    </div>
      </div>
      <div className="horizontal-container">
      <div className="horizontal-item">    
      <motion.div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent:"center",
            padding: "20px",
            height:"90%",
          
            // backgroundColor: "#f0f0f0",
            // borderRadius: "8px",
            // boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            // border:"1px solid red"
          }}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <motion.div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              marginBottom: "20px",
            }}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.1 }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: "#e0e0e0",
                padding: "20px",
                borderRadius: "10px",
                flex: "1",
                marginRight: "10px",
               
              }}
            >
              <motion.span
                style={{
                  fontSize: "30px",
                  fontWeight: "bold",
                  color: "#333",
                }}
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5, delay: 0.2 }}
              >
                {totalTasks}
              </motion.span>
              <span
                style={{
                  fontSize: "20px",
                  color: "#666",
                }}
              >
                Total Tasks
              </span>
            </div>
          </motion.div>
          <motion.div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: "#e0e0e0",
                padding: "20px",
                borderRadius: "10px",
                flex: "1",
                marginRight: "10px",
              }}
            >
              <motion.span
                style={{
                  fontSize: "30px",
                  fontWeight: "bold",
                  color: "#333",
                }}
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5, delay: 0.3 }}
              >
                {pendingTasks}
              </motion.span>
              <span
                style={{
                  fontSize: "20px",
                  color: "#666",
                }}
              >
                Pending Tasks
              </span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: "#e0e0e0",
                padding: "20px",
                borderRadius: "4px",
                flex: "1",
                
              }}
            >
              <motion.span
                style={{
                  fontSize: "32px",
                  fontWeight: "bold",
                  color: "#333",
                }}
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5, delay: 0.4 }}
              >
                {completedTasks}
              </motion.span>
              <span
                style={{
                  fontSize: "20px",
                  color: "#666",
                }}
              >
                Completed Tasks
              </span>
            </div>
          </motion.div>
        </motion.div>
        </div>
      <div className="horizontal-item"> <Paper elevation={5} className="progressBarContainer">
          <div className="progressBarContent">
            <Typography variant="h5" className="progressBarTitle" gutterBottom>
              My Performance Score
            </Typography>
            <AnimatedProgressProvider
              valueStart={0}
              valueEnd={isAnimationCompleted ? percentage : 0}
              duration={1.4}
              easingFunction={easeQuadInOut}
              onAnimationEnd={() => setIsAnimationCompleted(true)}
            >
              {(value) => (
                <CustomProgressBar
                  percentage={percentage}
                  size={200}
                  className="progressBar"
                />
              )}
            </AnimatedProgressProvider>
          </div>
        </Paper></div>

        <div className="horizontal-item"> 
        <Typography variant="body1" paragraph>
              Task Calendar
          </Typography>
          <TaskCalendarComponent taskData={data} />
            </div>
    </div>

    

    <div className="grid-container5">

    <div className="grid-item5" ><StoriesLevel completedTasks={completedTasks} /></div>

      <div className="grid-item5">  <PainScale value={leave} /></div>
      <div className="grid-item5"> 
      <div className="rating-box">
         <div className="rating-value">{userrating}
         </div>
         <div className="rating-label">Rating This Month</div>
         </div>

    </div>
           <div className="grid-item5">  {leave >= 4 ? (
            <Alert severity="error">
              <AlertTitle>Red Zone</AlertTitle>
              You have exceeded the maximum leaves for this month!
            </Alert>
          ) : (
            <Alert severity="success">
              <AlertTitle>Green Zone</AlertTitle>
              You have taken {leave} leave(s) this month.
            </Alert>
          )}</div>


<div className="target-info" style={{padding:"1rem 0rem 1rem 0rem"}}> 

      <h2>Target</h2>
      <h3>{targetDuration}</h3>
      <div className="targetFigure">{targetFigure}</div>
      <p className="description">{description}</p>
    
    </div>
    </div>

   
      {/* <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "2%",
          width: "100%",
        }}
      >
        <Paper
          elevation={6}
          style={{
            width: isMobile ? "100%" : "70%", // Adjust the width as needed
            height: isMobile ? "90%" : "50%",
            flexDirection: "row",
            padding: "2%",
            boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ width: "50%" }}>
            <Typography variant={isMobile ? "h5" : "h4"} gutterBottom>
              Accuracy by Point Each Month
            </Typography>
            {barChartData1 && barChartData1.length > 0 && (
              <BarChart
                dataset={barChartData1}
                xAxis={[{ scaleType: "band", dataKey: "month" }]}
                series={[
                  {
                    dataKey: "totalCompletedRatings",
                    label: "Total Completed Ratings",
                    valueFormatter,
                  },
                  {
                    dataKey: "pendingTasksCount",
                    label: "Pending Tasks Count",
                    valueFormatter,
                  },
                ]}
                {...chartSetting1}
              />
            )}
          </div>
          <div>
            <Typography
              variant="body1"
              paragraph
              style={{
                fontSize: isMobile ? "20px" : "50px",
              }}
            >
              {totalRating}
            </Typography>

            <Typography variant="body1" paragraph>
              Total Points
            </Typography>
            <Typography
              variant="body1"
              paragraph
              style={{
                fontSize: isMobile ? "20px" : "50px",
              }}
            >
              {averageRating.toFixed(2)}
            </Typography>
            <Typography variant="body1" paragraph>
              Average Points
            </Typography>
          </div>
        </Paper>

      </div> */}

      {/* Filter TextField */}
      <TextField
        label="Filter by Task Details or Team"
        variant="outlined"
        value={filter}
        onChange={handleFilterChange}
        style={{
          marginBottom: "2%",
          marginLeft: isMobile ? "2%" : "-65%",
          marginTop: isMobile ? "10%" : "2%",
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          style: {
            border: "none",
            borderBottom: "1px solid #ccc", // You can adjust the border bottom style
          },
        }}
      />

      {/* Table */}

      {/* <div></div> */}
      <TableContainer
        component={Paper}
        style={{
          width: isMobile ? "100%" : "90%",
          marginBottom: "5%",
          boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)",
          borderRadius: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "1%",
          }}
        >
          <Typography variant="h5" gutterBottom>
            Task Details
          </Typography>
        </div>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell
                onClick={() => handleSort("taskDetails")}
                style={{ cursor: "pointer" }}
              >
                Task Details {getSortIcon("taskDetails")}
              </TableCell>
              <TableCell
                onClick={() => handleSort("team")}
                style={{ cursor: "pointer" }}
              >
                Team {getSortIcon("team")}
              </TableCell>
              <TableCell
                onClick={() => handleSort("assignedDate")}
                style={{ cursor: "pointer" }}
              >
                Assigned Date {getSortIcon("assignedDate")}
              </TableCell>
              <TableCell
                onClick={() => handleSort("assignedDate")}
                style={{ cursor: "pointer" }}
              >
                Comments {getSortIcon("comments")}
              </TableCell>
              <TableCell
                onClick={() => handleSort("status")}
                style={{ cursor: "pointer" }}
              >
                Status {getSortIcon("status")}
              </TableCell>
              <TableCell
                onClick={() => handleSort("rating")}
                style={{ cursor: "pointer" }}
              >
                Points {getSortIcon("rating")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => {
              // Convert ISO date string to Date object
              const assignedDate = new Date(row.assignedDate);

              // Options for formatting the date
              const options = {
                day: "numeric",
                month: "long",
                year: "numeric",
              };

              // Format the date using the options
              const formattedDate = assignedDate.toLocaleDateString(
                "en-US",
                options
              );

              return (
                <TableRow key={row._id}>
                   <TableCell>{row.taskDetails}</TableCell>
                 <TableCell>{row.team ? row.team.name : "-"}</TableCell> {/* Handle null team */}
                <TableCell>{formattedDate}</TableCell>
               <TableCell>{!row.comments ? "-" : row.comments}</TableCell>
             <TableCell>{row.status}</TableCell>
             <TableCell>{!row.rating ? "-" : row.rating * 2}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Chart
        chartType="Calendar"
        width="100%"
        height="500px"
        data={[
          [
            { type: "date", id: "Date" },
            { type: "number", id: "Completed Tasks" },
          ],
          ...dataTime,
        ]}
        options={options}
      />
    </div>
  );
};

export default TableComponent;

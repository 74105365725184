import { BASE_URL } from "./client";

export const ISSUE_CERTIFICATE_URL = (userId) =>
  `${BASE_URL}/api/documentRequest/postData/${userId}`;

export const ISSUE_LOR_URL = (userId) =>
  `${BASE_URL}/api/documentRequest/postDataLor/${userId}`;

export const GET_CERT_DATA_URL = (userId) =>
  `${BASE_URL}/api/documentRequest/getData2/${userId}`;
export const generateCertificateUrl = (userId) =>
  `${BASE_URL}/certificate/${userId}`;
export const generateLorUrl = (userId) => `${BASE_URL}/lor/${userId}`;
export const GET_LOR_DATA_URL = (userId) =>
  `${BASE_URL}/api/documentRequest/getDataLor2/${userId}`;

import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  Select,
  MenuItem,
  TextareaAutosize,
  TextField,
  Snackbar,
  Alert,
} from "@mui/material";
import { UserLeftContainer } from "../UI/index";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  ADD_MEMBER_TO_TEAM_URL,
  CREATE_TASK_URL,
  FETCH_TEAM_MEMBERS_URL,
  FETCH_USER_TASKS_URL,
  GET_ALL_TASKS_URL,
  GET_ALL_USERS_URL,
  MAKE_MANAGER_URL,
  DELETE_TASK_URL,
  UPDATE_URL,
} from "../../api/user";

import {
  useMediaQuery,
  IconButton,
  Drawer,
  AppBar,
  Toolbar,
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";

const TaskAssigned = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const isMobile = useMediaQuery("(max-width: 768px)");
  const { teamName, teamId } = useParams();
  const [data, setData] = useState([]);
  const [users, setUsers] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [userTasks, setUserTasks] = useState([]);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");
  const [userName, setUserName] = useState("");
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    taskDetails: "",
    team: teamName, // Set the default team name
    status: "Assigned",
    assignedDate: "",
    completedDate: "",
    rating: "",
  });

  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  // State for the Make Manager confirmation dialog
  const [isMakeManagerDialogOpen, setIsMakeManagerDialogOpen] = useState(false);
  const [selectedMemberForManager, setSelectedMemberForManager] =
    useState(null);
  // Create state to manage available members to add to the team
  const [availableMembers, setAvailableMembers] = useState([]);

  const [isAddMemberDialogOpen, setIsAddMemberDialogOpen] = useState(false);

  const [admin, setAdmin] = useState("");
  const [manager, setManager] = useState("");
  const [manageTeams, setManageTeams] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const token = localStorage.getItem("token");

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const tokenPayload = token
        ? JSON.parse(atob(token.split(".")[1]))
        : {};
      const currentUserId = tokenPayload.userId;

      // Fetch the user data using the currentUserId
      const response = await axios.get(GET_ALL_USERS_URL, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 200) {
        const allUsers = response.data;
        const currentUser = allUsers.find((user) => user._id === currentUserId);
        setCurrentUser(currentUser); // Set the current user object
        setAdmin(currentUser.isAdmin);
        setManager(currentUser.isManager);
        setManageTeams(currentUser.managedTeams);
      }
    } catch (error) {
      console.error("Error fetching user data: ", error);
    }
  };
  // Function to open the dialog
  const openAddMemberDialog = () => {
    setIsAddMemberDialogOpen(true);
  };

  // Function to close the dialog
  const closeAddMemberDialog = () => {
    setIsAddMemberDialogOpen(false);
  };
  // Function to handle logout
  const handleLogout = () => {
    // Remove the token from local storage
    localStorage.removeItem("token");
    localStorage.removeItem("isAdmin");
    // Redirect the user to the login page or any other desired page
    window.location.href = "/login";
  };
  useEffect(() => {
    fetchData();
  }, [teamName]);

  useEffect(() => {
    fetchUserTasks();
  }, [teamMembers]);

  useEffect(() => {
    fetchTaskData();
  }, []);
  useEffect(() => {
    fetchUsers();
  }, []);
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const fetchTaskData = async () => {
    try {
      const response = await axios.get(GET_ALL_TASKS_URL, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 200) {
        const teamTasks = response.data.filter(
          (task) => task.team && task.team._id === teamId
        );
  
        // Set the filtered data using setData
        setData(teamTasks);
      }
    } catch (error) {
      console.error("Error fetching tasks", error);
    }
  };
  

  const fetchUsers = async () => {
    try {
      const response = await axios.get(GET_ALL_USERS_URL, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 200) {
        setUsers(response.data);
      }
    } catch (error) {
      console.error("Error fetching users", error);
    }
  };

  const fetchData = async () => {
    try {
      const fetchTeamMembersUrl = FETCH_TEAM_MEMBERS_URL(teamName);
      const response = await axios.get(fetchTeamMembersUrl, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const members = response.data;
      setTeamMembers(members);
    } catch (error) {
      console.error("Error fetching team members", error);
    }
  };
  const fetchUserTasks = async () => {
    try {
      const promises = teamMembers.map(async (member) => {
        try {
          const fetchUserTasksUrl = FETCH_USER_TASKS_URL(member._id);
          const taskResponse = await axios.get(fetchUserTasksUrl);
          const tasks = taskResponse.data;
  
          // Filter tasks based on the team name
          const filteredTasks = tasks.filter(
            (task) => task.team?.name === teamName
          );
  
          // Map tasks to include the team _id
          const updatedTasks = filteredTasks.map((task) => ({
            ...task,
            team: {
              name: teamName,
              _id: teamId,
            },
          }));
  
          return { memberId: member._id, tasks: updatedTasks };
        } catch (error) {
          console.error(`Error fetching tasks for ${member.name}:`, error);
          return { memberId: member._id, tasks: [] }; // Return an empty task list on error
        }
      });
  
      const userTasksData = await Promise.all(promises);
      setUserTasks(userTasksData.filter(data => data.tasks.length > 0)); // Filter out members with no tasks
    } catch (error) {
      console.error("Error fetching user tasks", error);
    }
  };
  

  // Fetch available members to add to the team
  useEffect(() => {
    // Fetch members who are not already in the team
    const membersNotInTeam = users.filter((user) => {
      return !teamMembers.some((member) => member._id === user._id);
    });
    setAvailableMembers(membersNotInTeam);
  }, [users, teamMembers]);

  const handleAddMemberToTeam = (userId) => {
    // Make a POST request to add the selected member to the team
    const addMemberToTeamUrl = ADD_MEMBER_TO_TEAM_URL(teamId, userId);
    axios
      .post(addMemberToTeamUrl)
      .then((response) => {
        if (response.status === 200) {
          // Handle the success response here

          // Optionally, you can update the team members list or perform any other action
          const newMember = availableMembers.find(
            (member) => member._id === userId
          );
          if (newMember) {
            setTeamMembers([...teamMembers, newMember]);
          }

          // Close the dialog after adding the member
          closeAddMemberDialog();
        } else {
          // Handle the response when adding member was not successful
          console.error(
            "Failed to add member to the team:",
            response.data.message
          );
          // Display an error message to the user
          setSnackbarMessage("Failed to add member to the team");
          setIsSnackbarOpen(true);
        }
      })
      .catch((error) => {
        console.error("Error adding member to the team:", error);
        // Handle the error response here
        // Display an error message to the user
        setSnackbarMessage("Error adding member to the team");
        setIsSnackbarOpen(true);
      });
  };

  const calculateTaskCounts = (userId) => {
    const userTasksData = userTasks.find(
      (userTask) => userTask.memberId === userId
    );
    if (userTasksData) {
      const tasks = userTasksData.tasks;
      const totalAssigned = tasks.length;
      const pending = tasks.filter((task) => task.status === "Assigned").length;
      const completed = tasks.filter(
        (task) => task.status === "Completed and Validated"
      ).length;
      return { totalAssigned, pending, completed };
    }
    return { totalAssigned: 0, pending: 0, completed: 0 };
  };

  const handleCreateTask = () => {
    setIsCreateDialogOpen(true);
  };

  const handleCloseCreateDialog = () => {
    setIsCreateDialogOpen(false);
    setSelectedUser("");
    setFormData({
      name: "",
      taskDetails: "",
      team: teamName, // Set the default team name
      status: "Assigned",
      assignedDate: "",
      completedDate: "",
      rating: "",
    });
  };

  const handleUserSelect = (event) => {
    const selectedUserName = event.target.value;
    setSelectedUser(selectedUserName);
    setFormData((prevData) => ({
      ...prevData,
      name: selectedUserName,
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCreateTaskSubmit = async () => {
    try {
      setIsCreateDialogOpen(false);
      const selectedMember = teamMembers.find(
        (member) => member.name === selectedUser
      );

      if (selectedMember) {
        const newTaskData = {
          assignedTo: selectedMember._id,
          taskDetails: formData.taskDetails,
          team: teamId, // Use the actual teamId
          status: formData.status,
          assignedDate: formData.assignedDate,
        };

        const response = await axios.post(CREATE_TASK_URL, newTaskData);

        const newTask = {
          _id: response.data._id,
          assignedTo: { _id: selectedMember._id }, // Use the actual _id value for assignedTo
          taskDetails: formData.taskDetails,
          team: { _id: teamId }, // Use the actual _id value for team
          status: formData.status,
          assignedDate: formData.assignedDate,
        };

        // Close the create task dialog

        // Clear/reset the selectedUser state
        setSelectedUser("");

        // Clear the formData object
        setFormData({
          name: "",
          taskDetails: "",
          team: teamName, // Set the default team name
          assignedDate: "",
          status: "Assigned",
        });

        // Display a success message
        setSnackbarMessage("Task created successfully");
        setIsSnackbarOpen(true);

        // You may want to update the userTasks state here to reflect the newly created task
        // and update the UI accordingly.
      }
    } catch (error) {
      if (error.response.status === 400) {
        // If the error is a validation error, handle multiple validation errors
        if (error.response.data && error.response.data.details) {
          const validationErrors = Object.values(
            error.response.data.details
          ).flat();
          setSnackbarMessage(
            `Validation error: ${validationErrors.join(", ")}`
          );
        } else {
          // If no details provided, show a generic error message
          setSnackbarMessage("Validation error: Something went wrong.");
        }
        // Open the Snackbar
        setIsSnackbarOpen(true);
      }
      console.error("Error creating task", error);
    }
  };
  const handleMakeManagerClick = (member) => {
    setSelectedMemberForManager(member);
    setIsMakeManagerDialogOpen(true);
  };
  // Function to confirm making a user a manager

  const handleConfirmMakeManager = async () => {
    try {
      // Send a request to the server to make the user a manager
      const makeManagerUrl = MAKE_MANAGER_URL(
        selectedMemberForManager._id,
        teamId
      );

      // Send a request to the server to make the user a manager
      const response = await axios.put(makeManagerUrl);

      if (response.status === 200) {
        // Close the confirmation dialog
        setIsMakeManagerDialogOpen(false);

        // Display a success message
        setSnackbarMessage(
          `Made ${selectedMemberForManager?.name} the manager successfully`
        );
        setIsSnackbarOpen(true);

        // Refresh the team members data to reflect the new manager
        fetchData();
      }
    } catch (error) {
      console.error("Error making manager", error);
    }
  };
  const handleEditClick = (task) => {
    setSelectedTask(task);
    setFormData({
      name: task.assignedTo.name,
      taskDetails: task.taskDetails,
      team: teamName,
      status: task.status,
      assignedDate: task.assignedDate,
      completedDate: task.completedDate,
      rating: task.rating || "",
    });
    setIsEditDialogOpen(true);
  };
  const handleDeleteClick = async (taskId) => {
    try {
      const response = await axios.delete(DELETE_TASK_URL(taskId));
      if (response.status === 200) {
        setSnackbarMessage("Task deleted successfully");
        setIsSnackbarOpen(true);
        fetchTaskData(); // Refresh task data after deletion
      }
    } catch (error) {
      console.error("Error deleting task", error);
      setSnackbarMessage("Error deleting task");
      setIsSnackbarOpen(true);
    }
  };
  const handleUpdateTaskSubmit = async () => {
    try {
      const updatedTaskData = {
        assignedTo: selectedTask.assignedTo._id,
        taskDetails: formData.taskDetails,
        team: teamId,
        status: formData.status,
        assignedDate: formData.assignedDate,
        completedDate: formData.completedDate,
        rating: formData.rating,
      };

      const response = await axios.put(
        UPDATE_URL(selectedTask._id),
        updatedTaskData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setIsEditDialogOpen(false);
        setSnackbarMessage("Task updated successfully");
        setIsSnackbarOpen(true);
        fetchTaskData(); // Refresh task data after update
      }
    } catch (error) {
      console.error("Error updating task", error);
      setSnackbarMessage("Error updating task");
      setIsSnackbarOpen(true);
    }
  };
  const handleCloseEditDialog = () => {
    setIsEditDialogOpen(false);
    setSelectedTask(null);
    setFormData({
      name: "",
      taskDetails: "",
      team: teamName,
      status: "",
      assignedDate: "",
      completedDate: "",
      rating: "",
    });
  };
  const isAdmin = () => {
    return admin === "true";
  };
  return (
    <div className="container">
      {isMobile ? (
        <>
          <AppBar
            position="fixed"
            style={{ background: "transparent", boxShadow: "none" }}
          >
            <Toolbar>
              <IconButton
                color="primary"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
              <p>Welcome, Admin!</p>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="temporary"
            open={isDrawerOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <UserLeftContainer />
          </Drawer>
        </>
      ) : (
        <>
          <UserLeftContainer
            userName={userName}
            isAdmin={isAdmin()}
            handleLogout={handleLogout}
          />
        </>
      )}
      <div className="right-container">
        <Typography variant="h4" gutterBottom>
          Team: {teamName}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          style={{ marginBottom: "16px", width: "150px" }}
          onClick={handleCreateTask}
        >
          Create Task
        </Button>

        <Dialog
          open={isCreateDialogOpen}
          onClose={handleCloseCreateDialog}
          PaperProps={{
            style: {
              maxWidth: "600px", // Adjust the width as needed
              width: "100%",
            },
          }}
        >
          <DialogTitle>Create Task</DialogTitle>
          <DialogContent>
            <div>
              <InputLabel htmlFor="name">User</InputLabel>
              <Select
                labelId="name-label"
                id="name"
                name="name"
                value={selectedUser}
                onChange={handleUserSelect}
                fullWidth
              >
                {teamMembers.map((member) => (
                  <MenuItem key={member._id} value={member.name}>
                    {member.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div>
              <InputLabel htmlFor="taskDetails">
                Task Details (can include links)
              </InputLabel>
              <TextareaAutosize
                id="taskDetails"
                name="taskDetails"
                value={formData.taskDetails}
                onChange={handleInputChange}
                minRows={3}
                style={{
                  width: "100%",
                  maxWidth: "100%",
                }}
              />
            </div>
            <div>
              <InputLabel htmlFor="team">Team</InputLabel>
              <Select
                labelId="team-label"
                id="team"
                name="team"
                value={teamName}
                disabled
                fullWidth
              >
                <MenuItem value={teamName}>{teamName}</MenuItem>
              </Select>
            </div>
            <div>
              <InputLabel htmlFor="assignedDate">Assigned Date</InputLabel>
              <TextField
                type="date"
                name="assignedDate"
                value={formData.assignedDate}
                onChange={handleInputChange}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div>
              <InputLabel htmlFor="status">Status</InputLabel>
              <Select
                labelId="status-label"
                id="status"
                name="status"
                value={formData.status}
                onChange={handleInputChange}
                fullWidth
              >
                <MenuItem value="Assigned">Assigned</MenuItem>
                <MenuItem value="Pending">Pending</MenuItem>
                <MenuItem value="Completed">Completed</MenuItem>
                <MenuItem value="Completed and Validated">
                  Completed and Validated
                </MenuItem>
              </Select>
            </div>
            {/* Other form fields */}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseCreateDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={handleCreateTaskSubmit} color="primary">
              Create
            </Button>
          </DialogActions>
        </Dialog>
        <div style={{ maxHeight: "500px", overflowY: "auto" }}>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Task Details</TableCell>
                  <TableCell>Team</TableCell>
                  <TableCell>Assigned Date</TableCell>
                  <TableCell>Completed Date</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row) => (
                  <TableRow key={row._id}>
                    <TableCell>{row.assignedTo.name}</TableCell>
                    <TableCell style={{ width: "60px" }}>
                      {row.taskDetails}
                    </TableCell>
                    <TableCell>{row.team.name}</TableCell>
                    <TableCell>{row.assignedDate.substring(0, 10)}</TableCell>
                    <TableCell>
                      {row.completedDate?.substring(0, 10) || "-"}
                    </TableCell>
                    <TableCell>{row.status}</TableCell>
                    <TableCell>
                      <Button
                        color="primary"
                        onClick={() => handleEditClick(row)}
                      >
                        Edit
                      </Button>
                      {/* <Button
                        color="error"
                        onClick={() => handleDeleteClick(row._id)}
                      >
                        Delete
                      </Button> */}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Dialog
          open={isEditDialogOpen}
          onClose={handleCloseEditDialog}
          PaperProps={{
            style: {
              maxWidth: "600px",
              width: "100%",
            },
          }}
        >
          <DialogTitle>Edit Task</DialogTitle>
          <DialogContent>
            <div>
              <InputLabel htmlFor="name">User</InputLabel>
              <Select
                labelId="name-label"
                id="name"
                name="name"
                value={formData.name}
                disabled
                fullWidth
              >
                {teamMembers.map((member) => (
                  <MenuItem key={member._id} value={member.name}>
                    {member.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div>
              <InputLabel htmlFor="taskDetails">
                Task Details (can include links)
              </InputLabel>
              <TextareaAutosize
                id="taskDetails"
                name="taskDetails"
                value={formData.taskDetails}
                onChange={handleInputChange}
                rowsMin={3}
                fullWidth
              />
            </div>
            <div>
              <InputLabel htmlFor="status">Status</InputLabel>
              <Select
                id="status"
                name="status"
                value={formData.status}
                onChange={handleInputChange}
                fullWidth
              >
                <MenuItem value="Assigned">Assigned</MenuItem>
                <MenuItem value="Pending">Pending</MenuItem>
                <MenuItem value="Completed">Completed</MenuItem>
                <MenuItem value="Completed and Validated">
                  Completed and Validated
                </MenuItem>
              </Select>
            </div>
            {/* <div>
              <InputLabel htmlFor="assignedDate">Assigned Date</InputLabel>
              <TextField
                id="assignedDate"
                name="assignedDate"
                type="date"
                value={formData.assignedDate}
                onChange={handleInputChange}
                fullWidth
              />
            </div> */}
            <div>
              <InputLabel htmlFor="completedDate">Completed Date</InputLabel>
              <TextField
                id="completedDate"
                name="completedDate"
                type="date"
                value={formData.completedDate}
                onChange={handleInputChange}
                fullWidth
              />
            </div>
            {/* <div>
              <InputLabel htmlFor="rating">Rating</InputLabel>
              <TextField
                id="rating"
                name="rating"
                type="number"
                value={formData.rating}
                onChange={handleInputChange}
                fullWidth
              />
            </div> */}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseEditDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={handleUpdateTaskSubmit} color="primary">
              Update
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          open={isSnackbarOpen}
          autoHideDuration={6000}
          onClose={() => setIsSnackbarOpen(false)}
        >
          <Alert onClose={() => setIsSnackbarOpen(false)} severity="success">
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
};

export default TaskAssigned;

import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import "../DashboardPage.css";
import TableComponentUser from "./TableComponentUser";
import {
  useMediaQuery,
  IconButton,
  Drawer,
  AppBar,
  Toolbar,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import UserLeftContainer from "../UI/UserLeftContainer";

const UserTasks = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [onBoardingStatus, setOnBoardingStatus] = useState(false);

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const isMobile = useMediaQuery("(max-width: 768px)");

  useEffect(() => {
    adminfetch();
  }, []);

  const adminfetch = async () => {
    const verified = await localStorage.getItem("verified");
    setOnBoardingStatus(verified);
    const storedAdmin = await localStorage.getItem("isAdmin");
  };

  return (
    <>
      <div className="container">
        {isMobile ? (
          <>
            <AppBar
              position="fixed"
              style={{ background: "transparent", boxShadow: "none" }}
            >
              <Toolbar>
                <IconButton
                  color="primary"
                  aria-label="open drawer"
                  onClick={handleDrawerToggle}
                  edge="start"
                >
                  <MenuIcon />
                </IconButton>
                <p>Welcome, Admin!</p>
              </Toolbar>
            </AppBar>
            <Drawer
              variant="temporary"
              open={isDrawerOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true,
              }}
            >
              <UserLeftContainer />
            </Drawer>
          </>
        ) : (
          <>
            <UserLeftContainer />
          </>
        )}
        <div
          className="right-container"
          style={{
            backgroundColor:"#DAEDE3",
            padding: "25px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="right-container-top">
            <Typography variant="body1" paragraph style={{ fontSize: "35px" }}>
              Task Details and Analysis
            </Typography>
          </div>
          <div className="right-container-table">
            <TableComponentUser />
          </div>
        </div>
      </div>
    </>
  );
};

export default UserTasks;

import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  CssBaseline,
  Box,
  Button,
  Container,
  Grid,
  Paper,
  TextField,
  MenuItem,
  Snackbar,
  Alert,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import countries from "../../utils/constants";
import HoverButton from "../../utils/UI/HoverButton";
import TypeText from "../../utils/UI/TypeText";
import { DEMO_POST } from "../../api/demo";
import axios from "axios";

function Demo() {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false); // State for Snackbar
  const [snackbarMessage, setSnackbarMessage] = useState(""); // Message for the Snackbar

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const gradientHeight = 200; // Adjust this value based on your preference
  const backgroundColor = "#87bfff"; // Change this to your desired background color
  const getBackgroundColor = () => {
    return scrollPosition + 150 > gradientHeight ? "white" : "transparent";
  };

  const [formData, setFormData] = useState({
    name: "",
    workEmail: "",
    phoneNumber: "",
    jobTitle: "",
    companyName: "",
    country: "",
    monthlyActiveUsers: "",
    howDidYouHear: "",
    comments: "",
  });

  const [formErrors, setFormErrors] = useState({});

  const handleChange = (field) => (event) => {
    setFormData({ ...formData, [field]: event.target.value });
    // Clear the error message when the user starts typing
    setFormErrors({ ...formErrors, [field]: "" });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validate the form
    const errors = {};
    Object.entries(formData).forEach(([key, value]) => {
      if (value.trim() === "") {
        errors[key] = "This field is required";
      }
    });

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    try {
      // Make a POST request to your API endpoint
      const response = await axios.post(DEMO_POST, formData);

      // Optionally, you can reset the form after successful submission
      setFormData({
        name: "",
        workEmail: "",
        phoneNumber: "",
        jobTitle: "",
        companyName: "",
        country: "",
        monthlyActiveUsers: "",
        howDidYouHear: "",
        comments: "",
      });

      setSnackbarMessage(response.data.message);
      setIsSnackbarOpen(true);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const boxStyles = {
    background: `linear-gradient(to bottom, #a68aff, ${backgroundColor} ${gradientHeight}px)`,
    height: isSmallScreen ? "auto" : "100vh",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
  };

  return (
    <React.Fragment>
      <Box component="main" style={boxStyles}>
        <CssBaseline />
        <AppBar
          position="fixed"
          style={{
            borderBottom: "none",
            boxShadow: scrollPosition + 150 > gradientHeight ? "" : "none",
            backgroundColor: getBackgroundColor(),
          }}
        >
          <Toolbar>
            <Typography
              variant="h4"
              component="div"
              style={{
                color: scrollPosition + 150 > gradientHeight ? "black" : "white",
                fontWeight: "bold",
                marginLeft: scrollPosition + 150 > gradientHeight ? "50px" : "100px", // Adjust the margin
              }}
            >
              IMS PORTAL
            </Typography>
            <div style={{ marginLeft: "auto", marginRight: "50px" }}>
              <HoverButton />
            </div>
          </Toolbar>
        </AppBar>
        <Toolbar /> {/* Ensures content below app bar is pushed down */}
        <Container
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            flex: 1,
          }}
        >
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Paper
                elevation={5}
                style={{
                  padding: 20,
                  borderRadius: 16,
                  backgroundColor: "transparent",
                  boxShadow: "none",
                }}
              >
                <Typography variant="h2" gutterBottom style={{ color: "white" }}>
                  Simplify retention with IMS
                </Typography>
                <TypeText />
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper elevation={3} style={{ padding: 20, borderRadius: 16 }}>
                <Typography variant="h5" gutterBottom>
                  Schedule a LIVE demo today
                </Typography>
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        label="Your Full Name"
                        fullWidth
                        required
                        error={!!formErrors.name}
                        helperText={formErrors.name}
                        value={formData.name}
                        onChange={handleChange("name")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Your Work Email"
                        fullWidth
                        required
                        type="email"
                        error={!!formErrors.workEmail}
                        helperText={formErrors.workEmail}
                        value={formData.workEmail}
                        onChange={handleChange("workEmail")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Your Phone Number"
                        fullWidth
                        required
                        type="text"
                        error={!!formErrors.phoneNumber}
                        helperText={formErrors.phoneNumber}
                        value={formData.phoneNumber}
                        onChange={handleChange("phoneNumber")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Your Job Title"
                        fullWidth
                        required
                        type="text"
                        error={!!formErrors.jobTitle}
                        helperText={formErrors.jobTitle}
                        value={formData.jobTitle}
                        onChange={handleChange("jobTitle")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Your Company Name"
                        fullWidth
                        required
                        type="text"
                        error={!!formErrors.companyName}
                        helperText={formErrors.companyName}
                        value={formData.companyName}
                        onChange={handleChange("companyName")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        select
                        label="Your Country"
                        fullWidth
                        type="text"
                        required
                        error={!!formErrors.country}
                        helperText={formErrors.country}
                        value={formData.country}
                        onChange={handleChange("country")}
                      >
                        {countries.map((country) => (
                          <MenuItem key={country} value={country}>
                            {country}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Total Monthly Active Users"
                        fullWidth
                        required
                        type="text"
                        error={!!formErrors.monthlyActiveUsers}
                        helperText={formErrors.monthlyActiveUsers}
                        value={formData.monthlyActiveUsers}
                        onChange={handleChange("monthlyActiveUsers")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="How did you hear about us?"
                        fullWidth
                        type="text"
                        value={formData.howDidYouHear}
                        onChange={handleChange("howDidYouHear")}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Comments for Us"
                        fullWidth
                        type="text"
                        multiline
                        rows={2}
                        value={formData.comments}
                        onChange={handleChange("comments")}
                      />
                    </Grid>
                  </Grid>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{ marginTop: "20px" }}
                  >
                    Submit
                  </Button>
                </form>
              </Paper>
            </Grid>
          </Grid>
        </Container>
        <Snackbar
          open={isSnackbarOpen}
          autoHideDuration={3000} // Adjust the duration as needed (in milliseconds)
          onClose={() => setIsSnackbarOpen(false)}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert severity="success" onClose={() => setIsSnackbarOpen(false)}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </React.Fragment>
  );
}

export default Demo;

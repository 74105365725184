import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import "../DashboardPage.css";
import {
  useMediaQuery,
  IconButton,
  Drawer,
  AppBar,
  Toolbar,
  Grid,
  Button,
  Badge,
  DialogTitle,
  DialogContent,
  InputLabel,
  Select,
  MenuItem,
  TextareaAutosize,
  Dialog,
  DialogActions,
  Snackbar,
  Alert,
  Typography,
  Card,
  CardContent,
  Link as Linking,
} from "@mui/material";
import { useNotificationContext } from "../../utils/NotificationContext"; // Import the context hook
import MenuIcon from "@mui/icons-material/Menu";
import axios from "axios";
import {
  CREATE_NOTIFICATION_ID,
  DELETE_NOTIFICATION_ALL,
  DELETE_NOTIFICATION_ID,
  GET_ALL_NOTIFICATION,
  GET_ALL_USERS_URL,
} from "../../api/user";
import { idID } from "@mui/material/locale";
import LeftContainer from "../UI/LeftContainer";

function Notification() {
  const navigate = useNavigate();
  const { state, dispatch } = useNotificationContext(); // Use the context hook
  const [data, setData] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    notificationDetails: "",
    link:""
  });

  const handleCloseCreateDialog = () => {
    setIsCreateDialogOpen(false);
    setSelectedUser("");
    setFormData({
      name: "",
      notificationDetails: "",
      link:""
    });
  };
  const handleUserSelect = (event) => {
    const selectedUserName = event.target.value;
    setSelectedUser(selectedUserName);
    setFormData((prevData) => ({
      ...prevData,
      name: selectedUserName,
    }));
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    fetchNoti();
  }, [dispatch]);
  useEffect(() => {
    fetchData();
  },[]);

  const fetchNoti = async () => {
    const token = await localStorage.getItem("token");
    try {
      const res = await axios.get(GET_ALL_NOTIFICATION, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      // Check for new notifications
      const newNotifications = res.data.notification.filter(
        (newNotif) =>
          !state.notifications.some(
            (existingNotif) => existingNotif._id === newNotif._id
          )
      );
  
      // Sort notifications by creation time in descending order
      const sortedNotifications = res.data.notification.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt); // Assuming 'createdAt' field exists
      });
  
      if (newNotifications.length > 0) {
        setData(sortedNotifications); // Set sorted notifications
  
        // Increment the notification count when new notifications arrive
        dispatch({ type: "INCREMENT" });
  
        // Add new notifications to the context state
        dispatch({
          type: "ADD_NOTIFICATION",
          payload: newNotifications,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  
  const fetchData = async () => {
    try {
      const token = localStorage.getItem('token'); // or wherever you store your token
      const response = await axios.get(GET_ALL_USERS_URL, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
  
      if (response.status === 200) {
        // Filter users based on admin status and account approval
        const filteredUsers = response.data.filter(
          (user) => user.isAdmin === false && user.isApproved === true
        );
  
        setTeamMembers(filteredUsers);
      }
    } catch (error) {
      console.error("Error fetching team members", error);
    }
  };
  
  const handleCreateTaskSubmit = async () => {
    try {
      const token = await localStorage.getItem("token");
      const selectedMember = teamMembers.find(
        (member) => member._id === selectedUser
      );

      if (selectedMember) {
        const newNotificationData = {
          name: selectedMember.name,
          comment: formData.notificationDetails,
          link: formData.link,
        };
  

        const response = await axios.post(
          CREATE_NOTIFICATION_ID(selectedUser),
          newNotificationData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        
        // Close the create task dialog
        setIsCreateDialogOpen(false);

        // Clear/reset the selectedUser state
        setSelectedUser("");

        // Clear the formData object
        setFormData({
          name: "",
          notificationDetails: "",
          link:""
        });
        fetchNoti();
        // Display a success message
        setSnackbarMessage(`Notification send to ${selectedMember.name} successfully`);
        setIsSnackbarOpen(true);

        // You may want to update the userTasks state here to reflect the newly created task
        // and update the UI accordingly.
      }
    } catch (error) {
      if (error.response.status === 400) {
        // If the error is a validation error, handle multiple validation errors
        if (error.response.data && error.response.data.details) {
          const validationErrors = Object.values(
            error.response.data.details
          ).flat();
          setSnackbarMessage(
            `Validation error: ${validationErrors.join(", ")}`
          );
        } else {
          // If no details provided, show a generic error message
          setSnackbarMessage("Validation error: Something went wrong.");
        }
        // Open the Snackbar
        setIsSnackbarOpen(true);
      }
      console.error("Error creating task", error);
    }
  };
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("isAdmin");
    window.location.href = "/login";
  };
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const isMobile = useMediaQuery("(max-width: 768px)");

  const deleteById = async (id) => {
    try {
      const token = await localStorage.getItem("token");
      await axios.delete(DELETE_NOTIFICATION_ID(id), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // Update the state to reflect changes after deletion
      setData((prevData) => prevData.filter((item) => item._id !== id));
    } catch (error) {
      console.error(error);
    }
  };

  const deleteAll = async () => {
    try {
      const token = await localStorage.getItem("token");
      await axios.delete(DELETE_NOTIFICATION_ALL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // Update the state to reflect changes after clearing all notifications
      setData([]);
    } catch (error) {
       
      console.error(error);
    }
  };
  const handleCreateTask = () => {
    setIsCreateDialogOpen(true);
  };
  return (
    <div className="container">
     {isMobile ? (
          <>
            <AppBar
              position="fixed"
              style={{ background: "transparent", boxShadow: "none" }}
            >
              <Toolbar>
                <IconButton
                  color="primary"
                  aria-label="open drawer"
                  onClick={handleDrawerToggle}
                  edge="start"
                >
                  <MenuIcon />
                </IconButton>
                {/* <LeftContainer handleLogout={handleLogout} /> */}
              </Toolbar>
            </AppBar>
            <Drawer
              variant="temporary"
              open={isDrawerOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true,
              }}
            >
              {/* ... Other code ... */}
              <LeftContainer handleLogout={handleLogout} />
              {/* ... Other code ... */}
            </Drawer>
          </>
        ) : (
          <>
            <LeftContainer handleLogout={handleLogout} />
            {/* ... Other code ... */}
          </>
        )}

      <div className="right-container">
        <div className="right-container-top">
          <p>Welcome, Admin!</p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              className="createTask"
              style={{
                marginLeft: "-10px",
                marginTop: "35px",
              }}
              onClick={handleCreateTask}
            >
              Create Notification
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="createTask"
              style={{
                height: "36.5px",
                marginTop: "35px",
              }}
              onClick={deleteAll}
            >
              clear all
            </Button> 
          </div>

          <Dialog
            open={isCreateDialogOpen}
            onClose={handleCloseCreateDialog}
            PaperProps={{
              style: {
                maxWidth: "600px", // Adjust the width as needed
                width: "100%",
              },
            }}
          >
            <DialogTitle>Create Notification</DialogTitle>
            <DialogContent>
              <div>
                <InputLabel htmlFor="name">User</InputLabel>
                <Select
                  labelId="name-label"
                  id="name"
                  name="name"
                  value={selectedUser}
                  onChange={handleUserSelect}
                  fullWidth
                >
                  {teamMembers.map((member) => (
                    <MenuItem key={member._id} value={member._id}>
                      {member.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div>
                <InputLabel htmlFor="taskDetails">
                  Notification Details (can include links)
                </InputLabel>
                <TextareaAutosize
                  id="notificationDetails"
                  name="notificationDetails"
                  value={formData.notificationDetails}
                  onChange={handleInputChange}
                  minRows={3}
                  style={{
                    width: "100%",
                    maxWidth: "100%",
                  }}
                />
              </div>
              <div>
                <InputLabel htmlFor="taskDetails">
                  Link (If any)
                </InputLabel>
                <TextareaAutosize
                  id="link"
                  name="link"
                  value={formData.link}
                  onChange={handleInputChange}
                  minRows={3}
                  style={{
                    width: "100%",
                    maxWidth: "100%",
                  }}
                />
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseCreateDialog} color="primary">
                Cancel
              </Button>
              <Button onClick={handleCreateTaskSubmit} color="primary">
                Create
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <h1>Notification</h1>
        <Grid
  container
  spacing={2}
  className="grid-container"
  style={{
    flexDirection: "column",
    width: isMobile ? "100%" : "70%",
    margin: "3px",
  }}
>
  {/* Admin Notifications Section */}
  <Grid item xs={12}>
    <Typography variant="h4" style={{ fontWeight: "bold", marginBottom: "20px" }}>
      Admin Notifications
    </Typography>
    {data.filter(item => item.idAdmin).length > 0 ? (
      data.filter(item => item.idAdmin).map((item) => (
        <Grid item key={item._id} className="grid-item">
          <Card>
            <CardContent>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                  <Linking
                    onClick={() => {
                      localStorage.setItem("Name", item.name);
                      navigate("/dashboard");
                    }}
                  >
                    {item.name}
                  </Linking>
                </Typography>
                <IconButton onClick={() => deleteById(item._id)}>
                  <CloseIcon
                    style={{
                      marginTop: "-40%",
                    }}
                  />
                </IconButton>
              </div>
              <Typography>{item.comment}</Typography>
              <Typography variant="h5" style={{ marginTop: "1rem" }} color="textSecondary">
                {item.link ? (
                  <a href={item.link} target="_blank" style={{ textDecoration: 'none', color: '#1976d2' }}>
                    Click here to view
                  </a>
                ) : (
                  'No link'
                )}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))
    ) : (
      <Typography>No admin notifications</Typography>
    )}
  </Grid>

  {/* User Notifications Section */}
  <Grid item xs={12}>
    <Typography variant="h4" style={{ fontWeight: "bold", marginBottom: "20px" }}>
      User Notifications
    </Typography>
    {data.filter(item => !item.idAdmin).length > 0 ? (
      data.filter(item => !item.idAdmin).map((item) => (
        <Grid item key={item._id} className="grid-item">
          <Card>
            <CardContent>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                  <Linking
                    onClick={() => {
                      localStorage.setItem("Name", item.name);
                      navigate("/dashboard");
                    }}
                  >
                    {item.name}
                  </Linking>
                </Typography>
                <IconButton onClick={() => deleteById(item._id)}>
                  <CloseIcon
                    style={{
                      marginTop: "-40%",
                    }}
                  />
                </IconButton>
              </div>
              <Typography>{item.comment}</Typography>
              <Typography variant="h5" style={{ marginTop: "1rem" }} color="textSecondary">
                {item.link ? (
                  <a href={item.link} target="_blank" style={{ textDecoration: 'none', color: '#1976d2' }}>
                    Click here to view
                  </a>
                ) : (
                  'No link'
                )}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))
    ) : (
      <Typography>No user notifications</Typography>
    )}
  </Grid>
</Grid>


        <Snackbar
          open={isSnackbarOpen}
          autoHideDuration={3000}
          onClose={() => setIsSnackbarOpen(false)}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert
            severity={
              snackbarMessage.includes("successfully") ? "success" : "error"
            }
            onClose={() => setIsSnackbarOpen(false)}
          >
            {snackbarMessage.includes("successfully") ? (
              <>{snackbarMessage}</>
            ) : (
              <>{snackbarMessage}</>
            )}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
}

export default Notification;

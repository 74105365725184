import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  useMediaQuery,
  IconButton,
  Drawer,
  AppBar,
  Toolbar,
  Box,
  Button,
  TextField,
  Card,
  CardContent,
  Typography,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Snackbar, Alert, CircularProgress } from "@mui/material"; // Import Snackbar and Alert
import { useNotificationContext } from "../../utils/NotificationContext";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import NonLinearStepper from "./NonLinearStepper";
import EditIcon from "@mui/icons-material/Edit"; // Add EditIcon
import VisibilityIcon from "@mui/icons-material/Visibility";
import axios from "axios";
import {
  FETCH_PROCESS_DATA_URL,
  PROCESS_DELETE_URL,
  PROCESS_SUBMIT_URL,
  PROCESS_UPDATE_URL,
} from "../../api/onBoarding";
import LeftContainer from "../UI/LeftContainer";

function AdminProcess() {
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false); // State for Snackbar
  const [snackbarMessage, setSnackbarMessage] = useState(""); // Message for the Snackbar
  const params = useParams();
  const { state, dispatch } = useNotificationContext();
  const { process: defaultProcessName } = params;
  const [flag, setFlag] = useState();
  if (defaultProcessName === "Add new Proces") {
    setFlag(1);
  }
  const [processName, setProcessName] = useState(defaultProcessName || "Field"); // State to hold the process name

  // ... (other functions)
  const navigate = useNavigate();
  const handleReset = () => {
    setFormInstances([{}]);
    setActiveStep(0);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Make an Axios request to fetch data based on the process name
        const response = await axios.get(FETCH_PROCESS_DATA_URL(processName));

        // Handle the response as needed

        // Update formInstances with the fetched data
        const { name, steps } = response.data;
        const updatedFormInstances = steps.map((step) => ({
          field1: step.title || "", // Assuming field1 is the title
          field2: step.link || "", // Assuming field2 is the link
        }));
        setProcessName(name);
        setFormInstances(updatedFormInstances);
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };

    // Fetch data when the component mounts or when the process name changes
    fetchData();
  }, [processName]);
  const [editMode, setEditMode] = useState(true); // Track edit/view mode

  // const handleEditMode = () => {
  //   setEditMode(true);
  // };

  // const handleViewMode = () => {
  //   setEditMode(false);
  // };

  const handleContinue = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const [activeStep, setActiveStep] = useState(0);
  const [viewMode, setViewMode] = useState(false); // To toggle between view and edit mode

  const handleViewMode = () => {
    setViewMode(true);
  };

  const handleEditMode = () => {
    setViewMode(false);
  };

  const handleDeleteInstance = (index) => {
    setFormInstances((prevInstances) => {
      const updatedInstances = [...prevInstances];
      updatedInstances.splice(index, 1);
      return updatedInstances;
    });
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("isAdmin");
    window.location.href = "/login";
  };

  const [formFields, setFormFields] = useState({});
  const [formInstances, setFormInstances] = useState([{}]);

  const handleInputChange = (instanceIndex, field, value) => {
    setFormInstances((prevInstances) => {
      const updatedInstances = [...prevInstances];
      updatedInstances[instanceIndex] = {
        ...(updatedInstances[instanceIndex] || {}),
        [field]: value,
      };
      return updatedInstances;
    });
  };

  const handleAddInstance = () => {
    setFormInstances((prevInstances) => [
      ...prevInstances,
      { field1: "", field2: "" }, // Adjust with default values if needed
    ]);
  };

  const handleDeleteProcess = async () => {
    try {
      // Make an Axios request to delete the process
      await axios.delete(PROCESS_DELETE_URL(processName));
      navigate("/onBoardingAdmin");
      // Handle the response as needed

      // You can reset the form or perform any other actions upon successful deletion
      handleReset();
    } catch (error) {
      // Handle errors
      console.error("Error deleting process:", error.message);
    }
  };
  const handleUpdate = async () => {
    try {
      // Prepare the data to be sent in the specified format

      const steps1 = formInstances.map((instance) => ({
        title: instance.field1 || "", // Assuming field1 is the title
        link: instance.field2 || "", // Assuming field2 is the link
      }));

      // Make an Axios request to update the data
      const response = await axios.put(
        PROCESS_UPDATE_URL(defaultProcessName),
        {
          processName: processName,
          steps: steps1,
        },
        {
          headers: {
            // Add any headers if required
            "Content-Type": "application/json",
            // Add your authentication headers if needed
          },
        }
      );
      navigate("/onBoardingAdmin");
      // Handle the response as needed

      // Update the state with the updated data
      const { name, steps } = response.data;
      const updatedFormInstances = steps.map((step) => ({
        field1: step.title || "", // Assuming field1 is the title
        field2: step.link || "", // Assuming field2 is the link
      }));
      setProcessName(name);
      setFormInstances(updatedFormInstances);

      // Optionally, switch back to view mode after updating
      setViewMode(true);

      // You can reset the form or perform any other actions upon successful update
      // handleReset();
    } catch (error) {
      if (error.response.status === 400) {
        // If the error is a validation error, handle multiple validation errors
        if (error.response.data && error.response.data.details) {
          const validationErrors = Object.values(
            error.response.data.details
          ).flat();
          setSnackbarMessage(
            `Validation error: ${validationErrors.join(", ")}`
          );
        } else {
          // If no details provided, show a generic error message
          setSnackbarMessage("Validation error: Something went wrong.");
        }
        // Open the Snackbar
        setIsSnackbarOpen(true);
      }
      console.error("Error fetching data:", error);
    }
  };
  const handleSubmit = async () => {
    try {
      // Prepare the data to be sent in the specified format
      const formData = {
        name: processName,
        steps: formInstances.map((instance) => ({
          title: instance.field1 || "", // Assuming field1 is the title
          link: instance.field2 || "", // Assuming field2 is the link
        })),
      };

      // Make an Axios request to submit the data
      const response = await axios.post(PROCESS_SUBMIT_URL, formData, {
        headers: {
          // Add any headers if required
          "Content-Type": "application/json",
          // Add your authentication headers if needed
        },
      });

      navigate("/onBoardingAdmin");
      const { name, steps } = response.data;
      const updatedFormInstances = steps.map((step) => ({
        field1: step.title || "", // Assuming field1 is the title
        field2: step.link || "", // Assuming field2 is the link
      }));
      setProcessName(name);
      setFormInstances(updatedFormInstances);
      // Handle the response as needed

      // You can reset the form or perform any other actions upon successful submission
      handleReset();
    } catch (error) {
      // Handle errors
      if (error.response.status === 400) {
        // If the error is a validation error, handle multiple validation errors
        if (error.response.data && error.response.data.details) {
          const validationErrors = Object.values(
            error.response.data.details
          ).flat();
          setSnackbarMessage(
            `Validation error: ${validationErrors.join(", ")}`
          );
        } else {
          // If no details provided, show a generic error message
          setSnackbarMessage("Validation error: Something went wrong.");
        }
        // Open the Snackbar
        setIsSnackbarOpen(true);
      }
      console.error("Error submitting form:", error.message);
    }
  };

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const isMobile = useMediaQuery("(max-width: 768px)");
  const handleProcessNameChange = (e) => {
    setProcessName(e.target.value);
  };
  return (
    <div className="container">
      {isMobile ? (
        <>
          <AppBar
            position="fixed"
            style={{ background: "transparent", boxShadow: "none" }}
          >
            <Toolbar>
              <IconButton
                color="primary"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
              {/* <LeftContainer handleLogout={handleLogout} /> */}
            </Toolbar>
          </AppBar>
          <Drawer
            variant="temporary"
            open={isDrawerOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {/* ... Other code ... */}
            <LeftContainer handleLogout={handleLogout} />
            {/* ... Other code ... */}
          </Drawer>
        </>
      ) : (
        <>
          <LeftContainer handleLogout={handleLogout} />
          {/* ... Other code ... */}
        </>
      )}

      <div className="right-container">
        <div className="right-container-top">
          <p>Welcome, Admin!</p>
          <div style={{ flexGrow: 1 }}></div>

          <div className="onboarding-title">
            <h2>Process Board - Step {activeStep + 1}</h2>
            {viewMode ? (
              <h2>{processName}</h2>
            ) : (
              <TextField
                label="Process Name"
                value={processName}
                onChange={handleProcessNameChange}
                fullWidth
                margin="normal"
              />
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {viewMode ? (
            <NonLinearStepper
              formInstances={formInstances}
              activeStep={activeStep}
              responsive={isMobile}
              completed={{}}
              handleStep={() => {}}
              handleNext={() => {}}
              handleBack={() => {}}
              handleComplete={() => {}}
              handleReset={() => {}}
            />
          ) : (
            <Stepper activeStep={activeStep} orientation="vertical">
              {formInstances.map((instance, index) => (
                <Step key={index}>
                  <StepLabel>{`${processName} Step ${index + 1}: ${
                    instance.field1 || ""
                  }`}</StepLabel>
                  <Card style={{ marginBottom: "16px", position: "relative" }}>
                    <CardContent>
                      <Typography variant="h6" gutterBottom>
                        {`${processName} Step ${index + 1}`}
                        <IconButton
                          edge="end"
                          color="secondary"
                          onClick={() => handleDeleteInstance(index)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Typography>
                      <TextField
                        label={`${processName} Field 1`}
                        value={instance.field1 || ""}
                        onChange={(e) =>
                          handleInputChange(index, "field1", e.target.value)
                        }
                        fullWidth
                        margin="normal"
                      />
                      <TextField
                        label={`${processName} Field 2`}
                        value={instance.field2 || ""}
                        onChange={(e) =>
                          handleInputChange(index, "field2", e.target.value)
                        }
                        fullWidth
                        margin="normal"
                      />
                    </CardContent>

                    {index === formInstances.length - 1 && (
                      <IconButton
                        style={{
                          position: "absolute",
                          top: "8px",
                          right: "8px",
                        }}
                        onClick={handleAddInstance}
                      >
                        <AddIcon />
                      </IconButton>
                    )}
                  </Card>
                </Step>
              ))}
            </Stepper>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            gap: "20px",
            marginTop: "20px",
            marginBottom: "30px",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={
              defaultProcessName === "Add new Process"
                ? handleSubmit
                : handleUpdate
            }
            style={{ width: isMobile ? "30%" : "10%" }}
          >
            {viewMode ? <SaveIcon /> : <SaveIcon />}
            {defaultProcessName === "Add new Process" ? "Save" : "Update"}
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={viewMode ? handleEditMode : handleViewMode}
            style={{ width: isMobile ? "30%" : "10%" }}
          >
            {!viewMode ? <VisibilityIcon /> : <EditIcon />}
            {!viewMode ? "View" : "Edit"}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleDeleteProcess}
            style={{ width: isMobile ? "30%" : "10%" }}
          >
            Delete
          </Button>
        </div>
      </div>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={3000}
        onClose={() => setIsSnackbarOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          severity={
            snackbarMessage.includes("successfully") ? "success" : "error"
          }
          onClose={() => setIsSnackbarOpen(false)}
        >
          {snackbarMessage.includes("successfully") ? (
            <>{snackbarMessage}</>
          ) : (
            <>{snackbarMessage}</>
          )}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default AdminProcess;

import * as React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useState, useEffect } from "react";
import { Button, MenuItem, Select } from "@mui/material";
import {
  CHANGE_AFTER_STATUS_URL,
  CHANGE_ASSIGNED_STATUS_URL,
  FETCH_APPLY_COURSE_URL,
} from "../../api/sales";
import { GET_ALL_USERS_URL } from "../../api/user";
import {
  useMediaQuery,
  IconButton,
  Drawer,
  AppBar,
  Toolbar,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import LeftContainer from "../UI/LeftContainer";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const ORG_BASE_URL = process.env.REACT_APP_ORG_BASE_URL;
function DataGridDemo() {
  const navigation = useNavigate();
  const [purchasedCount, setPurchasedCount] = useState(0);
  const [notPurchasedCount, setNotPurchasedCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [data, setData] = useState();
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [assigned, setAssignedTo] = useState();
  const [status, setStatus] = useState();
  const [id, setId] = useState("");

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const isMobile = useMediaQuery("(max-width: 768px)");

  const handleAssignedToChange = async (selectedOption, rowIndex, courseId) => {
    try {
      const changeAssignedStatusUrl = CHANGE_ASSIGNED_STATUS_URL(
        courseId,
        selectedOption
      );

      const res = await axios.patch(changeAssignedStatusUrl, {
        status: "Assigned",
      });

      setId(selectedOption);
      setAssignedTo(res.data.message.courseId);
    } catch (error) {
      console.error(error);
    }
    if (rowIndex >= 0) {
      setRows((prevRows) => {
        const updatedRows = [...prevRows];
        updatedRows[rowIndex].assignedTo = selectedOption;
        return updatedRows;
      });
    }
  };

  const handleAfterStatusChange = async (
    selectedValue,
    rowId,
    courseId,
    userId
  ) => {
    try {
      const changeAfterStatusUrl = CHANGE_AFTER_STATUS_URL(courseId, userId);

      const res = await axios.patch(changeAfterStatusUrl, {
        status: selectedValue,
      });

      setStatus(res.data.message.afterStatus);
    } catch (error) {
      console.error(error);
    }

    if (rowId >= 0) {
      setRows((prevRows) => {
        const updatedRows = [...prevRows];
        updatedRows[rowId].afterStatus = selectedValue;
        return updatedRows;
      });
    }
  };

  useEffect(() => {
    getData();
  }, [id]);

  const getData = async () => {
    try {
      const res = await axios.get(FETCH_APPLY_COURSE_URL);

      const add = await res.data.data.map((item, index) => ({
        courseId: item._id,
        id: index + 1,
        fullname: item.fName + " " + item.lName,
        date: new Date(item.date),
        description: item.description,
        email: item.email,
        wNumber: item.wNumber,
        year: item.year,
        assignedTo: item.assignedTo,
        afterStatus: item.status,
        userStatus: item.userStatus,
      }));
      const purchasedCount = add.filter(
        (item) => item.userStatus === "purchased"
      ).length;
      const notPurchasedCount = add.filter(
        (item) => item.userStatus === "not purchased"
      ).length;
      const totalCount = add.length - purchasedCount - notPurchasedCount;

      setPurchasedCount(purchasedCount);
      setNotPurchasedCount(notPurchasedCount);
      setTotalCount(totalCount);

      setRows(add);

      const response = await axios.get(GET_ALL_USERS_URL);
      if (response.status === 200) {
        // Filter users based on admin status and account approval
        const filteredUsers = response.data.filter(
          (user) => user.isAdmin === false && user.isApproved === true
        );

        setData(filteredUsers);

        const col = [
          { field: "fullname", headerName: "Full Name", width: 120 },
          { field: "date", headerName: "Date", width: 150 },
          { field: "description", headerName: "Description", width: 210 },
          { field: "email", headerName: "Email", width: 200 },
          {
            field: "assignedTo",
            headerName: "Assigned To",
            width: 100,
            renderCell: (params) => {
              const rowIndex = params.row.id - 1; // Adjusting the index based on the id

              return (
                <Select
                  value={add[rowIndex].assignedTo}
                  onChange={(event) => {
                    const newValue = event.target.value;

                    handleAssignedToChange(
                      newValue,
                      rowIndex,
                      params.row.courseId
                    );
                  }}
                >
                  {filteredUsers.map((item) => (
                    <MenuItem key={item._id} value={item._id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              );
            },
          },

          {
            field: "afterStatus",
            headerName: "After Status",
            width: 150,
            renderCell: (params) => {
              const rowIndex = params.row.id - 1; // Adjusting the index based on the id
              return (
                <Select
                  value={add[rowIndex].afterStatus} // Use the row-specific value
                  onChange={(event) => {
                    const newValue = event.target.value;
                    handleAfterStatusChange(
                      newValue,
                      rowIndex,
                      params.row.courseId,
                      add[rowIndex].assignedTo
                    ); // Pass row id for identifying the row
                  }}
                  placeholder="Status"
                >
                  <MenuItem value="Assigned">Assigned</MenuItem>
                  <MenuItem value="Deactivate">Deactivate</MenuItem>
                  <MenuItem value="Purchased">Purchased</MenuItem>
                  <MenuItem value="Upselling">Upselling</MenuItem>
                </Select>
              );
            },
          },

          { field: "wNumber", headerName: "Number", width: 120 },
          { field: "year", headerName: "Year" },
        ];
        setColumns(col);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Create an array of rows using data

  const [filterModel, setFilterModel] = React.useState({ items: [] });

  const handleFilterModelChange = (model) => {
    setFilterModel(model);
  };

  const handleLogout = () => {
    // Remove the token from local storage
    localStorage.removeItem("token");
    localStorage.removeItem("isAdmin");
    // Redirect the user to the login page or any other desired page
    window.location.href = "/login";
  };

  return (
    <div className="container">
    {isMobile ? (
          <>
            <AppBar
              position="fixed"
              style={{ background: "transparent", boxShadow: "none" }}
            >
              <Toolbar>
                <IconButton
                  color="primary"
                  aria-label="open drawer"
                  onClick={handleDrawerToggle}
                  edge="start"
                >
                  <MenuIcon />
                </IconButton>
                {/* <LeftContainer handleLogout={handleLogout} /> */}
              </Toolbar>
            </AppBar>
            <Drawer
              variant="temporary"
              open={isDrawerOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true,
              }}
            >
              {/* ... Other code ... */}
              <LeftContainer handleLogout={handleLogout} />
              {/* ... Other code ... */}
            </Drawer>
          </>
        ) : (
          <>
            <LeftContainer handleLogout={handleLogout} />
            {/* ... Other code ... */}
          </>
        )}

      <div className="right-container">
        <div className="right-container-top">
          <p>Welcome, Admin!</p>
          <div
            style={{
              left: "70%",
              marginBottom: "10px",
              padding: "10px",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              style={{
                marginRight: "10px",
              }}
              // style={{ marginLeft: "-10px", marginTop: "50px", marginBottom: "10px" }}
              onClick={() => {
                navigation("/salesstatus", {
                  state: {
                    statusSales: "purchased",
                  },
                });
              }}
            >
              Purchased
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                navigation("/salesstatus", {
                  state: {
                    statusSales: "not purchased",
                  },
                });
              }}
            >
              Not Purchased
            </Button>
          </div>
          <div className="status-boxes">
            <div className="status-box assigned">
              Purchased: {purchasedCount}
            </div>
            <div className="status-box pending">Assigned: {totalCount}</div>
            <div className="status-box completed">
              Not Purchased: {notPurchasedCount}
            </div>
            <div className="status-box completed">
              Total: {purchasedCount + notPurchasedCount + totalCount}
            </div>
          </div>
        </div>

        <div className="right-container-table" style={{ marginTop: "-30px" }}>
          <h1>Sales</h1>
          <div style={{ height: 600, width: "100%" }}>
            {/* DataGrid with a toolbar */}
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5, 10, 20]}
              checkboxSelection={false}
              filterModel={filterModel}
              disableColumnSelector // Disable column selector
              showCellVerticalBorder={false}
              onFilterModelChange={handleFilterModelChange}
              components={{
                Toolbar: GridToolbar,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DataGridDemo;

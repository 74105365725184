import { BASE_URL } from "./client";

// Fetch course by assigned ID
export const FETCH_APPLY_COURSE_ID_URL = (ToAssigned) =>
  `${BASE_URL}/api/applycourse/${ToAssigned}`;

// Fetch all leads
export const FETCH_LEADS = `${BASE_URL}/api/leads`;

// Create a new lead
export const CREATE_LEADS = `${BASE_URL}/api/leads`;

// Update a lead
export const UPDATE_LEADS = `${BASE_URL}/api/leads`;

// Save multiple leads
export const  SAVE_LEAD_DATA_URL = `${BASE_URL}/api/leads/save`;

import { Navigate, Route, Routes } from "react-router-dom";
import "./App.css";

import { Dashboard } from "./Components/Dashboard";
import {
  CompletedTaskDashboard,
  AdminDashboard,
  TaskAssigned,
  GraphicalView,
  OLDAdminDashboard,
  NewDashboard

} from "./Components/AdminDashboard";

import {
  UserTasks,
  Team,
  NewTeam,
  TeamTable,
  TeamDetails,
  NewTeamDetails,
  NewTaskAssigned,
} from "./Components/ManageUser";
import { AdminProfile, Profile, UserProfile } from "./Components/Profile/index";
import Target from "./Components/Target/Target"
import AllTarget from "./Components/Target/AllTarget"
import {
  AdminProcess,
  CompleteProgress,
  OnBoardingAdmin,
  UserOnBoarding,
  ViewProgress,
} from "./Components/OnBoardingAdmin/index";
import { ForgetEmail, HomePage, Login, ResetPassword } from "./Components/auth";
import LevelAchieved from "./Components/LevelAchieved";
import { useEffect, useState } from "react";

import { Lor, Certificate } from "./Components/Certificate";
import { Lead,NewUserLead } from "./Components/Lead";
import { Demo } from "./Components/Demo";
import { PastTable, OverviewTable } from "./Components/Overview";

import { Notification, UserNotification } from "./Components/Notification";

import { SalesStatus, DataGridDemo } from "./Components/Sales";

import { NoShow, Leave, LeaveAdminHandleScreen } from "./Components/Leave";

import { Access } from "./Components/AccessManagment";
import { ReplyChat, ChatSupportPanel } from "./Components/ChatSupport";
import NewProfileAdminView from "./Components/AdminDashboard/NewProfileAdminView";
import NewProfileAdminDashboard from "./Components/AdminDashboard/NewProfileAdminDashboard";


import NewLead from "./Components/NewLead"
function App() {
  //state for check isAuthenticated or not
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  useEffect(() => {
    // Check local storage for authentication token
    const token = localStorage.getItem("token");
    const isAdmin1 = localStorage.getItem("isAdmin");
    const isSuperAdmin = localStorage.getItem("isSuperAdmin");
    // console.log(token);
    if (token) {
      setIsAuthenticated(true);
    }
    if (isAdmin1 === "true") {
      setIsAdmin(true);
    }
  }, [isAuthenticated, isAdmin]);

  return (
    <div>
      <Routes>
        {isAuthenticated ? (
          <>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/leave" element={<Leave />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/tasks" element={<UserTasks />} />
          </>
        ) : (
          <></>
        )}
        {isAuthenticated ? (
          !isAdmin ? (
            <>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/userNotification" element={<UserNotification />} />
              <Route path="/replyChat" element={<ReplyChat />} />
              <Route path="/userOnBoarding" element={<UserOnBoarding />} />
              <Route path="/levelAchieved" element={<LevelAchieved />} />
              <Route path="/lead" element={<Lead />} />
              <Route path="/newlead" element={<NewUserLead />} />
              <Route path="/leave" element={<Leave />} />
              <Route path="/teams" element={<NewTeam />} />
              <Route
                path="/taskAssign/:teamName/:teamId"
                element={<NewTaskAssigned />}
              />
              <Route
                path="/team/:teamName/:teamId"
                element={<NewTeamDetails />}
              />
              <Route path="/noshow" element={<NoShow />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/tasks" element={<UserTasks />} />
              {/* <Route path="/adminDashboard" element={<AdminDashboard />} /> */}
              <Route
                path="/login"
                element={<Navigate to="/dashboard" replace />}
              />
              <Route
                path="/adminDashboard"
                element={<Navigate to="/dashboard" replace />}
              />
              <Route
                path="/sales"
                element={<Navigate to="/dashboard" replace />}
              />
              <Route
                path="/past"
                element={<Navigate to="/dashboard" replace />}
              />
              <Route path="/" element={<Navigate to="/dashboard" replace />} />
              <Route
                path="/Forgetpassword"
                element={<Navigate to="/dashboard" replace />}
              />
              <Route
                path="/resetpassword/:id/:token"
                element={<Navigate to="/dashboard" replace />}
              />
            </>
          ) : (
            <>
              <Route path="/adminDashboard" element={<AdminDashboard />} />
              <Route path="/newDashboard" element={<NewDashboard />} />
              <Route
                path="/newProfileAdminView/:id"
                element={<NewProfileAdminDashboard />}
              />
              <Route path="/graphicalView" element={<GraphicalView />} />
              <Route
                path="/oldAdminDashboard"
                element={<OLDAdminDashboard />}
              />
              {/* <Route path="/chatSupport" element={<ChatSupportPanel />} /> */}
              <Route
                path="/leaveAdminHandle"
                element={<LeaveAdminHandleScreen />}
              />

              <Route path="/notification" element={<Notification />} />
              <Route path="/access" element={<Access />} />
              <Route path="/completed" element={<CompletedTaskDashboard />} />
              <Route path="/levelAchieved" element={<LevelAchieved />} />
              <Route path="/onBoardingAdmin" element={<OnBoardingAdmin />} />
              <Route path="/viewProgress" element={<ViewProgress />} />
              <Route path="/completedProgress" element={<CompleteProgress />} />
              <Route path="/adminProcess/:process" element={<AdminProcess />} />
              <Route path="/salesstatus" element={<SalesStatus />} />
              <Route path="/sales" element={<DataGridDemo />} />
              <Route
                path="/taskAssign/:teamName/:teamId"
                element={<TaskAssigned />}
              />

                <Route path="/newlead" element={<NewLead/>}/>

              <Route path="/past" element={<PastTable />} />
              <Route path="/overview" element={<OverviewTable />} />
              <Route path="/teams" element={<Team />} />
              <Route path="/teamsDashboard" element={<TeamTable />} />
              <Route path="/team/:teamName" element={<TeamDetails />} />
              <Route path="/team/:teamName/:teamId" element={<TeamDetails />} />
              <Route path="/Adminprofile" element={<AdminProfile />} />
              <Route path="/target-set" element={<Target/>}/>
              <Route path="/alltarget" element={<AllTarget/>}/>
              <Route path="/users" element={<UserProfile />} />
              <>
                <Route
                  path="/lead"
                  element={<Navigate to="/adminDashboard" replace />}
                />
                <Route
                  path="/login"
                  element={<Navigate to="/adminDashboard" replace />}
                />
                <Route
                  path="/"
                  element={<Navigate to="/adminDashboard" replace />}
                />
                <Route
                  path="/Forgetpassword"
                  element={<Navigate to="/adminDashboard" replace />}
                />
                <Route
                  path="/resetpassword/:id/:token"
                  element={<Navigate to="/adminDashboard" replace />}
                />
              </>
            </>
          )
        ) : (
          <>
            <>
              <Route path="/" element={<HomePage />} />
              <Route path="/login" element={<Login />} />
              <Route path="/Forgetpassword" element={<ForgetEmail />} />
              <Route
                path="/resetpassword/:id/:token"
                element={<ResetPassword />}
              />
            </>
          </>
        )}
        <Route path="/certificate/:userId" element={<Certificate />} />
        <Route path="/lor/:userId" element={<Lor />} />
        <Route path="/demo" element={<Demo />} />
        {/* For unauthenticated or other routes */}
      </Routes>
    </div>
  );
}

export default App;

import { BASE_URL } from "./client";

export const UPDATE_PROFILE_URL = (admin, id) => {
  const baseEndpoint = admin
    ? `${BASE_URL}/api/userRequest/updateProfileByAdmin/${id}`
    : `${BASE_URL}/api/userRequest/updateProfile`;

  return baseEndpoint;
};

export const CHANGE_PASSWORD_URL = (userId) =>
  `${BASE_URL}/api/auth/change-password/${userId}`;

export const CHANGE_PASSWORD_ADMIN_URL = `${BASE_URL}/api/auth/change-password`;

export const FETCH_USER_DATA_URL = (admin, id) =>
  admin === "true"
    ? `${BASE_URL}/api/userRequest/${id}`
    : `${BASE_URL}/api/userRequest/taskUser`;
export const FETCH_USER_DATA_NOID_URL = `${BASE_URL}/api/userRequest/taskUser`;

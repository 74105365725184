import React from "react";
import "./HoverButton.css";

function HoverButton() {
  return (
    <div>
      <a href="#" className="button">
        Request a Demo
        <span className="arrow"></span>
      </a>
    </div>
  );
}

export default HoverButton;

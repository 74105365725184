import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  IconButton,
  Drawer,
  AppBar,
  Toolbar,
  Button,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { DataGrid } from "@mui/x-data-grid";
import LeftContainer from "../UI/LeftContainer";
import { GET_ALL_USERS_URL, UPDATE_ACCESS_REQ } from "../../api/user";
import axios from "axios";

function Access() {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedFields, setSelectedFields] = useState({
    adminDashboard: false,
    leave: false,
    manageUsers: false,
    notCurrent: false,
    onBoarding: false,
    notifications: false,
    teams: false,
    overview: false,
    sales: false,
    chatSupport: false,
  });

  useEffect(() => {
    fetchUser();
  }, []);

  const fetchUser = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(GET_ALL_USERS_URL, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 200) {
        const filteredUsers = response.data.filter(
          (user) => user.isAdmin === true && user.isApproved === true
        );
        setData(filteredUsers);
      }
    } catch (error) {
      console.error("Error fetching users", error);
      // Optional: Navigate to login if unauthorized
      if (error.response && error.response.status === 401) {
        handleLogout();
      }
    }
  };

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("isAdmin");
    window.location.href = "/login";
  };

  const handleOpenDialog = (user) => {
    setSelectedUser(user);
    setSelectedFields({
      adminDashboard: user.adminDashboard || false,
      leave: user.leave || false,
      manageUsers: user.manageUsers || false,
      notCurrent: user.notCurrent || false,
      onBoarding: user.onBoarding || false,
      notifications: user.notifications || false,
      teams: user.teams || false,
      overview: user.overview || false,
      sales: user.sales || false,
      chatSupport: user.chatSupport || false,
    });
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedUser(null);
  };

  const handleCheckboxChange = (field) => (event) => {
    setSelectedFields({
      ...selectedFields,
      [field]: event.target.checked,
    });
  };

  const handleDialogSubmit = async () => {
    try {
      const token = localStorage.getItem("token");
      await axios.patch(
        UPDATE_ACCESS_REQ(selectedUser.userId),
        selectedFields,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      fetchUser();
    } catch (error) {
      console.error("Error updating access", error);
    }
    handleCloseDialog();
  };

  const handleDialogCancel = () => {
    setSelectedFields({
      adminDashboard: false,
      leave: false,
      manageUsers: false,
      notCurrent: false,
      onBoarding: false,
      notifications: false,
      teams: false,
      overview: false,
      sales: false,
      chatSupport: false,
    });
    handleCloseDialog();
  };

  const renderConfigureButton = (user) => (
    <Button
      key={user._id}
      variant="contained"
      color="primary"
      className="configureFields"
      onClick={() => handleOpenDialog(user)}
    >
      Configure Fields
    </Button>
  );

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "name", headerName: "Name", width: 150 },
    { field: "email", headerName: "Email", width: 200 },
    {
      field: "selectedFields",
      headerName: "Selected Fields",
      flex: 1,
      renderCell: (params) => {
        const selectedFields = Object.entries(params.row)
          .filter(
            ([field, value]) =>
              field !== "id" && field !== "userId" && value === true
          )
          .map(([field]) => field)
          .join(", ");

        return (
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            title={selectedFields}
          >
            {selectedFields}
          </div>
        );
      },
    },
    {
      field: "configure",
      headerName: "Configure",
      width: 200,
      renderCell: (params) => renderConfigureButton(params.row),
    },
  ];

  const rows = data.map((user, index) => ({
    id: index + 1,
    userId: user._id,
    name: user.name,
    email: user.email,
    adminDashboard: user.adminDashboard || false,
    leave: user.leave || false,
    manageUsers: user.manageUsers || false,
    notCurrent: user.notCurrent || false,
    onBoarding: user.onBoarding || false,
    notifications: user.notifications || false,
    teams: user.teams || false,
    overview: user.overview || false,
    sales: user.sales || false,
    chatSupport: user.chatSupport || false,
  }));

  return (
    <div className="container">
      {isMobile ? (
        <>
          <AppBar
            position="fixed"
            style={{ background: "transparent", boxShadow: "none" }}
          >
            <Toolbar>
              <IconButton
                color="primary"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="temporary"
            open={isDrawerOpen}
            onClose={handleDrawerToggle}
            ModalProps={{ keepMounted: true }}
          >
            <LeftContainer handleLogout={handleLogout} />
          </Drawer>
        </>
      ) : (
        <LeftContainer handleLogout={handleLogout} />
      )}
      <div className="right-container">
        <div
          className="right-container-top"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <p>Welcome, Admin!</p>
        </div>
        <div className="right-container-table" style={{ marginTop: "50px", height: "600px" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5, 10, 20]}
            checkboxSelection
          />
        </div>
      </div>
      {selectedUser && (
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>{`Configure Fields for ${selectedUser.name}`}</DialogTitle>
          <DialogContent>
            <FormGroup>
              {Object.entries(selectedFields).map(([field, checked]) => (
                <FormControlLabel
                  key={field}
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={handleCheckboxChange(field)}
                    />
                  }
                  label={field}
                />
              ))}
            </FormGroup>
          </DialogContent>
          <div style={{ padding: "10px", textAlign: "right" }}>
            <Button variant="outlined" onClick={handleDialogCancel}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: "10px" }}
              onClick={handleDialogSubmit}
            >
              Submit
            </Button>
          </div>
        </Dialog>
      )}
    </div>
  );
}

export default Access;

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import { useNotificationContext } from "../../utils/NotificationContext";
import "../DashboardPage.css";
import {
  useMediaQuery,
  IconButton,
  Drawer,
  AppBar,
  Toolbar,
  Grid,
  Button,
  Badge,
  DialogTitle,
  DialogContent,
  InputLabel,
  Select,
  MenuItem,
  TextareaAutosize,
  Dialog,
  DialogActions,
  Snackbar,
  Alert,
  Typography,
  Card,
  CardContent,
  Link as Linking,
} from "@mui/material";

import CheckIcon from "@mui/icons-material/Check";
import MenuIcon from "@mui/icons-material/Menu";
import axios from "axios";
import {
  CREATE_NOTIFICATION_ID,
  DELETE_NOTIFICATION_ALL,
  DELETE_NOTIFICATION_ID,
  GET_ALL_NOTIFICATION,
  GET_ALL_USERS_URL_BY_USER,
} from "../../api/user";
import UserLeftContainer from "../UI/UserLeftContainer";

function UserNotification() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const { state, dispatch } = useNotificationContext(); // Use the context hook
  const [admin, setAdmin] = useState("false");
  const [onBoardingStatus, setOnBoardingStatus] = useState(false);
  const [teamMembers, setTeamMembers] = useState([]);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [selectedAdmin, setselectedAdmin] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    notificationDetails: "",
    link:""
  });

  const handleCloseCreateDialog = () => {
    setIsCreateDialogOpen(false);
    setselectedAdmin("");
    setFormData({
      name: "",
      notificationDetails: "",
      link:""
    });
  };

  const handleUserSelect = (event) => {
    const selectedAdminName = event.target.value;
    setselectedAdmin(selectedAdminName);
    setFormData((prevData) => ({
      ...prevData,
      name: selectedAdminName,
    }));
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  useEffect(() => {
    fetchNoti();
  }, [dispatch]);
  useEffect(() => {
    fetchData();
  },[]);

  const fetchNoti = async () => {
    const token = await localStorage.getItem("token");
    try {
      const res = await axios.get(GET_ALL_NOTIFICATION, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      // Check for new notifications
      const newNotifications = res.data.notification.filter(
        (newNotif) =>
          !state.notifications.some(
            (existingNotif) => existingNotif._id === newNotif._id
          )
      );
  
      // Sort notifications by creation time in descending order
      const sortedNotifications = res.data.notification.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt); // Assuming 'createdAt' field exists
      });
  
      if (newNotifications.length > 0) {
        setData(sortedNotifications); // Set sorted notifications
      // Store the notification count in localStorage
      localStorage.setItem("notificationCount", res.data.notification.length);
       
        // Increment the notification count when new notifications arrive
        dispatch({ type: "INCREMENT" });
  
        // Add new notifications to the context state
        dispatch({
          type: "ADD_NOTIFICATION",
          payload: newNotifications,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  
  const fetchData = async () => {
    try {
      const token = localStorage.getItem("token"); // or wherever you store your token
      const response = await axios.get(GET_ALL_USERS_URL_BY_USER, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response.status === 200) {
        // Filter users based on admin and superAdmin status
        const filteredUsers = response.data.filter(
          (user) => user.isAdmin === true || user.isSuperAdmin === true
        );
  
        setTeamMembers(filteredUsers); // Set the filtered admins and super admins
      }
    } catch (error) {
      console.error("Error fetching team members", error);
    }
  };

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const isMobile = useMediaQuery("(max-width: 768px)");

  const deleteById = async (id) => {
    try {
      const token = await localStorage.getItem("token");
      await axios.delete(DELETE_NOTIFICATION_ID(id), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // Update the state to reflect changes after deletion
      setData((prevData) => prevData.filter((item) => item._id !== id));
      // Update the notification count in localStorage
      localStorage.setItem("notificationCount", data.length - 1);
    } catch (error) {
      console.error(error);
    }
  };

  const deleteAll = async () => {
    try {
      const token = await localStorage.getItem("token");
      await axios.delete(DELETE_NOTIFICATION_ALL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // Update the state to reflect changes after clearing all notifications
      setData([]);
      // Reset the notification count in localStorage
      localStorage.setItem("notificationCount", 0);
    } catch (error) {
      console.error(error);
    }
  };
  const handleCreateTask = () => {
    setIsCreateDialogOpen(true);
  };
  const handleCreateTaskSubmit = async () => {
    try {
      const token = await localStorage.getItem("token");
      const selectedMember = teamMembers.find(
        (member) => member._id === selectedAdmin
      );

      if (selectedMember) {
        const newNotificationData = {
          name: selectedMember.name,
          comment: formData.notificationDetails,
          link: formData.link,
        };
  

        const response = await axios.post(
          CREATE_NOTIFICATION_ID(selectedAdmin),
          newNotificationData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        
        // Close the create task dialog
        setIsCreateDialogOpen(false);

        // Clear/reset the selectedAdmin state
        setselectedAdmin("");

        // Clear the formData object
        setFormData({
          name: "",
          notificationDetails: "",
          link:""
        });
        fetchNoti();
        // Display a success message
        setSnackbarMessage(`Notification send to ${selectedMember.name} successfully`);
        setIsSnackbarOpen(true);

        // You may want to update the userTasks state here to reflect the newly created task
        // and update the UI accordingly.
      }
    } catch (error) {
      if (error.response.status === 400) {
        // If the error is a validation error, handle multiple validation errors
        if (error.response.data && error.response.data.details) {
          const validationErrors = Object.values(
            error.response.data.details
          ).flat();
          setSnackbarMessage(
            `Validation error: ${validationErrors.join(", ")}`
          );
        } else {
          // If no details provided, show a generic error message
          setSnackbarMessage("Validation error: Something went wrong.");
        }
        // Open the Snackbar
        setIsSnackbarOpen(true);
      }
      console.error("Error creating task", error);
    }
  };
  return (
    <div className="container">
      {isMobile ? (
        <>
          <AppBar
            position="fixed"
            style={{ background: "transparent", boxShadow: "none" }}
          >
            <Toolbar>
              <IconButton
                color="primary"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
              <p>Welcome, Admin!</p>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="temporary"
            open={isDrawerOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <UserLeftContainer />
          </Drawer>
        </>
      ) : (
        <>
          <UserLeftContainer />
        </>
      )}
      <div
        className="right-container"
        style={{
          backgroundColor: "#DAEDE3",
          padding: "20px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div className="right-container-top">
          <p>Welcome, Admin!</p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
          <Button
              variant="contained"
              color="primary"
              className="createTask"
              style={{
                marginLeft: "-10px",
                marginTop: "35px",
              }}
              onClick={handleCreateTask}
            >
              Create Notification
            </Button>
          <Button
            variant="contained"
            color="primary"
            className="createTask"
            style={{
              marginLeft: "-10px",
              marginTop: "50px",
              marginBottom: "10px",
            }}
            onClick={deleteAll}
          >
            clear all
          </Button>
          </div>
        </div>
    <Dialog
  open={isCreateDialogOpen}
  onClose={handleCloseCreateDialog}
  PaperProps={{
    style: {
      maxWidth: "600px", // Adjust the width as needed
      width: "100%",
    },
  }}
>
  <DialogTitle>Create Notification</DialogTitle>
  <DialogContent>
    <div>
      <InputLabel htmlFor="name">User</InputLabel>
      <Select
        labelId="name-label"
        id="name"
        name="name"
        value={selectedAdmin}
        onChange={handleUserSelect}
        fullWidth
      >
        {teamMembers.map((member) => (
          <MenuItem key={member._id} value={member._id}>
            {member.name} 
            {member.role === "admin" && " (Admin)"}
            {member.role === "superAdmin" && " (Super Admin)"}
          </MenuItem>
        ))}
      </Select>
    </div>
    <div>
      <InputLabel htmlFor="taskDetails">
        Notification Details (can include links)
      </InputLabel>
      <TextareaAutosize
        id="notificationDetails"
        name="notificationDetails"
        value={formData.notificationDetails}
        onChange={handleInputChange}
        minRows={3}
        style={{
          width: "100%",
          maxWidth: "100%",
        }}
      />
    </div>
    <div>
      <InputLabel htmlFor="link">Link (If any)</InputLabel>
      <TextareaAutosize
        id="link"
        name="link"
        value={formData.link}
        onChange={handleInputChange}
        minRows={3}
        style={{
          width: "100%",
          maxWidth: "100%",
        }}
      />
    </div>
  </DialogContent>
  <DialogActions>
    <Button onClick={handleCloseCreateDialog} color="primary">
      Cancel
    </Button>
    <Button onClick={handleCreateTaskSubmit} color="primary">
      Create
    </Button>
  </DialogActions>
</Dialog>
<Dialog
  open={isCreateDialogOpen}
  onClose={handleCloseCreateDialog}
  PaperProps={{
    style: {
      maxWidth: "600px", // Adjust the width as needed
      width: "100%",
    },
  }}
>
  <DialogTitle>Create Notification</DialogTitle>
  <DialogContent>
    <div>
      <InputLabel htmlFor="name">Admin</InputLabel>
      <Select
        labelId="name-label"
        id="name"
        name="name"
        value={selectedAdmin}
        onChange={handleUserSelect}
        fullWidth
      >
        {teamMembers.map((member) => (
          <MenuItem key={member._id} value={member._id}>
            {member.name} 
            {member.isAdmin && " (Admin)"}
            {member.isSuperAdmin && " (Super Admin)"}
          </MenuItem>
        ))}
      </Select>
    </div>
    <div>
      <InputLabel htmlFor="taskDetails">
        Notification Details (can include links)
      </InputLabel>
      <TextareaAutosize
        id="notificationDetails"
        name="notificationDetails"
        value={formData.notificationDetails}
        onChange={handleInputChange}
        minRows={3}
        style={{
          width: "100%",
          maxWidth: "100%",
        }}
      />
    </div>
    <div>
      <InputLabel htmlFor="link">Link (If any)</InputLabel>
      <TextareaAutosize
        id="link"
        name="link"
        value={formData.link}
        onChange={handleInputChange}
        minRows={3}
        style={{
          width: "100%",
          maxWidth: "100%",
        }}
      />
    </div>
  </DialogContent>
  <DialogActions>
    <Button onClick={handleCloseCreateDialog} color="primary">
      Cancel
    </Button>
    <Button onClick={handleCreateTaskSubmit} color="primary">
      Create
    </Button>
  </DialogActions>
</Dialog>

        <h1>Notification</h1>
        <Grid
          container
          spacing={2}
          className="grid-container"
          style={{
            flexDirection: "column",
            width: isMobile ? "100%" : "70%",
            margin: "3px",
          }}
        >
          {data.map((item) => (
            <Grid item key={item._id} className="grid-item">
            <Card>
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginBottom: "20px",
                  }}
                >
                  <Typography variant="h6" style={{ fontWeight: "bold" }}>
                    <Linking
                      onClick={() => {
                        localStorage.setItem("Name", item.name);
                        navigate("/dashboard");
                      }}
                    >
                      {item.name}
                    </Linking>
                  </Typography>
                  <IconButton onClick={() => deleteById(item._id)}>
                    <CloseIcon
                      style={{
                        marginTop: "-40%",
                      }}
                    />
                  </IconButton>
                </div>
                <Typography>{item.comment}</Typography>
                <Typography variant="h5" style={{ marginTop:"1rem"}} color="textSecondary">
              {item.link ? (
                <a href={item.link} target="_blank" style={{ textDecoration: 'none', color: '#1976d2' }}>
                Click here to view
              </a>
              ) : (
                'No link'
              )}
            </Typography>
              </CardContent>
            </Card>
          </Grid>
          ))}
        </Grid>
        <Snackbar
          open={isSnackbarOpen}
          autoHideDuration={3000}
          onClose={() => setIsSnackbarOpen(false)}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert
            severity={
              snackbarMessage.includes("successfully") ? "success" : "error"
            }
            onClose={() => setIsSnackbarOpen(false)}
          >
            {snackbarMessage.includes("successfully") ? (
              <>{snackbarMessage}</>
            ) : (
              <>{snackbarMessage}</>
            )}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
}

export default UserNotification;
import React, { useState, useEffect } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import {
  useMediaQuery,
  IconButton,
  Drawer,
  AppBar,
  Toolbar,
  Typography,
  CircularProgress,
  Select,
  MenuItem,
  TextField,
  Button,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import UserLeftContainer from "../UI/UserLeftContainer";
import { FETCH_LEADS, UPDATE_LEADS } from "../../api/lead";
const NewUserLead = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [leads, setLeads] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingRowId, setEditingRowId] = useState(null);
  const [editValues, setEditValues] = useState({});

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const isMobile = useMediaQuery("(max-width: 768px)");

  useEffect(() => {
    const fetchLeads = async () => {
      try {
        const storedUserId = localStorage.getItem("id");
        const token = localStorage.getItem("token");
        const response = await axios.get(FETCH_LEADS, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const filteredLeads = response.data.filter(
          (lead) => lead.assigned_user && lead.assigned_user.id === storedUserId
        );
        setLeads(filteredLeads);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching leads:", error);
        setLoading(false);
      }
    };

    fetchLeads();
  }, []);

  const handleEditClick = (id, row) => {
    setEditingRowId(id);
    setEditValues(row);
  };

  const handleSaveClick = async (id) => {
    try {
      const token = localStorage.getItem("token");
      const updatedLead = { ...editValues };

      await axios.put(`${UPDATE_LEADS}/${id}`, updatedLead, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setLeads((prevLeads) =>
        prevLeads.map((lead) =>
          lead._id === id ? { ...lead, ...editValues } : lead
        )
      );
      setEditingRowId(null); // Exit editing mode after saving
    } catch (error) {
      console.error("Error updating lead:", error);
    }
  };

  const handleFieldChange = (field, value) => {
    setEditValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  const stageOptions = [
    "1st Call",
    "Message",
    "Explanation Call",
    "Doubt Call",
    "1st Follow Up",
    "2nd Follow Up",
    "Converted",
    "Next Month",
    "Not Interested",
  ];

  const columns = [
    { field: "full_name", headerName: "Name", width: 200, editable: false },
    { field: "email", headerName: "Email", width: 250, editable: false },
    { field: "phone_number", headerName: "Phone Number", width: 150, editable: false },
    {
      field: "stage",
      headerName: "Stage",
      width: 150,
      editable: false,
      renderCell: (params) =>
        params.row._id === editingRowId ? (
          <Select
            value={editValues.stage || params.value}
            onChange={(e) => handleFieldChange("stage", e.target.value)}
          >
            {stageOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        ) : (
          params.value
        ),
    },
    {
      field: "notes",
      headerName: "Notes",
      width: 300,
      editable: false,
      renderCell: (params) =>
        params.row._id === editingRowId ? (
          <TextField
            value={editValues.notes !== undefined ? editValues.notes : params.value}
            onChange={(e) => handleFieldChange("notes", e.target.value)}
            multiline
          />
        ) : (
          params.value
        ),
    },
    { field: "assigned_user.name", headerName: "Assigned User Name", width: 200, editable: false },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) =>
        params.row._id === editingRowId ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleSaveClick(params.row._id)}
          >
            Save
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleEditClick(params.row._id, params.row)}
          >
            Edit
          </Button>
        ),
    },
  ];

  const rows = leads.map((lead) => ({
    ...lead,
    "assigned_user.name": lead.assigned_user?.name || "Unassigned",
  }));

  return (
    <div className="container" style={{ height: "100vh", display: "flex" }}>
      {isMobile ? (
        <>
          <AppBar position="fixed">
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap>
                Welcome, Admin!
              </Typography>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="temporary"
            open={isDrawerOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <UserLeftContainer />
          </Drawer>
        </>
      ) : (
        <UserLeftContainer />
      )}

      <div
        className="right-container"
        style={{
          backgroundColor: "#DAEDE3",
          padding: "20px",
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          height:"95%",
          marginTop: isMobile ? "64px" : "0",
        }}
      >
        <Typography variant="h4" gutterBottom>
          Lead Data
        </Typography>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexGrow: 1,
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <div style={{ height: "100%", width: "100%" }}>
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={5}
              getRowId={(row) => row._id}
              components={{ Toolbar: GridToolbar }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default NewUserLead;
